import { ISetBlockedUserResponse, IGetUsersResponse, ISetUserSubscribeRequest } from '../../types/users/users-dto';
import { IRevenueCatUser, IUser } from '../../types/users/user-entity';
import { IAppState } from '../../state-context';
import { IAction } from '../../actions/action';

interface IGetUsersPayload {
  data: IGetUsersResponse;
  count: number;
  isAdmin: string;
}

interface IGetUsersRequest {
  isAdmin?: boolean;
}

export enum UsersReducerFields {
  ADMINS_COUNT = 'adminsCount',
  COUNT = 'count',
  ADMINS_LOADING = 'adminsLoading',
  LOADING = 'loading',
  ADMINS = 'admins',
  LIST = 'list',
}

export const getUsersRequest = (
  state: IAppState,
  { payload }: IAction<IGetUsersRequest>,
): IAppState => {
  const key = payload.isAdmin ? 'adminsLoading' : 'loading';
  return {
    ...state,
    users: {
      ...state.users,
      [key]: true,
    },
  };
};

export const getUsersSuccess = (
  state: IAppState,
  { payload }: IAction<IGetUsersPayload>,
): IAppState => {
  const { data, count, isAdmin } = payload;
  const key = isAdmin ? UsersReducerFields.ADMINS : UsersReducerFields.LIST;
  const loadingKey = isAdmin ? UsersReducerFields.ADMINS_LOADING : UsersReducerFields.LOADING;
  const countKey = isAdmin ? UsersReducerFields.ADMINS_COUNT : UsersReducerFields.COUNT;
  return {
    ...state,
    users: {
      ...state.users,
      [key]: data,
      [countKey]: count,
      [loadingKey]: false,
    },
  };
};

export const getUsersFailure = (
  state: IAppState,
  { payload }: IAction<IGetUsersRequest>,
): IAppState => {
  const loadingKey = payload.isAdmin
    ? UsersReducerFields.ADMINS_LOADING
    : UsersReducerFields.LOADING;
  return {
    ...state,
    users: {
      ...state.users,
      [loadingKey]: false,
    },
  };
};

export const getUserByIdRequest = (state: IAppState): IAppState => ({
  ...state,
  users: {
    ...state.users,
    currentLoading: true,
  },
});

export const getUserByIdSuccess = (state: IAppState, { payload }: IAction<IUser>): IAppState => ({
  ...state,
  users: {
    ...state.users,
    current: payload,
    currentLoading: false,
  },
});

export const getUserByIdFailure = (state: IAppState): IAppState => ({
  ...state,
  users: {
    ...state.users,
    currentLoading: false,
  },
});

export const getRevenueCatUserByIdSuccess = (state: IAppState, { payload }: IAction<IRevenueCatUser>): IAppState => ({
  ...state,
  users: {
    ...state.users,
    revCatUser: payload,
  },
});

export const setBlockedUserRequest = (state: IAppState): IAppState => ({
  ...state,
  users: {
    ...state.users,
    setBlockedUserLoading: true,
  },
});

export const setBlockedUserSuccess = (
  state: IAppState,
  { payload: { blocked } }: IAction<ISetBlockedUserResponse>,
): IAppState => {
  const { current } = state.users;
  return ({
    ...state,
    users: {
      ...state.users,
      current: !current ? null : { ...current, blocked },
      setBlockedUserLoading: false,
    },
  });
};

export const setBlockedUserFailure = (state: IAppState): IAppState => ({
  ...state,
  users: {
    ...state.users,
    setBlockedUserLoading: false,
  },
});

export const SetUserSubscribeRequest = (state: IAppState): IAppState => ({
  ...state,
});

export const SetUserSubscribeSuccess = (
  state: IAppState,
  { payload: { isSubscribed } }: IAction<ISetUserSubscribeRequest>,
): IAppState => {
  const { current } = state.users;
  return ({
    ...state,
    users: {
      ...state.users,
      current: !current ? null : { ...current, isSubscribed },
    },
  });
};

export const SetUserSubscribeFailure = (state: IAppState): IAppState => {
  return ({
    ...state,
  });
};

export const deleteUserRequest = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    users: {
      ...state.users,
      loading: true,
    },
  };
};

export const deleteUserSuccess = (
  state: IAppState,
  { payload: deletedId }: IAction<number>,
): IAppState => {
  return {
    ...state,
    users: {
      ...state.users,
      list: state.users.list.filter((config) => config.id !== deletedId),
      admins: state.users.admins.filter((config) => config.id !== deletedId),
      loading: false,
    },
  };
};

export const deleteUserFailure = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    users: {
      ...state.users,
      loading: false,
    },
  };
};
