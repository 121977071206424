import { IError } from '../../types/common/index';
import { IUser } from '../../types/users/user-entity';
import { IAppState, initialState } from '../../state-context';
import { IAction } from '../../actions/action';
import { ILoginResponse } from '../../types/auth/auth-dto';

export const authRequest = (state: IAppState): IAppState => ({
  ...state,
  auth: { ...initialState.auth, loading: true },
});

export const authSuccess = (state: IAppState, { payload }: IAction<ILoginResponse>): IAppState => ({
  ...state,
  auth: {
    ...initialState.auth,
    accessToken: payload.accessToken,
    refreshToken: payload.refreshToken,
  },
});

export const authFailure = (state: IAppState, { payload }: IAction<IError>): IAppState => ({
  ...state,
  auth: {
    ...initialState.auth,
    error: payload,
  },
});

export const unauthorizedErrorReceived = (state: IAppState) => ({
  ...state,
  auth: {
    ...state.auth,
    accessToken: null,
  },
});

export const logout = (state: IAppState) => ({
  ...state,
  auth: {
    accessToken: null,
    refreshToken: null,
    loading: false,
    error: null,
    user: null,
  },
});

export const getUserRequest = (state: IAppState): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: true,
  },
});

export const getUserSuccess = (state: IAppState, { payload }: IAction<IUser>): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    user: payload,
    loading: false,
  },
});

export const getUserFailure = (state: IAppState, { payload }: IAction<IError>): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
    error: payload,
  },
});

export const forgotPasswordRequest = (state: IAppState): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: true,
  },
});

export const forgotPasswordSuccess = (state: IAppState): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
  },
});

export const forgotPasswordFailure = (state: IAppState, { payload }): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
    error: payload,
  },
});

export const deleteUserRequest = (state: IAppState): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: true,
  },
});

export const deleteUserSuccess = (state: IAppState): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
  },
});

export const deleteUserFailure = (state: IAppState, { payload }: IAction<IError>): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
    error: payload,
  },
});

export const updateUserInfoRequest = (state: IAppState): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: true,
  },
});

export const updateUserInfoSuccess = (state: IAppState, { payload }:IAction<IUser>): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    user: payload,
    loading: false,
  },
});

export const updateUserInfoFailure = (state: IAppState, { payload }): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
    error: payload,
  },
});

export const updateUserPasswordRequest = (state: IAppState): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: true,
  },
});

export const updateUserPasswordSuccess = (state: IAppState): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
  },
});

export const updateUserPasswordFailure = (state: IAppState, { payload }): IAppState => ({
  ...state,
  auth: {
    ...state.auth,
    loading: false,
    error: payload,
  },
});
