import React from 'react';
import {
  Breadcrumb, Layout, Spin,
} from 'antd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Routes } from '../../router/routes';
import BackButton from '../../components/back-button';
import { useStore } from '../../store';
import useUserData from '../../hooks/use-user-data';
import EditUserContent from './edit-user-content';
import './styles.css';
import { USER } from '../../router/constants';
import i18n from '../../features/utils/i18n';

const EditUser: React.FC = () => {
  useUserData();
  const { id } = useParams<{ id: string }>();
  const { users } = useStore();

  if (users.currentLoading || !users.current) {
    return <Spin className="spinner full-width" />;
  }

  return (
    <Layout className="edit-user">
      <div className="container">
        <div className="header-holder">
          <BackButton path={`${Routes.user}/${id}`} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Routes.users}>{i18n.t('users.users')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${USER}/${id}`}>{i18n.t('users.user')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{i18n.t('common.edit')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        { users.current && <EditUserContent /> }
      </div>
    </Layout>
  );
};

export default EditUser;
