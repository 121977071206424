import { IError } from '../../types/common/index';

import { IAction } from '../../actions/action';
import { IAppState } from '../../state-context';
import { IGetLoansListResponse } from '../../types/loan/loan-dto';
import { ILoan } from '../../types/loan/loan-entity';

export const getLoansRequest = (state: IAppState): IAppState => ({
  ...state,
  loans: {
    ...state.loans,
    loading: true,
  },
});

export const getLoanByIdRequest = (state: IAppState): IAppState => ({
  ...state,
  loans: {
    ...state.loans,
    currentLoading: true,
  },
});

export const getLoanByIdSuccess = (state: IAppState, { payload }: IAction<ILoan>): IAppState => ({
  ...state,
  loans: {
    ...state.loans,
    current: payload,
    currentLoading: false,
  },
});

export const getLoanByIdFailure = (state: IAppState, { payload }: IAction<IError>): IAppState => ({
  ...state,
  loans: {
    ...state.loans,
    currentLoading: false,
    error: payload,
  },
});

export const getLoansSuccess = (
  state: IAppState,
  { payload: { data, count } }: IAction<IGetLoansListResponse>,
): IAppState => ({
  ...state,
  loans: {
    ...state.loans,
    list: data,
    loading: false,
    count,
  },
});

export const getLoansFailure = (state: IAppState): IAppState => ({
  ...state,
  loans: {
    ...state.loans,
    loading: false,
  },
});

export const changeLoanStatusSuccess = (
  state: IAppState,
  { payload }: IAction<ILoan>,
): IAppState => ({
  ...state,
  loans: {
    ...state.loans,
    current: { ...state.loans.current, ...payload },
  },
});

export const addToOwnWatchesRequest = (state: IAppState): IAppState => ({
  ...state,
  loans: {
    ...state.loans,
    loading: true,
  },
});

export const addToOwnWatchesFailure = (
  state: IAppState,
  { payload }: IAction<IError>,
): IAppState => ({
  ...state,
  loans: {
    ...state.loans,
    error: payload,
    loading: false,
  },
});

export const deleteLoanRequest = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    loans: {
      ...state.loans,
      loading: true,
    },
  };
};

export const deleteLoanSuccess = (
  state: IAppState,
  { payload: deletedId }: IAction<number>,
) => {
  return {
    ...state,
    loans: {
      ...state.watchConfigs,
      list: state.loans.list.filter((config) => config.id !== deletedId),
      loading: false,
    },
  };
};

export const deleteLoanFailure = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    loans: {
      ...state.loans,
      loading: false,
    },
  };
};