import React, { useEffect } from 'react';
import {
  Button, Empty, Layout, Spin,
} from 'antd';
import { Link, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import SubscriptionCard from '../../components/subscription-card';
import { useActions, useStore } from '../../store';
import './styles.css';
import { EDIT_SUBSCRIPTION } from '../../router/constants';
import i18n from '../../features/utils/i18n';

const Subscriptions = () => {
  const { subscriptionsActions } = useActions();
  const { subscriptions } = useStore();
  const { pathname } = useLocation();

  useEffect(() => {
    subscriptionsActions.getSubscriptions();
  }, []);

  if (subscriptions.loading) {
    return <Spin className="spinner full-width" />;
  }

  return (
    <Layout className="subscriptions">
      <div className="container">
        <div className="header-holder">
          <div className="page-title__holder">
            <h1 className="subscriptions__title">{i18n.t('subscriptions.subscription')}</h1>
          </div>
        </div>
        { !isEmpty(subscriptions.list) ? subscriptions.list.map((subscription) => (
          <div className="subscriptions__item" key={subscription.id}>
            <SubscriptionCard data={subscription} />
            <Link
              key={subscription.id}
              to={{ pathname: `${EDIT_SUBSCRIPTION}/${subscription.id}`, state: { prevPath: pathname } }}
            >
              <Button className="subscriptions__edit-btn">{i18n.t('common.edit')}</Button>
            </Link>
          </div>
        )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={i18n.t('subscriptions.noSubscriptions')} />}
      </div>
    </Layout>
  );
};

export default Subscriptions;
