import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Select } from 'antd';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import WatchStatus from '../../constants/watch-status';
import { useActions, useStore } from '../../store';

import IOwnWatchEntity from '../../store/types/own-watches/own-watch-entity';
import getWatchStatus from '../../utility/get-watch-status-data';
import UserCard from '../user-card';
import './styles.css';
import { IWatch } from '../../store/types/watch/watch-entity';
import { IUser } from '../../store/types/users/user-entity';
import { getPropertyWatchName, watchWhiteListProp } from '../../utility/get-watch-data';
import { EDIT_USER_WATCH, LOAN } from '../../router/constants';
import { FormCases } from '../edit-user-watch-form/utils';
import { ILoan } from '../../store/types/loan/loan-entity';
import LoanStatus from '../../constants/loan-status';
import i18n from '../../features/utils/i18n';

interface IWatchFeatureProps {
  watch: IOwnWatchEntity | IWatch;
  loans?: [],
  isOwn?: boolean;
}

const { Option } = Select;

const WatchFeatures: React.FC<IWatchFeatureProps> = ({ isOwn, watch, loans = [] }) => {
  const { ownWatchesActions } = useActions();
  const { t } = useTranslation();
  const { ownWatches } = useStore();
  const [watchStatus, setWatchStatus] = useState(watch?.status);

  const { location, push } = useHistory();
  const currentUrl = `${location.pathname}${location.search}`;

  if (!watch) {
    return null;
  }
  const { statusColor, status } = getWatchStatus(watch);

  const formatStatus = (str: string) => ((str === 'in process' || str === 'for sale') ? str : str.replace(/\s/g, ','));

  const handleStatusChange = (value: string) => {
    setWatchStatus(value);
    if (watch?.id && value) {
      ownWatchesActions.changeOwnWatchStatus({ id: watch?.id, status: value });
    }
  };
  const redirectToLoanPage = (loanId) => {
    push({ pathname: `${LOAN}/${loanId}/` }, { prevPath: currentUrl });
  };
  const redirectToEditPage = () => {
    push({ pathname: `${EDIT_USER_WATCH}/${watch?.id}/` }, { prevPath: currentUrl });
  };

  const user: IUser | null = get(watch, 'user', null);
  return (
    <div className="watch-features">
      {isOwn ? (
        <div className="watch-features__header--own-watch">
          <div className="watch-features__header--column">
            <div className="watch-features__model">
              <div>{getPropertyWatchName(watch, watchWhiteListProp.model)}</div>
              { watch.status !== WatchStatus.SOLD
                && <Button className="button--ghost--gray" onClick={redirectToEditPage}>{i18n.t('common.edit')}</Button>}
            </div>
            <div className="watch-features__brand">{watch?.brand?.name}</div>
            <Select
              onSelect={handleStatusChange}
              value={watchStatus}
              defaultValue={watchStatus}
              style={{ width: 110 }}
              loading={ownWatches.currentLoading}
            >
              <Option key={WatchStatus.FOR_SALE} value={WatchStatus.FOR_SALE}>{i18n.t('watches.forSale')}</Option>
              <Option key={WatchStatus.SOLD} value={WatchStatus.SOLD}>{i18n.t('watches.sold')}</Option>
            </Select>
          </div>
          <Divider type="vertical" className="watch-features__header--divider" />
          {watch && (
            <div className="watch-features__header--column">
              <UserCard user={user} hideButtons />
            </div>
          )}
        </div>
      ) : (
        <div className="watch-features__header">
          <div className="watch-features__model">
            <div>{getPropertyWatchName(watch, watchWhiteListProp.model)}</div>
            { watch.status !== WatchStatus.SOLD
                && <Button className="button--ghost--gray" onClick={redirectToEditPage}>{i18n.t('common.edit')}</Button>}
          </div>
          <div className="watch-features__brand">{watch?.brand?.name}</div>
          <div className={`watch-features__status ${statusColor}`}>{formatStatus(status)}</div>
          {
            watch.evaluatedAt && (
              <div className="watch-features__evaluation-date">
                {i18n.t('watches.evaluatedOn')}
                {`${moment(watch.evaluatedAt).format('D MMM, YYYY')}`}
              </div>
            )
          }
          {
            loans.map((loan: ILoan) => (loan.status === LoanStatus.ACTIVE || loan.status === LoanStatus.IN_REVIEW)
              && (
                <div key={loan.id} className="watch-features__evaluation-date">
                  includedIn
                  {' '}
                  <div className="ant-btn-link" onClick={(e) => redirectToLoanPage(loan.id)}>{i18n.t('watches.loan')}</div>
                </div>
              ))
          }
        </div>
      )}
      <div className="watch-features__content">
        <div className="watch-features__list">
          <div className="watch-features__list--column">
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.boxAndPapersWF')}</div>
              <div className="watch-features__value">{watch?.boxAndPapers?.name}</div>
            </div>
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.conditionWF')}</div>
              <div className="watch-features__value">{watch?.condition?.name}</div>
            </div>
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.caseMetalWF')}</div>
              <div className="watch-features__value">{getPropertyWatchName(watch, watchWhiteListProp.caseMetal)}</div>
            </div>
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.caseTypeWF')}</div>
              <div className="watch-features__value">{getPropertyWatchName(watch, watchWhiteListProp.form)}</div>
            </div>
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.caseDiameterWF')}</div>
              <div className="watch-features__value">{watch?.caseDiameter?.name}</div>
            </div>
            {(watch?.formId === FormCases.RECTANGLE
            || watch?.formId === FormCases.TONNEAU) && (
              <div className="watch-features__item">
                <div className="watch-features__name">{i18n.t('watches.caseWidth')}</div>
                <div className="watch-features__value">{watch?.notListedInfo.customWidth}</div>
              </div>
            )}
            {(watch?.formId === FormCases.RECTANGLE
            || watch?.formId === FormCases.TONNEAU) && (
              <div className="watch-features__item">
                <div className="watch-features__name">{i18n.t('watches.caseHeight')}</div>
                <div className="watch-features__value">{watch?.notListedInfo.customHeight}</div>
              </div>
            )}
            {(watch?.formId === FormCases.SQUARE) && (
              <div className="watch-features__item">
                <div className="watch-features__name">{i18n.t('watches.sideLengthWF')}</div>
                <div className="watch-features__value">{watch?.notListedInfo.customWidth}</div>
              </div>
            )}
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.dialColorWF')}</div>
              <div className="watch-features__value">{getPropertyWatchName(watch, watchWhiteListProp.dialColor)}</div>
            </div>
          </div>
          <div className="watch-features__list--column">
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.strapWF')}</div>
              <div className="watch-features__value">{getPropertyWatchName(watch, watchWhiteListProp.strap) !== 'other' ? getPropertyWatchName(watch, watchWhiteListProp.strap) : ''}</div>
            </div>
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.braceletWF')}</div>
              <div className="watch-features__value">{getPropertyWatchName(watch, watchWhiteListProp.bracelet) !== 'other' ? getPropertyWatchName(watch, watchWhiteListProp.bracelet) : ''}</div>
            </div>
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.buckleType')}</div>
              <div className="watch-features__value">{getPropertyWatchName(watch, watchWhiteListProp.buckle)}</div>
            </div>
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.tier')}</div>
              <div className="watch-features__value">{watch?.tier?.name || ''}</div>
            </div>
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.referenceNumberWF')}</div>
              <div className="watch-features__value">{watch?.referenceNumber}</div>
            </div>
            <div className="watch-features__item">
              <div className="watch-features__name">{i18n.t('watches.complicationWF')}</div>
              <div className="watch-features__value">{getPropertyWatchName(watch, watchWhiteListProp.complication)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchFeatures;
