import React, { useCallback } from 'react';
import { Upload, Button, message } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import './styles.css';
import { RcFile } from 'antd/lib/upload';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { useActions } from '../../store';
import i18n from '../../features/utils/i18n';

const AvatarUploader = () => {
  const { usersActions, authActions } = useActions();

  const beforeUpload = useCallback((file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(i18n.t('profile.onlyJPGPNG'));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(i18n.t('profile.mustBeSmaller'));
    }
    return isJpgOrPng && isLt2M;
  }, []);

  const customRequest = async ({ file }: RcCustomRequestOptions) => {
    try {
      const extension = file.type.split('/')[1];
      const credentials = await usersActions.getAWSCredentials(extension);
      if (credentials) {
        const avatar = await usersActions.uploadAvatar(file, credentials);
        return avatar && authActions.updateUserInfo({ avatar });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Upload
      multiple={false}
      className="avatar-uploader"
      beforeUpload={beforeUpload}
      customRequest={customRequest}
    >
      <Button icon={<PlusCircleFilled />} />
    </Upload>
  );
};

export default AvatarUploader;
