import { IError } from '../../types/common/index';
import { IAction } from '../../actions/action';
import { IAppState } from '../../state-context';
import { IGetSubscriptionsListResponse } from '../../types/subscription/subscription-dto';
import { ISubscription } from '../../types/subscription/subscription-entity';

export const getSubscriptionsRequest = (state: IAppState): IAppState => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    loading: true,
  },
});

export const getSubscriptionsSuccess = (
  state: IAppState,
  { payload }: IAction<IGetSubscriptionsListResponse>,
): IAppState => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    list: payload,
    loading: false,
  },
});

export const getSubscriptionsFailure = (
  state: IAppState, { payload }: IAction<IError>,
): IAppState => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    loading: false,
    error: payload,
  },
});

export const getSubscriptionByIdRequest = (state: IAppState): IAppState => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    currentLoading: true,
  },
});

export const getSubscriptionByIdSuccess = (
  state: IAppState, { payload }: IAction<ISubscription>,
): IAppState => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    current: payload,
    currentLoading: false,
  },
});

export const getSubscriptionByIdFailure = (
  state: IAppState, { payload }: IAction<IError>,
): IAppState => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    loading: false,
    error: payload,
  },
});

export const updateSubscriptionByIdRequest = (state: IAppState): IAppState => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    currentLoading: true,
  },
});

export const updateSubscriptionByIdSuccess = (
  state: IAppState, { payload }: IAction<ISubscription>,
): IAppState => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    current: payload,
    currentLoading: false,
  },
});

export const updateSubscriptionByIdFailure = (
  state: IAppState, { payload }: IAction<IError>,
): IAppState => ({
  ...state,
  subscriptions: {
    ...state.subscriptions,
    currentLoading: false,
    error: payload,
  },
});
