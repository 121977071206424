export const takeResultsPerPage = 15;

export enum SocialNetworkTypes {
  google = 'google',
  facebook = 'facebook',
  amazon = 'amazon',
}

export enum SocialNetworkKeys {
  google = 'ggl',
  facebook = 'fb',
  amazon = 'amz',
}

export const SubscriptionTypes = {
  premium: 'Premium',
};

export enum Roles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum RoutingNumberType {
  ABA = 'ABA',
  SWIFT = 'SWIFT',
}

export enum NotificationsType {
  USER_SENT_WATCH_FOR_EVALUATING = 'USER_SENT_WATCH_FOR_EVALUATING',
  USER_SENT_WATCHES_FOR_EVALUATING = 'USER_SENT_WATCHES_FOR_EVALUATING',
  USER_COMPLETES_FIN_AGREEMENT = 'USER_COMPLETES_FIN_AGREEMENT',
  USER_CREATES_FIN_AGREEMENT = 'USER_CREATES_FIN_AGREEMENT',
  USER_BOUGHT_SUBSCRIPTION = 'USER_BOUGHT_SUBSCRIPTION',
  USER_INVITE_PARTNER = 'USER_INVITE_PARTNER',
  ADMIN_ADDED_NEW_PARTNER = 'ADMIN_ADDED_NEW_PARTNER',
  ADMIN_ADDED_USER_TO_PARTNER = 'ADMIN_ADDED_USER_TO_PARTNER',
  ADMIN_REMOVED_USER_TO_PARTNER = 'ADMIN_REMOVED_USER_TO_PARTNER',
  ADMIN_CHANGED_SUBSCRIPTION_CONFIG = 'ADMIN_CHANGED_SUBSCRIPTION_CONFIG',
  ADMIN_CHANGED_FIN_AGREEMENT_TERMS = 'ADMIN_CHANGED_FIN_AGREEMENT_TERMS',
  ADMIN_CHANGED_WATCH_CONFIG = 'ADMIN_CHANGED_WATCH_CONFIG',
  ADMIN_CHANGED_USER_PROFILE = 'ADMIN_CHANGED_USER_PROFILE',
  ADMIN_ADDED_NEW_ADMIN = 'ADMIN_ADDED_NEW_ADMIN',
  ADMIN_ADDED_WATCH_CONFIG = 'ADMIN_ADDED_WATCH_CONFIG',
  ADMIN_UNBLOCKED_USER = 'ADMIN_UNBLOCKED_USER',
  ADMIN_CLOSED_FIN_AGREEMENT = 'ADMIN_CLOSED_FIN_AGREEMENT',
  ADMIN_BLOCKED_USER = 'ADMIN_BLOCKED_USER',
  ADMIN_APPRAISED_WATCH = 'ADMIN_APPRAISED_WATCH',
  ADMIN_EDITED_WATCH = 'ADMIN_EDITED_WATCH',
  USER_BUYBACK_WATCH = 'USER_BUYBACK_WATCH'
}

export enum PhoneMask {
  TEN_DIGITS = '(###) ###-##-##',
}
