import React from 'react';
import { Card, Spin } from 'antd';
import get from 'lodash/get';

import './style.css';
import { IWatch } from '../../store/types/watch/watch-entity';
import getWatchStatus from '../../utility/get-watch-status-data';
import defaultWatchImage from '../../assets/images/default-watch-image.svg';
import { getPropertyWatchName, watchWhiteListProp } from '../../utility/get-watch-data';

interface IWatchItemProps {
  watch: IWatch;
  isLoading?: boolean;
}

const WatchItem: React.FC<IWatchItemProps> = ({ watch, isLoading }) => {
  const { statusColor, status } = getWatchStatus(watch);

  const watchData = {
    brand: watch?.brand,
    model: getPropertyWatchName(watch, watchWhiteListProp.model),
    src: get(watch, 'watchMedia[0].url'),
    status,
    statusColor,
  };

  return (
    <div className="item__wrapper">
      <Card
        bordered={false}
        cover={<img alt="" src={watchData.src || defaultWatchImage} />}
        className="item__card"
      >
        <p className="item__text item__model">{getPropertyWatchName(watch, watchWhiteListProp.model)}</p>
        <p className="item__text item__brand">{watch?.brand?.name}</p>
        {isLoading ? <Spin /> : <p className={`item__text item__status ${watchData.statusColor}`}>{watchData.status}</p>}
      </Card>
    </div>
  );
};

export default WatchItem;
