import React from 'react';
import { Empty, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router';

import { IUser } from '../../store/types/users/user-entity';
import { useActions, useStore } from '../../store';
import { USER } from '../../router/constants';


interface IUsersList {
  list: Array<IUser>;
  columns: ColumnsType<IUser>;
}

const UsersListTable = ({ list, columns}: IUsersList) => {
  const { location, push } = useHistory();
  const { users, ui } = useStore();
  const { usersActions } = useActions();
  const currentUrl = `${location.pathname}${location.search}`;
  const listWithKeys = list.map((item, i) => ({ ...item, key: i }));

  const handleRowClick = (data: IUser): void => {
    return push({ pathname: `${USER}/${data.id}` }, { prevPath: currentUrl });
  };

  const isLoading = users.loading || users.adminsLoading;
  const isListEmpty = ui.activeUsersTab !== 'admins' ? !users.list.length : !users.admins.length;

  if (isLoading) {
    return <Spin className="spinner" />;
  }

  if (!isLoading && isListEmpty) {
    return <Empty className="empty-list--min-height" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Table
      columns={columns}
      onRow={(data) => ({onClick: () => handleRowClick(data)})}
      dataSource={listWithKeys}
      className="custom-table custom-table--min-height"
      scroll={{ x: true }}
      sticky
      bordered
      pagination={false}
    />
  );
};

export default UsersListTable;
