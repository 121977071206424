import { IValuationValues, IValuationValue } from '../../store/types/valuation/valuation-dto';

export const validateValuationForm = (
  values: IValuationValues, t,
) => {
  return Object.keys(values).reduce((errors, id) => {
    const { minCost, maxCost }: IValuationValue = values[id];

    if (minCost === null && maxCost === null) {
      return { ...errors };
    }

    if (minCost <= 0) {
      return {
        ...errors,
        [id]: {
          ...errors[id],
          minCost: t('validation.minCost'),
        },
      };
    }

    if (maxCost <= 0) {
      return {
        ...errors,
        [id]: {
          ...errors[id],
          maxCost: t('validation.maxCost'),
        },
      };
    }

    if (minCost >= maxCost) {
      return {
        ...errors,
        [id]: {
          ...errors[id],
          minCost: t('validation.minCostLower'),
        },
      };
    }

    if (!minCost) {
      return {
        ...errors,
        [id]: {
          ...errors[id],
          minCost: t('validation.minCostEmpty'),
        },
      };
    }

    if (!maxCost) {
      return {
        ...errors,
        [id]: {
          ...errors[id],
          maxCost: t('validation.maxCostEmpty'),
        },
      };
    }

    if (minCost < 0) {
      return {
        ...errors,
        [id]: {
          ...errors[id],
          minCost: t('validation.minCostPositive'),
        },
      };
    }

    if (maxCost < 0) {
      return {
        ...errors,
        [id]: {
          ...errors[id],
          minCost: t('validation.maxCostPositive'),
        },
      };
    }
    return { ...errors };
  }, {});
};
