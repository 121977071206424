import {
  IsOptional, IsPositive, Max, Min,
} from 'class-validator';

import { IsMaxValueGreaterThanMin } from '../../utility/validation/match-validator';
import i18n from '../../features/utils/i18n';

class ValuateWatch {
  public constructor(minCost = 0, maxCost: 0, description: '') {
    this.minCost = minCost;
    this.maxCost = maxCost;
    this.description = description;
  }

  @Min(1, {
    message: i18n.t('validation.minValueIs'),
  })
  @Max(1000000, {
    message: i18n.t('validation.maxValueIs'),
  })
  @IsPositive()
  minCost: number;

  @Min(1, {
    message: i18n.t('validation.minValueIs'),
  })
  @Max(1000000, {
    message: i18n.t('validation.maxValueIs'),
  })
  @IsPositive()
  @IsMaxValueGreaterThanMin('minCost')

  maxCost: number;

  @IsOptional()
  description: string;
}

export default ValuateWatch;
