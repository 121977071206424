import Axios, {
  AxiosInstance, AxiosResponse, Method, ResponseType,
} from 'axios';
import { Dispatch } from 'react';

import { IAction } from '../../actions/action';
import { AuthActionType } from '../../actions/actions-types';

export interface IRequestMethodParams<P> {
  url?: string;
  params?: object;
  data?: P;
  headers?: object;
  responseType?: ResponseType;
  baseURL? : string;
  withToken?: boolean;
}

interface IRequestParamsWithMethod<P> extends IRequestMethodParams<P> {
  method: Method;
}

export interface IApiService {
  get<T>(requestParams: IRequestMethodParams<undefined>): Promise<T>;

  post<P, T>(requestParams: IRequestMethodParams<P>): Promise<T>;

  put<P, T>(requestParams: IRequestMethodParams<P>): Promise<T>;

  patch<P, T>(requestParams: IRequestMethodParams<P>): Promise<T>;

  delete<P, T>(requestParams: IRequestMethodParams<P>): Promise<T>;
}

export class ApiService implements IApiService {
  protected readonly axios: AxiosInstance;

  protected readonly dispatch: Dispatch<IAction>;

  constructor(baseURL: string, dispatch: Dispatch<IAction>) {
    this.dispatch = dispatch;
    this.axios = Axios.create({ baseURL });
  }

  public async get<T>(requestParams: IRequestMethodParams<undefined>): Promise<T> {
    return this.request<undefined, T>({ method: 'get', ...requestParams });
  }

  public async post<P, T>(requestParams: IRequestMethodParams<P>): Promise<T> {
    return this.request<P, T>({ method: 'post', ...requestParams });
  }

  public async put<P, T>(requestParams: IRequestMethodParams<P>): Promise<T> {
    return this.request<P, T>({ method: 'put', ...requestParams });
  }

  public async patch<P, T>(requestParams: IRequestMethodParams<P>): Promise<T> {
    return this.request<P, T>({ method: 'patch', ...requestParams });
  }

  public async delete<P, T>(requestParams: IRequestMethodParams<P>): Promise<T> {
    return this.request<P, T>({ method: 'delete', ...requestParams });
  }

  private async request<P, T>(requestParams: IRequestParamsWithMethod<P>): Promise<T> {
    try {
      const {
        data, headers, method, params, url, responseType, baseURL, withToken = true,
      } = requestParams;
      const accessToken = localStorage.getItem('accessToken');
      let customHeaders = { ...headers };
      if (accessToken && withToken) {
        customHeaders = {
          ...customHeaders,
          Authorization: `Bearer ${accessToken}`,
        };
      }
      const response: AxiosResponse = await this.axios.request({
        data,
        headers: customHeaders,
        method,
        params,
        url,
        responseType,
        baseURL,
      });
      return response.data as T;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.dispatch({
          type: AuthActionType.UnauthorizedErrorReceived,
          payload: null,
        });
      }
      return Promise.reject(error.response.data);
    }
  }
}

export default ApiService;
