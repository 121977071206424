import React, { useEffect } from 'react';
import { Breadcrumb, Layout } from 'antd';
import { Link } from 'react-router-dom';
import BackButton from '../../components/back-button';
import WatchForm from '../../components/watch-form';
import { IWatchFormValues } from '../../components/watch-form/utils';
import i18n from '../../features/utils/i18n';
import { useBackPath } from '../../hooks/use-back-path';
import { Routes } from '../../router/routes';
import { useActions, useStore } from '../../store';

import './style.css';

const { Content } = Layout;

const AddWatchPage = () => {
  const { watchConfigsActions } = useActions();
  const backPath = useBackPath(Routes.watches);
  const { auth: { user } } = useStore();

  useEffect(() => {
    watchConfigsActions.getWatchParameters();
  }, []);

  const onSubmit = (values: IWatchFormValues) => {
    if (
      /* values.caseSizeId
      && */values.caseMetalId
      && values.formId
      && values.dialColorId
      && values.buckleId
      && values.tierId
    ) {
      const data = {
        newBrand: !values.brandId ? values.brandAutoComplete : '',
        newModel: !values.modelId ? values.modelAutoComplete : '',
        newCaseDiameter: !values.caseDiameterId ? values.caseDiameterAutoComplete : '',
        brandId: values.brandId,
        modelId: values.modelId,
        caseSizeId: values.caseSizeId,
        caseMetalId: values.caseMetalId,
        caseDiameterId: values.caseDiameterId,
        formId: values.formId,
        dialColorId: values.dialColorId,
        strapId: values.strapId,
        braceletId: values.braceletId,
        buckleId: values.buckleId,
        tierId: values.tierId,
        minCost: values.minCost ? values.minCost : null,
        maxCost: values.maxCost ? values.maxCost : null
      };
      watchConfigsActions.create(data);
    }
  };

  return (
    <Layout className="add-watch-page">
      <header>
        <BackButton path={backPath} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={backPath}>{i18n.t('watches.watches')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{i18n.t('watches.addingWatch')}</Breadcrumb.Item>
        </Breadcrumb>
      </header>
      <Content>
        <div className="add-watch-page__form-wrapper">
          <WatchForm onSubmit={onSubmit} />
        </div>
      </Content>
    </Layout>
  );
};

export default AddWatchPage;
