import { Dispatch } from 'react';

import { ITemplate } from '../../types/template/template-entity';
import { IGetTemplateRequest, IGetTemplateListResponse } from '../../types/template/template-dto';
import { TemplatesActionType } from '../actions-types';
import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';

interface IAddTemplateData {
  content: string,
  isDuplicated?: boolean
}
export interface ITemplatesActions {
  getTemplates: () => Promise<void>;
  addTemplate: (data: IAddTemplateData) => Promise<void>;
  updateTemplate: (data: ITemplate) => Promise<void>;
  deleteTemplate: (data: IGetTemplateRequest) => Promise<void>;
}

export class TemplatesActions implements ITemplatesActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService) {
    this.dispatch = dispatch;
    this.api = api;
  }

  public getTemplates = async (): Promise<void> => {
    try {
      this.dispatch({ type: TemplatesActionType.GetTemplatesListRequest, payload: null });
      const response: IGetTemplateListResponse = await this.api.get({
        url: 'text-template',
      });
      this.dispatch({
        type: TemplatesActionType.GetTemplatesListSuccess,
        payload: response,
      });
    } catch (error) {
      this.dispatch({ type: TemplatesActionType.GetTemplatesListFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public addTemplate = async ({ content, isDuplicated }: IAddTemplateData): Promise<void> => {
    try {
      this.dispatch({ type: TemplatesActionType.AddTemplateRequest, payload: null });
      const response: ITemplate = await this.api.post({
        url: 'text-template',
        data: { content },
      });
      this.dispatch({
        type: TemplatesActionType.AddTemplateSuccess,
        payload: response,
      });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: `Template was ${isDuplicated ? 'duplicated' : 'saved'}`,
      });
    } catch (error) {
      this.dispatch({ type: TemplatesActionType.AddTemplateFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public updateTemplate = async ({ id, content }: ITemplate): Promise<void> => {
    try {
      this.dispatch({ type: TemplatesActionType.UpdateTemplateRequest, payload: null });
      const response: ITemplate = await this.api.patch({
        url: `text-template/${id}`,
        data: { content },
      });
      this.dispatch({
        type: TemplatesActionType.UpdateTemplateSuccess,
        payload: response,
      });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: 'Template was updated',
      });
    } catch (error) {
      this.dispatch({ type: TemplatesActionType.UpdateTemplateFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public deleteTemplate = async ({ id }: IGetTemplateRequest): Promise<void> => {
    try {
      this.dispatch({ type: TemplatesActionType.DeleteTemplateRequest, payload: null });
      await this.api.delete({
        url: `text-template/${id}`,
      });
      this.dispatch({
        type: TemplatesActionType.DeleteTemplateSuccess,
        payload: { id },
      });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: 'Template was deleted',
      });
    } catch (error) {
      this.dispatch({ type: TemplatesActionType.DeleteTemplateFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };
}

export default TemplatesActions;
