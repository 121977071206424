import { Dispatch } from 'react';

import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import { LoanActionType } from '../actions-types';
import { ILoan } from '../../types/loan/loan-entity';
import formatDate from '../../../utility/date/format-date';
import buildQueryString from '../../../utility/build-query-string';
import LoanStatus from '../../../constants/loan-status';
import { IGetLoansListQueryParams } from '../../types/loan/loan-dto';
import { IPushTo } from '../../../router/types';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';
import { LOANS } from '../../../router/constants';
import { IDeleteResult } from '../../types/common';
import i18n from '../../../features/utils/i18n';

export interface ILoansActions {
  getLoans: (payload: IGetLoansListQueryParams) => Promise<void>;
  getLoanById: (id: number) => Promise<void>;
  changeLoanStatus: (id: number, status: LoanStatus) => Promise<void>;
  getContractPreviewUrl: (id: number) => Promise<string>;
  addToOwnWatches: (id: number) => Promise<void>;
  getByWatchId: (watchId: number) => Promise<void>;
  delete: (id: number) => Promise<void>;
}
export class LoansActions implements ILoansActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  protected readonly pushTo: IPushTo;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService, pushTo: IPushTo) {
    this.dispatch = dispatch;
    this.api = api;
    this.pushTo = pushTo;
  }

  public getLoans = async (payload: IGetLoansListQueryParams): Promise<void> => {
    try {
      this.dispatch({type: LoanActionType.GetLoansRequest, payload: null});
      let queryParams = {...payload};
      if (payload.dateFrom && payload.dateTo) {
        queryParams = {
          ...queryParams,
          dateFrom: formatDate(payload.dateFrom),
          dateTo: formatDate(payload.dateTo),
        };
      }
      const queryString = buildQueryString(queryParams);
      if (queryString) {
        this.pushTo(`${LOANS}/?${queryString}`);
      }

      const result: ILoan = await this.api.get({
        url: `loan/admin/gets-all?${queryString}`,
      });
      this.dispatch({
        type: LoanActionType.GetLoansSuccess,
        payload: result,
      });
    } catch (error) {
      this.dispatch({type: LoanActionType.GetLoansFailure, payload: error});
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getByWatchId = async (id: number): Promise<void> => {
    try {
      const response: ILoan = await this.api.get({
        url: `/loan/admin/by-watch/${id}`,
      });
      this.dispatch({
        type: LoanActionType.GetLoansByWatchSuccess,
        payload: response,
      });

    } catch (error) {
      this.dispatch({type: LoanActionType.GetLoansByWatchFailure, payload: error});
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public delete = async (id: number): Promise<void> => {
    try {
      this.dispatch({type: LoanActionType.DeleteLoanRequest, payload: {id}});
      const {id: deletedId}: IDeleteResult = await this.api.delete({url: `/loan/admin/${id}`});
      this.dispatch({type: LoanActionType.DeleteLoanSuccess, payload: deletedId});
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: i18n.t('notifications.deleteLoan'),
      });
    } catch (error) {
      this.dispatch({type: LoanActionType.GetLoansByWatchFailure, payload: error});
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.deleteLoanError'),
      });
    }
  };

  public getLoanById = async (id: number): Promise<void> => {
    try {
      this.dispatch({type: LoanActionType.GetLoanByIdRequest, payload: {id}});
      const response: ILoan = await this.api.get({
        url: `/loan/admin/single/${id}`,
      });
      this.dispatch({
        type: LoanActionType.GetLoanByIdSuccess,
        payload: response,
      });
    } catch (error) {
      this.dispatch({type: LoanActionType.GetLoanByIdFailure, payload: error});
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public changeLoanStatus = async (id: number, status: LoanStatus) => {
    try {
      const response: ILoan = await this.api.patch({
        url: `/loan/admin/change-status/${id}/${status}`,
      });
      this.dispatch({
        type: LoanActionType.ChangeLoanStatusSuccess,
        payload: response,
      });
      toastNotification({type: ToastNotificationTypes.SUCCESS, message: i18n.t('notifications.statusChanged')});
    } catch (error) {
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public async getContractPreviewUrl(id: number) {
    const {url}: { url: string } = await this.api.get({
      url: `/loan/admin/generate-doc/${id}`,
    });
    return url;
  }

  public addToOwnWatches = async (id: number) => {
    try {
      this.dispatch({
        type: LoanActionType.AddToOwnWatchesRequest,
        payload: null,
      });
      await this.api.patch({
        url: `/loan/admin/add-to-own-watches/${id}`,
      });
      this.dispatch({
        type: LoanActionType.AddToOwnWatchesSuccess,
        payload: null,
      });
      this.getLoanById(id);
    } catch (error) {
      this.dispatch({
        type: LoanActionType.AddToOwnWatchesFailure,
        payload: error,
      });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };
};