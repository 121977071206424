import React, { useCallback, useMemo, useState } from 'react';
import get from 'lodash/get';
import { Pagination, Spin } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { useActions, useStore } from '../../store';
import LoanStatus from '../../constants/loan-status';
import WatchItem from '../watch-item';
import LoanInfo from './LoanInfo';

import './style.css';
import { USER_WATCH } from '../../router/constants';

const PAGE_SIZE = 6;

const LoansDetails = () => {
  const {
    loans: { current, currentLoading },
  } = useStore();
  const { pathname } = useLocation();
  const [currentPage, setPage] = useState(1);
  const { loansActions } = useActions();
  const watches = get(current, 'watches', []);

  const paginatedWatches = useMemo(() => {
    const offset = currentPage === 1 ? 0 : (currentPage - 1) * 6;
    const end = offset + PAGE_SIZE;
    return watches.slice(offset, end);
  }, [currentPage]);

  const handleChangeStatus = useCallback(
    (status: LoanStatus) => {
      if (current) {
        loansActions.changeLoanStatus(current.id, status);
      }
    },
    [current],
  );

  const onPageChange = useCallback((page: number) => {
    setPage(page);
  }, []);

  if (!current) {
    return <Spin className="full-width" />;
  }

  return (
    <div className="loan-details">
      <div className="loan-details__watches-wrapper">
        {currentLoading ? <Spin />
          : (
            <div className="loan-details__watches-list">
              {paginatedWatches.map((watch) => (
                <Link
                  key={watch.id}
                  to={{ pathname: `${USER_WATCH}/${watch.id}`, state: { prevPath: pathname } }}
                >
                  <WatchItem key={watch.id} watch={watch} isLoading={currentLoading} />
                </Link>
              ))}
            </div>
          )}
        {watches.length > PAGE_SIZE && (
          <div className="pagination-wrapper">
            <Pagination
              current={currentPage}
              defaultCurrent={currentPage}
              defaultPageSize={PAGE_SIZE}
              total={watches.length}
              onChange={onPageChange}
            />
          </div>
        )}
      </div>
      <div className="loan-details__info-wrapper">
        <LoanInfo loan={current} handleStatusChange={handleChangeStatus} />
      </div>
    </div>
  );
};

export default LoansDetails;
