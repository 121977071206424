import React from 'react';
import { useParams } from 'react-router';
import { Breadcrumb, Layout, Spin } from 'antd';

import useUserData from '../../hooks/use-user-data';
import BackButton from '../../components/back-button';
import { Routes } from '../../router/routes';
import { useStore } from '../../store';
import './styles.css';
import { RoutingNumberType } from '../../constants';
import { USER } from '../../router/constants';
import i18n from '../../features/utils/i18n';
import { Link } from 'react-router-dom';

const BankDetails: React.FC = () => {
  useUserData();
  const { id } = useParams<{ id: string }>();
  const { users: { current, currentLoading } } = useStore();
  const {
    name, 
    addressLine1, 
    addressLine2, 
    city, 
    state, 
    country, 
    postCode,
    bankName, 
    bankAccountNumber, 
    bankRoutingNumber, 
    routingNumberType, 
    bankAddressLine1, 
    bankAddressLine2,
    bankCity, 
    bankState, 
    bankCountry, 
    bankPostCode,
  } = current?.bankDetails || {};

  if (currentLoading || !current) {
    return <Spin className="spinner full-width" />;
  }

  return (
    <Layout className="bank-details">
      <div className="container">
        <div className="header-holder">
          <BackButton path={`${Routes.user}/${id}`} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Routes.users}>{i18n.t('users.users')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${USER}/${id}`}>{i18n.t('users.user')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{i18n.t('valuations.bankDetails')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        { current
          && (
            <div className="bank-details__wrapper">
              <div className="bank-details__column">
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.firstLastName')}</p>
                  <p className="bank-details__item">{name}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.addressLine1')}</p>
                  <p className="bank-details__item">{addressLine1}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.addressLine2')}</p>
                  <p className="bank-details__item">{addressLine2}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.state')}</p>
                  <p className="bank-details__item">{state}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.country')}</p>
                  <p className="bank-details__item">{country}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.city')}</p>
                  <p className="bank-details__item">{city}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.zipPostalCode')}</p>
                  <p className="bank-details__item">{postCode}</p>
                </div>
              </div>
              <div className="bank-details__column">
              <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.bankAddressLine1')}</p>
                  <p className="bank-details__item">{bankAddressLine1}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.bankAddressLine2')}</p>
                  <p className="bank-details__item">{bankAddressLine2}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.bankState')}</p>
                  <p className="bank-details__item">{bankState}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.bankCountry')}</p>
                  <p className="bank-details__item">{bankCountry}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.bankCity')}</p>
                  <p className="bank-details__item">{bankCity}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.bankZipPostalCode')}</p>
                  <p className="bank-details__item">{bankPostCode}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label"> {i18n.t('users.bankName')}</p>
                  <p className="bank-details__item">{bankName}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">{i18n.t('users.bankAccountNumber')}</p>
                  <p className="bank-details__item">{bankAccountNumber}</p>
                </div>
                <div className="bank-details__block">
                  <p className="bank-details__label">
                    {routingNumberType === RoutingNumberType.ABA ? i18n.t('users.abaNumber'): i18n.t('users.swiftBic')}
                  </p>
                  <p className="bank-details__item">{bankRoutingNumber}</p>
                </div>
              </div>
            </div>
          )}
      </div>
    </Layout>
  );
};

export default BankDetails;
