import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table, Modal, Spin, Empty, Pagination, Button,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { QuestionCircleOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';

import WatchStatus from '../../constants/watch-status';
import { takeResultsPerPage } from '../../constants';
import { IValuation } from '../../store/types/valuation/valuation-entity';
import { IValuationValues } from '../../store/types/valuation/valuation-dto';
import { useActions, useStore } from '../../store';
import getColumns from './columns';
import { validateValuationForm } from './validation';
import extractChangedData from './extractChangedData';
import toastNotification from '../../utility/toast-notification/toast-notification';
import ToastNotificationTypes from '../../utility/toast-notification/types';
import {EDIT_WATCH, USER_WATCH } from '../../router/constants';
import { getPropertyWatchName, watchWhiteListProp } from '../../utility/get-watch-data';
import i18n from '../../features/utils/i18n';

const { confirm } = Modal;

interface IValuationsTableProps {
  data: IValuation[];
  isLoading: boolean;
  handlePageChange: (page: number) => void;
  currentPage: number;
  count: number;
}

const ValuationsTable: React.FC<IValuationsTableProps> = ({
  data, isLoading, count, currentPage, handlePageChange,
}) => {
  const { location, push } = useHistory();
  const currentUrl = `${location.pathname}${location.search}`;
  const { valuationsActions } = useActions();
  const { valuations } = useStore();

  const handleClickRow = (valuation: IValuation) => {
    push({ pathname: `${USER_WATCH}/${valuation.id}` }, { prevPath: currentUrl });
  };

  const initialValues: IValuationValues = useMemo(() => data.reduce(
    (acc, valuation) => ({
      ...acc,
      [valuation.id]: {
        minCost: valuation.minCost,
        maxCost: valuation.maxCost,
      },
    }),
    {},
  ), [data]);

  const watchDataSelected = data.map((watchItem) => ({
    ...watchItem,
    buckle: getPropertyWatchName(watchItem, watchWhiteListProp.buckle),
    caseMetal: getPropertyWatchName(watchItem, watchWhiteListProp.caseMetal),
    dialColor: getPropertyWatchName(watchItem, watchWhiteListProp.dialColor),
    complication: getPropertyWatchName(watchItem, watchWhiteListProp.complication),
    model: getPropertyWatchName(watchItem, watchWhiteListProp.model),
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: async (values) => validateValuationForm(values, i18n.t),
    onSubmit: (values) => onSubmit(values),
  });

  // useEffect(() => {
  //   if (!isEmpty(formik.errors)) {
  //     toastNotification({
  //       type: ToastNotificationTypes.ERROR,
  //       message: i18n.t('valuations.evaluationError'),
  //     });
  //   }
  // }, [formik.errors]);

  useEffect(() => {
    formik.setErrors({});
  }, [formik.dirty]);

  const handleSubmit = (values: IValuationValues) => {
    const requestData = extractChangedData(values, initialValues);
    valuationsActions.valuateBunchWatches(requestData);
  };

  const onSubmit = useCallback(async (values: IValuationValues) => {
    const errors = await formik.validateForm();
    if (!Object.keys(errors).length) {
      confirm({
        title: i18n.t('valuations.saveChanges'),
        icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
        centered: true,
        onOk() {
          handleSubmit(values);
        },
      });
    }
  }, [initialValues]);

  const onDeleteClick = (id: number) => {
    confirm({
      title: i18n.t('valuations.deleteValuation'),
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      onOk() {
        valuationsActions.deleteValuation(id);
      },
    });
  };
  if (valuations.loading) {
    return <Spin className="spinner" />;
  }

  if (!valuations.loading && !valuations.list.length) {
    return <Empty className="empty-list--min-height" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <>
      <div className="entity-table">
        <Button
          onClick={formik.submitForm}
          disabled={!formik.dirty || !isEmpty(formik.errors)}
          className="button--primary valuate-watch__btn entity-table__save-btn"
        >
          {i18n.t('common.save')}
        </Button>
        <Table
          bordered
          columns={getColumns(formik, onDeleteClick)}
          pagination={false}
          dataSource={watchDataSelected.map((item, i) => ({ ...item, key: i }))}
          loading={isLoading}
          scroll={{ x: true }}
          sticky
          rowClassName={(record) => (record.status === WatchStatus.INCLUDED_IN_LOAN ? 'grey-row' : '')}
          className="custom-table custom-table--min-height reset-padding-margin"
          onRow={(record) => ({
            onClick: () => handleClickRow(record),
          })}
        />
      </div>
      {count > takeResultsPerPage && (
        <div className="pagination-wrapper">
          <Pagination
            total={count}
            onChange={(page: number) => {
              handlePageChange(page);
              formik.resetForm();
            }}
            defaultCurrent={currentPage}
            defaultPageSize={takeResultsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default ValuationsTable;
