import React from 'react';
import { Input, Layout, Pagination } from 'antd';
import i18n from '../../features/utils/i18n';
import './styles.css';
import { SearchOutlined } from '@ant-design/icons';
import { takeResultsPerPage } from '../../constants';
import { useActions, useStore } from '../../store';
import { PartnerModal, PartnerTable } from '../../components/partner';

let timeout: NodeJS.Timeout;

const Partners: React.FC = () => {
  const [filter, setFilter] = React.useState({
    page: 1,
    companyName: '',
    take: takeResultsPerPage
  });
  
  const { partnerActions } = useActions();
  const { partner } = useStore();
  const { list, loading, count } = partner;

  React.useEffect(() => {
    partnerActions.getPartners({ ...filter });
  }, [filter]);

  React.useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  const onSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setFilter({
        ...filter,
        page: 1,
        companyName: event.target.value
      });
    }, 1000)
  };

  const handlePageChange = (page: number) => {
    setFilter({ ...filter, page });
  };

  const deletePartner = (id) => {
    partnerActions.deletePartner(id, filter);
  }

  return (
    <Layout className="container">
      <header className="partners__header">
        <h1 className="partners-title">
          {i18n.t('partner.title')}
        </h1>
        <Input
          className="partners__search-input"
          placeholder={i18n.t('common.search')}
          suffix={<SearchOutlined />}
          defaultValue={filter.companyName}
          onChange={onSearchChange}
        />
        <PartnerModal />
      </header>
      <Layout.Content>
        <PartnerTable
          partners={list} 
          loading={loading}
          deletable
          onDelete={deletePartner}
        />
        {count > takeResultsPerPage && (
          <div className="pagination-wrapper">
            <Pagination
              defaultCurrent={filter.page}
              current={filter.page}
              defaultPageSize={takeResultsPerPage}
              total={count || 1}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Layout.Content>
    </Layout>
  );
}

export default Partners;
