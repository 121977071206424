import React from 'react';
import {
  Input, Form, Button, Spin,
} from 'antd';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { useActions, useStore } from '../../../../store';

import ChangePassword from './validation-schema';
import { validateForm } from '../../../../utility/validation/class-validator';
import i18n from '../../../../features/utils/i18n';

const Settings: React.FC = () => {
  const { auth: { loading } } = useStore();
  const { authActions } = useActions();

  const initialValues = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };
  const formik = useFormik({
    initialValues,
    validate: async (values) => {
      const result = await validateForm(ChangePassword, values);
      return result;
    },
    onSubmit: (values) => authActions.updateUserPassword({
      oldPassword: values.oldPassword,
      password: values.password,
    }),
  });

  const isSaveDisabled = !formik.dirty || !isEmpty(formik.errors);

  if (loading) {
    return <Spin className="spinner-height-auto" />;
  }

  return (
    <Form
      name="profile-password"
      className="profile-password"
      onFinish={formik.handleSubmit}
    >
      <Form.Item label={i18n.t('auth.password')} className="profile-password__label">
        <Input
          name="oldPassword"
          type="password"
          disabled
          placeholder="************"
          className="profile-password__input"
        />
      </Form.Item>
      <Form.Item label={i18n.t('auth.repeatPassword')} className="profile-password__label">
        <Input
          onChange={formik.handleChange}
          name="oldPassword"
          type="password"
          placeholder={i18n.t('auth.enterYourPassword')}
          className="profile-password__input"
        />
      </Form.Item>
      <Form.Item label={i18n.t('newPassword')} className="profile-password__label">
        <Input
          onChange={formik.handleChange}
          name="password"
          type="password"
          placeholder={i18n.t('auth.enterYourPassword')}
          className="profile-password__input"
        />
        <div className="login-form__error  error-validation--color">{formik.errors.password ? formik.errors.password : ''}</div>
      </Form.Item>
      <Form.Item label={i18n.t('auth.repeatNewPassword')} className="profile-password__label">
        <Input
          onChange={formik.handleChange}
          name="confirmPassword"
          type="password"
          placeholder={i18n.t('auth.enterYourPassword')}
          className="profile-password__input"
        />
        <div className="login-form__error  error-validation--color">{formik.errors.confirmPassword ? formik.errors.confirmPassword : ''}</div>
      </Form.Item>
      <Button htmlType="submit" className="ant-btn button--primary profile__save-btn" disabled={isSaveDisabled}>
        {i18n.t('common.save')}
      </Button>
    </Form>
  );
};

export default Settings;
