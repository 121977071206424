import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useActions } from '../store/actions-context';

export const useWatchData = (): void => {
  const { watchActions, loansActions } = useActions();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      watchActions.getUserWatchById(id);
      loansActions.getByWatchId(parseInt(id));
    }
  }, []);
};

export default useWatchData;
