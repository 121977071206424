import { Min, Max } from 'class-validator';

import { errorMessages } from '../validation-schema';

export class ValidateInitialPercent {
  public constructor(initialPercent = 0) {
    this.initialPercent = initialPercent;
  }

  @Max(100, {
    message: errorMessages.greaterThan,
  })
  @Min(0, {
    message: errorMessages.lessThan,
  })
  initialPercent: number;
}

export class ValidateMinPercent {
  public constructor(minPercent = 0) {
    this.minPercent = minPercent;
  }

  @Max(100, {
    message: errorMessages.greaterThan,
  })
  @Min(0, {
    message: errorMessages.lessThan,
  })
  minPercent: number;
}

export class ValidateMaxPercent {
  public constructor(maxPercent = 0) {
    this.maxPercent = maxPercent;
  }

  @Max(100, {
    message: errorMessages.greaterThan,
  })
  @Min(0, {
    message: errorMessages.lessThan,
  })
  maxPercent: number;
}
