import React, { useRef, useState } from 'react';
import {
  Button, Input, Modal, Form,
} from 'antd';
import { HexColorPicker } from 'react-colorful';
import Text from 'antd/lib/typography/Text';
import { RcFile } from 'antd/lib/upload';
import NumberFormat from 'react-number-format';
import i18n from '../../features/utils/i18n';
import { useActions } from '../../store';
import { IPartner } from '../../store/types/partner/partner-entity';
import { IGetPartnersParams } from '../../store/types/partner/partner-dto';
import { LogoUploader } from './logo-uploader';
import { PhoneMask } from '../../constants';

interface ICreateModalProps {
  filter?: IGetPartnersParams;
  partner?: IPartner;
}

export const PartnerModal = ({
  filter = {},
  partner,
}: ICreateModalProps) => {
  const colorPickerRef = useRef(null);
  const colorDisplay = useRef(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [color, setColor] = useState('');
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [file, setFile] = useState<RcFile | null>(null);
  const [dataChanged, setDataChanged] = useState({});

  const [form] = Form.useForm();

  React.useEffect(() => {
    setInitialValues({
      companyName: null,
      email: null,
      phoneNumber: null,
      info: null,
    });
    setColor('#FFFFFF');
    document.body.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.body.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  React.useEffect(() => {
    if (partner) {
      for (const key in initialValues) {
        if (key === 'info') {
          form.setFieldsValue({
            info: partner?.configs?.info || '',
          });
        } else {
          form.setFieldsValue({
            [key]: partner[key],
          });
        }
      }
      setColor(partner?.configs?.gamma || '');
    }
  }, [isModalVisible]);

  const uploadImg = (file: RcFile) => {
    setFile(file);
    setDataChanged({
      ...dataChanged,
      companyLogo: true,
    });
  };

  const onChange = (name: string) => {
    setDataChanged({
      ...dataChanged,
      [name]: true,
    });
  };

  const onChangeColorPicker = (hex: string) => {
    setColor(hex);
    setDataChanged({
      ...dataChanged,
      configs: true,
    });
  };

  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());

    if (
      !path.includes(colorPickerRef.current)
      && !path.includes(colorDisplay.current)
    ) {
      setColorPickerVisible(false);
    }
  };

  const { partnerActions, usersActions } = useActions();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    let result = false;
    try {
      const { info, ...data } = values;
      const newPartner = {
        ...data,
        companyLogo: { url: null },
        configs: {
          gamma: color,
          info,
        },
      };
      if (file) {
        const extension = file.type.split('/')[1];
        const credentials = await usersActions.getAWSCredentials(extension);
        if (!credentials) {
          return;
        }
        const logoUrl = await usersActions.uploadAvatar(file, credentials);
        newPartner.companyLogo.url = logoUrl;
      }
      if (partner) {
        for (const key in newPartner) {
          if (!dataChanged[key]) {
            delete newPartner[key];
          }
        }
        result = await partnerActions.updatePartner(newPartner, partner.id);
      } else {
        result = await partnerActions.createPartner(newPartner, filter);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      if (result) {
        handleCancel();
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setColor('#FFFFFF');
    setDataChanged({});
  };

  const toggleVisibleColorPicker = () => {
    setColorPickerVisible((val) => !val);
  };

  return (
    <>
      <Button
        className="button--primary add-partner__btn"
        onClick={showModal}
      >
        {partner ? i18n.t('common.edit') : i18n.t('partner.add')}
      </Button>
      <Modal
        closable={false}
        centered
        title={<h2>{partner ? i18n.t('partner.edit') : i18n.t('partner.add')}</h2>}
        visible={isModalVisible}
        footer={false}
      >
        <div className="top-content">
          <LogoUploader
            upload={uploadImg}
            url={partner?.companyLogo?.url}
            visible={isModalVisible}
          />
          <div className="color-picker__wrapper">
            <Text strong>
              {i18n.t('common.color')}
              :
            </Text>
            <div
              ref={colorDisplay}
              onClick={toggleVisibleColorPicker}
              style={{ background: color }}
              className="hex-color"
            />
            <div className="color-text">
              {color}
              ;
            </div>
            {colorPickerVisible && (
              <div ref={colorPickerRef} className="color-picker">
                <HexColorPicker
                  color={color}
                  onChange={onChangeColorPicker}
                />
              </div>
            )}
          </div>
        </div>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          onFinish={onFinish}
          className="create-promo-modal"
          initialValues={initialValues}
          style={{ position: 'relative', overflow: 'hidden' }}
        >
          <Form.Item
            label={i18n.t('partner.companyName')}
            name="companyName"
            rules={[{ required: true, message: i18n.t('requiredError.companyName') }]}
          >
            <Input onChange={() => onChange('companyName')} />
          </Form.Item>
          <Form.Item
            label={i18n.t('common.email')}
            name="email"
            rules={[{ required: true, message: i18n.t('requiredError.email') }]}
          >
            <Input onChange={() => onChange('email')} />
          </Form.Item>
          <Form.Item
            label={i18n.t('common.phoneNumber')}
            name="phoneNumber"
            rules={[{ required: true, message: i18n.t('requiredError.phoneNumber') }]}
          >
            <NumberFormat
              className="ant-input"
              format={PhoneMask.TEN_DIGITS}
              mask="_"
              onChange={() => onChange('phoneNumber')}
            />
          </Form.Item>
          <Form.Item
            label={i18n.t('common.info')}
            name="info"
          >
            <Input.TextArea
              onChange={() => onChange('configs')}
              className="partner__number-input"
            />
          </Form.Item>
          <div className="form-buttons">
            <Button onClick={handleCancel}>
              {i18n.t('common.cancel')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={!!partner && !Object.keys(dataChanged).length}
            >
              {partner ? i18n.t('common.save') : i18n.t('common.add')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
