import React from 'react';
import i18n from '../../features/utils/i18n';

import { ISubscription } from '../../store/types/subscription/subscription-entity';
import './styles.css';

interface IProps {
  data: ISubscription
}

const SubscriptionCard: React.FC<IProps> = ({ data }) => (
  <div className="subscription-card">
    <div className="subscription-card__title">{data.name}</div>
    <div className="subscription-card__rate">
      {`$${data.price} ${i18n.t('subscriptions.inA')} ${data.period}`}
    </div>
    <div className="subscription-card__description">{data.description}</div>
  </div>
);

export default SubscriptionCard;
