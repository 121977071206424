import React from 'react';
import {Button, Tag } from 'antd';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { AlignType } from 'rc-table/lib/interface';

import './styles.css';
import NotActiveTag from '../not-active-tag';
import { PhoneMask } from '../../constants';
import i18n from '../../features/utils/i18n';
import { ColumnsType } from 'antd/lib/table';
import { IUser } from '../../store/types/users/user-entity';

export const allUsersColumns = (
  onDelete: (id: number) => void
): ColumnsType<IUser> =>{
  return  [
    {
      title: i18n.t('common.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '16.5%',
      className: 'users-list-table__date',
      render: (data) => moment(data).format('MM.DD.YYYY'),
    },
    {
      title: i18n.t('common.name'),
      dataIndex: 'name',
      key: 'name',
      width: '16.5%',
      className: 'users-list-table__name',
    },
    {
      title: i18n.t('common.phone'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '20.5%',
      className: 'users-list-table__phone',
      render: (val) => <NumberFormat className="users-list-table__phone--border-zero" value={val} format={PhoneMask.TEN_DIGITS} mask="_" />,
    },
    {
      title: i18n.t('common.email'),
      dataIndex: 'email',
      key: 'email',
      width: '16.5%',
      className: 'users-list-table__email',
    },
    // {
    //   title: 'Subscription',
    //   dataIndex: 'subscriptions',
    //   key: 'subscriptions',
    //   width: '20.5%',
    //   className: 'users-list-table__subscription',
    //   render: (tags) => renderTag(tags),
    // },
    {
      title: i18n.t('common.status'),
      dataIndex: 'blocked',
      key: 'blocked',
      width: '5%',
      align: 'center' as AlignType,
      className: 'users-list-table__status',
      render: (status: string) => (status ? <Tag color="red">{i18n.t('common.block')}</Tag> : <NotActiveTag content="Block" />),
    },
    {
      title: i18n.t('common.action'),
      width: '2%',
      className: 'user-list-table__action',
      render: (_,userConfig: IUser ) => {
        return (
          <div className="user-actions" role="button" onClick={(e) => e.stopPropagation()}>
            <Button
              className="button--danger"
              onClick={() => onDelete(userConfig.id)}
            >
              {i18n.t('common.delete')}
            </Button>
          </div>
        );
      },
    },
  ];
}

export const adminsColumns = (
  onDelete: (id: number) => void,
  // onEdit: (id: number) => void,
   ): ColumnsType<IUser> =>  {
  return [
    {
      title: i18n.t('common.name'),
      dataIndex: 'name',
      key: 'name',
      width: '27.4%',
      className: 'users-list-table__name',
    },
    {
      title: i18n.t('common.phone'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '34.25%',
      className: 'users-list-table__phone',
      render: (val) => <NumberFormat className="users-list-table__phone--border-zero" value={val} format={PhoneMask.TEN_DIGITS} mask="_" />,
    },
    {
      title: i18n.t('common.email'),
      dataIndex: 'email',
      key: 'email',
      width: '27.25%',
      className: 'users-list-table__email',
    },
    {
      title: i18n.t('common.tags'),
      dataIndex: 'role',
      key: 'tags',
      width: '5%',
      align: 'center' as AlignType,
      className: 'users-list-table__tags',
      render: () => <Tag color="cyan">{i18n.t('common.admin')}</Tag>,
    },
    {
      title: i18n.t('common.action'),
      width: '10%',
      className: 'watch-config-table__action',
      render: (_,userConfig: IUser ) => {
        return (
          <div className="table__button" role="button" onClick={(e) => e.stopPropagation()}>
            <Button
              className="button--danger"
              onClick={() => onDelete(userConfig.id)}
            >
              {i18n.t('common.delete')}
            </Button>
          </div>
        );
      },
    },
  ];
};
