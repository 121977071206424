import React from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {
  CalendarOutlined, CloseOutlined, DoubleLeftOutlined,
  DoubleRightOutlined, LeftOutlined, RightOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';

interface ICustomDateRangePickerProps {
  value: Array<Date | string | undefined>;
  handleChange: (values) => void;
}

const CustomDateRangePicker: React.FC<ICustomDateRangePickerProps> = ({ value, handleChange }) => (
  <DateRangePicker
    value={value}
    onChange={handleChange}
    calendarIcon={<CalendarOutlined style={{ color: '#bfbfbf' }} />}
    clearIcon={<CloseOutlined style={{ color: '#bfbfbf' }} />}
    prevLabel={<LeftOutlined style={{ color: '#bfbfbf' }} />}
    prev2Label={<DoubleLeftOutlined style={{ color: '#bfbfbf' }} />}
    nextLabel={<RightOutlined style={{ color: '#bfbfbf' }} />}
    next2Label={<DoubleRightOutlined style={{ color: '#bfbfbf' }} />}
    className={classNames('custom-datepicker', { 'has-init-value': value[0] })}
  />
);

export default CustomDateRangePicker;
