import React, { useState } from 'react';
import {
  Button,
  Input,
  Select,
  Form,
} from 'antd';
import { useParams } from 'react-router';

import { useActions, useStore } from '../../store';
import {
  Roles,
} from '../../constants';
import { isPremiumUser, getRevCatManageSubscriberPageUrl, getSubscriptionExpiresAt } from '../../utility/revenue-cat';
import i18n from '../../features/utils/i18n';

const fields: any = [
  {
    label: i18n.t('common.firstName'),
    type: 'text',
    name: 'firstName',
    rules: [{ required: true }],
  },
  {
    label: i18n.t('common.lastName'),
    type: 'text',
    name: 'lastName',
    rules: [{ required: true }],
  },
  {
    label: i18n.t('common.phone'),
    type: 'number',
    name: 'phoneNumber',
    rules: [{ required: true }],
  },
  {
    label: i18n.t('common.emailLabel'),
    type: 'email',
    required: true,
    name: 'email',
    rules: [{ required: true, type: 'email' }],
  },
];

const validateMessages = {
  required: i18n.t('validation.required'),
  types: {
    email: i18n.t('validation.email'),
    number: i18n.t('validation.number'),
  },
  number: {
    range: i18n.t('validation.minMax'),
  },
};

const { Option } = Select;

const EditUserContent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    usersActions: {
      setUserSubscribe,
      setUserRole,
      updateUser,
    },
  } = useActions();
  const { users: { current, currentLoading, revCatUser }, auth } = useStore();
  const isPremium = isPremiumUser(revCatUser);
  const [currentRole, setCurrentRole] = useState(current?.role);
  const [currentUserIsSubscribed, setCurrentUserIsSubscribed] = useState(
    current?.isSubscribed === undefined
      ? false
      : current?.isSubscribed,
  );
  const [dataChanged, setDataChanged] = useState<any>({});

  const initialValues = {
    firstName: current?.firstName,
    lastName: current?.lastName,
    phoneNumber: current?.phoneNumber,
    email: current?.email,
  };

  const isSuperAdmin = auth.user?.role === Roles.SUPER_ADMIN;
  const manageSubscriberURL = getRevCatManageSubscriberPageUrl(id);

  const [form] = Form.useForm();

  const handleSelect = (value: string) => {
    setCurrentRole(value);
    onChangeData('role');
  };

  const handleSave = async (values) => {
    const { role, ...formDataChanged } = dataChanged;
    if (role && currentRole) {
      await setUserRole({ id: Number(id), role: currentRole });
    }
    if (Object.keys(formDataChanged).length) {
      const updatedUserData = {
        email: current?.email,
      };
      for (const key in formDataChanged) {
        updatedUserData[key] = values[key];
      }
      await updateUser(updatedUserData, id);
    }
    setDataChanged({});
    // if (current?.isSubscribed === currentUserIsSubscribed) return;
    // setUserSubscribe({ id: Number(id), isSubscribed: currentUserIsSubscribed });
  };

  const handleCheckbox = (e) => {
    setCurrentUserIsSubscribed(e.target.checked);
  };

  const onChangeData = (name: string) => {
    setDataChanged({
      ...dataChanged,
      [name]: true,
    });
  };

  return (
    <Form
      form={form}
      onFinish={handleSave}
      layout="vertical"
      initialValues={initialValues}
      validateMessages={validateMessages}
      className="edit-user-form"
    >
      {fields.map((field) => (
        <Form.Item
          key={field.name}
          label={field.label}
          name={field.name}
          rules={field.rules}
        >
          <Input type={field.type} onChange={() => onChangeData(field.name)} />
        </Form.Item>
      ))}
      <div className="edit-user-form__label">{i18n.t('subscriptions.subscription')}</div>
      <div>
        {
          isPremium
            ? `${i18n.t('subscriptions.subscription')} ${getSubscriptionExpiresAt(revCatUser)}`
            : i18n.t('subscriptions.notPremium')
        }
      </div>
      <a
        className="ant-btn button--default manage-subs__btn"
        href={manageSubscriberURL}
        target="_blank"
        rel="noreferrer"
      >
        {i18n.t('profile.manageSubscription')}
      </a>
      <p className="edit-user-form__label">{i18n.t('common.role')}</p>
      <Select
        onSelect={handleSelect}
        value={currentRole}
        defaultValue={currentRole}
        style={{ width: 110 }}
        className="edit-user-form__select"
        disabled={!isSuperAdmin}
      >
        <Option key={Roles.USER} value={Roles.USER}>{i18n.t('users.user')}</Option>
        <Option key={Roles.ADMIN} value={Roles.ADMIN}>
          {' '}
          {i18n.t('common.admin')}
        </Option>
      </Select>
      {isSuperAdmin && (
        <Button
          disabled={!Object.keys(dataChanged).length}
          className="button--default edit-user-form__save-btn"
          loading={currentLoading}
          htmlType="submit"
        >
          {i18n.t('common.save')}
        </Button>
      )}
    </Form>
  );
};

export default EditUserContent;
