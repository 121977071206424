import React from 'react';
import {
  Button, Layout, Modal, Spin,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import ProfileContentWrapper from '../../components/profile-content/profile-content-wrapper';
import { UseProfileHooks } from './use-profile-hooks';

import './style.css';
import i18n from '../../features/utils/i18n';

const Profile: React.FC = () => {
  const {
    user, deleteUser, loading, logoutUser,
  } = UseProfileHooks();

  if (!user) {
    return null;
  }

  const showConfirm = () => {
    Modal.confirm({
      title: i18n.t('profile.deleteProfile'),
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      onOk() {
        deleteUser();
        logoutUser();
      },
    });
  };

  if (loading) {
    return <Spin className="spinner full-width" />;
  }

  return (
    <Layout className="profile">
      <div className="container">
        <ProfileContentWrapper loggedInAdmin user={user} />
        <Button className="profile-delete" type="primary" danger onClick={showConfirm}>{i18n.t('common.delete')}</Button>
      </div>
    </Layout>
  );
};

export default Profile;
