enum ToastNotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  WARN = 'warn',
  OPEN = 'open',
  CLOSE = 'close',
  DESTROY = 'destroy'
}

export enum ToastNotificationMessage {
  DEFAULT_ERROR_MESSAGE = 'Something ',
}

export default ToastNotificationTypes;
