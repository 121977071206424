import React from 'react';
import {
  Button, Card, Layout, Pagination, Spin, Table,
} from 'antd';
import { useParams } from 'react-router';
import Avatar from 'antd/lib/avatar/avatar';
import { CameraOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import Text from 'antd/lib/typography/Text';
import NumberFormat from 'react-number-format';
import { AlignType } from 'rc-table/lib/interface';
import moment from 'moment';

import BackButton from '../../components/back-button';
import { Routes } from '../../router/routes';
import { useActions, useStore } from '../../store';
import i18n from '../../features/utils/i18n';
import './styles.css';
import { PartnerModal } from '../../components/partner';
import { PhoneMask, takeResultsPerPage } from '../../constants';

const columns = [
  {
    title: `${i18n.t('common.name')}:`,
    dataIndex: 'name',
  },
  {
    title: i18n.t('common.appliedDate'),
    dataIndex: 'lastPartnerUpdatedDate',
    width: '120px',
    align: 'center' as AlignType,
    render: (date: moment.MomentInput) => {
      return (
        <div className="custom-table-spaces">
          {date ? moment(date).format('MM.DD.YYYY') : ''}
        </div>
      );
    },
  },
];

const Partner: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { partnerActions } = useActions();
  const { partner } = useStore();
  const { current, loading, users } = partner;
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    partnerActions.getPartnerById(id);

    return () => {
      partnerActions.resetCurrentPartner();
    };
  }, []);

  const handleDelete = () => {
    confirm({
      title: i18n.t('partner.deleteConfirm'),
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      onOk() {
        partnerActions.deletePartner(current?.id);
      },
    });
  };

  React.useEffect(() => {
    if (current) {
      partnerActions.getUsersByPartnerId({
        take: takeResultsPerPage,
        page,
        partner: current.id,
      });
    }
  }, [page]);

  const fetchUserList = () => {
    if (current && current.usersCount && current.usersCount > 0 && !users.length) {
      partnerActions.getUsersByPartnerId({
        take: takeResultsPerPage,
        page,
        partner: current.id,
      });
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    fetchUserList();
  };

  if (!current) {
    return null;
  }

  return (
    <Layout className="container partner-details">
      <BackButton path={Routes.partners} />
      <Spin spinning={loading}>
        <Card
          title={current.companyName}
          extra={(
            <>
              <PartnerModal partner={current} />
              <Button
                onClick={handleDelete}
                type="primary"
                danger
                className="partner-details__delete"
              >
                {i18n.t('common.delete')}
              </Button>
            </>
          )}
        >
          <div className="partner-details__content">
            <div className="partner-details__item">
              <div className="partner-details__title">
                {i18n.t('partner.logo')}
                :
              </div>
              <Avatar
                className="partner-details__logo"
                src={current.companyLogo?.url}
                shape="square"
                icon={<CameraOutlined />}
              />
            </div>
            <div className="partner-details__item">
              <div className="partner-details__title">
                {i18n.t('common.email')}
                :
              </div>
              {current.email}
            </div>
            <div className="partner-details__item">
              <div className="partner-details__title">
                {i18n.t('common.phoneNumber')}
                :
              </div>
              <NumberFormat
                className="partner-details__phone"
                value={current.phoneNumber}
                format={PhoneMask.TEN_DIGITS}
                mask="_"
                readOnly
              />
            </div>
            <div className="partner-details__item">
              <div className="partner-details__title">
                {i18n.t('common.info')}
                :
              </div>
              <div className="partner-details__info">{current.configs?.info || ''}</div>
            </div>
            <div className="partner-details__item">
              <div className="partner-details__title">
                {i18n.t('common.color')}
                :
              </div>
              <div
                style={{ background: current.configs.gamma }}
                className="partner-details__color"
              />
              <div className="color-text">
                {current.configs.gamma}
                ;
              </div>
            </div>
            <div className="partner-details__item">
              <div className="partner-details__title">
                {i18n.t('partner.numberOfUsers')}
                :
              </div>
              <div className="partner-details__info">{current.usersCount || 0}</div>
              {current.usersCount ? (
                <Button
                  onClick={fetchUserList}
                  type="primary"
                  disabled={!!users.length}
                >
                  {i18n.t('partner.showUserList')}
                </Button>
              ) : null}
            </div>
            {users.length ? (
              <>
                <Table
                  size="small"
                  className="partner__user-table"
                  bordered
                  columns={columns}
                  dataSource={users?.map((user) => ({ ...user, key: user.id })) || []}
                  pagination={false}
                  title={() => (
                    <Text strong>
                      {i18n.t('users.users')}
                      :
                    </Text>
                  )}
                />
                {(current.usersCount > takeResultsPerPage) ? (
                  <div className="pagination-wrapper">
                    <Pagination
                      defaultCurrent={page}
                      current={page}
                      defaultPageSize={takeResultsPerPage}
                      total={current.usersCount || 1}
                      onChange={handlePageChange}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </Card>
      </Spin>
    </Layout>
  );
};

export default Partner;
