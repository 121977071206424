import React from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './styles.css';
import i18n from '../../features/utils/i18n'

interface IBackButtonProps {
  path: string;
}

const BackButton: React.FC<IBackButtonProps> = ({ path }) => {
  const history = useHistory();
  return (
    <div
      role="button"
      className="back-button"
      onClick={() => {
        if (path === '/users') {
          return history.push(path);
        }
        return history.go(-1);
      }}
    >
      <ArrowLeftOutlined
        style={{ color: '#262626' }}
      />
      {i18n.t('common.back')}
    </div>
  );
};

export default BackButton;
