import React from 'react';
import { Layout } from 'antd';

import './styles.css';
import i18n from '../../features/utils/i18n';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="page-wrapper">
        <div className="header-holder">
          <div className="top-row">
            <div className="page-title__holder pp-title">
              <h1>{i18n.t('privacy.privacyPolicy')}</h1>
            </div>
          </div>
          <Content>
            <div className="pp-content">
              <p>{i18n.t('privacy.updatedAt')}</p>
              <p>{i18n.t('privacy.paragraph1')}</p>
              <p>{i18n.t('privacy.paragraph2')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.subtitle1')}</p>
              <ul>{i18n.t('privacy.toHelp')}
                <li>{i18n.t('privacy.cookie')}</li>
                <li>{i18n.t('privacy.company')}</li>
                <li>{i18n.t('privacy.country')}</li>
                <li>{i18n.t('privacy.customer')}</li>
                <li>{i18n.t('privacy.device')}</li>
                <li>{i18n.t('privacy.ipAddress')}</li>
                <li>{i18n.t('privacy.personnel')}</li>
                <li>{i18n.t('privacy.personal')}</li>
                <li>{i18n.t('privacy.service')}</li>
                <li>{i18n.t('privacy.thirdParty')}</li>
                <li>{i18n.t('privacy.website')}</li>
                <li>{i18n.t('privacy.you')}</li>
              </ul>
              <p className="pp-subtitle">{i18n.t('privacy.what')}</p>
              <p>{i18n.t('privacy.weCollect')}</p>
              <ul>
                <li>{i18n.t('privacy.nameUsername')}</li>
                <li>{i18n.t('privacy.phoneNumbers')}</li>
                <li>{i18n.t('privacy.emailAddresses')}</li>
                <li>{i18n.t('privacy.mailingAddresses')}</li>
                <li>{i18n.t('privacy.debitCredit')}</li>
                <li>{i18n.t('auth.password')}</li>
              </ul>
              <ul>
                {i18n.t('privacy.weAlso')}
                <li>{i18n.t('privacy.photo')}</li>
              </ul>
              <p className="pp-subtitle"> {i18n.t('privacy.howDo')}</p>
              <ul>
                <p className="pp-subtitle">{i18n.t('privacy.anyOf')}</p>
                <li>{i18n.t('privacy.toPersonalize')}</li>
                <li>{i18n.t('privacy.toPersonalize')}</li>
                <li>{i18n.t('privacy.improveCustomer')}</li>
                <li>{i18n.t('privacy.toProcess')}</li>
                <li>{i18n.t('privacy.toAdminister')}</li>
                <li>{i18n.t('privacy.toSend')}</li>
              </ul>
              <p className="pp-subtitle">{i18n.t('privacy.whenDoes')}</p>
              <p>{i18n.t('privacy.mechanical')}</p>
              <p>{i18n.t('privacy.endUsers')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.doesMech')}</p>
              <p>{i18n.t('privacy.weReceive')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.weShare')}</p>
              <p>{i18n.t('privacy.mayShare')}</p>
              <p>{i18n.t('privacy.mayEngage')}</p>
              <p>{i18n.t('privacy.sharePortions')}</p>
              <p>{i18n.t('privacy.mayAlso')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.where')}</p>
              <p>{i18n.t('privacy.useYour')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.bySubmitting')}</p>
              <p>{i18n.t('auth.ppSubtitle9')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.howLong')}</p>
              <p>{i18n.t('auth.weProtect')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.weProtect')}</p>
              <p>{i18n.t('auth.weImplement')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.couldMy')}</p>
              <p>{i18n.t('auth.llc')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.isThe')}</p>
              <p>{i18n.t('auth.precautions')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.update')}</p>
              <p>{i18n.t('privacy.rights')}</p>
              <p>{i18n.t('privacy.customers')}</p>
              <p>{i18n.t('privacy.aware')}</p>
              <p>{i18n.t('privacy.ifYouAre')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.personnel')}</p>
              <p>{i18n.t('privacy.workerLLC')}</p>
              <p>{i18n.t('privacy.contactUs')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.sale')}</p>
              <p>{i18n.t('privacy.reserve')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.affiliates')}</p>
              <p>{i18n.t('privacy.disclose')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.governing')}</p>
              <p>{i18n.t('privacy.thisPrivacy')}</p>
              <p>{i18n.t('privacy.theLaws')}</p>
              <p>{i18n.t('privacy.byUsing')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.consent')}</p>
              <p>{i18n.t('privacy.updated')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.websites')}</p>
              <p>{i18n.t('privacy.applies')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.advertising')}</p>
              <p>{i18n.t('privacy.app')}</p>
              <p>{i18n.t('privacy.keeps')}</p>
              <p>{i18n.t('privacy.advertisements')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.cookiesFor')}</p>
              <p>{i18n.t('privacy.collect')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.cook')}</p>
              <p>{i18n.t('privacy.uses')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.blocking')}</p>
              <p>{i18n.t('privacy.wherever')}</p>
              <p className="pp-subtitle">{i18n.t('privacy.remarketing')}</p>
              <p>{i18n.t('privacy.weUseServices')}</p>
                <p className="pp-subtitle">{i18n.t('privacy.payment')}</p>
                <p>{i18n.t('privacy.respect')}</p>
                <p className="pp-subtitle">{i18n.t('privacy.kids')}</p>
                <p>{i18n.t('privacy.anyone')}</p>
                <p className="pp-subtitle">{i18n.t('privacy.changesTo')}</p>
                <p>{i18n.t('privacy.mayChange')}</p>
                <p className="pp-subtitle">{i18n.t('privacy.services')}</p>
                <p>
                  {i18n.t('privacy.display')}
                  <br />
                  {i18n.t('privacy.acknowledge')}
                  <br />
                  {i18n.t('privacy.thirdServices')}
                </p>
                <ul>
                  {i18n.t('privacy.tracking')}
                  <li>
                    {i18n.t('privacy.cookies')}
                    <p>{i18n.t('privacy.weUse')}</p>
                  </li>
                  <li>
                    {i18n.t('privacy.sessions')}
                    <p>{i18n.t('privacy.mechanicalArt')}</p>
                  </li>
                </ul>
                <p className="pp-subtitle">{i18n.t('privacy.weMay')})</p>
                <p>{i18n.t('privacy.isGDPR')}</p>
                <p className="pp-subtitle">{i18n.t('privacy.euWide')}</p>
                <p>{i18n.t('privacy.relevant')}</p>
                <p>{i18n.t('privacy.personalData')}</p>
                <p className="pp-subtitle">{i18n.t('privacy.theData')}</p>
                <p>{i18n.t('privacy.protection')}</p>
                <ul>
                  {i18n.t('privacy.protection')}
                  <li>{i18n.t('privacy.personalCollected')}</li>
                  <li>{i18n.t('privacy.dataShould')}</li>
                  <li>{i18n.t('privacy.held')}</li>
                  <li>{i18n.t('privacy.peopleCovered')}</li>
                </ul>
                <p className="pp-subtitle">{i18n.t('privacy.whyIs')}</p>
                <p>{i18n.t('privacy.gdpr')}</p>
                <p>{i18n.t('privacy.individual')}</p>
                <p>{i18n.t('privacy.committed')}</p>
                <p>{i18n.t('privacy.awareThat')}</p>
                <p className="pp-subtitle">{i18n.t('privacy.awareThat')}</p>
                <p>{i18n.t('privacy.awareThat')}</p>
                <ul>
                  {i18n.t('privacy.california')}
                  <li>{i18n.t('privacy.theCalifornia')}</li>
                  <li>{i18n.t('privacy.weAre')}</li>
                  <li>{i18n.t('privacy.knowAnd')}</li>
                  <li>{i18n.t('privacy.equalService')}</li>
                </ul>
                <p>
                  {i18n.t('privacy.ifYouMake')}
                  <br />
                  {i18n.t('privacy.weDoNot')}
                  <br />
                  {i18n.t('privacy.forMore')}
                </p>
                <p>{i18n.t('privacy.contact')}</p>
                <p>{i18n.t('privacy.hesitate')}</p>
                <ul>
                  <li>
                    {i18n.t('privacy.email')}
                    <Link to="mailto:financing@mechartcap.com"> financing@mechartcap.com</Link>
                  </li>
                  <li>
                    {i18n.t('privacy.viaPhone')}
                    <Link to="tel:+18335327040"> {i18n.t('privacy.number')}</Link>
                  </li>
                  <li>
                    {i18n.t('privacy.via')}
                    <Link to="https://www.mechanicalartcapital.com">{i18n.t('privacy.www')}</Link>
                  </li>
                </ul>
            </div>
          </Content>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
