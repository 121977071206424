import {
  // Matches,
  MaxLength,
  MinLength,
  Validate,
} from 'class-validator';
import i18n from '../../features/utils/i18n'

// import passwordValidation from '../../utility/validation/password-validation';
import { CustomLoginValidator } from '../../utility/validation/validator-constraint';


class Login {
  public constructor(login = '', password = '') {
    this.login = login;
    this.password = password;
  }

  @MinLength(8, {
    message: i18n.t('validation.least8'),
  })
  @MaxLength(30, {
    message: i18n.t('validation.less30'),
  })
  @Validate(CustomLoginValidator)
  login: string;

  // @Matches(passwordValidation.regex, {
  //   message: passwordValidation.message,
  // })
  // valueShouldBeAtLeast6Characters
  @MinLength(6, {
    message: i18n.t('validation.least6'),
  })
  password: string;
}

export default Login;
