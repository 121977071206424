import React, { useState } from 'react';
import {
  Breadcrumb, Empty, Pagination, Layout, Spin,
} from 'antd';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import UserCard from '../../components/user-card';
import WatchItem from '../../components/watch-item';
import BackButton from '../../components/back-button';
import FilterUserWatches from '../../components/filter-user-watches';
import { useStore } from '../../store';
import parseParams from '../../utility/parse-params';
import { takeResultsPerPage } from '../../constants';
import { Routes } from '../../router/routes';
import { IUser } from '../../store/types/users/user-entity';
import { useBackPath } from '../../hooks/use-back-path';
import { USER_WATCH } from '../../router/constants';
import i18n from '../../features/utils/i18n';
import Partner from './partner';
import { PromocodeTable } from '../../components/promocode';

const { Content } = Layout;

interface IProps {
  user: IUser;
}

const UserProfile: React.FC<IProps> = ({ user }) => {
  const {
    userWatch: { list: watches = [], count, loading },
  } = useStore();
  const { search, pathname } = useLocation();
  const backPath = useBackPath(Routes.users);

  const params = parseParams(search);
  const { page }: any = params;
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);

  return (
    <>
      <div className="header-holder">
        <BackButton path={backPath} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={Routes.users}>{i18n.t('users.users')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{i18n.t('users.user')}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Content>
        {user && <UserCard user={user} />}
        {user.promocodes.length ? (
          <PromocodeTable
            title
            small
            promocodes={user.promocodes}
            className="promocode-table"
          />
        ) : null}
        {user && <Partner />}
        <FilterUserWatches currentPage={currentPage} setCallbackPage={setCurrentPage} />
        <div className="user__watches-list">
          {!watches.length && !loading && <Empty className="full-width" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {loading && <Spin className="spinner full-width" />}
          {Boolean(watches.length) && watches.map((watch) => (
            <Link
              key={watch.id}
              to={{ pathname: `${USER_WATCH}/${watch.id}`, state: { prevPath: pathname } }}
            >
              <WatchItem watch={watch} />
            </Link>
          ))}
        </div>
        {count > takeResultsPerPage && (
          <div className="pagination-wrapper">
            <Pagination
              current={currentPage}
              defaultCurrent={currentPage}
              defaultPageSize={takeResultsPerPage}
              total={count}
              onChange={(e) => setCurrentPage(e)}
            />
          </div>
        )}
      </Content>
    </>
  );
};

export default UserProfile;
