import React from 'react';
import Text from 'antd/lib/typography/Text';

import i18n from '../../features/utils/i18n';
import { Button, Tag } from 'antd';
import { useActions, useStore } from '../../store';
import moment from 'moment';

const partnerStatusColors = {
  accepted: "green",
  refused: "red"
};

const Partner: React.FC = () => {
  const { users: { current } } = useStore();
  const { invite } = current || {};
  const { usersActions } = useActions();

  const applyInvite = (type: string) => {
    if (invite?.id && current) {
      usersActions.applyUserInvitePartner(invite.id, current.id, type);
    };
  };

  const appliedDate = () => {
    const { acceptedDate, refusedDate } = current?.invite || {};
    const date = acceptedDate || refusedDate || false;
    return date ? moment(date).format('MM.DD.YYYY') : '';
  };

  return (
    <>
      {current?.invite ? (
        <div className="user-card__partner">
          <Text strong>{i18n.t('common.partners')}</Text>
          {current.invite?.status === 'pending' ? (
            <div className="partner__company-details">
              {current.invite.partner?.companyName || ''}
              <div className="partners-btns">
                <Button
                  onClick={() => applyInvite('refuse')}
                  type="primary"
                  danger
                >
                  {i18n.t('common.reject')}
                </Button>
                <Button
                  onClick={() => applyInvite('accept')}
                  type="primary"
                >
                  {i18n.t('common.partnerConfirm')}
                </Button>
              </div>
            </div>
          ) : (
            <table className="partner__custom-table">
              <thead>
                <tr>
                  <td>{i18n.t('common.name')}</td>
                  <td className="table-td">{i18n.t('common.appliedDate')}</td>
                  <td className="table-td">{i18n.t('common.status')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{current.invite.partner?.companyName || ''}</td>
                  <td className="table-td">
                    {appliedDate()}
                  </td>
                  <td className="table-td">
                    <Tag
                      color={partnerStatusColors[current.invite.status]}
                    >
                      {current.invite.status?.toUpperCase() || ''}
                    </Tag>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      ) : null}
    </>
  );
};

export default Partner;
