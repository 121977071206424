import { Dispatch } from "react";
import { PromocodeActionType, UsersActionType } from "..";
import i18n from "../../../features/utils/i18n";
import toastNotification from "../../../utility/toast-notification/toast-notification";
import ToastNotificationTypes from "../../../utility/toast-notification/types";
import { IApiService } from "../../services/api-service/api-service";
import { ICreatePromocode, IGetPromocodesParams, IUpdatePromocode } from "../../types/promocode/promocode-dto";
import { IGetUsersParams } from "../../types/users/users-dto";
import { IAction } from "../action";

const errorMessage = (error: any) => {
  if (
    typeof error.statusCode === 'number' || 
    typeof error.code === 'number'
  ) {
    return error.message;
  }
  return i18n.t('notifications.default');
};

export interface IPromocodeActions {
  createPromocode: (
    data: ICreatePromocode,
    params: IGetPromocodesParams
  ) => Promise<boolean>;
  updatePromocode: (data: IUpdatePromocode, id: number) => Promise<boolean>;
  getPromocodes: (payload: IGetPromocodesParams) => Promise<void>;
  getPromoById: (id: string) => Promise<void>;
  resetCurrent: () => void;
  getUsersByPartnerId: (params: IGetUsersParams) => Promise<any>;
}

export class PromocodeActions implements IPromocodeActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService) {
    this.dispatch = dispatch;
    this.api = api;
  }

  public createPromocode = async (
    data: ICreatePromocode,
    params: IGetPromocodesParams
  ): Promise<boolean> => {
    try {
      await this.api.post({ url: '/promocode', data });
      await this.getPromocodes(params);
      return true;
    } catch (error) {
      console.error(error);
      const message = errorMessage(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message,
      });
      return false;
    }
  };

  public updatePromocode = async (
    data: IUpdatePromocode,
    id: number
  ): Promise<boolean> => {
    try {
      await this.api.patch({ url: `/promocode/${id}`, data });
      await this.getPromoById(String(id));
      return true;
    } catch (error) {
      console.error(error);
      const message = errorMessage(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message,
      });
      return false;
    }
  };

  public resetCurrent = (): void => {
    this.dispatch({ type: PromocodeActionType.ResetCurrent, payload: null });
  };

  public getPromocodes = async (params: IGetPromocodesParams): Promise<void> => {
    try {
      for (const key in params) {
        if (!params[key]) {
          delete params[key]
        }
      }
      this.dispatch({ type: PromocodeActionType.GetPromocodesRequest, payload: null });
      const response = await this.api.get({
        url: '/promocode',
        params
      });
      this.dispatch({ type: PromocodeActionType.GetPromocodesSuccess, payload: response });
    } catch (error) {
      this.dispatch({ type: PromocodeActionType.GetPromocodesFailure, payload: error });
      console.error(error);
      const message = errorMessage(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message,
      });
    }
  };

  public getPromoById = async (id: string): Promise<void> => {
    try {
      this.dispatch({ type: PromocodeActionType.GetPromoByIdRequest, payload: null });
      const response = await this.api.get({ url: `promocode/${id}` });
      this.dispatch({ type: PromocodeActionType.GetPromoByIdSuccess, payload: response });
    } catch (error) {
      this.dispatch({ type: PromocodeActionType.GetPromoByIdFailure, payload: null });
      console.error(error);
      const message = errorMessage(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message,
      });
    }
  }

  public getUsersByPartnerId = async (params: IGetUsersParams): Promise<any> => {
    try {
      this.dispatch({ type: PromocodeActionType.SetLoading, payload: true });
      const { data } = await this.api.get({
        url: `user/get-all`,
        params
      });
      this.dispatch({ type: PromocodeActionType.SetPromoUsers, payload: data });
    } catch (error) {
      console.error(error);
    } finally {
      this.dispatch({ type: PromocodeActionType.SetLoading, payload: false });
    }
  };
}