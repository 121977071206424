import { MinLength } from 'class-validator';
import i18n from '../../features/utils/i18n'

class Templates {
  public constructor(content) {
    this.content = content;
  }

  @MinLength(1, {
    message: i18n.t('validation.least1'),
  })
  content: string;
}

export default Templates;
