import { IAction } from "../../actions/action";
import { IAppState } from "../../state-context";
import { IError } from "../../types/common";
import { IGetPromocodesResponse } from "../../types/promocode/promocode-dto";
import { IPromocode } from "../../types/promocode/promocode-entity";
import { IUser } from "../../types/users/user-entity";

export const enableLoading = (state: IAppState): IAppState => {
  return {
    ...state,
    promocode: {
      ...state.promocode,
      loading: true,
    }
  }
};

export const getPromocodesSuccess = (
  state: IAppState,
  { payload }: IAction<IGetPromocodesResponse>
): IAppState => {
  return {
    ...state,
    promocode: {
      ...state.promocode,
      list: payload.data,
      count: payload.count,
      loading: false
    }
  }
};

export const getPromocodesFailure = (
  state: IAppState,
  action: IAction<IError>
): IAppState => {
  return {
    ...state,
    promocode: {
      ...state.promocode,
      list: [],
      count: 0,
      loading: false,
      error: action.payload
    }
  }
};

export const getPromoByIdSuccess = (
  state: IAppState,
  { payload }: IAction<IPromocode>
): IAppState => {
  return {
    ...state,
    promocode: {
      ...state.promocode,
      current: payload,
      loading: false
    }
  }
};

export const getPromoByIdFailure = (
  state: IAppState,
  action: IAction<IError>
): IAppState => {
  return {
    ...state,
    promocode: {
      ...state.promocode,
      current: null,
      loading: false,
      error: action.payload
    }
  }
};

export const resetCurrentPromo = (state: IAppState): IAppState => {
  return {
    ...state,
    promocode: {
      ...state.promocode,
      current: null,
      users: []
    }
  }
};

export const setPromoLoading = (
  state: IAppState,
  { payload }: IAction<boolean>
) => {
  return {
    ...state,
    promocode: {
      ...state.promocode,
      loading: payload
    }
  }
};

export const setPromoUsers = (
  state: IAppState,
  { payload }: IAction<IUser[]>
) => {
  return {
    ...state,
    promocode: {
      ...state.promocode,
      users: payload
    }
  }
};