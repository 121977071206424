import { IGetWatchesResponse } from '../../types/watch/watch-dto';
import { IWatch } from '../../types/watch/watch-entity';
import { IAppState } from '../../state-context';
import { IAction } from '../../actions/action';

export const getUserWatchesByIdRequest = (state: IAppState): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    loading: true,
  },
});

export const getUserWatchesByIdSuccess = (
  state: IAppState,
  { payload }: IAction<IGetWatchesResponse>,
): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    list: payload.data,
    count: payload.count,
    loading: false,
  },
});

export const getUserWatchesByIdFailure = (state: IAppState): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    loading: false,
  },
});

export const getUserWatchByIdRequest = (state: IAppState): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    currentLoading: true,
  },
});

export const getUserWatchByIdSuccess = (
  state: IAppState,
  { payload }: IAction<IWatch>,
): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    current: payload,
    currentLoading: false,
  },
});

export const getUserWatchByIdFailure = (state: IAppState): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    currentLoading: false,
  },
});

export const appraiseWatchRequest = (state: IAppState): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    currentLoading: true,
  },
});

export const appraiseWatchSuccess = (
  state: IAppState,
  { payload }: IAction<IWatch>,
): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    current: payload,
    currentLoading: false,
  },
});

export const appraiseWatchFailure = (state: IAppState): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    currentLoading: false,
  },
});

export const rejectWatchRequest = (state: IAppState): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    currentLoading: true,
  },
});

export const rejectWatchSuccess = (
  state: IAppState,
  { payload }: IAction<IWatch>,
): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    current: payload,
    currentLoading: false,
  },
});

export const rejectWatchFailure = (state: IAppState): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    currentLoading: false,
  },
});

export const getLoansByWatchSuccess = (
  state: IAppState,
  { payload }: IAction<[]>,
): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    loans: payload,
  },
});

export const getLoansByWatchFailure = (state: IAppState): IAppState => ({
  ...state,
  userWatch: {
    ...state.userWatch,
    currentLoading: false,
  },
});


