import React from 'react';
import { Col, Row } from 'antd';

import ResetPasswordForm from '../../components/reset-password-form';
import loginImage from '../../assets/images/loginImage.svg';
import '../login/styles.css';
import i18n from '../../features/utils/i18n';

const ResetPassword: React.FC = () => (
  <Row className="auth-section" align="middle">
    <Col xs={0} lg={10}>
      <div className="auth-screen-wrapper">
        <img src={loginImage} alt="loginImage" className="auth-screen-wrapper__image" />
      </div>
    </Col>
    <Col xs={24} lg={14}>
      <div className="auth-screen-wrapper__form">
        <h1 className="auth-screen-wrapper__form__title">{i18n.t('common.logo')}</h1>
        <ResetPasswordForm />
      </div>
    </Col>
  </Row>
);

export default ResetPassword;
