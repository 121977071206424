import { Dispatch } from 'react';

import { IAction } from '../action';
import { UiActionType } from '../actions-types';

export interface IUiActions {
  setActiveUsersTab: (key: string) => void;
}

export class UiActions implements IUiActions {
  protected readonly dispatch: Dispatch<IAction>;

  public constructor(dispatch: Dispatch<IAction>) {
    this.dispatch = dispatch;
  }

  public setActiveUsersTab = (key: string): void => {
    this.dispatch({ type: UiActionType.SetActiveUsersTab, payload: key });
  };
}
