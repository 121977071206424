import React, { useState } from 'react';
import { Layout, Pagination } from 'antd';
import { useLocation } from 'react-router';

import { useStore } from '../../store';
import { takeResultsPerPage } from '../../constants';
import parseParams from '../../utility/parse-params';
import LoansFilterBar from '../../components/loans-filter-bar';

import LoansTable from './table';
import './style.css';
import { IGetLoansListQueryParams } from '../../store/types/loan/loan-dto';

const { Header, Content } = Layout;

const LoansContent = () => {
  const location = useLocation();
  const params: IGetLoansListQueryParams = parseParams(location.search);
  const { loans } = useStore();
  const [currentPage, setCurrentPage] = useState<number>(Number(params.page) || 1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Layout className="loans">
      <div className="container">
        <Header>
          <LoansFilterBar currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </Header>
        <Content>
          <div className="table-wrapper">
            <LoansTable isLoading={loans.loading} data={loans.list} />
          </div>
          {loans.count > takeResultsPerPage && (
            <div className="pagination-wrapper">
              <Pagination
                defaultCurrent={currentPage}
                current={currentPage}
                defaultPageSize={takeResultsPerPage}
                total={loans.count || 1}
                onChange={handlePageChange}
              />
            </div>
          )}
        </Content>
      </div>
    </Layout>
  );
};

export default LoansContent;
