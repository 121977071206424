import { useEffect } from 'react';

import { useActions, useStore } from '../../store';
import { IUser } from '../../store/types/users/user-entity';

export interface IUseProfileHooks {
  user: IUser | null;
  deleteUser: () => Promise<void>;
  logoutUser: () => void;
  loading: boolean;
}

export function UseProfileHooks(): IUseProfileHooks {
  const {
    authActions: {
      getUser,
      deleteUser,
      logoutUser,
    },
  } = useActions();

  const { auth: { user, loading } } = useStore();

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, []);

  return {
    user,
    deleteUser,
    logoutUser,
    loading,
  };
}
