import { Dispatch } from "react";
import i18n from "../../../features/utils/i18n";
import { Routes } from "../../../router/routes";
import { IPushTo } from "../../../router/types";
import toastNotification from "../../../utility/toast-notification/toast-notification";
import ToastNotificationTypes from "../../../utility/toast-notification/types";
import { IApiService } from "../../services/api-service/api-service";
import {
  ICreatePartner,
  IGetPartnersParams
} from "../../types/partner/partner-dto";
import { IGetUsersParams } from "../../types/users/users-dto";
import { IAction } from "../action";
import { PartnerActionType } from "../actions-types";

const errorMessage = (error: any) => {
  if (
    typeof error.statusCode === 'number' || 
    typeof error.code === 'number'
  ) {
    return error.message;
  }
  return i18n.t('notifications.default');
};

export interface IPartnerActions {
  createPartner: (
    data: ICreatePartner,
    params: IGetPartnersParams
  ) => Promise<boolean>;
  deletePartner: (id, params?: IGetPartnersParams) => Promise<void>;
  updatePartner: (data: ICreatePartner, id: number) => Promise<boolean>;
  getPartners: (payload: IGetPartnersParams) => Promise<void>;
  getPartnerById: (id: string) => Promise<void>;
  resetCurrentPartner: () => void;
  getUsersByPartnerId: (params: IGetUsersParams) => Promise<any>;
}

export class PartnerActions implements IPartnerActions {
  protected readonly dispatch: Dispatch<IAction>;

  protected readonly pushTo: IPushTo;

  private readonly api: IApiService;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService, pushTo: IPushTo) {
    this.dispatch = dispatch;
    this.api = api;
    this.pushTo = pushTo;
  }

  public createPartner = async (
    data: ICreatePartner,
    params: IGetPartnersParams
  ): Promise<boolean> => {
    try {
      await this.api.post({ url: '/partner', data });
      await this.getPartners(params);
      return true;
    } catch (error) {
      console.error(error);
      const message = errorMessage(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message,
      });
      return false;
    }
  };

  public updatePartner = async (
    data: ICreatePartner,
    id: number
  ): Promise<boolean> => {
    try {
      await this.api.patch({ url: `/partner/${id}`, data });
      this.getPartnerById(String(id));
      return true;
    } catch (error) {
      console.error(error);
      const message = errorMessage(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message,
      });
      return false;
    }
  };

  public getPartners = async (params: IGetPartnersParams): Promise<void> => {
    try {
      for (const key in params) {
        if (!params[key]) {
          delete params[key]
        }
      }
      this.dispatch({ type: PartnerActionType.GetPartnersRequest, payload: null });
      const response = await this.api.get({
        url: '/partner',
        params
      });
      this.dispatch({ type: PartnerActionType.GetPartnersSuccess, payload: response });
    } catch (error) {
      this.dispatch({ type: PartnerActionType.GetPartnersFailure, payload: error });
      console.error(error);
      const message = errorMessage(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message,
      });
    }
  };

  public resetCurrentPartner = (): void => {
    this.dispatch({ type: PartnerActionType.ResetCurrentPartner, payload: null });
  };

  public getPartnerById = async (id: string): Promise<void> => {
    try {
      this.dispatch({ type: PartnerActionType.GetPartnerByIdRequest, payload: null });
      const response = await this.api.get({ url: `partner/${id}` });
      this.dispatch({ type: PartnerActionType.GetPartnerByIdSuccess, payload: response });
    } catch (error) {
      this.dispatch({ type: PartnerActionType.GetPartnerByIdFailure, payload: null });
      console.error(error);
      const message = errorMessage(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message,
      });
    }
  }

  public deletePartner = async (id, params?: IGetPartnersParams) => {
    try {
      this.dispatch({ type: PartnerActionType.SetLoading, payload: true });
      await this.api.delete({ url: `partner/${id}` });
      await this.getPartners(params = {});
    } catch (error) {
      console.error(error);
      const message = errorMessage(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message,
      });
    } finally {
      this.dispatch({ type: PartnerActionType.SetLoading, payload: false });
      this.pushTo(Routes.partners);
    }
  }

  public getUsersByPartnerId = async (params: IGetUsersParams): Promise<any> => {
    try {
      this.dispatch({ type: PartnerActionType.SetLoading, payload: true });
      const { data } = await this.api.get({
        url: `user/get-all`,
        params
      });
      this.dispatch({ type: PartnerActionType.SetPartnerUsers, payload: data });
    } catch (error) {
      console.error(error);
    } finally {
      this.dispatch({ type: PartnerActionType.SetLoading, payload: false });
    }
  };
}