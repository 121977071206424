import React from 'react';
import 'reflect-metadata';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import AppRouter from './router/AppRouter';
import StoreProvider from './components/providers/store-provider';
import ApiProvider from './components/providers/api-provider';
import ActionsProvider from './components/providers/actions-provider';
import './index.css';
import * as serviceWorker from './serviceWorker';

const uri = process.env.REACT_APP_API_URL || '';

const Index = () => (
  <StoreProvider>
    <ApiProvider apiConfig={{ uri }}>
      <Router>
        <ActionsProvider>
          <AppRouter />
        </ActionsProvider>
      </Router>
    </ApiProvider>
  </StoreProvider>
);

ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
