import { useEffect } from 'react';

import { useStore } from '../store/state-context';
import { useActions } from '../store';

export const useRefreshToken = (): void => {
  const { authActions } = useActions();
  const { auth } = useStore();
  useEffect(() => {
    if (!auth.accessToken && auth.refreshToken) {
      authActions.getNewAccessToken();
    }
  }, [auth.accessToken]);
};

export default useRefreshToken;
