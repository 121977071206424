import React from 'react';
import { Select, Input } from 'antd';
import './style.css';
import { FieldError } from './error-component';
import i18n from '../../features/utils/i18n';

const { Option } = Select;

export interface ISelectOptionWithMenu {
  value: any;
  label: string;
  meta?: any;
}

interface ISelectorProps {
  fieldName: string;
  options: ISelectOptionWithMenu[];
  onSelect: (value: any) => void;
  value?: any;
  formik: any;
  label?: string;
  disabled?: boolean;
  className?: string;
  defaultValue?: any;
  placeholder?: string;
}

export const SelectorWithInput: React.FC<ISelectorProps> = ({
  value,
  options,
  formik,
  onSelect,
  fieldName,
  defaultValue,
  placeholder = '',
  className = '',
  label = '',
  disabled = false,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      className={`selector ${className}`}
    >
      {label && <p className="selector__label">{label}</p>}
      <Select
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onSelect={onSelect}
        className="selector__input"
        disabled={disabled}
      >
        {options.map((o) => <Option key={o.value} value={o.value}>{o.label}</Option>)}
      </Select>

      { value === null
        && (
          <div style={{ marginBottom: '-25px' }}>
            <p style={{ marginTop: '25px' }} className="selector__label">
              {label}
              {' '}
              -
              {' '}
              {i18n.t('watches.customValue')}
            </p>
            <Input
              defaultValue={formik.values[fieldName]}
              className="watch-form__autocomplete"
              value={formik.values[fieldName]}
              name={fieldName}
              onChange={formik.handleChange}
            />
            <FieldError error={formik.errors[fieldName]} />
          </div>
        )}
    </div>
  );
};
