import React from 'react';
import { Form, Input, Button } from 'antd';
import { useFormik } from 'formik';

import { validateForm } from '../../utility/validation/class-validator';
import ForgotPassword from './validation-schema';
import { useActions } from '../../store';
import { IForgotPasswordRequest } from '../../store/types/auth/auth-dto';
import i18n from '../../features/utils/i18n';

const ForgotPasswordForm = () => {
  const { authActions } = useActions();
  const initialValues: IForgotPasswordRequest = {
    email: '',
  };

  const handleSubmit = (values: IForgotPasswordRequest) => {
    authActions.forgotPassword(values);
  };

  const formik = useFormik({
    initialValues,
    validate: async (values) => await validateForm(ForgotPassword, values),
    onSubmit: handleSubmit,
  });
  return (
    <div className="login-form">
      <h3 className="login-form__title">{i18n.t('auth.forgotPassword')}</h3>
      <p className="login-form__description">
        {i18n.t('auth.enterTheEmail')}
      </p>
      <Form name="email" onFinish={formik.handleSubmit}>
        <Form.Item label={i18n.t('auth.emailOrPhone')} className="login-form__label">
          <Input
            name="email"
            placeholder={i18n.t('auth.exEmail')}
            value={formik.values.email}
            onChange={formik.handleChange}
            className="login-form__input"
          />
          <div className="error-validation error-validation--color">{formik.errors.email ? formik.errors.email : ''}</div>
        </Form.Item>
        <Button htmlType="submit" className="button--primary login-form__submit-btn" disabled={!formik.dirty || !!formik.errors.email}>
          {i18n.t('auth.resetPassword')}
        </Button>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
