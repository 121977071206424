import { MaxLength, MinLength, Validate } from 'class-validator';
import { CustomLoginValidator } from '../../utility/validation/validator-constraint';
import i18n from '../../features/utils/i18n'

class ForgotPassword {
  public constructor(email = '') {
    this.email = email;
  }

  @MinLength(8, {
    message: i18n.t('auth.least8'),
  })
  @MaxLength(30, {
    message: i18n.t('auth.less30') ,
  })
  @Validate(CustomLoginValidator)
  email: string;
}

export default ForgotPassword;
