import { Dispatch } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Routes } from '../../../router/routes';
import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import { NotificationsActionType } from '../actions-types';
import formatDate from '../../../utility/date/format-date';
import { IGetNotificationsListResponse, IGetNotificationsListQueryParams } from '../../types/notification/notification-dto';
import { IPushTo } from '../../../router/types';
import buildQueryString from '../../../utility/build-query-string';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import { IDeleteResult } from '../../types/common';

export interface INotificationsActionTypes {
  getNotifications:
  (payload: IGetNotificationsListQueryParams) => Promise<IGetNotificationsListResponse | undefined>;
  deleteNotification: ({ id: number, query: any }) => Promise<void>;
  setNotificationViewed: (id: number) => Promise<void>;
  getNotificationsAndSetViewedRenderedNotification:
  (payload: IGetNotificationsListQueryParams) => void;
  getNotificationsCounter: () => void;
}
export class NotificationsActionTypes implements INotificationsActionTypes {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  protected readonly pushTo: IPushTo;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService, pushTo: IPushTo) {
    this.dispatch = dispatch;
    this.api = api;
    this.pushTo = pushTo;
  }

  public getNotifications = async (payload: IGetNotificationsListQueryParams):
  Promise<IGetNotificationsListResponse | undefined> => {
    try {
      this.dispatch({ type: NotificationsActionType.GetNotificationsRequest, payload: null });
      let queryParams = { ...payload };

      if (payload.dateFrom && payload.dateTo) {
        queryParams = {
          ...queryParams,
          dateFrom: formatDate(payload.dateFrom),
          dateTo: formatDate(payload.dateTo),
        };
      }

      const queryString = buildQueryString(queryParams);
      if (queryString) {
        this.pushTo(`${Routes.notifications}/?${queryString}`);
      }

      const urlString = buildQueryString(queryParams);

      const result: IGetNotificationsListResponse = await this.api.get({
        url: `notification?${urlString}`,
      });

      this.dispatch({
        type: NotificationsActionType.GetNotificationsSuccess,
        payload: result,
      });

      return result;
    } catch (error) {
      this.dispatch({ type: NotificationsActionType.GetNotificationsFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
      return error;
    }
  };

  public deleteNotification = async ({ id, query }) => {
    try {
      this.dispatch({ type: NotificationsActionType.DeleteNotificationRequest, payload: null });
      const result: IDeleteResult = await this.api.delete({
        url: `/notification/${id}`,
      });
      this.dispatch({
        type: NotificationsActionType.DeleteNotificationSuccess,
        payload: result.id,
      });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Notification was deleted' });
      this.getNotificationsAndSetViewedRenderedNotification({ ...query });
    } catch (err) {
      this.dispatch({ type: NotificationsActionType.DeleteNotificationFailure, payload: null });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public setNotificationViewed = async (id: number) => {
    try {
      this.dispatch({ type: NotificationsActionType.SetNotificationViewedRequest, payload: null });
      await this.api.patch({
        url: `/notification/set-viewed/${id}`,
      });
      this.dispatch({ type: NotificationsActionType.SetNotificationViewedSuccess, payload: null });
    } catch (error) {
      this.dispatch({ type: NotificationsActionType.SetNotificationViewedFailure, payload: null });
      console.error(error);
    }
  };

  public getNotificationsAndSetViewedRenderedNotification =
  async (payload: IGetNotificationsListQueryParams) => {
    try {
      const response:
      IGetNotificationsListResponse | undefined = await this.getNotifications(payload);
      if (!isEmpty(response)) {
        return response?.data.map(({ id, viewed }) => !viewed && this.setNotificationViewed(id));
      }
      return;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  public getNotificationsCounter = async () => {
    try {
      const result: IGetNotificationsListResponse = await this.api.get({
        url: 'notification?page=1',
      });
      this.dispatch({
        type: NotificationsActionType.GetNotificationsCounterSuccess,
        payload: result.newCount,
      });
    } catch (error) {
      console.error(error);
      return error;
    }
  };
}
