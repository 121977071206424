import React from 'react';
import { Col, Row, Spin } from 'antd';

import ForgotPasswordForm from '../../components/forgot-password-form';
import loginImage from '../../assets/images/loginImage.svg';
import '../login/styles.css';
import { useStore } from '../../store';
import i18n from '../../features/utils/i18n';

const ForgotPassword = () => {
  const { auth } = useStore();
  return (
    <Row className="auth-section" align="middle">
      <Col xs={0} lg={10}>
        <div className="auth-screen-wrapper">
          <img src={loginImage} alt="Login Image" className="auth-screen-wrapper__image" />
        </div>
      </Col>
      <Col xs={24} lg={14}>
        {auth.loading ? <Spin className="spinner" />
          : (
            <div className="auth-screen-wrapper__form">
              <h1 className="auth-screen-wrapper__form__title">{i18n.t('common.logo')}</h1>
              <ForgotPasswordForm />
            </div>
          )}
      </Col>
    </Row>
  );
};

export default ForgotPassword;
