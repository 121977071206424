import React, { useMemo } from 'react';

import ApiContext from './api-context';
import { ApiService } from '../../../store/services';
import { useDispatch } from '../../../store/dispatch-context';

export interface IApiConfig {
  uri: string;
  headers?: Record<string, string>;
}

interface IApiProviderProps {
  apiConfig: IApiConfig;
}

const ApiProvider: React.FC<IApiProviderProps> = ({ children, apiConfig }) => {
  const { uri } = apiConfig;
  const dispatch = useDispatch();
  const api = useMemo(() => new ApiService(uri, dispatch), [uri, dispatch]);
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

ApiProvider.displayName = 'ApiProvider';

export default ApiProvider;
