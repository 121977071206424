import React from 'react';
import { Avatar, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import AvatarUploader from '../avatar-uploader';
import { IUser } from '../../store/types/users/user-entity';
import { Roles } from '../../constants';

import ProfileContent from './profile-content';
import i18n from '../../features/utils/i18n';

interface IProps {
  user: IUser;
  loggedInAdmin: boolean;
}
const ProfileContentWrapper: React.FC<IProps> = ({ user, loggedInAdmin }) => {
  if (!user) {
    return null;
  }

  const {
    avatar,
    role,
    name,
    isSubscribed
  } = user;

  return (
    <>
      <div className="header-holder">
        <h2 className="header__title">
          {loggedInAdmin ? i18n.t('profile.profile') :  i18n.t('profile.adminsProfile')}
        </h2>
        <div className="profile-card">
          <div className="profile-card__left-side">
            {loggedInAdmin && <AvatarUploader />}
            {avatar ? (
              <Avatar size={102} src={avatar} className="profile-card__avatar" />
            ) : (
              <Avatar size={102} icon={<UserOutlined />} className="profile-card__avatar" />
            )}
            <div className="profile-card__info">
              <div className="profile-card__name">{name}</div>
              {role === Roles.ADMIN
                ? <Tag className="profile-card__tag" color="cyan">{ i18n.t('profile.admin')}</Tag>
                : <Tag className="profile-card__tag" color="green">{ i18n.t('profile.superAdmin')}</Tag>}
              <div className="profile-card__status">
                {i18n.t('common.status')}
                :
                {' '}
                {i18n.t(`profile.${isSubscribed ? "platinum" : "nonPlatinum"}`)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileContent profile={user} loggedInAdmin={loggedInAdmin} />
    </>
  );
};

export default ProfileContentWrapper;
