import { IGetOwnWatchesListResponse } from '../../types/own-watches/own-watch-dto';
import { IAction } from '../../actions/action';
import { IAppState } from '../../state-context';
import IOwnWatchEntity from '../../types/own-watches/own-watch-entity';

export const getOwnWatchesRequest = (state: IAppState): IAppState => ({
  ...state,
  ownWatches: {
    ...state.ownWatches,
    loading: true,
  },
});

export const getOwnWatchesSuccess = (
  state: IAppState,
  { payload: { data, count } }: IAction<IGetOwnWatchesListResponse>,
): IAppState => ({
  ...state,
  ownWatches: {
    ...state.ownWatches,
    list: data,
    loading: false,
    count,
  },
});

export const getOwnWatchesFailure = (state: IAppState): IAppState => ({
  ...state,
  ownWatches: {
    ...state.ownWatches,
    loading: false,
  },
});

export const getOwnWatchByIdRequest = (state: IAppState): IAppState => ({
  ...state,
  ownWatches: {
    ...state.ownWatches,
    currentLoading: true,
  },
});

export const getOwnWatchByIdSuccess = (
  state: IAppState,
  { payload }: IAction<IOwnWatchEntity>,
): IAppState => ({
  ...state,
  ownWatches: {
    ...state.ownWatches,
    current: payload,
    currentLoading: false,
  },
});

export const getOwnWatchByIdFailure = (state: IAppState): IAppState => ({
  ...state,
  ownWatches: {
    ...state.ownWatches,
    currentLoading: false,
  },
});

export const changeOwnWatchStatusByIdRequest = (state: IAppState): IAppState => ({
  ...state,
  ownWatches: {
    ...state.ownWatches,
    currentLoading: true,
  },
});

export const changeOwnWatchStatusByIdSuccess = (state: IAppState): IAppState => ({
  ...state,
  ownWatches: {
    ...state.ownWatches,
    currentLoading: false,
  },
});

export const changeOwnWatchStatusByIdFailure = (state: IAppState): IAppState => ({
  ...state,
  ownWatches: {
    ...state.ownWatches,
    currentLoading: false,
  },
});

export const deleteOwnWatchRequest = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    ownWatches: {
      ...state.ownWatches,
      loading: true,
    },
  };
};

export const deleteOwnWatchSuccess = (
  state: IAppState,
  { payload: deletedId }: IAction<number>,
): IAppState => {
  const result = {
    ...state,
    ownWatches: {
      ...state.ownWatches,
      list: state.ownWatches.list.filter((ownWatch) => ownWatch.id !== deletedId),
      loading: false,
    },
  };
  return result;
};

export const deleteOwnWatchFailure = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    ownWatches: {
      ...state.ownWatches,
      loading: false,
    },
  };
};
