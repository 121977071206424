export const getFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
): string | null | undefined => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } if (firstName || lastName) {
    return firstName || lastName;
  }
  return null;
};

export default getFullName;
