import React from 'react';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import classNames from 'classnames';

import { useActions } from '../../../../store';
import { validateForm } from '../../../../utility/validation/class-validator';
import DeliveryMethod from './validation-schema';
import PlusInCircle from '../../../icons/plus-in-circle-icon';
import i18n from '../../../../features/utils/i18n';

const { confirm } = Modal;

interface IWrappedFormProps {
  initialValues: {
    id: number;
    name: string;
    insuredShipping: boolean;
  }
  placeholder: string;
  disabled?: boolean;
  showPlusButton?: boolean;
}

export const WrappedForm: React.FC<IWrappedFormProps> = ({
  initialValues,
  placeholder,
  disabled = false,
}) => {
  const isNew = initialValues.id < 0;
  const { t } = useTranslation();
  const { loanTermsActions } = useActions();

  const onDelete = () => {
    confirm({
      title: i18n.t('finAgreementTerms.confirmDelete'),
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      centered: true,
      onOk() {
        if (initialValues.id > 0) {
          loanTermsActions.deleteDeliveryMethod(initialValues.id);
        }
      },
    });
  };

  const formik = useFormik({
    initialValues,
    validate: async (values) => validateForm(DeliveryMethod, values),
    onSubmit: (values) => {
      confirm({
        title:  i18n.t('finAgreementTerms.confirmAdd'),
        icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
        onOk() {
          loanTermsActions.createDeliveryMethod({
            name: values.name,
            insuredShipping: values.insuredShipping,
          });
          formik.resetForm();
        },
      });
    },
  });

  const disabledAddButton = Boolean(formik.errors.name) || !formik.dirty;

  return (
    <Form>
      <div className="delivery-method-form__input-wrapper">
        <Input
          name="name"
          disabled={disabled}
          readOnly={!isNew}
          placeholder={placeholder}
          value={formik.values.name}
          className={classNames({
            'delivery-method-form__read-only-input': isNew,
            'delivery-method-form__name': true,
          })}
          onChange={formik.handleChange}
          onBlur={() => formik.setErrors({})}
        />
        {!isNew && (
          <DeleteOutlined
            role="button"
            onClick={onDelete}
            className="delivery-method-form__btn delivery-method-form__delete-btn"
          />
        )}
        {isNew && (
          <span className="delivery-method-form__btn delivery-method-form__add-btn" role="button" onClick={formik.submitForm}>
            <PlusInCircle classname={classNames({ 'delivery-method-form__add-btn--disabled': disabledAddButton })} />
          </span>
        )}
        <div className="error-validation error-validation--color">{formik.errors.name}</div>
      </div>
    </Form>
  );
};
