import React from 'react';
import moment from 'moment';

import WatchStatus from '../../constants/watch-status';
import { IUser } from '../../store/types/users/user-entity';
import WatchStatusColor from '../../constants/watch-status-color';
import { IBrand, IModel, IWatchParameter } from '../../store/types/common/watch-params';
import i18n from '../../features/utils/i18n'
import { Button } from 'antd';
import IOwnWatchEntity from '../../store/types/own-watches/own-watch-entity';

export const ownWatchesColumns = (
  onDelete: (id: number) => void,
) => {
  return  [
    {
      title: i18n.t('ownWatches.referenceNumber'),
      dataIndex: 'referenceNumber',
      width: '13.7%',
      className: 'own-watches-table__reference-number',
    },
    {
      title: i18n.t('common.brand'),
      dataIndex: 'brand',
      width: '15%',
      className: 'own-watches-table__brand',
      render: (brand: IBrand) => <div className="custom-table-spaces">{brand?.name || ''}</div>,
    },
    {
      title: i18n.t('common.model'),
      width: '9.5%',
      dataIndex: 'model',
      className: 'own-watches-table__brand',
      render: (model: IModel) => <div className="custom-table-spaces">{model}</div>,
    },
    {
      title: i18n.t('common.tiers'),
      width: '7.7%',
      dataIndex: 'tier',
      className: 'own-watches-table__tiers',
      render: (tier: IWatchParameter) => <div className="custom-table-spaces">{tier?.name || ''}</div>,
    },
    {
      title: i18n.t('ownWatches.condition'),
      width: '10%',
      dataIndex: 'condition',
      className: 'own-watches-table__conditional',
      render: (condition: IWatchParameter) => <div className="custom-table-spaces">{condition?.name || ''}</div>,
    },
    {
      title: i18n.t('ownWatches.boxAndPapers'),
      width: '13%',
      dataIndex: 'boxAndPapers',
      className: 'own-watches-table__boxAndPapers',
      render: (boxAndPapers: IWatchParameter) => <div className="custom-table-spaces">{boxAndPapers?.name || ''}</div>,
    },
    {
      title: i18n.t('common.date'),
      dataIndex: 'createdAt',
      width: '8%',
      className: 'own-watches-table__date',
      sorter: {
        compare: (a: { createdAt: string }, b: { createdAt: string }): number => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return Number(dateA) - Number(dateB);
        },
        render: (date) => moment(date).format('MM.DD.YYYY'),
      }
    },
    {
      title: i18n.t('financialAgreements.fromUser'),
      dataIndex: 'user',
      width: '19.7%',
      className: 'own-watches-table__fromUser',
      render: (user: IUser) => user?.name || 'N/A',
    },
    {
      title: i18n.t('common.status'),
      dataIndex: 'status',
      width: '13.8%',
      className: 'own-watches-table__status',
      render: (status: string) => {
        let watchStatus = status;
        switch (status) {
          case WatchStatus.FOR_SALE:
            watchStatus = i18n.t('common.forSale');
            break;
          case WatchStatus.SOLD:
            watchStatus = i18n.t('common.sold');
            break;
          default: break;
        }
        return <div style={{ color: WatchStatusColor[status] }}>{watchStatus}</div>;
      }
    },
    {
      title: i18n.t('common.action'),
      width: '10%',
      className: 'own-watches-table__action',
      render: (_,ownWatchConfig: IOwnWatchEntity ) => {
        return (
          <div className="own-watches__actions" role="button" onClick={(e) => e.stopPropagation()}>
            <Button
              className="button--danger"
              onClick={() => onDelete(ownWatchConfig.id)}
            >
              {i18n.t('common.delete')}
            </Button>
          </div>
        );
      },
    }
  ]
};
