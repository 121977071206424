import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

import { INotification } from '../../store/types/notification/notification-entity';
import defineNotificationConfig from './defineNotificationConfig';
import i18n from '../../features/utils/i18n';

export default function RenderMessage(notification: INotification) {
  const data = defineNotificationConfig(notification);
  return (
    <>
      <div className="notifications__title-wrapper">
        <h3 className="notifications__title">{data.title}</h3>
        {notification.viewed && (
          <div className="notifications__viewed">
            <CheckOutlined style={{ color: '#389E0D', fontSize: '12px', margin: '0 3px' }} />
            {i18n.t('notifications.seen')}
          </div>
        ) }
      </div>
      <h4 className="notifications__subtitle">{data.subtitle}</h4>
    </>
  );
}
