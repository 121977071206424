enum WatchStatus {
  FREE_APPRAISAL = 'FREE_APPRAISAL',
  AWAITING_FOR_SUBSCRIPTION = 'AWAITING_FOR_SUBSCRIPTION',
  APPRAISAL_IN_PROGRESS = 'APPRAISAL_IN_PROGRESS',
  INCLUDED_IN_LOAN = 'INCLUDED_IN_LOAN',
  REJECTED = 'REJECTED',
  APPRAISED = 'APPRAISED',
  FOR_SALE = 'FOR_SALE',
  SOLD = 'SOLD',
}

export default WatchStatus;
