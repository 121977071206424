import React, { useEffect, useState } from 'react';
import { Button, Select, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import './style.css';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useActions, useStore } from '../../../../store';
import i18n from '../../../../features/utils/i18n';

const { confirm } = Modal;
const { Option } = Select;

const getOptions = (t) => {
  return [
    { value: 1, label: `1 ${t('common.month')}` },
    { value: 2, label: `2 ${t('common.month')}` },
    { value: 3, label: `3 ${t('common.month')}` },
    { value: 4, label: `4 ${t('common.month')}` },
    { value: 5, label: `5 ${t('common.month')}` },
    { value: 6, label: `6 ${t('common.month')}` },
    { value: 7, label: `7 ${t('common.month')}` },
    { value: 8, label: `8 ${t('common.month')}` },
    { value: 9, label: `9 ${t('common.month')}` },
    { value: 10, label: `10 ${t('common.month')}` },
    { value: 11, label: `11 ${t('common.month')}` },
    { value: 12, label: `12 ${t('common.month')}` },
  ];
};

const PeriodForm = () => {
  const [isSaveDisabled, setDisabled] = useState(true);
  const { loanTermsActions } = useActions();
  const { loanTerms: { periodConfig: { data } } } = useStore();
  const [min, setMin] = useState(get(data, 'minMonths', 1));
  const [max, setMax] = useState(get(data, 'maxMonths', 12));
  const [error, setError] = useState('');
  const errorMessage = i18n.t('validation.maxGreaterMin');

  useEffect(() => {
    loanTermsActions.getPeriodConfig();
  }, []);

  useEffect(() => {
    if (data) {
      setMin(data.minMonths);
      setMax(data.maxMonths);
      setError((data.minMonths > data.maxMonths) ? errorMessage : '');
    }
  }, [data]);

  if (!data) {
    return null;
  }

  const onSave = () => {
    confirm({
      title: i18n.t('common.saveChanges'),
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      centered: true,
      onOk() {
        if (data) {
          loanTermsActions.updatePeriodConfiguration(
            data.id,
            { minMonths: min, maxMonths: max },
          );
          setDisabled(true);
        }
      },
    });
  };

  const onMinChange = (selected: number) => {
    setMin(selected);
    setError((selected >= max) ? errorMessage : '');
    setDisabled(selected >= max);
  };

  const onMaxChange = (selected: number) => {
    setMax(selected);
    setError((min >= selected) ? errorMessage : '');
    setDisabled(min >= selected);
  };

  return (
    <div>
      <p>{i18n.t('finAgreementTerms.terms')}</p>
      <div>
        <div className="period-selector-wrapper">
          <Select onSelect={onMinChange} className="period-selector__min" value={min} defaultValue={min}>
            {getOptions(i18n.t).map((o) => <Option key={o.value} value={o.value}>{o.label}</Option>)}
          </Select>
          <p className="period-selector__delimiter">{i18n.t('common.to')}</p>
          <Select onSelect={onMaxChange} className="period-selector__max" value={max} defaultValue={max}>
            {getOptions(i18n.t).map((o) => <Option key={o.value} value={o.value}>{o.label}</Option>)}
          </Select>
          <Button
            disabled={isSaveDisabled}
            className="button--primary fin-agreement-save-btn"
            onClick={onSave}
          >
            {i18n.t('common.save')}
          </Button>
        </div>
        <div className="error-validation--color">{error}</div>
      </div>
    </div>
  );
};

export default PeriodForm;
