import React, { useRef } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useFormik } from 'formik';

import { TemplateActionTypes } from '../../pages/templates/use-templates';
import './styles.css';
import { ITemplate } from '../../store/types/template/template-entity';
import { useStore } from '../../store';
import toastNotification from '../../utility/toast-notification/toast-notification';
import ToastNotificationTypes from '../../utility/toast-notification/types';
import { validateForm } from '../../utility/validation/class-validator';
import Templates from './validation-schema';
import i18n from '../../features/utils/i18n';

interface ITemplateCardProps {
  isNew?: boolean;
  template?: ITemplate;
  getCallback: (action: TemplateActionTypes, payload: string | number | ITemplate) => void;
}

const TemplateCard: React.FC<ITemplateCardProps> = ({
  isNew, template, getCallback,
}) => {
  const textAreaRef = useRef<any>();
  const { t } = useTranslation();
  const { templates } = useStore();
  const initialValues = {
    content: template?.content || '',
  };

  const defaultHandleSubmit = (values) => {
    if (template && !isNew) {
      return getCallback(TemplateActionTypes.EDIT, { id: template.id, content: values.content });
    }
    return getCallback(TemplateActionTypes.SAVE, values.content);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: async (values) => validateForm(Templates, values),
    onSubmit: defaultHandleSubmit,
  });

  const noContent = !!formik.errors.content || !formik.values.content.trim();
  const disabledSave = templates.loading || !formik.dirty || noContent;

  const copyToClipboard = (e) => {
    const select = textAreaRef?.current.select();
    document.execCommand('copy', false);
    const blur = textAreaRef?.current.blur();
    toastNotification({
      type: ToastNotificationTypes.SUCCESS,
      message: i18n.t('templates.templateCopied'),
    });
  };

  return (
    <div className={classNames('template-card__wrapper', { 'template-card__wrapper--new': isNew })}>
      <textarea
        ref={textAreaRef}
        name="content"
        placeholder={i18n.t('common.message')}
        value={formik.values.content}
        onChange={formik.handleChange}
        className="template-card__textarea"
      />
      {formik.errors.content && <div className="error-validation--color">{formik.errors.content}</div>}
      <div className="template-card__buttons">
        {isNew ? (
          <Button disabled={disabledSave} className="button--primary template-card__btn" onClick={() => getCallback(TemplateActionTypes.SAVE, formik.values.content)}>{i18n.t('common.save')}</Button>
        )
          : (template
            && (
              <div>
                <Button disabled={noContent} className="button--primary template-card__btn" onClick={copyToClipboard}>{i18n.t('templates.copy')}</Button>
                <Button disabled={noContent || !formik.dirty} className="button--primary template-card__btn" onClick={() => getCallback(TemplateActionTypes.EDIT, { id: template.id, content: formik.values.content })}>{i18n.t('common.edit')}</Button>
                <Button disabled={noContent} className="button--primary template-card__btn" onClick={() => getCallback(TemplateActionTypes.DUPLICATE, formik.values.content)}>{i18n.t('common.duplicate')}</Button>
                <Button className="button--primary template-card__btn" onClick={() => getCallback(TemplateActionTypes.DELETE, template.id)}>{i18n.t('common.delete')}</Button>
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default TemplateCard;
