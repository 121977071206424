import { IAction } from "../../actions/action";
import { IAppState } from "../../state-context";
import { IError } from "../../types/common";
import { IGetPartnersResponse } from "../../types/partner/partner-dto";
import { IPartner } from "../../types/partner/partner-entity";
import { IUser } from "../../types/users/user-entity";

export const enablePartnerLoading = (state: IAppState): IAppState => {
  return {
    ...state,
    partner: {
      ...state.partner,
      loading: true,
    }
  }
};

export const getPartnersSuccess = (
  state: IAppState,
  { payload }: IAction<IGetPartnersResponse>
): IAppState => {
  return {
    ...state,
    partner: {
      ...state.partner,
      list: payload.data,
      count: payload.count,
      loading: false
    }
  }
};

export const getPartnersFailure = (
  state: IAppState,
  action: IAction<IError>
): IAppState => {
  return {
    ...state,
    partner: {
      ...state.partner,
      list: [],
      count: 0,
      loading: false,
      error: action.payload
    }
  }
};

export const getPartnerByIdSuccess = (
  state: IAppState,
  { payload }: IAction<IPartner>
): IAppState => {
  return {
    ...state,
    partner: {
      ...state.partner,
      current: payload,
      loading: false
    }
  }
};

export const getPartnerByIdFailure = (
  state: IAppState,
  action: IAction<IError>
): IAppState => {
  return {
    ...state,
    partner: {
      ...state.partner,
      current: null,
      loading: false,
      error: action.payload
    }
  }
};

export const resetCurrentPartner = (state: IAppState): IAppState => {
  return {
    ...state,
    partner: {
      ...state.partner,
      current: null,
      users: [],
    }
  }
}

export const setPartnerLoading = (
  state: IAppState,
  { payload }: IAction<boolean>
) => {
  return {
    ...state,
    partner: {
      ...state.partner,
      loading: payload
    }
  }
};

export const setPartnerUsers = (
  state: IAppState,
  { payload }: IAction<IUser[]>
) => {
  return {
    ...state,
    partner: {
      ...state.partner,
      users: payload
    }
  }
};