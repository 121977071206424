import { IError } from '../../store/types/common';
import isForbiddenError from '../../utility/is-forbidden-error';

export interface IErrorsState {
  login: string[];
  password: string[] | string;
}

const prepareAuthErrors = (error: IError) => {
  let errors: IErrorsState = { login: [], password: [] };
  if (error && !Array.isArray(error?.message) && !isForbiddenError(error)) {
    errors = { ...errors, password: [error.message] };
  } else if (error && Array.isArray(error?.message)) {
    const messages = error?.message;
    const preparedErrors = messages.reduce((acc, message) => {
      if (message.toLowerCase().includes('login')) {
        return { ...acc, login: [...acc.login, message, message] };
      }
      return { ...acc, password: [...acc.password, message] };
    }, errors);
    errors = preparedErrors;
  }
  return errors;
};

export default prepareAuthErrors;
