import {
  Button, Card, Layout, Pagination, Spin, Table, Tag,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { AlignType } from 'rc-table/lib/interface';
import { useParams } from 'react-router';
import moment from 'moment';

import BackButton from '../../components/back-button';
import i18n from '../../features/utils/i18n';
import { Routes } from '../../router/routes';
import { useActions, useStore } from '../../store';
import { PromoModal } from '../../components/promocode';
import './styles.css';
import { takeResultsPerPage } from '../../constants';

const columns = [
  {
    title: i18n.t('common.name'),
    dataIndex: 'name',
  },
  {
    title: i18n.t('common.appliedDate'),
    dataIndex: 'regDate',
    width: '120px',
    align: 'center' as AlignType,
    render: (date: moment.MomentInput) => {
      return (
        <div className="custom-table-spaces">
          {moment(date).format('MM.DD.YYYY')}
        </div>
      );
    },
  },
];

const Promocode = () => {
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = React.useState(1);
  const { promocodeActions } = useActions();
  const {
    promocode: {
      current,
      loading,
      users,
    },
  } = useStore();
  // const { location, push } = useHistory();

  // const handleRowClick = (data: IUser): void => {
  //   return push({ pathname: `${USER}/${data.id}` }, { prevPath: location.pathname });
  // };

  React.useEffect(() => {
    promocodeActions.getPromoById(id);

    return () => {
      promocodeActions.resetCurrent();
    };
  }, []);

  if (!current) {
    return null;
  }

  const color = (status: string) => {
    let color = 'green';
    if (status === 'deleted') {
      color = 'red';
    } else if (status === 'expired') {
      color = 'orange';
    } else if (status === 'awaiting') {
      color = 'gray';
    }
    return color;
  };

  const fetchUserList = () => {
    if (current && current.usersCount && current.usersCount > 0 && !users.length) {
      promocodeActions.getUsersByPartnerId({
        take: takeResultsPerPage,
        page,
        promocode: current.id,
      });
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    fetchUserList();
  };

  return (
    <Layout className="container promo-details">
      <BackButton path={Routes.promocodes} />
      <Spin spinning={loading}>
        <Card
          title={current.name}
          extra={<PromoModal promo={current} />}
        >
          <div className="promo-details__item">
            <div className="promo-details__title">
              {i18n.t('common.code')}
              :
            </div>
            {current.code}
          </div>
          <div className="promo-details__item">
            <div className="promo-details__title">
              {i18n.t('common.status')}
              :
            </div>
            <Tag color={color(current.status)}>
              {current.status.toUpperCase()}
            </Tag>
          </div>
          <div className="promo-details__item">
            <div className="promo-details__title">
              {i18n.t('common.startDate')}
              :
            </div>
            {moment(current.startDate).format('MM.DD.YYYY')}
          </div>
          <div className="promo-details__item">
            <div className="promo-details__title">
              {i18n.t('common.endDate')}
              :
            </div>
            {moment(current.endDate).format('MM.DD.YYYY')}
          </div>
          <div className="promo-details__item">
            <div className="promo-details__title">
              {i18n.t('partner.numberOfUsers')}
              :
            </div>
            <div className="partner-details__info">{current.usersCount || 0}</div>
            {current.usersCount ? (
              <Button
                onClick={fetchUserList}
                type="primary"
                disabled={!!users.length}
              >
                {i18n.t('partner.showUserList')}
              </Button>
            ) : null}
          </div>
          {users.length ? (
            <>
              <Table
                size="small"
                className="promo__user-table"
                bordered
                columns={columns}
                dataSource={users?.map((user) => ({ ...user, key: user.id })) || []}
                pagination={false}
                title={() => (
                  <Text strong>
                    {i18n.t('users.users')}
                    :
                  </Text>
                )}
              />
              {(current.usersCount > takeResultsPerPage) ? (
                <div className="pagination-wrapper">
                  <Pagination
                    defaultCurrent={page}
                    current={page}
                    defaultPageSize={takeResultsPerPage}
                    total={current.usersCount || 1}
                    onChange={handlePageChange}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </Card>
      </Spin>
    </Layout>
  );
};

export default Promocode;
