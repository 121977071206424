import { IAppState } from '../../state-context';
import { IAction } from '../../actions/action';

export const setActiveUsersTab = (state: IAppState, { payload }: IAction<string>): IAppState => ({
  ...state,
  ui: {
    ...state.ui,
    activeUsersTab: payload,
  },
});
