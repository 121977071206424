import React from 'react';
import { Table, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';

import i18n from '../../features/utils/i18n';
import { IUserPromocode } from '../../store/types/users/user-entity';
import { useHistory } from 'react-router';
import { PROMOCODE } from '../../router/constants';

interface IProps {
  promocodes: IUserPromocode[];
  className?: string;
  title?: boolean;
  small?: boolean;
  loading?: boolean;
  clickable?: boolean
}

const columns = [
  {
    title: i18n.t('common.name'),
    dataIndex: 'name'
  },
  {
    title: i18n.t('common.code'),
    dataIndex: 'code'
  },
  {
    title: i18n.t('common.startDate'),
    dataIndex: 'startDate',
    width: '120px',
    align: 'center' as AlignType,
    render: (date: moment.MomentInput) => {
      return (
        <div className="custom-table-spaces">
          {moment(date).format('MM.DD.YYYY')}
        </div>
      )
    }
  },
  {
    title: i18n.t('common.endDate'),
    dataIndex: 'endDate',
    width: '120px',
    align: 'center' as AlignType,
    render: (date: moment.MomentInput) => {
      return (
        <div className="custom-table-spaces">
          {moment(date).format('MM.DD.YYYY')}
        </div>
      )
    }
  },
  {
    title: i18n.t('common.status'),
    dataIndex: 'status',
    width: '120px',
    align: 'center' as AlignType,
    render: (status: string) => {
      const color = () => {
        let color = 'green';
        if (status === 'deleted') {
          color = 'red';
        } else if (status === 'expired') {
          color = 'orange';
        } else if (status === 'awaiting') {
          color = 'gray';
        }
        return color;
      };
      return (
        <Tag color={color()}>
          {status.toUpperCase()}
        </Tag>
      )
    }
  },
]

export const PromocodeTable: React.FC<IProps> = ({
  promocodes,
  className,
  title,
  small,
  loading,
  clickable
}) => {
  const { push } = useHistory();

  const handleRowClick = (data: { id: number }) => {
    push({ pathname: `${PROMOCODE}/${data.id}` });
  };

  return (
    <Table
      loading={loading}
      size={small ? 'small' : 'middle'}
      className={className + ' promo-table'}
      bordered
      columns={columns}
      dataSource={promocodes.map(promo => ({...promo, key: promo.id}))}
      pagination={false}
      title={
        title ?
        () => <Text strong>{i18n.t('common.promocodes')}</Text>
        : undefined
      }
      onRow={
        clickable ?
          (data) => ({onClick: () => handleRowClick(data)})
          : undefined
      }
    />
  );
};
