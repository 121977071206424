import { Dispatch } from 'react';

import { OwnWatchesActionType } from '../actions-types';
import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import formatDate from '../../../utility/date/format-date';
import buildQueryString from '../../../utility/build-query-string';
import { IPushTo } from '../../../router/types';
import IOwnWatchEntity from '../../types/own-watches/own-watch-entity';
import { IChangeOwnWatchStatusPayload, IGetOwnWatchesListResponse, IGetOwnWatchesListQueryParams } from '../../types/own-watches/own-watch-dto';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';
import { OWN_WATCHES } from '../../../router/constants';
import { IDeleteResult } from '../../types/common';
import i18n from '../../../features/utils/i18n';

export interface IOwnWatchesActions {
  getOwnWatches: (payload: IGetOwnWatchesListQueryParams) => Promise<void>;
  getOwnWatchById: (id: string) => Promise<void>;
  changeOwnWatchStatus: (payload: IChangeOwnWatchStatusPayload) => Promise<void>;
  delete: (id: number) => Promise<void>;
}

export class OwnWatchesActions implements IOwnWatchesActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  protected readonly pushTo: IPushTo;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService, pushTo: IPushTo) {
    this.dispatch = dispatch;
    this.api = api;
    this.pushTo = pushTo;
  }

  public getOwnWatches = async (payload: IGetOwnWatchesListQueryParams): Promise<void> => {
    try {
      this.dispatch({ type: OwnWatchesActionType.GetOwnWatchesRequest, payload: null });
      let queryParams = { ...payload };

      if (payload.dateFrom && payload.dateTo) {
        queryParams = {
          ...queryParams,
          dateFrom: formatDate(payload.dateFrom),
          dateTo: formatDate(payload.dateTo),
        };
      }
      const queryString = buildQueryString(queryParams);
      if (queryString) {
        this.pushTo(`${OWN_WATCHES}/?${queryString}`);
      }

      const result: IGetOwnWatchesListResponse = await this.api.get({
        url: `watch/list?appropriated=true&${queryString}`,
      });
      this.dispatch({
        type: OwnWatchesActionType.GetOwnWatchesSuccess,
        payload: result,
      });
    } catch (error) {
      this.dispatch({ type: OwnWatchesActionType.GetOwnWatchesFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getOwnWatchById = async (id: string): Promise<void> => {
    try {
      this.dispatch({ type: OwnWatchesActionType.GetOwnWatchByIdRequest, payload: null });
      const response: IOwnWatchEntity = await this.api.get({
        url: `watch/admin/single/${id}`,
      });
      this.dispatch({
        type: OwnWatchesActionType.GetOwnWatchByIdSuccess,
        payload: response,
      });
    } catch (error) {
      this.dispatch({ type: OwnWatchesActionType.GetOwnWatchByIdFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public delete = async (id: number) => {
    try {
      this.dispatch({ type: OwnWatchesActionType.DeleteOwnWatchRequest, payload: { id } });
      const { id: deletedId }: IDeleteResult = await this.api.delete({ url: `/watch/admin/single/${id}` });
      this.dispatch({ type: OwnWatchesActionType.DeleteOwnWatchSuccess, payload: deletedId });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: i18n.t('notifications.deleteOwnWatch'),
      });
    } catch (error) {
      this.dispatch({ type: OwnWatchesActionType.DeleteOwnWatchFailure, payload: null });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.deleteOwnWatchError'),
      });
    }
  };

  public changeOwnWatchStatus =
  async ({ id, status }: IChangeOwnWatchStatusPayload): Promise<void> => {
    try {
      this.dispatch({ type: OwnWatchesActionType.ChangeOwnWatchStatusByIdRequest, payload: null });
      const response = await this.api.patch({
        url: `/watch/admin/set-status/${id}`,
        data: { status },
      });
      this.dispatch({
        type: OwnWatchesActionType.GetOwnWatchByIdSuccess,
        payload: response,
      });
      this.dispatch({
        type: OwnWatchesActionType.ChangeOwnWatchStatusByIdSuccess,
        payload: null,
      });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Changes was saved' });
    } catch (error) {
      this.dispatch({ type: OwnWatchesActionType.ChangeOwnWatchStatusByIdFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };
}
