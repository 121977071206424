import { Dispatch } from 'react';

import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import { IDeliverMethod } from '../../types/loan-terms/delivery-method-entity';
import { IPeriodConfig } from '../../types/loan-terms/period-config-entity';
import { LoanTermsActionType } from '../actions-types';
import {
  IUpdateLoanTermsPercentsRequest,
  ICreateDeliveryMethodRequestData,
  IUpdatePeriodConfigurationRequestData,
  ICalculationsConfigResponse,
} from '../../types/loan-terms/loan-terms-dto';
import { FeeConfig } from '../../types/loan-terms/fee-config-entity';
import { MonthlyPercentageConfig } from '../../types/loan-terms/monthly-percantage-config-entity';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';

export interface ILoanTermsActions {
  getDeliveryMethods: () => Promise<void>;
  getPeriodConfig: () => Promise<void>;
  getCalculationsConfig: () => Promise<void>;
  createDeliveryMethod: (data: ICreateDeliveryMethodRequestData) => Promise<void>;
  deleteDeliveryMethod: (id: number) => Promise<void>;
  updatePeriodConfiguration: (
    id: number,
    data: IUpdatePeriodConfigurationRequestData
  ) => Promise<void>;
  updateCalculationsConfig: (
    feeConfig: FeeConfig,
    monthlyPercentage: MonthlyPercentageConfig,
  ) => Promise<void>;
  getPercentCalculations: () => Promise<void>;
  updatePercentCalculations: (data: IUpdateLoanTermsPercentsRequest, id: number) => Promise<void>;
}

export class LoanTermsActions implements ILoanTermsActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService) {
    this.dispatch = dispatch;
    this.api = api;
  }

  public getDeliveryMethods = async () => {
    try {
      this.dispatch({ type: LoanTermsActionType.GetDeliveryMethodsRequest, payload: null });
      const result: IDeliverMethod[] = await this.api.get({
        url: 'loan-terms/delivery-method',
      });
      this.dispatch({ type: LoanTermsActionType.GetDeliveryMethodsSuccess, payload: result });
    } catch (error) {
      this.dispatch({ type: LoanTermsActionType.GetDeliveryMethodsFailure, payload: null });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public createDeliveryMethod = async (data: ICreateDeliveryMethodRequestData) => {
    try {
      this.dispatch({ type: LoanTermsActionType.CreateDeliveryMethodRequest, payload: null });
      const result: IDeliverMethod = await this.api.post({
        url: 'loan-terms/delivery-method',
        data,
      });
      this.dispatch({ type: LoanTermsActionType.CreateDeliveryMethodSuccess, payload: result });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Shipping method was added' });
    } catch (err) {
      this.dispatch({ type: LoanTermsActionType.CreateDeliveryMethodFailure, payload: null });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public deleteDeliveryMethod = async (id: number) => {
    try {
      this.dispatch({ type: LoanTermsActionType.DeleteDeliveryMethodRequest, payload: null });
      const result: { id: number} = await this.api.delete({
        url: `loan-terms/delivery-method/${id}`,
      });
      this.dispatch({ type: LoanTermsActionType.DeleteDeliveryMethodSuccess, payload: result.id });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Shipping method was deleted' });
    } catch (err) {
      this.dispatch({ type: LoanTermsActionType.DeleteDeliveryMethodFailure, payload: null });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getPeriodConfig = async () => {
    this.dispatch({ type: LoanTermsActionType.GetPeriodConfigRequest, payload: null });
    try {
      const result: IPeriodConfig = await this.api.get({
        url: 'loan-terms/period',
      });
      this.dispatch({ type: LoanTermsActionType.GetPeriodConfigSuccess, payload: result });
    } catch (err) {
      this.dispatch({ type: LoanTermsActionType.GetPeriodConfigFailure, payload: null });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getCalculationsConfig = async () => {
    this.dispatch({ type: LoanTermsActionType.GetCalculationsConfigRequest, payload: null });
    try {
      const result: IPeriodConfig = await this.api.get({
        url: 'loan-terms/calculations',
      });
      this.dispatch({ type: LoanTermsActionType.GetCalculationsConfigSuccess, payload: result });
    } catch (err) {
      this.dispatch({ type: LoanTermsActionType.GetCalculationsConfigFailure, payload: null });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public updatePeriodConfiguration = async (
    id: number,
    data: IUpdatePeriodConfigurationRequestData,
  ) => {
    console.log(id);
    this.dispatch({ type: LoanTermsActionType.UpdatePeriodConfigRequest, payload: null });
    try {
      const result: IPeriodConfig = await this.api.put({
        url: `loan-terms/period/${id}`,
        data,
      });
      this.dispatch({ type: LoanTermsActionType.UpdatePeriodConfigSuccess, payload: result });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Changes was saved' });
    } catch (err) {
      this.dispatch({ type: LoanTermsActionType.UpdatePeriodConfigFailure, payload: null });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public updateCalculationsConfig = async (
    feeConfig: FeeConfig,
    monthlyPercentage: MonthlyPercentageConfig,
  ) => {
    try {
      this.dispatch({ type: LoanTermsActionType.UpdateCalculationsConfigRequest, payload: null });
      const result: ICalculationsConfigResponse = await this.api.put({
        url: 'loan-terms/calculations',
        data: {
          feeConfig,
          monthlyPercentage,
        },
      });
      this.dispatch({ type: LoanTermsActionType.UpdateCalculationsConfigSuccess, payload: result });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Changes was saved' });
    } catch (err) {
      this.dispatch({ type: LoanTermsActionType.UpdateCalculationsConfigFailure, payload: null });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getPercentCalculations = async () => {
    this.dispatch({ type: LoanTermsActionType.GetPercentCalculationsRequest, payload: null });
    try {
      const result: IPeriodConfig = await this.api.get({
        url: 'loan/admin/get-percents',
      });
      this.dispatch({ type: LoanTermsActionType.GetPercentCalculationsSuccess, payload: result });
    } catch (err) {
      this.dispatch({ type: LoanTermsActionType.GetPercentCalculationsFailure, payload: null });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public updatePercentCalculations = async (data: IUpdateLoanTermsPercentsRequest, id: number) => {
    this.dispatch({ type: LoanTermsActionType.UpdatePercentCalculationsRequest, payload: null });
    try {
      await this.api.patch({
        url: `loan/admin/change-percents/${id}`,
        data,
      });
      this.getPercentCalculations();
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Changes was saved' });
    } catch (err) {
      this.dispatch({ type: LoanTermsActionType.UpdatePercentCalculationsFailure, payload: null });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };
}
