import React from 'react';

export const FieldError: React.FC<{ error?: string}> = ({ error = '' }) => (
  <div className="
    error-validation
    user-edit-watch-form__error-validation
    error-validation--color
    watch-form__error
    user-edit-watch-form__error"
  >
    <div>{error}</div>
  </div>
);
