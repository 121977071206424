import { ITemplate } from '../../types/template/template-entity';
import { IGetTemplateListResponse } from '../../types/template/template-dto';
import { IError } from '../../types/common/index';
import { IAction } from '../../actions/action';
import { IAppState } from '../../state-context';

export const getTemplatesRequest = (state: IAppState): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    loading: true,
  },
});

export const getTemplatesSuccess = (
  state: IAppState,
  { payload }: IAction<IGetTemplateListResponse>,
): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    list: payload,
    loading: false,
  },
});

export const getTemplatesFailure = (
  state: IAppState, { payload }: IAction<IError>,
): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    loading: false,
    error: payload,
  },
});

export const addTemplateRequest = (state: IAppState): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    loading: true,
  },
});

export const addTemplateSuccess = (
  state: IAppState,
  { payload }: IAction<ITemplate>,
): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    list: [...state.templates.list, payload],
    loading: false,
  },
});

export const addTemplateFailure = (
  state: IAppState, { payload }: IAction<IError>,
): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    loading: false,
    error: payload,
  },
});

export const updateTemplateRequest = (state: IAppState): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    loading: true,
  },
});

export const updateTemplateSuccess = (
  state: IAppState,
  { payload }: IAction<ITemplate>,
): IAppState => {
  const updatedList = state.templates.list.map((template) => {
    if (template.id === payload.id) {
      return payload;
    }
    return template;
  });
  return ({
    ...state,
    templates: {
      ...state.templates,
      list: updatedList,
      loading: false,
    },
  });
};

export const updateTemplateFailure = (
  state: IAppState, { payload }: IAction<IError>,
): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    loading: false,
    error: payload,
  },
});

export const deleteTemplateRequest = (state: IAppState): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    loading: true,
  },
});

export const deleteTemplateSuccess = (
  state: IAppState,
  { payload }: IAction<ITemplate>,
): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    list: state.templates.list.filter((template) => template.id !== payload.id),
    loading: false,
  },
});

export const deleteTemplateFailure = (
  state: IAppState, { payload }: IAction<IError>,
): IAppState => ({
  ...state,
  templates: {
    ...state.templates,
    loading: false,
    error: payload,
  },
});
