import { Dispatch } from 'react';
import { matchPath } from 'react-router';

import { showForbiddenSocialNetworkAuth } from '../../../components/login-form/login-form';
import {
  ISocialNetworkLoginRequest,
  IForgotPasswordRequest,
  IGetUserResponse,
  ILoginRequest,
  ILoginResponse,
  IResetPasswordRequest,
  IUpdateUserInfoRequest,
  IUpdateUserPasswordRequest,
} from '../../types/auth/auth-dto';

import { IUser } from '../../types/users/user-entity';
import { showResetPasswordSuccessModal } from '../../../components/reset-password-form/reset-password-form';
import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import { AuthActionType } from '../actions-types';
import { Routes } from '../../../router/routes';
import { IPushTo } from '../../../router/types';

import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';
import isForbiddenError from '../../../utility/is-forbidden-error';

export interface IAuthActions {
  initApp: (pathname: string) => void;
  loginUser: (data: ILoginRequest) => Promise<void>;
  socialNetworkLoginUser: ({ token, type }: ISocialNetworkLoginRequest) => Promise<void>;
  forgotPassword: (data: IForgotPasswordRequest) => Promise<void>;
  resetPassword: (data: IResetPasswordRequest, search: string) => Promise<void>;
  getNewAccessToken: () => Promise<void>;
  logoutUser: () => void;
  getUser: () => Promise<void>;
  deleteUser: () => Promise<void>;
  updateUserInfo: (data: IUpdateUserInfoRequest) => Promise<void>;
  updateUserPassword: (data: IUpdateUserPasswordRequest) => Promise<void>;
}

export class AuthActions implements IAuthActions {
  protected readonly dispatch: Dispatch<IAction>;

  protected readonly pushTo: IPushTo;

  private readonly api: IApiService;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService, pushTo: IPushTo) {
    this.dispatch = dispatch;
    this.api = api;
    this.pushTo = pushTo;
  }

  public initApp = (pathname: string): void => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const validAccessToken = accessToken !== 'null' && accessToken !== 'undefined';
      const refreshToken = localStorage.getItem('refreshToken');
      const matchResetPassword = matchPath<{ accessToken: 'string' }>(pathname, {
        path: Routes.resetPassword,
      });
      const accessTokenParam = matchResetPassword?.params?.accessToken;
      if (accessTokenParam) {
        localStorage.setItem('changePasswordToken', accessTokenParam);
      }
      const matchForgotPassword = matchPath(pathname, {
        path: Routes.forgotPassword,
      });
      const matchPrivacyPolicyPassword = matchPath(pathname, {
        path: Routes.privacyPolicy,
      });
      if (accessToken && validAccessToken) {
        this.dispatch({
          type: AuthActionType.AuthSuccess,
          payload: { accessToken, refreshToken },
        });
      }
      if ((!accessToken || !validAccessToken) && !matchResetPassword && !matchForgotPassword && !matchPrivacyPolicyPassword) {
        this.pushTo(Routes.login);
      }
    } catch (error) {
      console.error(error);
    }
  };

  public loginUser = async (payload: ILoginRequest): Promise<void> => {
    try {
      this.dispatch({ type: AuthActionType.AuthRequest, payload: null });
      const response: ILoginResponse = await this.api.post({
        url: 'auth/login',
        data: { ...payload, isAdmin: true },
      });
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      this.dispatch({ type: AuthActionType.AuthSuccess, payload: response });
    } catch (error) {
      this.dispatch({ type: AuthActionType.AuthFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getUser = async (): Promise<void> => {
    try {
      this.dispatch({ type: AuthActionType.GetUserRequest, payload: null });

      const response: IGetUserResponse = await this.api.get({
        url: '/user',
      });
      this.dispatch({ type: AuthActionType.GetUserSuccess, payload: response });
    } catch (error) {
      this.dispatch({ type: AuthActionType.Logout, payload: null });
      this.dispatch({ type: AuthActionType.GetUserFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public deleteUser = async (): Promise<void> => {
    try {
      this.dispatch({ type: AuthActionType.DeleteUserRequest, payload: null });

      const response = await this.api.delete({
        url: '/user',
      });
      this.dispatch({ type: AuthActionType.DeleteUserSuccess, payload: response });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Admin was deleted' });
    } catch (error) {
      this.dispatch({ type: AuthActionType.DeleteUserFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public updateUserInfo = async (data: IUpdateUserInfoRequest): Promise<void> => {
    try {
      const payload = { ...data, phoneNumber: data.phoneNumber || null };
      this.dispatch({ type: AuthActionType.UpdateUserInfoRequest, payload });
      const response: IUser = await this.api.patch({
        url: '/user',
        data: { ...payload },
      });
      this.dispatch({ type: AuthActionType.UpdateUserInfoSuccess, payload: response });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: 'Changes was saved',
      });
    } catch (error) {
      this.dispatch({ type: AuthActionType.UpdateUserInfoFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public updateUserPassword = async (data: IUpdateUserPasswordRequest): Promise<void> => {
    try {
      this.dispatch({ type: AuthActionType.UpdateUserPasswordRequest, payload: data });
      const response: IGetUserResponse = await this.api.patch({
        url: '/user/change-password',
        data,
      });
      this.dispatch({ type: AuthActionType.UpdateUserPasswordSuccess, payload: response });
    } catch (error) {
      this.dispatch({ type: AuthActionType.UpdateUserPasswordFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public socialNetworkLoginUser = async ({ token, type }: ISocialNetworkLoginRequest): Promise<void> => {
    try {
      const response: ILoginResponse = await this.api.post({
        url: 'auth/social',
        data: {
          token: token.accessToken,
          isAdmin: true,
          type,
        },
      });
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      this.dispatch({ type: AuthActionType.AuthSuccess, payload: response });
    } catch (error) {
      if (isForbiddenError(error)) {
        showForbiddenSocialNetworkAuth();
      }
      this.dispatch({ type: AuthActionType.AuthFailure, payload: error });
      console.error(error);
    }
  };

  public forgotPassword = async (data: IForgotPasswordRequest): Promise<void> => {
    try {
      this.dispatch({
        type: AuthActionType.ForgotPasswordRequest,
        payload: null,
      });
      const response = await this.api.post({
        url: 'auth/forgot-password',
        data: {
          ...data,
          isMobile: false
        },
      });
      this.dispatch({
        type: AuthActionType.ForgotPasswordSuccess,
        payload: response,
      });
      showResetPasswordSuccessModal();
    } catch (error) {
      this.dispatch({
        type: AuthActionType.ForgotPasswordFailure,
        payload: error,
      });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public resetPassword = async (data: IResetPasswordRequest, search: string): Promise<void> => {
    try {
      const changePasswordToken = localStorage.getItem('changePasswordToken');
      const response = await this.api.post({
        url: 'auth/reset-password',
        data,
        headers: {
          Authorization: `Bearer ${changePasswordToken}`,
        },
      });
      this.dispatch({
        type: AuthActionType.ResetPasswordSuccess,
        payload: response,
      });
      if (search.includes('isMobile=true')) {
        toastNotification({
          type: ToastNotificationTypes.SUCCESS,
          message: 'Password successfuly changed, go to mobile app',
        });
      } else {
        this.pushTo(Routes.login);
      }
      localStorage.removeItem('changePasswordToken');
    } catch (error) {
      this.dispatch({
        type: AuthActionType.ResetPasswordFailure,
        payload: error,
      });
      localStorage.removeItem('changePasswordToken');
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getNewAccessToken = async (): Promise<void> => {
    try {
      this.dispatch({ type: AuthActionType.AuthRequest, payload: null });
      const response: ILoginResponse = await this.api.post({
        url: 'auth/refresh-tokens',
        data: {
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('refreshToken'),
        },
      });
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      this.dispatch({ type: AuthActionType.AuthSuccess, payload: response });
    } catch (error) {
      this.dispatch({ type: AuthActionType.AuthFailure, payload: error });
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public logoutUser = (): void => {
    try {
      this.dispatch({ type: AuthActionType.Logout, payload: null });
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      this.pushTo(Routes.login);
    } catch (error) {
      console.error(error);
    }
  };
}
