import React, { useState, useEffect } from 'react';
import { Tabs, Pagination, Layout } from 'antd';
import { useHistory, useLocation } from 'react-router';
import confirm from 'antd/lib/modal/confirm';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { useActions, useStore } from '../../store';
import UsersListTable from '../users-list-table';
import { allUsersColumns, adminsColumns } from '../users-list-table/columns';
import UsersFilterBar from '../users-filter-bar/users-filter-bar';
import { takeResultsPerPage, Roles } from '../../constants';
import parseParams from '../../utility/parse-params';
import { Routes } from '../../router/routes';
import i18n from '../../features/utils/i18n';

const { TabPane } = Tabs;
const { Content } = Layout;

const ADMINS = 'admins';
const ALL_USERS = 'allUsers';

const UsersTab = () => {
  const { uiActions, usersActions } = useActions();
  const { users } = useStore();
  const location = useLocation();
  const history = useHistory();
  const params = parseParams(location.search);
  const { role, page }: any = params;
  const defaultPage = Number(page) || 1;
  const [allUsersPage, setAllUsersPage] = useState(defaultPage);
  const [adminsPage, setAdminsPage] = useState(defaultPage);
  const [tab, setTab] = useState(role ? ADMINS : ALL_USERS);
  const hasRole = Boolean(role);
  const handlePageChange = (newPage, callback) => {
    callback(newPage);
  };

  const handleDelete = (id: number) => {
    confirm({
      title: i18n.t('users.deleteUser'),
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      onOk() {
        usersActions.deleteUser(id);
      },
    });
  };

  const handleTabChange = (newTab: string) => {
    setTab(newTab);
    if (newTab !== ADMINS) {
      history.push(`${Routes.users}/?page=${defaultPage}`);
    }
  };

  useEffect(() => {
    if (tab === ADMINS) {
      usersActions.getUsers({
        page: adminsPage,
        role: Roles.ADMIN,
      });
    }
    setTab(tab);
    uiActions.setActiveUsersTab(tab);
  }, [tab, adminsPage, allUsersPage]);

  useEffect(() => {
    if (hasRole && tab !== role) {
      setAllUsersPage(1);
    } else if (!hasRole && tab === ADMINS) {
      setAdminsPage(1);
    }
    return () => (tab === ADMINS ? setAdminsPage(1) : setAllUsersPage(1));
  }, [tab]);

  return (
    <div className="container">
      <div className="header-holder">
        <div className="top-row" />
        <div className="tab-holder">
          <Tabs
            onChange={handleTabChange}
            size="large"
            className="users__tabs"
            activeKey={tab}
            defaultActiveKey={role || ALL_USERS}
          >
            <TabPane tab={i18n.t('users.allUsers')} key="allUsers">
              <UsersFilterBar currentPage={allUsersPage} setCallbackPage={setAllUsersPage} />
              <Content>
                <UsersListTable list={users.list} columns={allUsersColumns(handleDelete)} />
                {users.count > takeResultsPerPage && (
                  <div className="pagination-wrapper">
                    <Pagination
                      defaultCurrent={allUsersPage}
                      current={allUsersPage}
                      defaultPageSize={takeResultsPerPage}
                      total={users.count || 1}
                      onChange={(e) => handlePageChange(e, setAllUsersPage)}
                    />
                  </div>
                )}
              </Content>
            </TabPane>
            <TabPane tab={i18n.t('users.admins')} key="admins">
              <Content>
                <UsersListTable list={users.admins} columns={adminsColumns(handleDelete)} />
                {users.adminsCount > takeResultsPerPage && (
                  <div className="pagination-wrapper">
                    <Pagination
                      defaultCurrent={adminsPage}
                      current={adminsPage}
                      defaultPageSize={takeResultsPerPage}
                      total={users.adminsCount || 1}
                      onChange={(e) => handlePageChange(e, setAdminsPage)}
                    />
                  </div>
                )}
              </Content>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default UsersTab;
