import React, { useState } from 'react';
import { Layout, Pagination } from 'antd';
import { useLocation } from 'react-router';

import { useStore } from '../../store';
import { takeResultsPerPage } from '../../constants';
import parseParams from '../../utility/parse-params';
import OwnWatchesFilterBar from '../../components/own-watches-filter-bar';
import OwnWatchesTable from '../../components/own-watches-table';
import './styles.css';
import { IGetOwnWatchesListQueryParams } from '../../store/types/own-watches/own-watch-dto';

const { Header, Content } = Layout;

const OwnWatches = () => {
  const location = useLocation();
  const params: IGetOwnWatchesListQueryParams = parseParams(location.search);
  const { ownWatches } = useStore();
  const [currentPage, setCurrentPage] = useState<number>(Number(params.page) || 1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Layout className="own-watches">
      <div className="container">
        <Header>
          <OwnWatchesFilterBar currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </Header>
        <Content>
          <div className="table-wrapper">
            <OwnWatchesTable />
          </div>
          {ownWatches.count > takeResultsPerPage && (
            <div className="pagination-wrapper">
              <Pagination
                defaultCurrent={currentPage}
                defaultPageSize={takeResultsPerPage}
                total={ownWatches.count || 1}
                onChange={handlePageChange}
              />
            </div>
          )}
        </Content>
      </div>
    </Layout>
  );
};

export default OwnWatches;
