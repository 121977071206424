import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { Checkbox, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useLocation } from 'react-router';

import { useActions } from '../../store';
import { takeResultsPerPage } from '../../constants';
import parseParams from '../../utility/parse-params';
import { IGetValuationsListQueryParams } from '../../store/types/valuation/valuation-dto';
import WatchStatus from '../../constants/watch-status';
import CustomDateRangePicker from '../date-range-picker';
import i18n from '../../features/utils/i18n';

interface IValuationsFilterProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

interface IFilterState {
  dateTo?: Date | string;
  dateFrom?: Date | string;
  take?: number;
  page?: number;
  keyword?: string;
  statuses?: string;
}

const ValuationsFilterBar: React.FC<IValuationsFilterProps> = ({ currentPage, setCurrentPage }) => {
  const location = useLocation();
  const params = parseParams(location.search);
  const {
    dateTo, dateFrom, keyword = '', take, page, statuses: initialStatuses,
  }: IGetValuationsListQueryParams = params;
  const { valuationsActions } = useActions();

  const from = dateFrom && dateFrom.toLocaleString();
  const to = dateTo && dateTo.toLocaleString();
  const [filterState, setFilterState] = useState<IFilterState>({
    dateTo: to,
    dateFrom: from,
    take: take || takeResultsPerPage,
    page: page || currentPage,
    statuses: initialStatuses,
    keyword,
  });

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const debouncedSearch = debounce(() => {
      setFilterState({ ...filterState, keyword: event.target.value });
    }, 100);
    debouncedSearch();
    setCurrentPage(1);
  };

  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    const { checked, value } = event?.target;
    const { statuses = '' } = filterState;
    let selected = statuses ? statuses.split(',') : [];

    if (checked) {
      selected = [...selected, value];
    } else {
      selected = selected.filter((i) => i !== value);
    }

    setFilterState({ ...filterState, statuses: selected.join(',') });
    setCurrentPage(1);
  };

  const handleDateChange = (values) => {
    setFilterState({
      ...filterState,
      dateFrom: get(values, '[0]'),
      dateTo: get(values, '[1]'),
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    valuationsActions.getValuationsList({ ...filterState, page: currentPage });
  }, [filterState, currentPage]);

  return (
    <div className="header-holder">
      <div className="top-row">
        <div className="page-title__holder">
          <h1>{i18n.t('valuations.valuation')}</h1>
        </div>
        <div className="search-bar-holder">
          <Input
            placeholder={i18n.t('common.search')}
            suffix={<SearchOutlined />}
            defaultValue={filterState.keyword}
            onChange={onSearchChange}
          />
        </div>
      </div>
      <div className="filter-bar__filters">
        <CustomDateRangePicker
          value={[filterState.dateFrom, filterState.dateTo]}
          handleChange={handleDateChange}
        />
        <div className="filter-bar__status-filters">
          <Checkbox
            checked={filterState.statuses?.includes(WatchStatus.APPRAISAL_IN_PROGRESS)}
            onChange={handleCheckboxChange}
            value={WatchStatus.APPRAISAL_IN_PROGRESS}
          >
            {i18n.t('common.inProcess')}
          </Checkbox>
          <Checkbox
            checked={filterState.statuses?.includes(WatchStatus.APPRAISED)}
            onChange={handleCheckboxChange}
            value={WatchStatus.APPRAISED}
          >
            {i18n.t('common.evaluated')}
          </Checkbox>
          <Checkbox
            checked={filterState.statuses?.includes(WatchStatus.REJECTED)}
            onChange={handleCheckboxChange}
            value={WatchStatus.REJECTED}
          >
            {i18n.t('common.rejected')}
          </Checkbox>
          <Checkbox
            checked={filterState.statuses?.includes(WatchStatus.INCLUDED_IN_LOAN)}
            onChange={handleCheckboxChange}
            value={WatchStatus.INCLUDED_IN_LOAN}
          >
            {i18n.t('common.open')}
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export default ValuationsFilterBar;
