import { IAction } from '../../actions/action';
import { IAppState } from '../../state-context';
import { IGetNotificationsListResponse } from '../../types/notification/notification-dto';

export const getNotificationsRequest = (state: IAppState): IAppState => ({
  ...state,
  notifications: {
    ...state.notifications,
    loading: true,
  },
});

export const getNotificationsSuccess = (
  state: IAppState,
  { payload: { data, count, newCount } }: IAction<IGetNotificationsListResponse>,
): IAppState => ({
  ...state,
  notifications: {
    ...state.notifications,
    list: data,
    loading: false,
    count,
    newCount,
  },
});

export const getNotificationsFailure = (state: IAppState): IAppState => ({
  ...state,
  notifications: {
    ...state.notifications,
    loading: false,
  },
});

export const deleteNotificationsSuccess = (
  state: IAppState,
  { payload: deletedId }: IAction<number>,
): IAppState => {
  return ({
    ...state,
    notifications: {
      ...state.notifications,
      list: state.notifications.list.filter((i) => i.id !== deletedId),
      count: state.notifications.count - 1,
    },
  });
};

export const setNotificationViewedSuccess = (state: IAppState): IAppState => ({
  ...state,
  notifications: {
    ...state.notifications,
    newCount:
      state.notifications.newCount > 0
        ? state.notifications.newCount - 1
        : state.notifications.newCount,
  },
});

export const getNotificationsCounterSuccess = (
  state: IAppState,
  { payload: newCount }: IAction<number>,
): IAppState => ({
  ...state,
  notifications: {
    ...state.notifications,
    newCount,
  },
});
