export enum UiActionType {
  SetActiveUsersTab = 'SetActiveUsersTab'
}

export enum AuthActionType {
  AuthRequest = 'AuthRequest',
  AuthSuccess = 'AuthSuccess',
  AuthFailure = 'AuthFailure',
  ForgotPasswordRequest = 'ForgotPasswordRequest',
  ForgotPasswordSuccess = 'ForgotPasswordSuccess',
  ForgotPasswordFailure = 'ForgotPasswordFailure',
  ResetPasswordSuccess = 'ResetPasswordSuccess',
  ResetPasswordFailure = 'ResetPasswordFailure',
  UnauthorizedErrorReceived = 'UnauthorizedErrorReceived',
  GetNewAccessToken = 'GetNewAccessToken',
  Logout = 'Logout',
  GetUserRequest = 'GetUserRequest',
  GetUserSuccess = 'GetUserSuccess',
  GetUserFailure = 'GetUserFailure',
  DeleteUserRequest = 'DeleteUserRequest',
  DeleteUserSuccess = 'DeleteUserSuccess',
  DeleteUserFailure = 'DeleteUserFailure',
  UpdateUserInfoRequest = 'UpdateUserInfoRequest',
  UpdateUserInfoSuccess = 'UpdateUserInfoSuccess',
  UpdateUserInfoFailure = 'UpdateUserInfoFailure',
  UpdateUserPasswordRequest = 'UpdateUserPasswordRequest',
  UpdateUserPasswordSuccess = 'UpdateUserPasswordSuccess',
  UpdateUserPasswordFailure = 'UpdateUserPasswordFailure',
}

export enum UsersActionType {
  GetUsersRequest = 'GetUsersRequest',
  GetUsersSuccess = 'GetUsersSuccess',
  GetUsersFailure = 'GetUsersFailure',
  GetUserByIdRequest = 'GetUserByIdRequest',
  GetUserByIdSuccess = 'GetUserByIdSuccess',
  GetUserByIdFailure = 'GetUserByIdFailure',
  GetRevenueCatUserByIdRequest = 'GetRevenueCatUserByIdRequest',
  GetRevenueCatUserByIdSuccess = 'GetRevenueCatUserByIdSuccess',
  GetRevenueCatUserByIdFailure = 'GetRevenueCatUserByIdFailure',
  SetBlockedUserRequest = 'SetBlockedUserRequest',
  SetBlockedUserSuccess = 'SetBlockedUserSuccess',
  SetBlockedUserFailure = 'SetBlockedUserFailure',
  SetUserRoleRequest = 'SetUserRoleRequest',
  SetUserRoleSuccess = 'SetUserRoleSuccess',
  SetUserRoleFailure = 'SetUserRoleFailure',
  SetUserSubscribeRequest = 'SetUserSubscribeRequest',
  SetUserSubscribeSuccess = 'SetUserSubscribeSuccess',
  SetUserSubscribeFailure = 'SetUserSubscribeFailure',
  DeleteUserRequest = 'DeleteUserRoleRequest',
  DeleteUserSuccess = 'DeleteUserRoleSuccess',
  DeleteUserFailure = 'DeleteUserRoleFailure'
}

export enum LoanActionType {
  GetLoansRequest = 'GetLoansRequest',
  GetLoansSuccess = 'GetLoansSuccess',
  GetLoansFailure = 'GetLoansFailure',
  GetLoanByIdRequest = 'GetLoanByIdRequest',
  GetLoanByIdSuccess = 'GetLoanByIdSuccess',
  GetLoanByIdFailure = 'GetLoanByIdFailure',
  ChangeLoanStatusRequest = 'ChangeLoanStatusRequest',
  ChangeLoanStatusSuccess = 'ChangeLoanStatusSuccess',
  ChangeLoanStatusFailure = 'ChangeLoanStatusFailure',
  AddToOwnWatchesRequest = 'AddToOwnWatchesRequest',
  AddToOwnWatchesSuccess = 'AddToOwnWatchesSuccess',
  AddToOwnWatchesFailure = 'AddToOwnWatchesFailure',
  GetLoansByWatchSuccess = 'GetLoansByWatchSuccess',
  GetLoansByWatchFailure = 'GetLoansByWatchFailure',
  DeleteLoanRequest = 'DeleteLoanRequest',
  DeleteLoanSuccess = 'DeleteLoanSuccess',
  DeleteLoanFailure= 'DeleteLoanFailure'
}

export enum WatchActionType {
  GetUserWatchesByIdRequest = 'GetUserWatchesByIdRequest',
  GetUserWatchesByIdSuccess = 'GetUserWatchesByIdSuccess',
  GetUserWatchesByIdFailure = 'GetUserWatchesByIdFailure',
  GetUserWatchByIdRequest = 'GetUserWatchByIdRequest',
  GetUserWatchByIdSuccess = 'GetUserWatchByIdSuccess',
  GetUserWatchByIdFailure = 'GetUserWatchByIdFailure',
  AppraiseWatchRequest = 'AppraiseWatchRequest',
  AppraiseWatchSuccess = 'AppraiseWatchSuccess',
  AppraiseWatchFailure = 'AppraiseWatchFailure',
  RejectWatchRequest = 'RejectWatchRequest',
  RejectWatchSuccess = 'RejectWatchSuccess',
  RejectWatchFailure = 'RejectWatchFailure',
}

export enum LoanTermsActionType {
  GetDeliveryMethodsRequest = 'GetDeliveryMethodsRequest',
  GetDeliveryMethodsSuccess = 'GetDeliveryMethodsSuccess',
  GetDeliveryMethodsFailure = 'GetDeliveryMethodsFailure',

  CreateDeliveryMethodRequest = 'CreateDeliveryMethodRequest',
  CreateDeliveryMethodSuccess = 'CreateDeliveryMethodSuccess',
  CreateDeliveryMethodFailure = 'CreateDeliveryMethodFailure',

  UpdateDeliveryMethodRequest = 'UpdateDeliveryMethodRequest',
  UpdateDeliveryMethodSuccess = 'UpdateDeliveryMethodSuccess',
  updateDeliveryMethodFailure = 'UpdateDeliveryMethodFailure',

  DeleteDeliveryMethodRequest = 'UpdateDeliveryMethodRequest',
  DeleteDeliveryMethodSuccess = 'UpdateDeliveryMethodSuccess',
  DeleteDeliveryMethodFailure = 'UpdateDeliveryMethodFailure',

  GetPeriodConfigRequest = 'GetPeriodConfigRequest',
  GetPeriodConfigSuccess = 'GetPeriodConfigSuccess',
  GetPeriodConfigFailure = 'GetPeriodConfigFailure',

  UpdatePeriodConfigRequest = 'UpdatePeriodConfigRequest',
  UpdatePeriodConfigSuccess = 'UpdatePeriodConfigSuccess',
  UpdatePeriodConfigFailure = 'UpdatePeriodConfigFailure',

  GetCalculationsConfigRequest = 'GetCalculationsConfigRequest',
  GetCalculationsConfigSuccess = 'GetCalculationsConfigSuccess',
  GetCalculationsConfigFailure = 'GetCalculationsConfigFailure',

  UpdateCalculationsConfigRequest = 'GetCalculationsConfigRequest',
  UpdateCalculationsConfigSuccess = 'GetCalculationsConfigSuccess',
  UpdateCalculationsConfigFailure = 'GetCalculationsConfigFailure',

  GetPercentCalculationsRequest = 'GetPercentCalculationsRequest',
  GetPercentCalculationsSuccess = 'GetPercentCalculationsSuccess',
  GetPercentCalculationsFailure = 'GetPercentCalculationsFailure',

  UpdatePercentCalculationsRequest = 'UpdatePercentCalculationsRequest',
  UpdatePercentCalculationsSuccess = 'UpdatePercentCalculationsSuccess',
  UpdatePercentCalculationsFailure = 'UpdatePercentCalculationsFailure',
}

export enum ValuationsActionType {
  GetValuationsRequest = 'GetValuationsRequest',
  GetValuationsSuccess = 'GetValuationsSuccess',
  GetValuationsFailure = 'GetValuationsFailure',
  ValuateBunchWatchesRequest = 'ValuateBunchWatchesRequest',
  ValuateBunchWatchesSuccess = 'ValuateBunchWatchesSuccess',
  ValuateBunchWatchesFailure = 'ValuateBunchWatchesFailure',
  DeleteValuationSuccess = 'DeleteValuationSuccess',
  DeleteValuationFailure = 'DeleteValuationFailure',
  DeleteValuationRequest = 'DeleteValuationRequest'
}

export enum SubscriptionsActionType {
  GetSubscriptionsRequest = 'GetSubscriptionsRequest',
  GetSubscriptionsSuccess = 'GetSubscriptionsSuccess',
  GetSubscriptionsFailure = 'GetSubscriptionsFailure',
  GetSubscriptionByIdRequest = 'GetSubscriptionByIdRequest',
  GetSubscriptionByIdSuccess = 'GetSubscriptionByIdSuccess',
  GetSubscriptionByIdFailure = 'GetSubscriptionByIdFailure',
  UpdateSubscriptionByIdRequest = 'UpdateSubscriptionByIdRequest',
  UpdateSubscriptionByIdSuccess = 'UpdateSubscriptionByIdSuccess',
  UpdateSubscriptionByIdFailure = 'UpdateSubscriptionByIdFailure',
}

export enum OwnWatchesActionType {
  GetOwnWatchesRequest = 'GetOwnWatchesRequest',
  GetOwnWatchesSuccess = 'GetOwnWatchesSuccess',
  GetOwnWatchesFailure = 'GetOwnWatchesFailure',
  GetOwnWatchByIdRequest = 'GetOwnWatchByIdRequest',
  GetOwnWatchByIdSuccess = 'GetOwnWatchByIdSuccess',
  GetOwnWatchByIdFailure = 'GetOwnWatchByIdFailure',
  ChangeOwnWatchStatusByIdRequest = 'ChangeOwnWatchStatusByIdRequest',
  ChangeOwnWatchStatusByIdSuccess = 'ChangeOwnWatchStatusByIdSuccess',
  ChangeOwnWatchStatusByIdFailure = 'ChangeOwnWatchStatusByIdFailure',
  DeleteOwnWatchSuccess = 'DeleteOwnWatchSuccess',
  DeleteOwnWatchFailure = 'DeleteOwnWatchFailure',
  DeleteOwnWatchRequest = 'DeleteOwnWatchRequest'
}

export enum NotificationsActionType {
  GetNotificationsRequest = 'GetNotificationsRequest',
  GetNotificationsSuccess = 'GetNotificationsSuccess',
  GetNotificationsFailure = 'GetNotificationsFailure',
  DeleteNotificationRequest = 'DeleteNotificationRequest',
  DeleteNotificationSuccess = 'DeleteNotificationSuccess',
  DeleteNotificationFailure = 'DeleteNotificationFailure',
  SetNotificationViewedRequest = 'SetNotificationViewedRequest',
  SetNotificationViewedSuccess = 'SetNotificationViewedSuccess',
  SetNotificationViewedFailure = 'SetNotificationViewedFailure',
  GetNotificationsCounterSuccess = 'GetNotificationsCounterSuccess',
}

export enum WatchConfigsActionsType {
  // create
  CreateWatchConfigRequest = 'CreateWatchConfigRequest',
  CreateWatchConfigSuccess = 'CreateWatchConfigSuccess',
  CreateWatchConfigFailure = 'CreateWatchConfigFailure',
  // list
  GetWatchConfigsListRequest = 'GetWatchConfigsListRequest',
  GetWatchConfigsListSuccess = 'GetWatchConfigsListSuccess',
  GetWatchConfigsListFailure = 'GetWatchConfigsListFailure',
  // single
  GetWatchConfigSingleRequest = 'GetWatchConfigSingleRequest',
  GetWatchConfigSingleSuccess = 'GetWatchConfigSingleSuccess',
  GetWatchConfigSingleFailure = 'GetWatchConfigSingleFailure',
  // update
  UpdateWatchConfigRequest = 'UpdateWatchConfigRequest',
  UpdateWatchConfigSuccess = 'UpdateWatchConfigSuccess',
  UpdateWatchConfigFailure = 'UpdateWatchConfigFailure',
  // updateUserWatch
  UpdateUserWatchConfigRequest = 'UpdateUserWatchConfigRequest',
  UpdateUserWatchConfigSuccess = 'UpdateUserWatchConfigSuccess',
  UpdateUserWatchConfigFailure = 'UpdateUserWatchConfigFailure',
  // delete
  DeleteWatchConfigRequest = 'DeleteWatchConfigRequest',
  DeleteWatchConfigSuccess = 'DeleteWatchConfigSuccess',
  DeleteWatchConfigFailure = 'DeleteWatchConfigFailure',
  // delete many
  DeleteManyWatchConfigsRequest = 'DeleteManyWatchConfigsRequest',
  DeleteManyWatchConfigsSuccess = 'DeleteManyWatchConfigsSuccess',
  DeleteManyWatchConfigsFailure = 'DeleteManyWatchConfigsFailure',
  // get watch parameters
  GetWatchParametersRequest = 'GetWatchParametersRequest',
  GetWatchParametersSuccess = 'GetWatchParametersSuccess',
  GetWatchParametersFailure = 'GetWatchParametersFailure',
}

export enum TemplatesActionType {
  GetTemplatesListRequest = 'GetTemplatesListRequest',
  GetTemplatesListSuccess = 'GetTemplatesListSuccess',
  GetTemplatesListFailure = 'GetTemplatesListFailure',
  AddTemplateRequest = 'AddTemplateRequest',
  AddTemplateSuccess = 'AddTemplateSuccess',
  AddTemplateFailure = 'AddTemplateFailure',
  UpdateTemplateRequest = 'UpdateTemplateRequest',
  UpdateTemplateSuccess = 'UpdateTemplateSuccess',
  UpdateTemplateFailure = 'UpdateTemplateFailure',
  DeleteTemplateRequest = 'DeleteTemplateRequest',
  DeleteTemplateSuccess = 'DeleteTemplateSuccess',
  DeleteTemplateFailure = 'DeleteTemplateFailure',
}

export enum PromocodeActionType {
  GetPromocodesRequest = 'GetPromocodesRequest',
  GetPromocodesSuccess = ' GetPromocodesSuccess',
  GetPromocodesFailure = ' GetPromocodesFailure',
  GetPromoByIdRequest = 'GetPromoByIdRequest',
  GetPromoByIdSuccess = 'GetPromoByIdSuccess',
  GetPromoByIdFailure = 'GetPromoByIdFailure',
  ResetCurrent = 'ResetCurrent',
  SetPromoUsers = 'SetPromoUsers',
  SetLoading = 'SetLoading'
}

export enum PartnerActionType {
  GetPartnersRequest = 'GetPartnersRequest',
  GetPartnersSuccess = ' GetPartnersSuccess',
  GetPartnersFailure = ' GetPartnersFailure',
  GetPartnerByIdRequest = 'GetPartnerByIdRequest',
  GetPartnerByIdSuccess = 'GetPartnerByIdSuccess',
  GetPartnerByIdFailure = 'GetPartnerByIdFailure',
  ResetCurrentPartner = 'ResetCurrentPartner',
  SetPartnerUsers = 'SetPartnerUsers',
  SetLoading = 'SetLoading'
}
