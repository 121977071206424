import React from 'react';
import { Button, Table, Tag } from 'antd';
import { AlignType } from 'rc-table/lib/interface';

import i18n from '../../features/utils/i18n';
import { IUser } from '../../store/types/users/user-entity';
import { useHistory } from 'react-router';
import { PARTNER } from '../../router/constants';
import { IPartner } from '../../store/types/partner/partner-entity';
import confirm from 'antd/lib/modal/confirm';
import { QuestionCircleOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { PhoneMask } from '../../constants';

interface IProps {
  partners: IPartner[];
  className?: string;
  title?: boolean;
  small?: boolean;
  loading?: boolean;
  clickable?: boolean;
  deletable?: boolean;
  onDelete?: (id: number) => void;
}

const initialColumns = [
  {
    title: i18n.t('partner.companyName'),
    dataIndex: 'companyName'
  },
  {
    title: i18n.t('common.email'),
    dataIndex: 'email'
  },
  {
    title: i18n.t('common.info'),
    dataIndex: 'configs',
    className: 'info-column',
    render: (configs) => <span>{configs?.info || ''}</span>
  },
  {
    title: i18n.t('partner.numberOfUsers'),
    dataIndex: 'usersCount',
    width: '140px',
    align: 'center' as AlignType,
    render: (usersCount: number) => <span>{usersCount || 0}</span>
  },
  {
    title: i18n.t('common.phoneNumber'),
    width: '180px',
    align: 'center' as AlignType,
    dataIndex: 'phoneNumber',
    render: (phoneNumber: string) => (
      <NumberFormat
        className="table__phone-input"
        value={phoneNumber}
        format={PhoneMask.TEN_DIGITS}
        mask="_"
        readOnly
      />
    )
  }
]

export const PartnerTable: React.FC<IProps> = ({
  partners,
  className,
  loading,
  deletable,
  onDelete = () => {}
}) => {
  const [columns, setColumns] = React.useState(initialColumns);
  const { push } = useHistory();

  React.useEffect(() => {
    if (deletable) {
      setColumns([
        ...initialColumns,
        {
          title: i18n.t('common.action'),
          dataIndex: 'id',
          width: '140px',
          align: 'center' as AlignType,
          render: (id) => (
            <Button
              type="primary"
              danger
              onClick={(event) => handleDelete(event, id)}
            >
              {i18n.t('common.delete')}
            </Button>
          )
        }
      ]);
    }
  }, [deletable])

  const handleRowClick = (data: { id: number }) => {
    push({ pathname: `${PARTNER}/${data.id}` });
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement, MouseEvent>, id) => {
    event.stopPropagation();
    confirm({
      title: i18n.t('partner.deleteConfirm'),
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      onOk() {
        onDelete(id);
      },
    });
  };

  return (
    <Table
      loading={loading}
      className={className + ' partner-table'}
      bordered
      columns={columns}
      dataSource={partners?.map(promo => ({...promo, key: promo.id})) || []}
      pagination={false}
      onRow={(data) => ({onClick: () => handleRowClick(data)})}
    />
  );
};
