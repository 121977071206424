import React from 'react';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { NotificationsType } from '../../constants';
import { INotification } from '../../store/types/notification/notification-entity';
import {
  LOAN, SUBSCRIPTIONS, USER, USER_WATCH,
} from '../../router/constants';
import { Routes } from '../../router/routes';
import i18n from '../../features/utils/i18n';

const iconColor = {
  orange: '#FAAD14',
  blue: '#1890FF',
  green: '#52C41A',
  red: '#F5222D',
};

const iconFontSize = '21px';

const linkConstructor = {
  goToWatchPage: ({ watchId }) => {
    if (!watchId) {
      return '';
    }
    return `${USER_WATCH}/${watchId}`;
  },
  goToUserPage: ({ user }) => {
    if (!user) {
      return '';
    }
    return `${USER}/${user.id}`;
  },
  goToFinAgreementPage: ({ loanId }) => loanId ? `${LOAN}/${loanId}` : Routes.loans,
  goToSubscriptionPage: () => `${SUBSCRIPTIONS}`,
  goToFinAgreementTermsPage: () => `${Routes.loanTerms}`,
  goToWatchConfigPage: () => `${Routes.watches}`
};

export default function defineNotificationConfig(notifications: INotification) {
  const { type, user, admin } = notifications;
  const adminName = admin?.name || (`${admin?.firstName || ''} ${admin?.lastName || ''}`);
  const userName = user?.name || (`${user?.firstName || ''} ${user?.lastName || ''}`);

  switch (type) {
    case NotificationsType.USER_SENT_WATCHES_FOR_EVALUATING:
      return {
        title: i18n.t('notifications.evaluating'),
        subtitle: `${userName} ${i18n.t('notifications.sentWatchesForEval')}`,
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.orange, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToUserPage,
      };
    case NotificationsType.USER_SENT_WATCH_FOR_EVALUATING:
      return {
        title: i18n.t('notifications.evaluating'),
        subtitle: `${userName} ${i18n.t('notifications.sentWatchForEval')}`,
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.orange, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToWatchPage,
      };
    case NotificationsType.USER_COMPLETES_FIN_AGREEMENT:
      return {
        title: i18n.t('notifications.userWantsComplete'),
        subtitle: `${userName} ${i18n.t('notifications.completeAgreement')}`,
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.orange, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToFinAgreementPage,
      };
    case NotificationsType.USER_CREATES_FIN_AGREEMENT:
      return {
        title: i18n.t('notifications.creatingFinAgreement'),
        subtitle: `${userName} ${i18n.t('notifications.createAgreement')}`,
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.orange, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToFinAgreementPage,
      };
    case NotificationsType.USER_BOUGHT_SUBSCRIPTION:
      return {
        title: i18n.t('notifications.userBoughtSub'),
        subtitle: `${userName} - ${i18n.t('notifications.newClient')}`,
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.blue, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToUserPage,
      };
    case NotificationsType.ADMIN_CHANGED_SUBSCRIPTION_CONFIG:
      return {
        title: i18n.t('notifications.changeToSub'),
        subtitle: `${i18n.t('notifications.haveBeenMadeSub')} ${adminName}`,
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.blue, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToSubscriptionPage,
      };
    case NotificationsType.ADMIN_CHANGED_FIN_AGREEMENT_TERMS:
      return {
        title: i18n.t('notifications.changesToFin'),
        subtitle: `${i18n.t('notifications.changesMadeData')} ${adminName}`,
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.blue, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToFinAgreementTermsPage,
      };
    case NotificationsType.ADMIN_CHANGED_WATCH_CONFIG:
      return {
        title: i18n.t('notifications.changesDatabase'),
        subtitle: `${i18n.t('notifications.changes')} ${adminName}`,
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.blue, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToWatchConfigPage,
      };
    case NotificationsType.ADMIN_ADDED_NEW_ADMIN:
      return {
        title: i18n.t('notifications.newAdmin'),
        subtitle: `${i18n.t('notifications.added')} ${userName} ${i18n.t('notifications.by')} ${adminName} ${i18n.t('notifications.toTheTeam')}`,
        icon: <CheckCircleOutlined
          style={{ color: iconColor.green, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToUserPage,
      };
    case NotificationsType.ADMIN_ADDED_WATCH_CONFIG:
      return {
        title: i18n.t('notifications.newWatches'),
        subtitle: `${i18n.t('notifications.returnDatabaseBy')} ${adminName}`,
        icon: <CheckCircleOutlined
          style={{ color: iconColor.green, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToWatchConfigPage,
      };
    case NotificationsType.ADMIN_UNBLOCKED_USER:
      return {
        title: i18n.t('notifications.unBlockedUser'),
        subtitle: `${userName} ${i18n.t('notifications.unblockedBy')} ${adminName}`,
        icon: <CheckCircleOutlined
          style={{ color: iconColor.green, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToUserPage,
      };
    case NotificationsType.ADMIN_CLOSED_FIN_AGREEMENT:
      return {
        title: i18n.t('notifications.wasCompleted'),
        subtitle: `${i18n.t('notifications.adminClosed')} ${userName}`,
        icon: <CheckCircleOutlined
          style={{ color: iconColor.green, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToFinAgreementPage,
      };
    case NotificationsType.ADMIN_APPRAISED_WATCH:
      return {
        title: i18n.t('notifications.adminAppraised'),
        subtitle: `${i18n.t('notifications.appraisedBy')} ${adminName}`,
        icon: <CheckCircleOutlined
          style={{ color: iconColor.green, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToWatchPage,
      };
    case NotificationsType.ADMIN_BLOCKED_USER:
      return {
        title: i18n.t('notifications.blockedUser'),
        subtitle: `${userName} ${i18n.t('notifications.blockedBy')} ${adminName}`,
        icon: <CheckCircleOutlined
          style={{ color: iconColor.red, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToUserPage,
      };
    case NotificationsType.ADMIN_EDITED_WATCH:
      return {
        title: i18n.t('notifications.editedWatch'),
        subtitle: `${adminName }${i18n.t('notifications.hasEditedWatch')} `,
        icon: <CheckCircleOutlined
          style={{ color: iconColor.green, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToWatchPage,
      };
    case NotificationsType.USER_INVITE_PARTNER:
      return {
        title: i18n.t('common.partners'),
        // subtitle: `${userName} ${i18n.t('notifications.requestToAddPartner')} `,
        subtitle: i18n.t('notifications.newUserFromPartner'),
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.blue, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToUserPage,
      };
    case NotificationsType.ADMIN_ADDED_NEW_PARTNER:
      return {
        title: i18n.t('common.partners'),
        subtitle: i18n.t('notifications.addedNewPartner'),
        icon: <CheckCircleOutlined
          style={{ color: iconColor.green, fontSize: iconFontSize }}
        />,
        // linkCreator: linkConstructor.goToUserPage,
      };
    case NotificationsType.ADMIN_ADDED_USER_TO_PARTNER:
      return {
        title: i18n.t('common.partners'),
        subtitle: i18n.t('notifications.addedToPartner'),
        icon: <CheckCircleOutlined
          style={{ color: iconColor.green, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToUserPage,
      };
    case NotificationsType.ADMIN_REMOVED_USER_TO_PARTNER:
      return {
        title: i18n.t('common.partners'),
        subtitle: i18n.t('notifications.removedToPartner'),
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.orange, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToUserPage,
      };
    case NotificationsType.USER_BUYBACK_WATCH:
      return {
        title: i18n.t('common.repurchase'),
        subtitle: `${i18n.t('users.user')} ${userName} ${i18n.t('notifications.repurchase')}`,
        icon: <ExclamationCircleOutlined
          style={{ color: iconColor.orange, fontSize: iconFontSize }}
        />,
        linkCreator: linkConstructor.goToFinAgreementPage,
      };
    default:
      return {
        title: '',
        subtitle: '',
        icon: '',
      };
  }
}
