import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import NumberFormat from 'react-number-format';

import WatchStatus from '../../constants/watch-status';
import { IValuation } from '../../store/types/valuation/valuation-entity';
import { IUser } from '../../store/types/users/user-entity';
import { IValuationValues } from '../../store/types/valuation/valuation-dto';
import './styles.css';
import WatchStatusColor from '../../constants/watch-status-color';
import getFullName from '../../utility/get-full-name';
import { IBrand, IWatchParameter } from '../../store/types/common/watch-params';
import i18n from '../../features/utils/i18n';
import { Button } from 'antd';
import { IWatchConfigEntity } from '../../store/types/watch-config/watch-config.entity';

const getColumns = (
  formik: FormikProps<IValuationValues>,
  onDelete: (id: number) => void,
  ) => {
  return [
    {
      title: i18n.t('common.name'),
      dataIndex: 'user',
      width: '17%',
      className: 'valuations-table__name',
      render: (user: IUser) => (
        <div className="custom-table-spaces">
          {getFullName(user.firstName, user.lastName)}
        </div>
      ),
    },
    {
      title: i18n.t('common.brand'),
      dataIndex: 'brand',
      width: '17%',
      className: 'valuations-table__brand',
      render: (brand: IBrand) => <div className="custom-table-spaces">{brand?.name || ''}</div>,
    },
    {
      title: i18n.t('common.model'),
      width: '13%',
      dataIndex: 'model',
      className: 'valuations-table__brand',
      render: (model: IWatchParameter) => <div className="custom-table-spaces">{model}</div>,
    },
    {
      title: i18n.t('common.tiers'),
      width: '10.33%',
      dataIndex: 'tier',
      className: 'valuations-table__tiers',
      render: (tier: IWatchParameter) => <div className="custom-table-spaces">{tier?.name || ''}</div>,
    },
    {
      title: i18n.t('common.date'),
      dataIndex: 'createdAt',
      width: '8%',
      className: 'valuations-table__date',
      sorter: {
        compare: (a: { createdAt: string }, b: { createdAt: string }): number => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return Number(dateA) - Number(dateB);
        },
      },
      render: (date) => <div className="custom-table-spaces">{moment(date).format('MM.DD.YYYY')}</div>,
    },
    {
      title: i18n.t('common.status'),
      dataIndex: 'status',
      width: '8.33%',
      className: 'valuations-table__status',
      render: (status: string) => {
        let watchStatus = status;
        switch (status) {
          case WatchStatus.APPRAISAL_IN_PROGRESS:
            watchStatus = i18n.t('common.inProcess');
            break;
          case WatchStatus.REJECTED:
            watchStatus = i18n.t('common.rejected');
            break;
          case WatchStatus.APPRAISED:
            watchStatus = i18n.t('common.evaluated');
            break;
          case WatchStatus.INCLUDED_IN_LOAN:
            watchStatus = i18n.t('common.open');
            break;
          default: break;
        }
        return <div className="custom-table-spaces" style={{ color: WatchStatusColor[status] }}>{watchStatus}</div>;
      },
    },
    {
      title: i18n.t('valuations.priceRange'),
      width: '14%',
      className: 'valuations-table__price-range',
      render: (arg, valuation: IValuation) => {
        const { minCost, maxCost } = formik.values[valuation.id] || {};

        const minCostFieldName = `[${valuation.id}.minCost]`;
        const maxCostFieldName = `[${valuation.id}.maxCost]`;
        const minCostErrorMessage = get(formik.errors, minCostFieldName);
        const maxCostErrorMessage = get(formik.errors, maxCostFieldName);

        return (
          <div
            onClickCapture={(e) => e.stopPropagation()}
            className={classNames({
              'invalid-cell': minCostErrorMessage || maxCostErrorMessage,
              'price-range__container': true,
              'custom-table-spaces': true,
            })}
          >
            <NumberFormat
              type="text"
              prefix="$"
              name={minCostFieldName}
              placeholder="0.00"
              value={minCost}
              thousandSeparator
              onValueChange={(values) => {
                const { floatValue } = values;
                formik.setFieldValue(minCostFieldName, floatValue);
              }}
              className="price-range__input"
              disabled={valuation.status === WatchStatus.INCLUDED_IN_LOAN}
            />
            <span className="price-range__divider"> - </span>
            <div>
              <NumberFormat
                type="text"
                prefix="$"
                name={maxCostFieldName}
                placeholder="0.00"
                value={maxCost}
                thousandSeparator
                onValueChange={(values) => {
                  const { floatValue } = values;
                  formik.setFieldValue(maxCostFieldName, floatValue);
                }}
                className="price-range__input"
                disabled={valuation.status === WatchStatus.INCLUDED_IN_LOAN}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: i18n.t('valuations.appreciatedBy'),
      dataIndex: 'appreciatedBy',
      width: '10.33%',
      className: 'valuations-table__appreciated-by',
      render: (appreciatedBy) => <div className="custom-table-spaces">{appreciatedBy}</div>,
    },
    {
      title: i18n.t('common.action'),
      width: '12%',
      className: 'valuations-table__action',
      render: (_,valuation: IValuation ) => {
        return (
          <div className="valuation_actions" role="button" onClick={(e) => e.stopPropagation()}>
            <Button
              className="button--danger"
              onClick={() => onDelete(valuation.id)}
            >
              {i18n.t('common.delete')}
            </Button>
          </div>
        );
      },
    },
  ];
};

export default getColumns;
