import { IGetValuationsListResponse } from '../../types/valuation/valuation-dto';
import { IAction } from '../../actions/action';
import { IAppState } from '../../state-context';
import { IValuation } from '../../types/valuation/valuation-entity';

export const getValuationsRequest = (state: IAppState): IAppState => ({
  ...state,
  valuations: {
    ...state.valuations,
    loading: true,
  },
});

export const getValuationsSuccess = (
  state: IAppState,
  { payload: { data, count } }: IAction<IGetValuationsListResponse>,
): IAppState => ({
  ...state,
  valuations: {
    ...state.valuations,
    list: data,
    loading: false,
    count,
  },
});

export const getValuationsFailure = (state: IAppState): IAppState => ({
  ...state,
  valuations: {
    ...state.valuations,
    loading: false,
  },
});

export const valuateBunchWatchesRequest = (state: IAppState): IAppState => ({
  ...state,
  valuations: {
    ...state.valuations,
    loading: true,
  },
});

export const valuateBunchWatchesSuccess = (
  state: IAppState,
  { payload: updatedValuations }: IAction<Array<IValuation>>,
): IAppState => ({
  ...state,
  valuations: {
    ...state.valuations,
    list: state.valuations.list.map((valuation) => {
      const updatedValuation = updatedValuations.find((i) => i.id === valuation.id);
      if (updatedValuation) {
        const {
          minCost, maxCost, status, appreciatedBy,
        } = updatedValuation;
        return {
          ...valuation, minCost, maxCost, status, appreciatedBy,
        };
      }
      return valuation;
    }),
    loading: false,
  },
});

export const valuateBunchWatchesFailure = (state: IAppState): IAppState => ({
  ...state,
  valuations: {
    ...state.valuations,
    loading: false,
  },
});

export const deleteValuationRequest = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    valuations: {
      ...state.valuations,
      loading: true,
    },
  };
};

export const deleteValuationSuccess = (
  state: IAppState,
  { payload: deletedId }: IAction<number>,
): IAppState => {
  const result = {
    ...state,
    valuations: {
      ...state.valuations,
      list: state.valuations.list.filter((valuation) => valuation.id !== deletedId),
      loading: false,
    },
  };
  return result;
};

export const deleteValuationFailure = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    valuations: {
      ...state.valuations,
      loading: false,
    },
  };
};