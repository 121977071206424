import React, { useCallback, useState } from 'react';
import { Button, Spin } from 'antd';

import './style.css';
import LoanStatus from '../../constants/loan-status';
import LoanStatusSelector from './LoanStatusSelector';
import { ILoan } from '../../store/types/loan/loan-entity';
import { useActions, useStore } from '../../store';
import i18n from '../../features/utils/i18n';

interface ILoanInfoProps {
  handleStatusChange: (value: LoanStatus) => void;
  loan: ILoan;
}

const LoanInfo: React.FC<ILoanInfoProps> = ({ handleStatusChange, loan }) => {
  const { loansActions } = useActions();
  const { loans } = useStore();
  const [previewDisabled, setPreviewDisabled] = useState(false);

  const onPreviewContract = useCallback(async () => {
    setPreviewDisabled(true);
    const contractUrl = await loansActions.getContractPreviewUrl(loan.id);
    if (contractUrl) {
      const baseUrl = process.env.REACT_APP_API_URL;
      window.open(baseUrl + contractUrl, "_blank");
      setPreviewDisabled(false);
    }
  }, [loan.id]);

  if (loans.currentLoading) {
    return <Spin className="spinner spinner-height-auto loan-info__spinner" />;
  }

  return (
    <div className="loan-info">
      <div className="loan-info__section">
        <p className="loan-info__title">{i18n.t('common.status')}</p>
        <div>
          <LoanStatusSelector value={loan.status} handleChange={handleStatusChange} />
        </div>
      </div>
      <div className="loan-info__section">
        <p className="loan-info__title">{i18n.t('financialAgreements.finAgreementReq')}</p>
        <div className="loan-info__data-items">
          <div className="loan-info__labels">
            <p>{i18n.t('financialAgreements.sellersName')}</p>
            <p>{i18n.t('financialAgreements.period')}</p>
            <p>{i18n.t('financialAgreements.appriseCollateral')}</p>
            <p>{i18n.t('financialAgreements.shippingFromUser')}</p>
            {loan.returnDeliveryMethod && <p>{i18n.t('financialAgreements.shippingToUser')}</p>}
          </div>
          <div className="loan-info__values">
            <p>{loan.sellersName}</p>
            <p>{`${loan.period} ${i18n.t('financialAgreements.months')}`}</p>
            <p>{`$ ${new Intl.NumberFormat('en-US').format(loan.amount)}`}</p>
            <p>{loan.deliveryMethod?.name}</p>
            {loan.returnDeliveryMethod && <p>{loan.returnDeliveryMethod.name}</p>}
          </div>
        </div>
        <Button disabled={previewDisabled} onClick={onPreviewContract} className="loan-info_download-contract" type="dashed">
          {i18n.t('financialAgreements.viewUserContract')}
        </Button>
      </div>
    </div>
  );
};

export default LoanInfo;
