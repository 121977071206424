import React from 'react';
import { Input } from 'antd';
import i18n from '../../features/utils/i18n';
import { Selector } from '../selector';
import { FieldError } from './error-component';
import { FormCases, watchFormFields } from './utils';

export const FormSelector = ({
  options,
  onSelect,
  formik,
}) => {
  return (
    <>
      <Selector
        label={i18n.t('watches.caseType')}
        value={formik.values.formId}
        className="watch-form__selector"
        onSelect={onSelect(watchFormFields.FORM)}
        options={options.form}
      />
      <FieldError error={formik.errors.formId} />
      {(formik.values.formId === FormCases.RECTANGLE || formik.values.formId === FormCases.TONNEAU)
            && (
              <>
                <p>{i18n.t('watches.height')}</p>
                <Input
                  type="number"
                  name="otherCustomHeight"
                  className="watch-form__autocomplete"
                  defaultValue={formik.values.otherCustomHeight}
                  onChange={formik.handleChange}
                />
                <FieldError error={formik.errors.otherCustomHeight} />
                <p>{i18n.t('watches.width')}</p>
                <Input
                  type="number"
                  name="otherCustomWidth"
                  className="watch-form__autocomplete"
                  defaultValue={formik.values.otherCustomWidth}
                  onChange={formik.handleChange}
                />
                <FieldError error={formik.errors.otherCustomWidth} />
              </>
            )}
      {formik.values.formId === FormCases.SQUARE
            && (
              <>
                <p>{i18n.t('watches.sideLength')}</p>
                <Input
                  type="number"
                  name="otherCustomWidth"
                  className="watch-form__autocomplete"
                  defaultValue={formik.values.otherCustomWidth}
                  onChange={formik.handleChange}
                />
                <FieldError error={formik.errors.otherCustomWidth} />
              </>
            )}
            <>
              <Selector
                label={i18n.t('watches.caseDiameter')}
                value={formik.values.caseDiameterId}
                className="watch-form__selector"
                onSelect={onSelect(watchFormFields.CASE_DIAMETER)}
                options={options.caseDiameter}
              />
              <FieldError error={formik.errors.caseDiameter} />
            </>
      {!formik.values.formId
            && (
              <>
                <p>{i18n.t('watches.customCaseType')}</p>
                <Input
                  type="text"
                  name="otherForm"
                  className="watch-form__autocomplete"
                  defaultValue={formik.values.otherForm}
                  onChange={formik.handleChange}
                />
                <FieldError error={formik.errors.otherForm} />
              </>
            )}
    </>
  );
};
