import { IsPositive, MaxLength, MinLength } from 'class-validator';
 import i18n from '../../features/utils/i18n';

class EditSubscription {
  public constructor(name, price, description) {
    this.name = name;
    this.price = price;
    this.description = description;
  }

  @MinLength(1, {
    message: i18n.t('validation.minLength'),
  })
  @MaxLength(12, {
    message: i18n.t('validation.maxLength') ,
  })
  name: string;

  @IsPositive()
  price: number;

  @MinLength(1, {
    message: i18n.t('validation.minLength'),
  })
  description: string;
}

export default EditSubscription;
