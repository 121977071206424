import React, { useState } from 'react';
import { CloseOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactPlayer from 'react-player';

import { IWatchMedia } from '../../store/types/watch/watch-entity';
import defaultWatchImage from '../../assets/images/default-watch-image.svg';
import './styles.css';

interface IProps {
  list: Array<IWatchMedia>;
}

const getVideoThumbnail = (key) => (
  <div className="image-gallery__video-thumbnail" key={key}>
    <PlayCircleOutlined style={{ fontSize: '20px', color: '#bfbfbf' }} />
  </div>
);

const ImageGallery: React.FC<IProps> = ({ list }) => {
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselIndexImage, setCarouselIndexImage] = useState(0);

  const handleClick = (index: number) => {
    if (list[index].url) {
      setShowCarousel(true);
      setCarouselIndexImage(index);
    }
  };

  return (
    <div className="image-gallery">
      <div role="button" className="image-gallery__main-photo image-gallery--border-radius" onClick={() => handleClick(0)}>
        <img
          alt=""
          style={{ width: '330' }}
          src={list[0].url || defaultWatchImage}
        />
      </div>
      <div className="image-gallery__thumbnails">
        {list.map((item, index) => (
          <div key={item.id} role="button" className="image-gallery--border-radius image-gallery__thumnbnail" onClick={() => handleClick(index)}>
            {item.type === 'video'
              ? getVideoThumbnail(item.id) : (
                <img
                  alt=""
                  src={item.url || defaultWatchImage}
                  key={item.id}
                />
              )}
          </div>
        ))}
      </div>
      <div>
        {showCarousel && (
          <div className="image-gallery__carousel">
            <CloseOutlined
              onClick={() => setShowCarousel(false)}
              style={{ color: '#fff', fontSize: '20px' }}
              className="image-gallery__close-btn"
            />
            <Carousel showThumbs={false} selectedItem={carouselIndexImage} autoPlay>
              {list.map((item) => (
                <div key={item.id} role="button" className="image-gallery--border-radius">
                  {
                    item.type === 'video'
                      ? <ReactPlayer width="100%" url={item.url} controls />
                      : (
                        <img
                          alt=""
                          src={item.url || defaultWatchImage}
                        />
                      )
                  }
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
