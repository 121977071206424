import { RadioChangeEvent } from 'antd/lib/radio';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useStore } from '../../store';
import { IWatchConfigEntity } from '../../store/types/watch-config/watch-config.entity';
import { validate } from './validation';
import {
  getInitialValues, IWatchFormValues, prepareSelects, watchFormFields,
} from './utils';

export const useWatchForm = (
  onSubmitCallback: (values: IWatchFormValues) => void,
  watchConfig?: IWatchConfigEntity,
) => {
  const { watchConfigs: { params } } = useStore();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validate,
    initialValues: getInitialValues(watchConfig),
    onSubmit: async (values: IWatchFormValues) => {
      const errors = await formik.validateForm();
      if (!isEmpty(errors)) {
        formik.setErrors(errors);
      } else {
        return onSubmitCallback(values);
      }
    },
  });

  const options = useMemo(() => {
    return prepareSelects(params);
  }, [params]);

  const onSelect = (fieldName: string) => (value: number) => {
    return formik.setFieldValue(fieldName, value);
  };

  const onBandTypeChange = (e: RadioChangeEvent) => {
    formik.setFieldValue(watchFormFields.STRAP, null);
    formik.setFieldValue(watchFormFields.BRACELET, null);
    return formik.setFieldValue(watchFormFields.BAND_TYPE, e.target.value);
  };

  const onCostChange = (fieldName: string, value?: number) => {
    return formik.setFieldValue(fieldName, value || null);
  };

  const onBrandSelect = (selectedId: string) => {
    const selectedOption = options.brand.find((option) => option.value === selectedId);
    formik.setFieldValue(watchFormFields.BRAND_AUTOCOMPLETE, selectedOption?.label);
    formik.setFieldValue(watchFormFields.BRAND, selectedId);
    formik.setFieldValue(watchFormFields.MODEL, null);
    formik.setFieldValue(watchFormFields.MODEL_AUTOCOMPLETE, null);
  };

  const onBrandSearch = (value: string) => {
    const brand = options.brand.find((option) => {
      return option.label.toLocaleLowerCase() === value.toLocaleLowerCase().trim().replace(/ +(?= )/g, '');
    });
    if (brand) {
      formik.setFieldValue(watchFormFields.BRAND_AUTOCOMPLETE, brand.label);
      formik.setFieldValue(watchFormFields.BRAND, brand.value);
    } else {
      formik.setFieldValue(watchFormFields.BRAND_AUTOCOMPLETE, value);
      formik.setFieldValue(watchFormFields.BRAND, null);
    }

    formik.setFieldValue(watchFormFields.MODEL_AUTOCOMPLETE, null);
    formik.setFieldValue(watchFormFields.MODEL, null);
  };

  const onCaseDiameterSelect = (selectedId: string) => {
    const selectedCaseDiameter = options.caseDiameter.find((option) => option.value === selectedId);
    formik.setFieldValue(watchFormFields.CASE_DIAMETER_AUTOCOMPLETE, selectedCaseDiameter?.label);
    formik.setFieldValue(watchFormFields.CASE_DIAMETER, selectedId);
  };

  const onCaseDiameterSearch = (value: string) => {
    const caseDiameter = options.caseDiameter.find((option) => {
      return option.label.toLocaleLowerCase() === value.toLocaleLowerCase().trim().replace(/ +(?= )/g, '');
    });
    if (caseDiameter) {
      formik.setFieldValue(watchFormFields.CASE_DIAMETER_AUTOCOMPLETE, caseDiameter.label);
      formik.setFieldValue(watchFormFields.CASE_DIAMETER, caseDiameter.value);
    } else {
      formik.setFieldValue(watchFormFields.CASE_DIAMETER_AUTOCOMPLETE, value);
      formik.setFieldValue(watchFormFields.CASE_DIAMETER, null);
    }
  };

  const onModelSelect = (selectedId: string) => {
    const selectedOption = options.model.find((option) => option.value === selectedId);
    formik.setFieldValue(watchFormFields.MODEL_AUTOCOMPLETE, selectedOption?.label);
    formik.setFieldValue(watchFormFields.MODEL, selectedId);
  };

  const onModelSearch = (value: string) => {
    const model = options.model.find((option) => {
      return option.label.toLocaleLowerCase() === value.toLocaleLowerCase().trim().replace(/ +(?= )/g, '');
    });
    if (model) {
      formik.setFieldValue(watchFormFields.MODEL_AUTOCOMPLETE, model.label);
      formik.setFieldValue(watchFormFields.MODEL, model.value);
    } else {
      formik.setFieldValue(watchFormFields.MODEL_AUTOCOMPLETE, value);
      formik.setFieldValue(watchFormFields.MODEL, null);
    }
  };

  return {
    options,
    onSelect,
    onBandTypeChange,
    onCostChange,
    values: formik.values,
    errors: formik.errors,
    isDirty: formik.dirty,
    submitForm: formik.submitForm,
    onBrandSelect,
    onBrandSearch,
    onModelSelect,
    onModelSearch,
    onCaseDiameterSelect,
    onCaseDiameterSearch
  };
};
