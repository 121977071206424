import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { useStore } from '../../../store/state-context';
import { ApiContext } from '../api-provider';
import { ActionsContext } from '../../../store/actions-context';
import { useDispatch } from '../../../store/dispatch-context';
import useGetActions from '../../../store/actions';
import { IApiService } from '../../../store/services/api-service/api-service';

const ActionsProvider: React.FC = ({ children }) => {
  const state = useStore();
  const dispatch = useDispatch();
  const api = useContext<IApiService>(ApiContext);
  const history = useHistory();
  const pushTo = history.push;
  const actions = useGetActions(state, dispatch, api, pushTo);

  return <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>;
};

ActionsProvider.displayName = 'ActionsProvider';

export default ActionsProvider;
