import React, { useState, useMemo, useEffect } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useFormik } from 'formik';
import {
  Button, Input, Radio,
} from 'antd';
import Form from 'antd/lib/form/Form';
import isEmpty from 'lodash/isEmpty';

import { Selector } from '../selector';
import './style.css';
import {
  IEditWatchFormValues,
  watchFormFields,
  getInitialValues,
  prepareSelects,
  addOtherToParams,
} from './utils';

import { validate } from './validation';
import { useStore } from '../../store';
import { FormSelector } from './component-form-selector';
import { IWatch } from '../../store/types/watch/watch-entity';
import { FieldError } from './error-component';
import { SelectorWithInput } from './selector-with-input';
import { BandType } from '../watch-form/utils';
import i18n from '../../features/utils/i18n';

interface WatchFormProps {
  watch: IWatch;
  onSubmitCallback: (values:IEditWatchFormValues) => void;
}

const EditUserWatchForm: React.FC<WatchFormProps> = ({ watch, onSubmitCallback }) => {
  const { watchConfigs: { params } } = useStore();
  const [isDisabled, setIsDisabled] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validate,
    initialValues: getInitialValues(watch),
    onSubmit: async (values: IEditWatchFormValues) => {
      const errors = await formik.validateForm();
      if (!isEmpty(errors)) {
        formik.setErrors(errors);
      } else {
        setIsDisabled(true);
        return onSubmitCallback(values);
      }
    },
  });

  useEffect(() => setIsDisabled(false), [formik.values]);
  const [options, setOptions] = useState(prepareSelects(addOtherToParams(params)));
  const onSelect = (fieldName: string) => (value: number) => {
    if (!fieldName.includes('other')) {
      const field = `other${fieldName[0].toUpperCase()}${fieldName.substring(1).slice(0, -2)}`;
      if (formik.values.hasOwnProperty(field)) formik.setFieldValue(field, null);
    }

    if (fieldName === 'brandId') {
      const clonedParamsObj = { ...params };
      clonedParamsObj.model = clonedParamsObj.model.filter((modelItem) => (
        modelItem.brandId === formik.values.brandId && modelItem));
      setOptions(prepareSelects(addOtherToParams(clonedParamsObj)));
      formik.setFieldValue('modelId', '');
    }

    return formik.setFieldValue(fieldName, value);
  };

  const onBandTypeChange = (e: RadioChangeEvent) => {
    formik.setFieldValue(watchFormFields.STRAP, null);
    formik.setFieldValue(watchFormFields.OTHER_STRAP, null);
    formik.setFieldValue(watchFormFields.BRACELET, null);
    formik.setFieldValue(watchFormFields.OTHER_BRACELET, null);
    return formik.setFieldValue(watchFormFields.BAND_TYPE, e.target.value);
  };

  const strap = useMemo(() => {
    return (
      <>
        <SelectorWithInput
          fieldName="otherStrap"
          label={i18n.t('watches.strap')}
          formik={formik}
          value={formik.values.strapId}
          className="watch-form__selector"
          onSelect={onSelect(watchFormFields.STRAP)}
          options={options.strap}
        />
        <FieldError error={formik.errors.strapId} />
      </>
    );
  }, [formik, onSelect, watchFormFields]);

  const bracelet = useMemo(() => {
    return (
      <>
        <SelectorWithInput
          fieldName="otherBracelet"
          label={i18n.t('watches.bracelet')}
          formik={formik}
          value={formik.values.braceletId}
          className="watch-form__selector"
          onSelect={onSelect(watchFormFields.BRACELET)}
          options={options.bracelet}
        />
        <FieldError error={formik.errors.braceletId} />
      </>
    );
  }, [formik, onSelect, watchFormFields]);

  return (
    <Form className="watch-form">

      <div className="watch-form__params">
        <div className="watch-form__section">

          <Selector
            label={i18n.t('common.brand')}
            value={formik.values.brandId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.BRAND)}
            options={options.brand}
          />
          <FieldError error={formik.errors.brandId} />

          <SelectorWithInput
            fieldName="otherModel"
            label={i18n.t('common.model')}
            formik={formik}
            value={formik.values.modelId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.MODEL)}
            options={options.model}
          />
          <FieldError error={formik.errors.modelId} />

          <p>{i18n.t('watches.referenceNumber')}</p>
          <Input
            name="referenceNumber"
            className="watch-form__autocomplete"
            defaultValue={formik.values.referenceNumber}
            onChange={formik.handleChange}
          />
          <FieldError error={formik.errors.referenceNumber} />

          <Selector
            label={i18n.t('ownWatches.condition')}
            value={formik.values.conditionId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.CONDITION)}
            options={options.condition}
          />
          <FieldError error={formik.errors.conditionId} />

          <Selector
            label={i18n.t('watches.boxAndPapers')}
            value={formik.values.boxAndPapersId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.BOX_AND_PAPERS)}
            options={options.boxAndPapers}
          />
          <FieldError error={formik.errors.boxAndPapersId} />

          <SelectorWithInput
            formik={formik}
            fieldName="otherCaseMetal"
            label={i18n.t('watches.caseMetal')}
            value={formik.values.caseMetalId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.CASE_METAL)}
            options={options.caseMetal}
          />
          <FieldError error={formik.errors.caseMetalId} />

          <FormSelector
            formik={formik}
            options={options}
            onSelect={onSelect}
          />

          <SelectorWithInput
            formik={formik}
            fieldName="otherDialColor"
            label={i18n.t('watches.dialColor')}
            value={formik.values.dialColorId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.DIAL_COLOR)}
            options={options.dialColor}
          />
          <FieldError error={formik.errors.dialColorId} />

          <SelectorWithInput
            formik={formik}
            fieldName="otherBuckle"
            label={i18n.t('watches.buckle')}
            value={formik.values.buckleId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.BUCKLE)}
            options={options.buckle}
          />
          <FieldError error={formik.errors.buckleId} />

          <div className="watch-form__band-type user-edit-watch-form__band-type">
            <p>{i18n.t('watches.band')}</p>
            <Radio.Group onChange={onBandTypeChange} value={formik.values.bandType}>
              <Radio value={BandType.BRACELET}>{i18n.t('watches.bracelet')}</Radio>
              <Radio value={BandType.STRAP}>{i18n.t('watches.strap')}</Radio>
            </Radio.Group>
            {formik.values.bandType === BandType.BRACELET ? bracelet : strap}
          </div>

          <SelectorWithInput
            formik={formik}
            fieldName="otherComplication"
            label={i18n.t('watches.complication')}
            value={formik.values.complicationId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.COMPLICATION)}
            options={options.complication}
          />
          <FieldError error={formik.errors.complicationId} />

        </div>
      </div>
      <Button
        disabled={isDisabled || !isEmpty(formik.errors)}
        onClick={formik.submitForm}
        className="button--default watch-form__submit-btn"
      >
        {i18n.t('common.save')}
      </Button>

    </Form>
  );
};

export default EditUserWatchForm;
