import React from 'react';
import { Breadcrumb, Layout } from 'antd';
import { Link } from 'react-router-dom';

import BackButton from '../../components/back-button';
import WatchFeatures from '../../components/watch-features';
import ImageGallery from '../../components/image-gallery';
import { Routes } from '../../router/routes';
import './styles.css';
import { useStore } from '../../store';
import useOwnWatchData from '../../hooks/use-own-watch-data';
import { useBackPath } from '../../hooks/use-back-path';
import i18n from '../../features/utils/i18n';

const { Content } = Layout;

const OwnWatch: React.FC = () => {
  const { ownWatches } = useStore();
  const watchImagesList = ownWatches.current?.watchMedia;
  const backPath = useBackPath(Routes.userWatch);
  useOwnWatchData();

  return (
    <Layout>
      <div className="page-wrapper">
        <div className="header-holder">
          <BackButton path={backPath} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`${Routes.ownWatches}/?page=1`}>{i18n.t('ownWatches.ownWatches')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{i18n.t('ownWatches.watch')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Content>
          <div className="watch-content">
            <div className="watch-content__left">
              {watchImagesList && watchImagesList.length && <ImageGallery list={watchImagesList} />}
            </div>
            {ownWatches.current && <WatchFeatures watch={ownWatches.current} isOwn />}
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default OwnWatch;
