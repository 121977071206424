export const watchWhiteListProp = {
  buckle: 'buckle',
  caseMetal: 'caseMetal',
  dialColor: 'dialColor',
  complication: 'complication',
  model: 'model',
  form: 'form',
  bracelet: 'bracelet',
  strap: 'strap',
};

export const getPropertyWatchName = (watchItem, paramName: string) => {
  const foo = `${paramName}Id`;
  return watchItem[foo] !== null ? watchItem[paramName] && watchItem[paramName].name
    : watchItem.notListedInfo && watchItem.notListedInfo[paramName];
};
