import React, { useEffect, useState } from 'react';
import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import {
  Button, Input, Layout, Modal,
} from 'antd';
import isEmpty from 'lodash/isEmpty';

import WatchConfigsTable from '../../components/watch-config-table';
import './style.css';
import { useActions, useStore } from '../../store';
import { IGetWatchesConfigsQueryParams } from '../../store/types/watch-config/watch-config.dto';
import { DEFAULT_TAKE_COUNT } from '../../components/watch-config-table/watch-config-table';
import { Routes } from '../../router/routes';
import i18n from '../../features/utils/i18n';

const { Content } = Layout;
const { confirm } = Modal;

const DEFAULT_PAGE = 1;

const WatchesConfigs = () => {
  const { location, push } = useHistory();
  const { watchConfigsActions } = useActions();
  const { watchConfigs } = useStore();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const isDeleteDisabled = isEmpty(selectedRowKeys) || watchConfigs.loading;
  const {
    keyword = '',
    page = DEFAULT_PAGE,
    take = DEFAULT_TAKE_COUNT,
  }: IGetWatchesConfigsQueryParams = queryString.parse(location.search);

  const initialFilterState = {
    keyword,
    page,
    take,
  };

  const [filterState, setFilterState] = useState(initialFilterState);

  useEffect(() => {
    watchConfigsActions.getList(filterState);
  }, [filterState]);

  const onPageChange = (currentPage: number) => {
    setFilterState({ ...filterState, page: currentPage });
  };

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterState({ ...filterState, keyword: e.target.value, page: DEFAULT_PAGE });
  };

  const onSelectChange = (selectedKeys: React.Key[]) => setSelectedRowKeys(selectedKeys);

  const showConfirmationDeleteManyModal = () => {
    confirm({
      title: `${i18n.t('watches.wantDelete')} ${selectedRowKeys.length > 1 ? i18n.t('watches.watches') : i18n.t('watches.watch')}?`,
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      centered: true,
      onOk() {
        deleteManyHandle(selectedRowKeys);
      },
    });
  };

  const deleteManyHandle = (ids: React.Key[]) => {
    watchConfigsActions.deleteMany(ids);
  };

  return (
    <Layout className="watch-configs-page">
      <Content>
        <header className="watch-configs-page__header">
          <div className="watch-configs-search-bar">
            <div className="watch-configs-search-bar__input-wrapper">
              <h1>{i18n.t('watches.watches')}</h1>
              <Input
                className="watch-configs-page__search-input"
                placeholder={i18n.t('common.search')}
                suffix={<SearchOutlined />}
                defaultValue={filterState.keyword}
                onChange={onSearchInputChange}
              />
            </div>
              <Button
                className="button--primary"
                disabled={watchConfigs.loading}
                onClick={() => push(Routes.addWatch)}
              >
                {i18n.t('common.addWatch')}
              </Button>
          </div>
        </header>
        <div className="watch-configs-page__table-wrapper">
          <WatchConfigsTable
            onPageChange={onPageChange}
            page={filterState.page}
            selectedIds={selectedRowKeys}
            handleSelect={onSelectChange}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default WatchesConfigs;
