import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Button } from 'antd';

import { IWatchConfigEntity } from '../../store/types/watch-config/watch-config.entity';

import './style.css';
import { IBrand, IModel, IWatchParameter } from '../../store/types/common/watch-params';
import i18n from '../../features/utils/i18n';

const columns = (
  onDelete: (id: number) => void,
  onEdit: (id: number) => void,
): ColumnsType<IWatchConfigEntity> => ([
  {
    title: i18n.t('common.brand'),
    dataIndex: 'brand',
    width: '12%',
    className: 'watch-config-table__brand',
    render: (brand: IBrand) => <div className="custom-table-spaces">{brand.name}</div>,
  },
  {
    title: i18n.t('common.model'),
    width: '8%',
    dataIndex: 'model',
    className: 'watch-config-table__model',
    render: (model: IModel) => <div className="custom-table-spaces">{model.name}</div>,
  },
  {
    title: i18n.t('common.tiers'),
    width: '6%',
    dataIndex: 'tier',
    className: 'watch-config-table__tiers',
    render: (tier: IWatchParameter) => <div className="custom-table-spaces">{tier.name}</div>,
  },
  // {
  //   title: 'Case diameter',
  //   width: '10.8%',
  //   dataIndex: 'caseSize',
  //   className: 'watch-config-table__caseSize',
  //   render: (caseSize: IWatchParameter) => <div className="custom-table-spaces">{caseSize.name}</div>,
  // },
  {
    title: i18n.t('watches.caseMetal'),
    width: '8%',
    dataIndex: 'caseMetal',
    className: 'watch-config-table__caseMetal',
    render: (caseMetal: IWatchParameter) => <div className="custom-table-spaces">{caseMetal.name}</div>,
  },
  {
    title: i18n.t('watches.caseType'),
    width: '8.2%',
    dataIndex: 'form',
    className: 'watch-config-table__form',
    render: (form: IWatchParameter) => <div className="custom-table-spaces">{form.name}</div>,
  },
  {
    title: i18n.t('watches.dialColor'),
    width: '9%',
    dataIndex: 'dialColor',
    className: 'watch-config-table__color',
    render: (dialColor: IWatchParameter) => <div className="custom-table-spaces">{dialColor.name}</div>,
  },
  {
    title: i18n.t('watches.strap'),
    width: '9.24%',
    dataIndex: 'strap',
    className: 'watch-config-table__strap',
    render: (strap: IWatchParameter) => strap && <div className="custom-table-spaces">{strap.name}</div>,
  },
  {
    title: i18n.t('watches.bracelet'),
    width: '7.9%',
    dataIndex: 'bracelet',
    className: 'watch-config-table__bracelet',
    render: (bracelet: IWatchParameter) => bracelet && <div className="custom-table-spaces">{bracelet.name}</div>,
  },
  {
    title: i18n.t('watches.buckleType'),
    width: '10.6%',
    dataIndex: 'buckle',
    className: 'watch-config-table__buckle',
    render: (buckle: IWatchParameter) => <div className="custom-table-spaces">{buckle.name}</div>,
  },
  {
    title: i18n.t('watches.price'),
    width: '11.9%',
    className: 'watch-config-table__price',
    render: (_, watchConfig: IWatchConfigEntity) => {
      if (watchConfig.minCost && watchConfig.maxCost) {
        return (
          <span>
            {`$${new Intl.NumberFormat('en-US').format(watchConfig.minCost)}-$${new Intl.NumberFormat('en-US').format(watchConfig.maxCost)}`}
          </span>
        );
      }
      return '';
    },
  },
  {
    title: i18n.t('common.action'),
    width: '2%',
    className: 'watch-config-table__action',
    render: (_, watchConfig: IWatchConfigEntity) => {
      return (
        <div className="watch-actions" role="button" onClick={(e) => e.stopPropagation()}>
          <Button
            className="button--ghost table__btn"
            onClick={() => onEdit(watchConfig.id)}
          >
            {i18n.t('common.edit')}
          </Button>
          <Button
            className="button--danger table__btn"
            onClick={() => onDelete(watchConfig.id)}
          >
            {i18n.t('common.delete')}
          </Button>
        </div>
      );
    },
  },
]);

export default columns;
