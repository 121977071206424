import React from 'react';
import { Layout, Spin } from 'antd';

import { useStore } from '../../store';
import { Roles } from '../../constants';
import useUserData from '../../hooks/use-user-data';

import UserProfile from './userProfile';
import AdminProfile from './adminProfile';
import './styles.css';

const User: React.FC = () => {
  useUserData();
  const {
    users: { current, currentLoading },
  } = useStore();

  if (currentLoading || !current) {
    return <Spin className="spinner full-width" />;
  }

  return (
    <Layout>
      <div className="user">
        <div className="container">
          {current?.role === Roles.USER
            ? <UserProfile user={current} />
            : <AdminProfile user={current} />}
        </div>
      </div>
    </Layout>
  );
};

export default User;
