import moment from "moment";

export const isPremiumUser = (revCatUser) => {
    if (revCatUser == null) {
      return false;
    }
    if (revCatUser.entitlements.length === 0) {
      return false;
    }
    if (!revCatUser.entitlements['premium_user']) {
      return false;
    }
  
    if (moment().isSameOrBefore(revCatUser.entitlements['premium_user'].expires_date)) {
      return true;
    }
    return false;
  }
  
export const getSubscriptionExpiresAt = (revCatUser) => {
    
    return isPremiumUser(revCatUser) && moment(revCatUser.entitlements['premium_user'].expires_date).format('MM.DD.YYYY hh:mm');
  }
  
export const getRevCatManageSubscriberPageUrl = (userId) => {
    const url = `https://app.revenuecat.com/customers/${process.env.REACT_APP_REVENUE_CAT_APP_ID}/${userId}`;
    return url;
  }