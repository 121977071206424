import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

const toastNotification = ({ type, message }: ArgsProps) => {
  if (type) {
    const notificationApi = notification[type];
    return notificationApi({
      placement: 'bottomRight',
      bottom: 50,
      duration: 3,
      message,
    });
  }
};

export default toastNotification;
