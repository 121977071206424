import {
  MaxLength, MinLength, Validate, IsOptional,
} from 'class-validator';
import { CustomEmailValidator, CustomPhoneValidator } from '../../../../utility/validation/validator-constraint';
import i18n from '../../../../features/utils/i18n'

export default class ValidateContacts {
  public constructor(phoneNumber = undefined, email = '') {
    this.phoneNumber = phoneNumber;
    this.email = email;
  }

  @Validate(CustomPhoneValidator)
  @IsOptional()
  phoneNumber: string | undefined;

  @MinLength(8, {
    message: i18n.t('validation.minLength8'),
  })
  @MaxLength(30, {
    message:  i18n.t('validation.maxLength30'),
  })
  @Validate(CustomEmailValidator)
  email: string;
}
