import { Dispatch } from 'react';

import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import { SubscriptionsActionType } from '../actions-types';
import { ISubscription } from '../../types/subscription/subscription-entity';
import {
  IGetSubscriptionsListResponse,
  IUpdateSubscriptionBody,
} from '../../types/subscription/subscription-dto';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';

interface IUpdateSubscriptionById extends IUpdateSubscriptionBody {
  id: string;
}

export interface ISubscriptionsActions {
  getSubscriptions: () => Promise<void>;
  getSubscriptionById: (id: string) => Promise<void>;
  updateSubscriptionById: (payload: IUpdateSubscriptionById) => Promise<void>;
}

export class SubscriptionsActions implements ISubscriptionsActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService) {
    this.dispatch = dispatch;
    this.api = api;
  }

  public getSubscriptions = async (): Promise<void> => {
    try {
      this.dispatch({ type: SubscriptionsActionType.GetSubscriptionsRequest, payload: null });
      const response: IGetSubscriptionsListResponse = await this.api.get({
        url: 'subscription/all',
      });
      this.dispatch({
        type: SubscriptionsActionType.GetSubscriptionsSuccess,
        payload: response,
      });
    } catch (error) {
      this.dispatch({ type: SubscriptionsActionType.GetSubscriptionsFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getSubscriptionById = async (id: string): Promise<void> => {
    try {
      this.dispatch({ type: SubscriptionsActionType.GetSubscriptionByIdRequest, payload: { id } });
      const response: ISubscription = await this.api.get({
        url: `/subscription/single/${id}`,
      });
      this.dispatch({
        type: SubscriptionsActionType.GetSubscriptionByIdSuccess,
        payload: response,
      });
    } catch (error) {
      this.dispatch({ type: SubscriptionsActionType.GetSubscriptionByIdFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public updateSubscriptionById = async (payload: IUpdateSubscriptionById): Promise<void> => {
    try {
      this.dispatch({ type: SubscriptionsActionType.UpdateSubscriptionByIdRequest, payload: null });
      const data = { name: payload.name, price: payload.price, description: payload.description };
      const response: ISubscription = await this.api.patch({
        url: `/subscription/${payload.id}`,
        data,
      });
      this.dispatch({
        type: SubscriptionsActionType.UpdateSubscriptionByIdSuccess,
        payload: response,
      });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Changes was saved' });
    } catch (error) {
      this.dispatch({
        type: SubscriptionsActionType.UpdateSubscriptionByIdFailure,
        payload: error,
      });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };
}
