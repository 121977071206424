import React, { useState } from 'react';
import { Tabs } from 'antd';
import DeliveryMethodForm from './forms/DeliveryMethodForm/DeliveryMethodForm';
import PeriodForm from './forms/PeriodForm/PeriodForm';
import CalculationsForm from './forms/CalculationsForm/CalculationsForm';

import './style.css';
import i18n from '../../features/utils/i18n';

const { TabPane } = Tabs;

const FinancialAgreementTerms = () => {
  const [activeTab, setActiveTab] = useState('1');
  const onChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    <div>
      <Tabs activeKey={activeTab} onChange={onChange}>
        <TabPane tab={i18n.t('finAgreementTerms.deliveryMethod')} key="1" className="fin-agreement-tab">
          <DeliveryMethodForm />
        </TabPane>
        <TabPane tab={i18n.t('finAgreementTerms.terms')} key="2" className="fin-agreement-tab">
          <PeriodForm />
        </TabPane>
        <TabPane tab={i18n.t('finAgreementTerms.calculations')} key="3" className="fin-agreement-tab">
          <CalculationsForm />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default FinancialAgreementTerms;
