import React, { useState } from 'react';
import { Tabs, Layout } from 'antd';

import PersonalDetails from './forms/personal-details';
import Settings from './forms/settings';
import { IUser } from '../../store/types/users/user-entity';
import './styles.css';
import { Roles } from '../../constants';
import i18n from '../../features/utils/i18n';

interface IProps {
  profile: IUser;
  loggedInAdmin: boolean;
}

const { TabPane } = Tabs;
const { Content } = Layout;

const PERSONAL_DETAILS = 'PERSONAL_DETAILS';
const SETTINGS = 'SETTINGS';

const ProfileContent: React.FC<IProps> = ({ profile, loggedInAdmin }) => {
  const [tab, setTab] = useState(PERSONAL_DETAILS);
  const handleTabChange = (newTab: string) => {
    setTab(newTab);
  };
  const { role } = profile;
  return (
    <div className="tab-holder">
      <Tabs
        onChange={handleTabChange}
        size="large"
        className="profile__tabs"
        activeKey={tab}
        defaultActiveKey={PERSONAL_DETAILS}
      >
        <TabPane tab={i18n.t('auth.personalDetails')} key={PERSONAL_DETAILS}>
          <Content>
            <PersonalDetails profile={profile} loggedInAdmin={loggedInAdmin} />
          </Content>
        </TabPane>
        { (loggedInAdmin || role === Roles.SUPER_ADMIN)
          && (
            <TabPane tab={i18n.t('auth.settings')} key={SETTINGS}>
              <Content>
                <Settings />
              </Content>
            </TabPane>
          )}
      </Tabs>
    </div>
  );
};

export default ProfileContent;
