import { IAction } from '../../actions/action';
import { IAppState } from '../../state-context';
import { IError } from '../../types/common';
import { IWatchParameters } from '../../types/watch-config/watch-config-state';
import {
  IDeleteManyWatchConfigsRequest, IGetWatchesConfigsResponse, IUpdateWatchConfigResponseData,
} from '../../types/watch-config/watch-config.dto';
import { IWatchConfigEntity } from '../../types/watch-config/watch-config.entity';

export const createWatchConfigRequest = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      currentLoading: true,
    },
  };
};

export const createWatchConfigSuccess = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      currentLoading: false,
    },
  };
};

export const createWatchConfigFailure = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      currentLoading: false,
    },
  };
};

export const updateWatchConfigRequest = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      currentLoading: true,
    },
  };
};

export const updateWatchConfigSuccess = (
  state: IAppState,
  action: IAction<IUpdateWatchConfigResponseData>,
): IAppState => {
  const { watchConfigs } = state;
  return {
    ...state,
    watchConfigs: {
      ...watchConfigs,
      current: watchConfigs.current ? { ...watchConfigs.current, ...action.payload } : null,
      currentLoading: false,
    },
  };
};

export const updateWatchConfigFailure = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      currentLoading: false,
    },
  };
};

export const getWatchesConfigsListRequest = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      loading: true,
    },
  };
};

export const getWatchesConfigsListSuccess = (
  state: IAppState,
  { payload }: IAction<IGetWatchesConfigsResponse>,
): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      list: payload.data,
      count: payload.count,
      loading: false,
    },
  };
};

export const getWatchesConfigsListFailure = (
  state: IAppState,
  action: IAction<IError>,
): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      list: [],
      count: 0,
      loading: false,
      error: action.payload,
    },
  };
};

export const getOneWatchConfigRequest = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      currentLoading: true,
    },
  };
};

export const getOneWatchConfigSuccess = (
  state: IAppState,
  { payload }: IAction<IWatchConfigEntity>,
): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      current: payload,
      currentLoading: false,
    },
  };
};

export const getOneWatchConfigFailure = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      currentLoading: false,
    },
  };
};

export const deleteWatchConfigRequest = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      loading: true,
    },
  };
};

export const deleteWatchConfigSuccess = (
  state: IAppState,
  { payload: deletedId }: IAction<number>,
): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      list: state.watchConfigs.list.filter((config) => config.id !== deletedId),
      loading: false,
    },
  };
};

export const deleteWatchConfigFailure = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      loading: false,
    },
  };
};

export const getWatchParamsRequest = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      paramsLoading: true,
    },
  };
};

export const getWatchParamsSuccess = (
  state: IAppState,
  { payload }: IAction<IWatchParameters>,
): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      params: payload,
      paramsLoading: false,
    },
  };
};

export const getWatchParamsFailure = (
  state: IAppState,
): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      paramsLoading: false,
    },
  };
};

export const deleteManyWatchConfigsRequest = (state: IAppState): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      loading: true,
    },
  };
};

export const deleteManyWatchConfigsSuccess = (
  state: IAppState,
  { payload }: IAction<IDeleteManyWatchConfigsRequest>,
): IAppState => {
  const filteredList = state.watchConfigs.list.filter(
    (watchConfig) => !payload.ids.includes(watchConfig.id),
  );
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      list: filteredList,
      count: state.watchConfigs.count - payload.ids.length,
      loading: false,
    },
  };
};

export const deleteManyWatchConfigsFailure = (
  state: IAppState,
  action: IAction<IError>,
): IAppState => {
  return {
    ...state,
    watchConfigs: {
      ...state.watchConfigs,
      loading: false,
      error: action.payload,
    },
  };
};
