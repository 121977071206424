import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useActions } from '../../store/actions-context';
import parseParams from '../../utility/parse-params';
import { IGetNotificationsListQueryParams } from '../../store/types/notification/notification-dto';

interface IFilterState {
  dateTo?: Date | string;
  dateFrom?: Date | string;
  page?: number;
  byNovelty?: boolean;
}

export interface IUseNotificationsData {
  filterState: IFilterState;
  currentPage: number;
  handleDateChange: (values: string[]) => void;
  handleCheckboxChange: (event: CheckboxChangeEvent) => void;
  handlePageChange: (value: number) => void;
  handleDeleteNotification: (id: number) => void;
}

export const useNotifications = (): IUseNotificationsData => {
  const { notificationsActions } = useActions();
  const location = useLocation();
  const params = parseParams(location.search);
  const {
    dateTo, dateFrom, page, byNovelty = false,
  }: IGetNotificationsListQueryParams = params;
  const from = dateFrom && dateFrom.toLocaleString();
  const to = dateTo && dateTo.toLocaleString();
  const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

  const [filterState, setFilterState] = useState<IFilterState>({
    dateTo: to,
    dateFrom: from,
    page: currentPage,
    byNovelty,
  });

  useEffect(() => {
    notificationsActions.getNotificationsAndSetViewedRenderedNotification({
      ...filterState,
      page: currentPage,
    });
  }, [filterState, currentPage]);

  const handleDeleteNotification = (id: number) => {
    notificationsActions.deleteNotification({ id, query: { ...filterState, page: currentPage } });
    notificationsActions.getNotificationsAndSetViewedRenderedNotification({
      ...filterState,
      page: currentPage,
    });
  };

  const handleDateChange = (values) => {
    setFilterState({
      ...filterState,
      dateFrom: get(values, '[0]'),
      dateTo: get(values, '[1]'),
    });
    setCurrentPage(1);
  };

  const handleCheckboxChange = ({ target }: CheckboxChangeEvent) => {
    setFilterState({ ...filterState, byNovelty: target.checked });
    setCurrentPage(1);
  };

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
  };

  return ({
    filterState,
    currentPage,
    handleDateChange,
    handleCheckboxChange,
    handlePageChange,
    handleDeleteNotification,
  });
};

export default useNotifications;
