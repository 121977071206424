import React, { useEffect } from 'react';
import { Breadcrumb, Layout, Spin } from 'antd';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { useStore } from '../../store/state-context';
import { useActions } from '../../store/actions-context';
import BackButton from '../../components/back-button';
import { Routes } from '../../router/routes';
import SubscriptionCard from '../../components/subscription-card';
import './styles.css';
import EditSubscriptionForm from '../../components/edit-subscription-form/edit-subscription-form';
import i18n from '../../features/utils/i18n';

const EditSubscription: React.FC = () => {
  const { subscriptionsActions } = useActions();
  const { subscriptions } = useStore();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    subscriptionsActions.getSubscriptionById(id);
  }, []);
  if (subscriptions.currentLoading) {
    return <Spin className="spinner full-width" />;
  }
  return (
    <Layout className="edit-subscription">
      <div className="container">
        <div className="header-holder">
          <BackButton path={Routes.subscriptions} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Routes.subscriptions}>{i18n.t('subscriptions.subscriptions')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{i18n.t('common.edit')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="edit-subscription__content">
          { subscriptions.current && <SubscriptionCard data={subscriptions.current} /> }
          { subscriptions.current && <EditSubscriptionForm subscription={subscriptions.current} /> }
        </div>
      </div>
    </Layout>
  );
};

export default EditSubscription;
