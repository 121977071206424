import { useEffect } from 'react';

import { ITemplate } from '../../store/types/template/template-entity';
import { useStore, useActions } from '../../store';

export enum TemplateActionTypes {
  SAVE = 'SAVE',
  COPY = 'COPY',
  EDIT = 'EDIT',
  DUPLICATE = 'DUPLICATE',
  DELETE = 'DELETE',
}

const useTemplates = () => {
  const { templatesActions } = useActions();
  const { templates } = useStore();

  const actions = {
    [TemplateActionTypes.SAVE]: (content: string) => templatesActions.addTemplate({ content }),
    [TemplateActionTypes.EDIT]: (data: ITemplate) => templatesActions.updateTemplate(data),
    [TemplateActionTypes.DUPLICATE]: (content: string) => templatesActions.addTemplate({ content, isDuplicated: true }),
    [TemplateActionTypes.DELETE]: (id: number) => templatesActions.deleteTemplate({ id }),
  };

  useEffect(() => {
    templatesActions.getTemplates();
  }, []);

  const getActionCallback = (action: TemplateActionTypes, content: string | number | ITemplate) => {
    const callback = actions[action];
    callback(content);
  };

  return { TemplateActionTypes, getActionCallback, templates };
};

export default useTemplates;
