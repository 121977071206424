import { MinLength } from 'class-validator';
import { IsEqualTo } from '../../../../utility/validation/match-validator';
import i18n from '../../../../features/utils/i18n'

class ChangePassword {
  public constructor(password = '', confirmPassword: '') {
    this.password = password;
    this.confirmPassword = confirmPassword;
  }

  @MinLength(6, {
    message: i18n.t('validation.least6')
  })
  @IsEqualTo('confirmPassword')
  password: string;

  @MinLength(6, {
    message: i18n.t('validation.least6')
  })
  @IsEqualTo('password')
  confirmPassword: string;
}

export default ChangePassword;
