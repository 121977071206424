import { FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';
import i18n from '../../../../features/utils/i18n'

export const errorMessages = {
  empty: i18n.t('validation.notBeEmpty'),
  greaterThan: i18n.t('validation.notBeGreater'),
  negative: i18n.t('validation.positiveNumber'),
  lessThan: i18n.t('validation.notBeLess'),
};

export const validateCalculationsForm = (
  values: FormikValues,
) => {
  const { feeConfig, monthlyPercentage } = values;
  let errors = {
    feeConfig: {},
    monthlyPercentage: {},
  };

  Object.keys(feeConfig).forEach((key) => {
    if (feeConfig[key] < 0) {
      errors = {
        ...errors,
        feeConfig: {
          ...errors.feeConfig,
          [key]: errorMessages.negative,
        },
      };
    }
    if (monthlyPercentage[key] < 0) {
      errors = {
        ...errors,
        monthlyPercentage: {
          ...errors.monthlyPercentage,
          [key]: errorMessages.negative,
        },
      };
    }

    if (feeConfig[key] > 100) {
      errors = {
        ...errors,
        feeConfig: {
          ...errors.feeConfig,
          [key]: errorMessages.greaterThan,
        },
      };
    }
    if (monthlyPercentage[key] > 100) {
      errors = {
        ...errors,
        monthlyPercentage: {
          ...errors.monthlyPercentage,
          [key]: errorMessages.greaterThan,
        },
      };
    }

    if (!feeConfig[key] && feeConfig[key] !== 0) {
      errors = {
        ...errors,
        feeConfig: {
          ...errors.feeConfig,
          [key]: errorMessages.empty,
        },
      };
    }
    if (!monthlyPercentage[key] && monthlyPercentage[key] !== 0) {
      errors = {
        ...errors,
        monthlyPercentage: {
          ...errors.monthlyPercentage,
          [key]: errorMessages.empty,
        },
      };
    }
    return errors;
  });
  const isValidForm = isEmpty(errors.feeConfig) && isEmpty(errors.monthlyPercentage);
  return isValidForm ? {} : errors;
};
