import get from 'lodash/get';
import { IValuateMultipleWatchesRequestData, IValuationValue, IValuationValues } from '../../store/types/valuation/valuation-dto';

const extractChangedData = (values: IValuationValues, initialValues: IValuationValues) => {
  const ids = Object.keys(values);
  const result = ids.reduce<IValuateMultipleWatchesRequestData>((acc, id) => {
    const valuation: IValuationValue = values[id];
    const initialValuation: IValuationValue = initialValues[id];

    const initialMinCost = get(initialValuation, 'minCost');
    const initialMaxCost = get(initialValuation, 'maxCost');

    const hasNotChanged = initialMinCost === valuation.minCost
      && initialMaxCost === valuation.maxCost;

    if (hasNotChanged) {
      return [...acc];
    }

    if (!valuation.minCost && !valuation.maxCost) {
      return [...acc];
    }

    return [...acc, { id: +id, minCost: valuation.minCost, maxCost: valuation.maxCost }];
  }, []);

  return result;
};

export default extractChangedData;
