import { Min } from 'class-validator';
import { validateForm } from '../../utility/validation/class-validator';
import { BandType, IWatchFormValues, watchFormFields } from './utils';
import i18n from '../../features/utils/i18n';

class ValidationSchema {
  @Min(1, { message: i18n.t('validation.buckleSelected') })
  buckleId: number;

  @Min(1, { message: i18n.t('validation.caseMetalSelected') })
  caseMetalId: number;

  // @Min(1, { message: 'Case diameter should be selected' })
  // caseSizeId: number;

  @Min(1, { message: i18n.t('validation.dialColorSelected') })
  dialColorId: number;

  @Min(1, { message: i18n.t('validation.formSelected') })
  formId: number;

  @Min(1, { message: i18n.t('validation.tierSelected' )})
  tierId: number;
}

export const validate = async (values: IWatchFormValues) => {
  const errors = await validateForm(ValidationSchema, values) || {};

  if (!values.brandAutoComplete && !values.brandId) {
    errors[watchFormFields.BRAND] = i18n.t('validation.brandSelected');
  }

  if (!values.caseDiameterAutoComplete && !values.caseDiameterId) {
    errors[watchFormFields.CASE_DIAMETER] = i18n.t('validation.caseDiameterSelected');
  }

  if (!values.modelAutoComplete && !values.modelId) {
    errors[watchFormFields.MODEL] =  i18n.t('validation.modelSelected');
  }

  const { minCost, maxCost } = values;

  if (minCost && minCost <= 0) {
    errors[watchFormFields.MIN_COST] = i18n.t('validation.minGreaterThan');
  }

  if (maxCost && maxCost <= 0) {
    errors[watchFormFields.MAX_COST] = i18n.t('validation.maxGreaterThan');
  }

  if (!minCost && maxCost) {
    errors[watchFormFields.MIN_COST] = i18n.t('validation.minCostEmpty');
  }

  if (minCost && !maxCost) {
    errors[watchFormFields.MAX_COST] = i18n.t('validation.maxCostEmpty');
  }

  if (minCost && maxCost && maxCost <= minCost) {
    errors[watchFormFields.MIN_COST] =  i18n.t('validation.minCostLowerMaxCost');
  }

  if (values[watchFormFields.BAND_TYPE] === BandType.BRACELET && !values.braceletId) {
    errors[watchFormFields.BRACELET] =  i18n.t('validation.braceletSelected');
  }

  if (values[watchFormFields.BAND_TYPE] === BandType.STRAP && !values.strapId) {
    errors[watchFormFields.STRAP] = i18n.t('validation.strapSelected');
  }

  if (!minCost && !maxCost) {
    errors[watchFormFields.MIN_COST] = i18n.t('validation.minCostAMaxNotBeEmpty');
  }

  return errors;
};
