import { IWatchUpdatedParameter } from '../../store/types/common/watch-params';
import { IWatchParameters, IWatchUpdatedParameters } from '../../store/types/watch-config/watch-config-state';
import { IWatch } from '../../store/types/watch/watch-entity';
import { watchWhiteListProp } from '../../utility/get-watch-data';
import { ISelectOption } from '../selector';
import { BandType } from '../watch-form/utils';

export enum FormCases {
  RECTANGLE = 1,
  SQUARE = 2,
  TONNEAU = 3,
  ROUND = 4,
  CONVEX_ROUND = 5,
}

export const watchFormFields = {
  BRAND: 'brandId',
  MODEL: 'modelId',
  BOX_AND_PAPERS: 'boxAndPapersId',
  BRACELET: 'braceletId',
  BUCKLE: 'buckleId',
  CASE_METAL: 'caseMetalId',
  CASE_SIZE: 'caseSizeId',
  CASE_DIAMETER: 'caseDiameterId',
  CONDITION: 'conditionId',
  DIAL_COLOR: 'dialColorId',
  STRAP: 'strapId',
  BAND_TYPE: 'bandType',
  FORM: 'formId',
  TIER: 'tierId',
  COMPLICATION: 'complicationId',
  REFERENCE_NUMBER: 'referenceNumber',

  OTHER_BRAND: 'otherBrand',
  OTHER_BOX_AND_PAPERS: 'otherBoxAndPapers',
  OTHER_CASE_METAL: 'otherCaseMetal',
  OTHER_CONDITION: 'otherCondition',
  OTHER_DIAL_COLOR: 'otherDialColor',
  OTHER_COMPLICATION: 'otherComplication',
  OTHER_MODEL: 'otherModel',
  OTHER_BRACELET: 'otherBracelet',
  OTHER_STRAP: 'otherStrap',
  OTHER_BUCKLE: 'otherBuckle',
  OTHER_FORM: 'otherForm',
  OTHER_CUSTOM_WIDTH: 'otherCustomWidth',
  OTHER_CUSTOM_HEIGHT: 'otherCustomHeight',
};

export interface IEditWatchFormValues {
  brandId: number;
  modelId: number| null;
  braceletId: number | null;
  buckleId: number | null;
  caseMetalId: number | null;
  caseSizeId: number | null;
  caseDiameterId: number | null;
  dialColorId: number | null;
  strapId: number | null;
  formId: number | null;
  tierId: number | null;
  bandType: BandType,
  complicationId: number | null,
  referenceNumber: string | null,
  boxAndPapersId: number,
  conditionId: number;

  otherBrand: string | null;
  otherBoxAndPapers: string | null;
  otherCaseMetal: string | null;
  otherCondition: string | null;
  otherDialColor: string | null;
  otherComplication: string | null;
  otherModel: string | null;
  otherBracelet: string | null;
  otherStrap: string | null;
  otherBuckle: string | null;
  otherForm: string | null;
  otherCustomWidth: string | null;
  otherCustomHeight: string | null;
}

export const getInitialValues = (watch: IWatch) => {
  return {
    brandId: watch.brandId,
    modelId: watch.modelId,
    buckleId: watch.buckleId,
    caseMetalId: watch.caseMetalId,
    caseSizeId: watch.caseSizeId,
    caseDiameterId: watch.caseDiameterId,
    dialColorId: watch.dialColorId,
    formId: watch.formId,
    tierId: watch.tierId,
    braceletId: watch.braceletId,
    strapId: watch.strapId,
    bandType: watch.strapId ? BandType.STRAP : BandType.BRACELET,
    complicationId: watch.complicationId,
    conditionId: watch.conditionId,
    referenceNumber: watch.referenceNumber,
    boxAndPapersId: watch.boxAndPapersId,

    otherBrand: watch.notListedInfo.brand,
    otherBoxAndPapers: watch.notListedInfo.boxAndPapers,
    otherCaseMetal: watch.notListedInfo.caseMetal,
    otherCondition: watch.notListedInfo.condition,
    otherDialColor: watch.notListedInfo.dialColor,
    otherComplication: watch.notListedInfo.complication,
    otherModel: watch.notListedInfo.model,
    otherBracelet: watch.notListedInfo.bracelet,
    otherStrap: watch.notListedInfo.strap,
    otherBuckle: watch.notListedInfo.buckle,
    otherForm: watch.notListedInfo.form,
    otherCustomWidth: watch.notListedInfo.customWidth,
    otherCustomHeight: watch.notListedInfo.customHeight,
  };
};

export const getOptions = (params: IWatchUpdatedParameter[] = []): ISelectOption[] => {
  return params.map(({ id, name, ...meta }) => ({ value: id, label: name, meta }));
};

export const prepareSelects = (params: IWatchUpdatedParameters) => {
  return {
    brand: getOptions(params.brand),
    boxAndPapers: getOptions(params.boxAndPapers),
    buckle: getOptions(params.buckle),
    caseMetal: getOptions(params.caseMetal),
    caseSize: getOptions(params.caseSize),
    caseDiameter: getOptions(params.caseDiameter),
    condition: getOptions(params.condition),
    dialColor: getOptions(params.dialColor),
    bracelet: getOptions(params.bracelet),
    strap: getOptions(params.strap),
    form: getOptions(params.form),
    tier: getOptions(params.tier),
    model: getOptions(params.model),
    complication: getOptions(params.complication),
    referenceNumber: getOptions(params.referenceNumber),
    bandType: [
      { value: BandType.BRACELET, label: BandType.BRACELET },
      { value: BandType.STRAP, label: BandType.STRAP },
    ],
  };
};

export const addOtherToParams = (paramsList: IWatchParameters): IWatchUpdatedParameters => {
  const paramsForUpdate = Object.keys(watchWhiteListProp);
  paramsForUpdate.forEach((item) => {
    const optionsList = paramsList[item];
    try {
      if (!optionsList) {
        optionsList.push({ id: null, name: 'other' });
        return;
      }
      if (
        optionsList[optionsList.length - 1].id === undefined
        || optionsList[optionsList.length - 1].id === null
      ) return;
      optionsList.push({ id: null, name: 'other' });
    } catch (error) {
      optionsList.push({ id: null, name: 'other' });
      console.error('Look like there is no models. \n', error);
    }
  });
  return paramsList;
};
