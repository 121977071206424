import { IWatch } from '../store/types/watch/watch-entity';
import WatchStatus from '../constants/watch-status';

export interface IWatchStatusTheme {
  statusColor: string;
  status: string;
}
export const getWatchStatus = (watch: IWatch | null): IWatchStatusTheme => {
  if (!watch) {
    return { statusColor: '', status: '' };
  }
  if (watch.status === WatchStatus.APPRAISED || watch.status === WatchStatus.INCLUDED_IN_LOAN) {
    return {
      statusColor: 'green',
      status: `\u0024${watch.minCost.toLocaleString()}-\u0024${watch.maxCost.toLocaleString()}`,
    };
  }
  const isInReview = watch.status === WatchStatus.APPRAISAL_IN_PROGRESS;
  return {
    statusColor: isInReview ? 'yellow' : 'red',
    status: isInReview ? 'in process' : watch.status.toLowerCase().replace('_', ' '),
  };
};

export default getWatchStatus;
