import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';

import { useActions, useStore } from '../store';
import useRefreshToken from '../hooks/use-refresh-token';
import SuspenseHOC from '../hocs/SuspenceHOC';
import '../index.css';

const loadRouter = () => import('./Routing');

const AppRouter: React.FC = () => {
  const { auth } = useStore();
  const { location } = useHistory();
  const { authActions } = useActions();

  useRefreshToken();
  useEffect(() => authActions.initApp(location.pathname), []);

  if (auth.loading) {
    return <Spin className="spinner full-width" />;
  };

  return <SuspenseHOC load={ loadRouter } />;
};

export default AppRouter;
