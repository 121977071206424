import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useActions } from '../store/actions-context';

export const useUser = (): void => {
  const { usersActions } = useActions();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      usersActions.getUserById(id);
      usersActions.getRevenueCatUser(id);
    }
  }, []);
};

export default useUser;
