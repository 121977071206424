import React, { useState } from 'react';
import {
  Button,
  Input,
  Modal,
  Form,
  DatePicker,
} from 'antd';
import moment from 'moment';
import i18n from '../../features/utils/i18n';
import { useActions } from '../../store';
import {
  ICreatePromocode,
  IGetPromocodesParams,
} from '../../store/types/promocode/promocode-dto';
import { IPromocode } from '../../store/types/promocode/promocode-entity';

interface ICreateModalProps {
  filter?: IGetPromocodesParams;
  promo?: IPromocode;
}

export const PromoModal = ({
  filter = {},
  promo,
}: ICreateModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const [form] = Form.useForm();

  const { promocodeActions } = useActions();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values: ICreatePromocode) => {
    setLoading(true);
    let result = false;
    const { startDate, endDate, ...params } = values;
    const start = moment(startDate).format('MM.DD.YYYY');
    const end = moment(endDate).format('MM.DD.YYYY');
    if (promo) {
      result = await promocodeActions.updatePromocode(
        { endDate: end },
        promo.id,
      );
    } else {
      result = await promocodeActions.createPromocode(
        { ...params, startDate: start, endDate: end },
        filter,
      );
    }
    setLoading(false);
    if (result) {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  React.useEffect(() => {
    setInitialValues({
      name: null,
      code: null,
      startDate: null,
      endDate: null,
    });
  }, []);

  React.useEffect(() => {
    if (promo) {
      for (const key in initialValues) {
        let value = promo[key];
        if (key === 'startDate' || key === 'endDate') {
          value = moment(promo[key]);
        }
        form.setFieldsValue({
          [key]: value,
        });
      }
    }
  }, [isModalVisible]);

  return (
    <>
      <Button
        className="button--primary add-promocode__btn"
        onClick={showModal}
      >
        {promo ? i18n.t('common.edit') : i18n.t('promocode.create')}
      </Button>
      <Modal
        closable={false}
        centered
        title={<h2>{promo ? i18n.t('promocode.edit') : i18n.t('promocode.create')}</h2>}
        visible={isModalVisible}
        footer={false}
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          onFinish={onFinish}
          className="create-promo-modal"
          initialValues={initialValues}
        >
          <Form.Item
            label={i18n.t('common.name')}
            name="name"
            rules={[{ required: true, message: i18n.t('rules.name') }]}
          >
            <Input disabled={!!promo} />
          </Form.Item>
          <Form.Item
            label={i18n.t('common.code')}
            name="code"
            rules={[
              { required: true, message: i18n.t('rules.code') },
              { min: 6, message: i18n.t('rules.min') },
            ]}
          >
            <Input disabled={!!promo} />
          </Form.Item>
          <Form.Item
            label={i18n.t('common.startDate')}
            name="startDate"
            rules={[{ required: true, message: i18n.t('rules.startDate') }]}
          >
            <DatePicker size="small" disabled={!!promo} format={'MM.DD.YYYY'} />
          </Form.Item>
          <Form.Item
            label={i18n.t('common.endDate')}
            name="endDate"
            rules={[{ required: true, message: i18n.t('rules.endDate') }]}
          >
            <DatePicker size="small" format={'MM.DD.YYYY'} />
          </Form.Item>
          <div className="form-buttons">
            <Button onClick={handleCancel}>
              {i18n.t('common.cancel')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {promo ? i18n.t('common.save') : i18n.t('common.create')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
