import React from 'react';
import { Dropdown, Menu, Button } from 'antd';
import {
  CameraOutlined,
  DownloadOutlined,
  SettingOutlined
} from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import i18n from '../../features/utils/i18n';

interface ILogoUploaderProps {
  upload: (file: any) => void;
  url?: string;
  visible: boolean;
}

export const LogoUploader: React.FC<ILogoUploaderProps> = ({ upload, url, visible }) => {
  let inputFile: HTMLInputElement | null;

  const [selectedFile, setSelectedFile] = React.useState();
  const [preview, setPreview] = React.useState<any>(null);

  React.useEffect(() => {
    if (!visible && preview) {
      setPreview(null);
    }
  }, [visible])

  React.useEffect(() => {
    if (!selectedFile) {
        setPreview(undefined);
        return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
}, [selectedFile])

  const onUpload = () => {
    if (inputFile) {
      inputFile.click();
    }
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          icon={<DownloadOutlined />}
          type="primary"
          block
          onClick={onUpload}
        >
          {i18n.t('common.upload')}
        </Button>
      </Menu.Item>
    </Menu>
  );

  const handleChange = (fileList) => {
    const file = fileList[0];
    setSelectedFile(file)
    upload(file);
  };

  return (
    <div className="partner-logo__wrapper">
      <div className="partner-logo">
        <Avatar
          src={preview || url}
          shape="square"
          icon={<CameraOutlined />}
        />
        <Dropdown overlay={menu}>
          <SettingOutlined className="settings-icon" />
        </Dropdown>
      </div>
      <input
        ref={input => inputFile = input}
        type="file"
        onChange={e => handleChange(e.target.files)}
        className="logo-uploader-file"
      />
    </div>
  );
};