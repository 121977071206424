import React from 'react';
import {
  AutoComplete, Button, Radio,
} from 'antd';
import Form from 'antd/lib/form/Form';
import isEmpty from 'lodash/isEmpty';
import NumberFormat from 'react-number-format';

import { IWatchConfigEntity } from '../../store/types/watch-config/watch-config.entity';
import { Selector } from '../selector';
import './style.css';
import {
  BandType,
  IWatchFormValues,
  watchFormFields,
} from './utils';
import { useWatchForm } from './use-watch-form';
import i18n from '../../features/utils/i18n';

interface WatchFormProps {
  isEdit?: boolean;
  watchConfig?: IWatchConfigEntity
  onSubmit: (values: IWatchFormValues) => void;
}

const FieldError: React.FC<{ error?: string}> = ({ error = '' }) => (
  <div className="error-validation error-validation--color">
    <div>{error}</div>
  </div>
);

const WatchForm: React.FC<WatchFormProps> = ({
  isEdit, watchConfig, onSubmit,
}) => {
  const {
    options,
    values,
    onSelect,
    onBandTypeChange,
    onCostChange,
    submitForm,
    onBrandSelect,
    onBrandSearch,
    onModelSelect,
    onModelSearch,
    onCaseDiameterSelect,
    onCaseDiameterSearch,
    errors,
  } = useWatchForm(onSubmit, watchConfig);

  const [submitted, setSubmitted] = React.useState(false);

  const handleOnSubmit = () => {
    setSubmitted(true);
    submitForm();
  };

  return (
    <Form className="watch-form">
      <div className="watch-form__params">
        <div className="watch-form__section">
          <div className="watch-form__autocomplete-wrapper">
            <p>{i18n.t('common.brand')}</p>
            <AutoComplete
              className="watch-form__autocomplete"
              options={options.brand.filter((item) => !item.meta.deleted)}
              value={values[watchFormFields.BRAND_AUTOCOMPLETE]}
              onSelect={onBrandSelect}
              onSearch={onBrandSearch}
              disabled={isEdit}
              placeholder="e.g. Richard Mille"
            />
            <FieldError error={submitted ? errors.brandId : ''} />
          </div>
          <div className="watch-form__autocomplete-wrapper">
            <p>{i18n.t('common.model')}</p>
            <AutoComplete
              value={values[watchFormFields.MODEL_AUTOCOMPLETE]}
              options={options.model.filter((o) => {
                return o.meta.brandId === values[watchFormFields.BRAND] && !o.meta.deleted;
              })}
              className="watch-form__autocomplete"
              onSelect={onModelSelect}
              onSearch={onModelSearch}
              disabled={isEdit}
              placeholder="e.g. RM011"
            />
            <FieldError error={submitted ? errors.modelId : ''} />
          </div>
          {/* <Selector
            label="Case diameter"
            placeholder="e.g. 31 mm to 54 mm"
            value={values.caseSizeId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.CASE_SIZE)}
            options={options.caseSize}
          />
          <FieldError error={errors.caseSizeId} /> */}
          <Selector
            label={i18n.t('watches.caseMetal')}
            placeholder="e.g. Silver"
            value={values.caseMetalId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.CASE_METAL)}
            options={options.caseMetal}
          />
          <FieldError error={submitted ? errors.caseMetalId : ''} />
          <Selector
            label={i18n.t('watches.caseType')}
            placeholder="e.g. Rectangular"
            value={values.formId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.FORM)}
            options={options.watchForm}
          />
          <FieldError error={submitted ? errors.formId : ''} />
          <div className="watch-form__autocomplete-wrapper">
            {isEdit ? (
              <Selector
                label={i18n.t('watches.caseDiameter')}
                value={values.caseDiameterId}
                className="watch-form__selector"
                onSelect={onSelect(watchFormFields.CASE_DIAMETER)}
                options={options.caseDiameter}
              />
            ) : (
              <>
                <p>{i18n.t('watches.caseDiameter')}</p>
                <AutoComplete
                  className="watch-form__autocomplete"
                  options={options.caseDiameter.filter((item) => !item.meta.deleted)}
                  value={values[watchFormFields.CASE_DIAMETER_AUTOCOMPLETE]}
                  onSelect={onCaseDiameterSelect}
                  onSearch={onCaseDiameterSearch}
                />
              </>
            )}
            <FieldError error={submitted ? errors.caseDiameterId : ''} />
          </div>
          <Selector
            label={i18n.t('watches.dialColor')}
            placeholder="e.g. Specialised"
            value={values.dialColorId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.DIAL_COLOR)}
            options={options.dialColor}
          />
          <FieldError error={submitted ? errors.dialColorId : ''} />
        </div>
        <div className="watch-form__section">
          <Selector
            label={i18n.t('watches.strap')}
            placeholder="e.g. Leather"
            disabled={values.bandType === BandType.BRACELET}
            value={values.strapId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.STRAP)}
            options={options.strap}
          />
          <FieldError error={submitted ? errors.strapId : ''} />
          <Selector
            label={i18n.t('watches.bracelet')}
            placeholder="e.g. Aluminium"
            disabled={values.bandType === BandType.STRAP}
            value={values.braceletId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.BRACELET)}
            options={options.bracelet}
          />
          <FieldError error={submitted ? errors.braceletId : ''} />
          <Selector
            label={i18n.t('watches.buckle')}
            placeholder="e.g. Tang Buckle"
            value={values.buckleId}
            className="watch-form__selector"
            onSelect={onSelect(watchFormFields.BUCKLE)}
            options={options.buckle}
          />
          <FieldError error={submitted ? errors.buckleId : ''} />
          <Selector
            label={i18n.t('watches.tier')}
            placeholder="e.g. 1"
            value={values.tierId}
            className="watch-form__selector"
            disabled={isEdit}
            onSelect={onSelect(watchFormFields.TIER)}
            options={options.tier}
          />
          <FieldError error={submitted ? errors.tierId : ''} />
        </div>
      </div>
      <div className="watch-form__band-type">
        <p>
          {i18n.t('watches.bandType')}
        </p>
        <Radio.Group onChange={onBandTypeChange} value={values.bandType}>
          <Radio value={BandType.STRAP}>{i18n.t('watches.strap')}</Radio>
          <Radio value={BandType.BRACELET}>{i18n.t('watches.bracelet')}</Radio>
        </Radio.Group>
      </div>
      <div className="watch-form__valuation">
        <p>{i18n.t('valuations.valuate')}</p>
        <div className="watch-form__valuation-controls">
          <div>
            <NumberFormat
              value={values[watchFormFields.MIN_COST]}
              type="text"
              placeholder="$7,000"
              max="0"
              thousandSeparator
              onValueChange={(val) => {
                const { floatValue } = val;
                onCostChange(watchFormFields.MIN_COST, floatValue);
              }}
              className="ant-input"
            />
          </div>
          <span className="watch-form__separator">{i18n.t('common.to')}</span>
          <div>
            <NumberFormat
              value={values[watchFormFields.MAX_COST]}
              min="0"
              type="text"
              placeholder="$8,000"
              thousandSeparator
              onValueChange={(val) => {
                const { floatValue } = val;
                onCostChange(watchFormFields.MAX_COST, floatValue);
              }}
              className="ant-input"
            />
          </div>
        </div>
        <FieldError error={submitted ? (errors.minCost || errors.maxCost) : ''} />
      </div>
      <Button
        onClick={handleOnSubmit}
        className="button--primary watch-form__submit-btn"
      >
        {isEdit ? i18n.t('common.save') : i18n.t('watches.addTheWatch')}
      </Button>
    </Form>
  );
};

export default WatchForm;
