// users
export const USERS = '/users';
export const USER = `${USERS}/user`;
export const EDIT_USER = `${USER}/edit-user`;
export const BANK_DETAILS = `${USER}/bank-details`;
export const USER_WATCH = `${USER}/user-watch`;
export const EDIT_USER_WATCH = `${USER}/edit-user-watch`;

// valuations
export const VALUATIONS = '/valuations';

// subscriptions
export const SUBSCRIPTIONS = '/subscriptions';
export const EDIT_SUBSCRIPTION = `${SUBSCRIPTIONS}/edit-subscription`;

// loans
export const LOANS = '/loans';
export const LOAN = `${LOANS}/loan`;

// own watches
export const OWN_WATCHES = '/own-watches';
export const OWN_WATCH = `${OWN_WATCHES}/own-watch`;

// watches
export const WATCHES = '/watches';
export const EDIT_WATCH = `${WATCHES}/edit-watch`;

// promocodes
export const PROMOCODES = '/promocodes';
export const PROMOCODE = `${PROMOCODES}/promocode`;

// partners
export const PARTNERS = '/partners';
export const PARTNER = `${PARTNERS}/partner`;