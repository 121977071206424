import React, { useState } from 'react';
import {
  Button,
  Modal,
  Radio,
  Space
} from 'antd';
import i18n from '../../features/utils/i18n';
import { DownloadOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useActions } from '../../store';

const formats = [
  { value: 'csv', label: 'CSV' },
  { value: 'pdf', label: 'PDF' },
  { value: 'xlsx', label: 'XLSX' }
];
const uploadPlaces = [
  { value: 'email', label: 'Email' },
  { value: 'me', label: 'Desktop' }
];

export const UploadModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [params, setParams] = useState({ type: 'csv', to: 'me' });

  const { usersActions } = useActions();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleChange = (e: RadioChangeEvent) => {
    const { name = '', value } = e.target;
    setParams({ ...params, [name]: value });
  };

  const exportList = (withFilters: boolean) => {
    usersActions.exportUserList(params, withFilters);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        icon={<DownloadOutlined />}
      >
        {i18n.t('export.btnLabel')}
      </Button>
      <Modal
        centered
        title={<h2>{i18n.t('export.title')}</h2>}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
          >
            {i18n.t('export.cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => exportList(true)}
            icon={<DownloadOutlined />}
          >
            {i18n.t('export.exportWithFilters')}
          </Button>,
          <Button
            key="export-all"
            type="primary"
            onClick={() => exportList(false)}
            icon={<DownloadOutlined />}
          >
            {i18n.t('export.exportAll')}
          </Button>
        ]}
      >
      <div className="export-modal">
        <h3 className="format-label">{i18n.t('export.fileFormat')}</h3>
        <Radio.Group
          onChange={handleChange}
          defaultValue={params.type}
          name="type"
        >
          <Space>
            {formats.map(item => (
              <Radio
                key={item.value}
                value={item.value}
              >
                {item.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        <h3 className="export-to-label">{i18n.t('export.to')}</h3>
        <Radio.Group
          onChange={handleChange}
          defaultValue={params.to}
          name="to"
        >
          <Space>
            {uploadPlaces.map(item => (
              <Radio
                key={item.value}
                value={item.value}
              >
                {item.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
      </Modal>
    </>
  );
};

export default UploadModal;
