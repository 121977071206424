import React, { useEffect } from 'react';
import {
  Form, Input, Modal, Button,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';

import { useActions, useStore } from '../../../../../store';
import { validateForm } from '../../../../../utility/validation/class-validator';
import { ValidateInitialPercent, ValidateMinPercent, ValidateMaxPercent } from './validation-schema';
import i18n from '../../../../../features/utils/i18n';
import '../style.css';


const { confirm } = Modal;

const PercentConfigForms = ({ percents, loading }) => {
  const { loanTermsActions } = useActions();

  useEffect(() => {
    loanTermsActions.getPercentCalculations();
  }, []);

  const initialValues = {
    initialPercent: percents?.interest,
    minPercent: percents?.min,
    maxPercent: percents?.max,
  };

  const getSubmitCallback = (key, value) => {
    confirm({
      title: i18n.t('common.saveChanges'),
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      centered: true,
      onOk() {
        if (value) {
          loanTermsActions.updatePercentCalculations({ [key]: value }, percents.id);
        }
      },
    });
  };

  const initialPercentFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: async (values) => validateForm(ValidateInitialPercent, values),
    onSubmit: (values) => getSubmitCallback('interest', values.initialPercent),
  });

  const minPercentFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: async (values) => validateForm(ValidateMinPercent, values),
    onSubmit: (values) => getSubmitCallback('min', values.minPercent),
  });

  const maxPercentFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: async (values) => validateForm(ValidateMaxPercent, values),
    onSubmit: (values) => getSubmitCallback('max', values.maxPercent),
  });

  const disabledInitialBtn = loading || !initialPercentFormik.dirty
    || Boolean(initialPercentFormik?.errors?.initialPercent);

  const disabledMinBtn = loading || !minPercentFormik.dirty
    || Boolean(minPercentFormik?.errors?.minPercent);

  const disabledMaxBtn = loading || !maxPercentFormik.dirty
    || Boolean(maxPercentFormik?.errors?.maxPercent);

  return (
    <div className="forms">
      <div>
        <div className="min-percent-form">
          <p className="calculations-form__column-title min-percent-form__title">{i18n.t('finAgreementTerms.interestRate')}</p>
          <Form className="min-percent-form__content">
            <Input
              key="initialPercent"
              name="initialPercent"
              min="0"
              max="100"
              type="number"
              placeholder="0"
              value={initialPercentFormik.values.initialPercent}
              onChange={initialPercentFormik.handleChange}
              className="calculations-form__input"
            />
            <Button
              disabled={disabledInitialBtn}
              className="button--primary min-percent-form__save-btn"
              onClick={initialPercentFormik.submitForm}
            >
              {i18n.t('common.save')}
            </Button>
          </Form>
          <div className="error-validation error-validation--color">
            {initialPercentFormik?.errors?.initialPercent}
          </div>
        </div>
        <div className="min-percent-form">
          <p className="calculations-form__column-title min-percent-form__title">{i18n.t('finAgreementTerms.minRate')}</p>
          <Form className="min-percent-form__content">
            <Input
              key="minPercent"
              name="minPercent"
              min="0"
              max="100"
              type="number"
              placeholder="0"
              value={minPercentFormik.values.minPercent}
              onChange={minPercentFormik.handleChange}
              className="calculations-form__input"
            />
            <Button
              disabled={disabledMinBtn}
              className="button--primary min-percent-form__save-btn"
              onClick={minPercentFormik.submitForm}
            >
              {i18n.t('common.save')}
            </Button>
          </Form>
          <div className="error-validation error-validation--color">
            {minPercentFormik?.errors?.minPercent}
          </div>
        </div>
        <div className="min-percent-form">
          <p className="calculations-form__column-title min-percent-form__title">{i18n.t('finAgreementTerms.maxRate')}</p>
          <Form className="min-percent-form__content">
            <Input
              key="maxPercent"
              name="maxPercent"
              min="0"
              max="100"
              type="number"
              placeholder="0"
              value={maxPercentFormik.values.maxPercent}
              onChange={maxPercentFormik.handleChange}
              className="calculations-form__input"
            />
            <Button
              disabled={disabledMaxBtn}
              className="button--primary min-percent-form__save-btn"
              onClick={maxPercentFormik.submitForm}
            >
              {i18n.t('common.save')}
            </Button>
          </Form>
          <div className="error-validation error-validation--color">
            {maxPercentFormik?.errors?.maxPercent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PercentConfigForms;
