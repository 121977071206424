import {
  // Matches,
  MaxLength,
  MinLength,
} from 'class-validator';

import { IsEqualTo } from '../../utility/validation/match-validator';
// import passwordValidation from '../../utility/validation/password-validation';
import i18n from '../../features/utils/i18n'

class ResetPassword {
  public constructor(password = '', confirmPassword: '') {
    this.password = password;
    this.confirmPassword = confirmPassword;
  }

  // @Matches(passwordValidation.regex, {
  //   message: passwordValidation.message,
  // })
  @MinLength(6, {
    message: i18n.t('validation.least6'),
  })
  @MaxLength(30, {
    message: i18n.t('validation.less30'),
  })
  @IsEqualTo('confirmPassword')
  password: string;

  // @Matches(passwordValidation.regex, {
  //   message: passwordValidation.message,
  // })
  @IsEqualTo('password')
  confirmPassword: string;
}

export default ResetPassword;
