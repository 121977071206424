import { Dispatch } from 'react';

import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import { ValuationsActionType } from '../actions-types';
import { IValuation } from '../../types/valuation/valuation-entity';
import formatDate from '../../../utility/date/format-date';
import buildQueryString from '../../../utility/build-query-string';
import { IPushTo } from '../../../router/types';
import { IGetValuationsListQueryParams, IValuateMultipleWatchesRequestData } from '../../types/valuation/valuation-dto';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';
import { VALUATIONS } from '../../../router/constants';
import i18n from '../../../features/utils/i18n';
import { IDeleteResult } from '../../types/common';

export interface IValuationsActions {
  getValuationsList: (payload: IGetValuationsListQueryParams) => Promise<void>;
  valuateBunchWatches: (payload: IValuateMultipleWatchesRequestData) => Promise<void>;
  deleteValuation: (id: number) => Promise<void>;
}

export class ValuationsActions implements IValuationsActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  protected readonly pushTo: IPushTo;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService, pushTo: IPushTo) {
    this.dispatch = dispatch;
    this.api = api;
    this.pushTo = pushTo;
  }

  public getValuationsList = async (payload: IGetValuationsListQueryParams): Promise<void> => {
    try {
      this.dispatch({ type: ValuationsActionType.GetValuationsRequest, payload: null });
      let queryParams = { ...payload };

      if (payload.dateFrom && payload.dateTo) {
        queryParams = {
          ...queryParams,
          dateFrom: formatDate(payload.dateFrom),
          dateTo: formatDate(payload.dateTo),
        };
      }
      const queryString = buildQueryString(queryParams);
      if (queryString) {
        this.pushTo(`${VALUATIONS}/?${queryString}`);
      }

      const result: IValuation = await this.api.get({
        url: `watch/list?${queryString}`,
      });
      this.dispatch({
        type: ValuationsActionType.GetValuationsSuccess,
        payload: result,
      });
    } catch (error) {
      this.dispatch({ type: ValuationsActionType.GetValuationsFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public deleteValuation = async ( id: number ) => {
    let type = ToastNotificationTypes.SUCCESS;
    let message = i18n.t('notifications.deleteValuation');
    try {
      this.dispatch({ type: ValuationsActionType.DeleteValuationRequest, payload: { id } });
      const { id: deletedId }: IDeleteResult = await this.api.delete({ url: `/watch/admin/single/${id}` });
      this.dispatch({ type: ValuationsActionType.DeleteValuationSuccess, payload: deletedId });
    } catch (error) {
      this.dispatch({ type: ValuationsActionType.DeleteValuationFailure, payload: null });
      console.error(error);
      type = ToastNotificationTypes.ERROR;
      message = i18n.t('notifications.deleteValuationError');
    } finally {
      toastNotification({ type, message });
    }
  };

  public valuateBunchWatches = async (
    payload: IValuateMultipleWatchesRequestData,
  ): Promise<void> => {
    try {
      this.dispatch({ type: ValuationsActionType.ValuateBunchWatchesRequest, payload: null });
      const result: IValuation = await this.api.patch({
        url: 'watch/appraise-list',
        data: { appraisals: payload },
      });
      this.dispatch({
        type: ValuationsActionType.ValuateBunchWatchesSuccess,
        payload: result,
      });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: i18n.t('notifications.saved') });
    } catch (error) {
      this.dispatch({ type: ValuationsActionType.ValuateBunchWatchesFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };
}
