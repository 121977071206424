import React, { useReducer } from 'react';

import { IAppState } from '../../../store/state-context';
import { StateContext, initialState, rootReducer } from '../../../store';
import { DispatchContext } from '../../../store/dispatch-context';

interface IStoreProviderProps {
  initialStateOverrides?: Partial<IAppState>;
}

const StoreProvider: React.FC<IStoreProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};

StoreProvider.displayName = 'StoreProvider';

export default StoreProvider;
