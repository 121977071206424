import handlersMap from '.';
import { IAppState, initialState } from '../state-context';
import { IAction } from '../actions/action';

export const rootReducer = (state: IAppState = initialState, action: IAction): IAppState => {
  const handler = handlersMap.get(action.type);

  if (handler) {
    return handler(state, action);
  }

  return state;
};
export default rootReducer;
