import { Dispatch, useMemo } from 'react';

import { IAction } from './action';
import { IAppState } from '../state-context';
import { UiActions, IUiActions } from './ui-actions';
import { AuthActions, IAuthActions } from './auth-actions/auth-actions';
import { UsersActions, IUsersActions } from './users-actions/users-actions';
import { LoansActions, ILoansActions } from './loans-actions/loans-actions';
import { WatchActions, IWatchActions } from './watch-actions/watch-actions';
import { ValuationsActions, IValuationsActions } from './valuation-actions/valuations-actions';
import { IPushTo } from '../../router/types';
import { ILoanTermsActions, LoanTermsActions } from './loan-terms-actions';
import { ISubscriptionsActions } from './subscription-actions';
import { SubscriptionsActions } from './subscription-actions/subscription-actions';
import { IApiService } from '../services/api-service/api-service';
import { IOwnWatchesActions } from './own-watches';
import { OwnWatchesActions } from './own-watches/own-watches';
import { INotificationsActionTypes, NotificationsActionTypes } from './notifications-actions';
import { IWatchConfigsActions, WatchConfigsActions } from './watch-configs-actions';
import { ITemplatesActions, TemplatesActions } from './templates-actions';
import { IPromocodeActions, PromocodeActions } from './promocode-actions';
import { IPartnerActions, PartnerActions } from './partner-actions';

export interface IActions {
  uiActions: IUiActions;
  authActions: IAuthActions;
  usersActions: IUsersActions;
  loansActions: ILoansActions;
  notificationsActions: INotificationsActionTypes;
  watchActions: IWatchActions;
  loanTermsActions: ILoanTermsActions;
  valuationsActions: IValuationsActions;
  subscriptionsActions: ISubscriptionsActions;
  ownWatchesActions: IOwnWatchesActions;
  watchConfigsActions: IWatchConfigsActions;
  templatesActions: ITemplatesActions;
  promocodeActions: IPromocodeActions;
  partnerActions: IPartnerActions;
}

export const useGetActions = (
  state: IAppState,
  dispatch: Dispatch<IAction>,
  api: IApiService,
  pushTo: IPushTo,
): IActions => ({
  uiActions: useMemo(() => new UiActions(dispatch), [dispatch, state]),
  authActions: useMemo(() => new AuthActions(dispatch, api, pushTo), [
    dispatch,
    api,
    state,
    pushTo,
  ]),
  usersActions: useMemo(() => new UsersActions(dispatch, api, pushTo), [
    dispatch,
    api,
    state,
    pushTo,
  ]),
  loansActions: useMemo(() => new LoansActions(dispatch, api, pushTo), [
    dispatch,
    api,
    state,
    pushTo,
  ]),
  notificationsActions: useMemo(() => new NotificationsActionTypes(dispatch, api, pushTo), [
    dispatch,
    api,
    state,
    pushTo,
  ]),
  watchActions: useMemo(() => new WatchActions(dispatch, api, pushTo), [
    dispatch,
    api,
    state,
    pushTo,
  ]),
  loanTermsActions: useMemo(() => new LoanTermsActions(dispatch, api), [
    dispatch,
    api,
    state,
  ]),
  valuationsActions: useMemo(() => new ValuationsActions(dispatch, api, pushTo), [dispatch,
    api,
    state,
    pushTo,
  ]),
  subscriptionsActions: useMemo(() => new SubscriptionsActions(dispatch, api), [
    dispatch,
    api,
    state,
  ]),
  ownWatchesActions: useMemo(() => new OwnWatchesActions(dispatch, api, pushTo), [
    dispatch,
    api,
    state,
    pushTo,
  ]),
  watchConfigsActions: useMemo(() => new WatchConfigsActions(dispatch, api, pushTo), [
    dispatch,
    api,
    state,
    pushTo,
  ]),
  templatesActions: useMemo(() => new TemplatesActions(dispatch, api), [dispatch, api, state]),
  promocodeActions: useMemo(() => new PromocodeActions(dispatch, api), [
    dispatch,
    api,
    state
  ]),
  partnerActions: useMemo(() => new PartnerActions(dispatch, api, pushTo), [
    dispatch,
    api,
    state,
    pushTo
  ]),
});

export {
  UiActionType,
  AuthActionType,
  UsersActionType,
  LoanActionType,
  NotificationsActionType,
  WatchActionType,
  LoanTermsActionType,
  ValuationsActionType,
  SubscriptionsActionType,
  OwnWatchesActionType,
  WatchConfigsActionsType,
  TemplatesActionType,
  PromocodeActionType,
  PartnerActionType
} from './actions-types';

export default useGetActions;
