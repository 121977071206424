import React, { useEffect } from 'react';

import { useActions, useStore } from '../../../../store';
import { WrappedForm } from './WrappedForm';

import './style.css';
import i18n from '../../../../features/utils/i18n';

const NEW_ITEM_ID = -1;

const DeliveryMethodForm = () => {
  const { loanTermsActions } = useActions();
  const { loanTerms: { deliveryMethods: { data } } } = useStore();

  useEffect(() => {
    loanTermsActions.getDeliveryMethods();
  }, []);

  const pickingUp = data.filter((method) => !method.insuredShipping);
  pickingUp.push({ id: NEW_ITEM_ID, name: '', insuredShipping: false });
  const insuredShipping = data.filter((method) => method.insuredShipping);
  insuredShipping.push({ id: NEW_ITEM_ID, name: '', insuredShipping: true });

  return (
    <div className="delivery-method-form">
      <div className="delivery-method-form__container">
        <div className="delivery-method-from__wrapper">
          <p>{i18n.t('finAgreementTerms.pickingUp')}</p>
          {pickingUp.map((method) => {
            return (
              <WrappedForm
                key={method.id}
                placeholder={i18n.t('finAgreementTerms.newPickingUp')}
                disabled={method.id !== NEW_ITEM_ID}
                initialValues={{
                  id: method.id,
                  name: method.name,
                  insuredShipping: method.insuredShipping,
                }}
              />
            );
          })}
        </div>
        <div className="delivery-method-from__wrapper">
          <p>{i18n.t('finAgreementTerms.shipping')}</p>
          {insuredShipping.map((method) => {
            return (
              <WrappedForm
                key={method.id}
                placeholder={i18n.t('finAgreementTerms.newShipping')}
                disabled={method.id !== NEW_ITEM_ID}
                initialValues={{
                  id: method.id,
                  name: method.name,
                  insuredShipping: method.insuredShipping,
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DeliveryMethodForm;
