import React from 'react';
import {
  Button, Form, Input, Spin, Modal,
} from 'antd';
import { useFormik } from 'formik';
import { QuestionCircleOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import NumberFormat from 'react-number-format';

import { validateForm } from '../../utility/validation/class-validator';
import { useActions, useStore } from '../../store';
import './styles.css';
import ValuateWatch from './validation-schema';
import WatchStatus from '../../constants/watch-status';
import i18n from '../../features/utils/i18n';

export const EVALUATE = 'EVALUATE';
export const REJECT = 'REJECT';

const { confirm } = Modal;

const ValuateWatchForm: React.FC = () => {
  const { watchActions } = useActions();
  const { userWatch } = useStore();
  const { current, currentLoading } = userWatch;

  const handleReject = () => {
    if (current?.id) {
      watchActions.rejectWatch(current?.id);
    }
  };

  const handleSubmit = (values, isReEdit) => {
    if (current?.id) {
      const payload = { ...values, id: current.id };
      watchActions.appraiseWatch(payload, isReEdit);
    }
  };

  const showConfirmationModal = (action: string) => {
    const { minCost, maxCost, description } = initialValues;
    const isReEdit = !!minCost || !!maxCost || !!description;
    confirm({
      title: isReEdit ? i18n.t('valuations.wantChange') : i18n.t('valuations.evaluateWatch'),
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      centered: true,
      onOk() {
        if (action === EVALUATE) {
          return handleSubmit(formik.values, isReEdit);
        }
        return handleReject();
      },
    });
  };

  const initialValues = {
    minCost: current?.minCost,
    maxCost: current?.maxCost,
    description: current?.description,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: async (values) => validateForm(ValuateWatch, values),
    onSubmit: () => showConfirmationModal(EVALUATE),
  });

  const includedInLoan = current?.status === WatchStatus.INCLUDED_IN_LOAN;
  const isRejected = current?.status === WatchStatus.REJECTED;

  const isDisabled = (
    currentLoading
    || includedInLoan
    || !isEmpty(formik.errors)
    || isRejected
  );

  if (currentLoading) {
    return <Spin className="spinner" />;
  }
  return (
    <div className="valuate-watch-form">
      <h3 className="valuate-watch-form__title">{i18n.t('valuations.valuate')}</h3>
      <Form
        name="valuate-watch"
        onFinish={formik.handleSubmit}
      >
        <div className="valuate-watch__values-wrapper">
          <Form.Item
            label={i18n.t('valuations.min')}
            className="valuate-watch__label"
          >
            <span className="ant-input-affix-wrapper valuate-watch__input">
              <NumberFormat
                type="text"
                prefix="$"
                name="minCost"
                placeholder={i18n.t('valuations.minValue')}
                value={formik.values.minCost}
                thousandSeparator
                onValueChange={(values) => {
                  const { floatValue } = values;
                  formik.setFieldValue('minCost', floatValue);
                }}
                className="ant-input"
                disabled={includedInLoan}
              />
            </span>
          </Form.Item>
          <div className="valuate-watch-form__text--to">{i18n.t('common.to')}</div>
          <Form.Item
            label={i18n.t('valuations.max')}
            className="valuate-watch__label"
          >
            <span className="ant-input-affix-wrapper valuate-watch__input">
              <span className="ant-input-prefix" />
              <NumberFormat
                type="text"
                prefix="$"
                name="maxCost"
                placeholder={i18n.t('valuations.maxValue')}
                value={formik.values.maxCost}
                thousandSeparator
                onValueChange={(values) => {
                  const { floatValue } = values;
                  formik.setFieldValue('maxCost', floatValue);
                }}
                className="ant-input"
                disabled={includedInLoan}
              />
            </span>
          </Form.Item>
        </div>
        <div className="error-validation--color">
          <div>{formik.errors.minCost}</div>
          <div>{formik.errors.maxCost}</div>
        </div>
        <Form.Item
          label={i18n.t('common.description')}
          className="valuate-watch-form__textarea"
        >
          <Input.TextArea
            name="description"
            placeholder={i18n.t('common.description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            className="valuate-watch__input"
          />
          <span className="valuate-watch-form__text--grey">{i18n.t('valuations.optional')}</span>
        </Form.Item>
        <Button
          onClick={() => formik.handleSubmit()}
          disabled={isDisabled}
          className="button--primary valuate-watch__btn"
        >
          {i18n.t('valuations.evaluate')}
        </Button>
        <Button
          onClick={() => showConfirmationModal(REJECT)}
          disabled={isDisabled}
          className="button--danger valuate-watch__btn"
        >
          {i18n.t('valuations.reject')}
        </Button>
      </Form>
    </div>
  );
};

export default ValuateWatchForm;
