import { Dispatch } from 'react';
import { RcFile } from 'antd/lib/upload';
import Axios from 'axios';
import { USERS } from '../../../router/constants';
import {
  IExportUserListRequest,
  IGetAWSCredentialsResponse,
  IGetUsersParams,
  ISetBlockedUserResponse,
  ISetUserRoleRequest,
  ISetUserSubscribeRequest
} from '../../types/users/users-dto';

import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import { UsersActionType } from '../actions-types';
import { IUser } from '../../types/users/user-entity';
import formatDate from '../../../utility/date/format-date';
import buildQueryString from '../../../utility/build-query-string';
import { IPushTo } from '../../../router/types';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';
import { Roles } from '../../../constants';
import { IDeleteResult } from '../../types/common';
import i18n from '../../../features/utils/i18n';
import parseParams from '../../../utility/parse-params';


export interface IUsersActions {
  getUsers: (payload: IGetUsersParams) => Promise<void>;
  getUserById: (id: string) => Promise<void>;
  setBlockedUser: (payload: ISetBlockedUserResponse) => Promise<void>;
  setUserRole: (payload: ISetUserRoleRequest) => Promise<void>;
  setUserSubscribe: (payload: ISetUserSubscribeRequest) => Promise<void>;
  getAWSCredentials: (extension: string) => Promise<IGetAWSCredentialsResponse | null>;
  uploadAvatar: (file: RcFile, credentials:IGetAWSCredentialsResponse) => Promise<string | null>;
  getRevenueCatUser: (id: string) => Promise<void>;
  deleteUser: (id: number) => Promise<void>;
  exportUserList: (payload: IExportUserListRequest, withFilters: boolean) => Promise<void>;
  applyUserInvitePartner: (id: number, userId: number, type: string) => Promise<void>;
  updateUser: (data: any, id: string) => Promise<boolean>;
}
export class UsersActions implements IUsersActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  protected readonly pushTo: IPushTo;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService, pushTo: IPushTo) {
    this.dispatch = dispatch;
    this.api = api;
    this.pushTo = pushTo;
  }

  public getUsers = async (payload: IGetUsersParams): Promise<void> => {
    try {
      let formattedData = payload;
      if (payload.dateFrom && payload.dateTo) {
        formattedData = {
          ...payload,
          dateFrom: formatDate(payload.dateFrom),
          dateTo: formatDate(payload.dateTo),
        };
      }

      const url = buildQueryString(formattedData);
      const isAdmin = Boolean(formattedData.role);

      if (url) {
        this.pushTo(`${USERS}/?${url}`);
      }

      this.dispatch({
        type: UsersActionType.GetUsersRequest,
        payload: { isAdmin },
      });
      const res: IUser = await this.api.get({
        url: `user/get-all?${url}`,
      });
      this.dispatch({
        type: UsersActionType.GetUsersSuccess,
        payload: { ...res, isAdmin },
      });
    } catch (error) {
      this.dispatch({ type: UsersActionType.GetUsersFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default'),
      });
    }
  };

  public getUserById = async (id: string): Promise<void> => {
    try {
      this.dispatch({ type: UsersActionType.GetUserByIdRequest, payload: { id } });
      const response: IUser = await this.api.get({
        url: `user/${id}`,
      });
      this.dispatch({
        type: UsersActionType.GetUserByIdSuccess,
        payload: response,
      });
    } catch (error) {
      this.dispatch({ type: UsersActionType.GetUserByIdFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default'),
      });
    }
  };

  public deleteUser = async (id: number) => {
    try {
      this.dispatch({ type: UsersActionType.DeleteUserRequest, payload: { id } });
      const { id: deletedId }: IDeleteResult = await this.api.delete({ url: `/user/admin/deletes/user/${id}` });
      this.dispatch({ type: UsersActionType.DeleteUserSuccess, payload: Number(deletedId) });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: i18n.t('notifications.deleteUser'),
      });
    } catch (error) {
      this.dispatch({ type: UsersActionType.DeleteUserFailure, payload: null });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.deleteUserError'),
      });
    }
  };

  public getRevenueCatUser = async (id: string): Promise<void> => {
    try {
      this.dispatch({ type: UsersActionType.GetRevenueCatUserByIdRequest, payload: { id } });
      const baseURL = process.env.REACT_APP_REVENUE_CAT_BASE_URL || '';

      const axios = Axios.create({
        baseURL,
        headers: {
          Authorization: `Bearer: ${process.env.REACT_APP_REVENUE_CAT_PUBLIC_KEY}`,
        },
      });
      const revCatUser = await axios.get(`/subscribers/${id}`);
      this.dispatch({
        type: UsersActionType.GetRevenueCatUserByIdSuccess,
        payload: revCatUser.data?.subscriber,
      });
    } catch (error) {
      this.dispatch({ type: UsersActionType.GetRevenueCatUserByIdFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default'),
      });
    }
  };

  public setBlockedUser = async (payload: ISetBlockedUserResponse) : Promise<void> => {
    try {
      this.dispatch({ type: UsersActionType.SetBlockedUserRequest, payload: null });
      const response: ISetBlockedUserResponse = await this.api.patch({
        url: `user/admin/set-blocked/${payload.id}`,
        data: { blocked: payload.blocked },
      });
      this.dispatch({ type: UsersActionType.SetBlockedUserSuccess, payload: response });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: payload.blocked ? i18n.t('notifications.userBlocked') : i18n.t('notifications.userUnBlocked') });
    } catch (error) {
      this.dispatch({ type: UsersActionType.SetBlockedUserFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default')
      });
    }
  };

  public setUserRole = async ({ id, role }: ISetUserRoleRequest) : Promise<void> => {
    try {
      this.dispatch({ type: UsersActionType.SetUserRoleRequest, payload: null });
      const response: ISetBlockedUserResponse = await this.api.patch({
        url: `user/admin/set-role/${id}`,
        data: { role },
      });
      const adminMessage = i18n.t('notifications.admin');
      const userMessage = i18n.t('notifications.user');
      this.dispatch({ type: UsersActionType.SetUserRoleSuccess, payload: response });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: role === Roles.USER ? userMessage : adminMessage,
      });
    } catch (error) {
      console.error(error);
      this.dispatch({ type: UsersActionType.SetUserRoleFailure, payload: error });
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default'),
      });
    }
  };

  public setUserSubscribe = async ({ id, isSubscribed }: ISetUserSubscribeRequest) : Promise<void> => {
    console.log("isSubscribed", isSubscribed);
    try {
      this.dispatch({ type: UsersActionType.SetUserSubscribeRequest, payload: null });
      const response: ISetUserSubscribeRequest = await this.api.patch({
        url: `user/admin/set-subscription/${id}`,
        data: { isSubscribed },
      });
      this.dispatch({ type: UsersActionType.SetUserSubscribeSuccess, payload: response });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: i18n.t('notifications.wasChanged'),
      });
    } catch (error) {
      console.error(error);
      this.dispatch({ type: UsersActionType.SetUserSubscribeFailure, payload: error });
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default'),
      });
    }
  };

  public getAWSCredentials = async (fileExtension: string) => {
    try {
      const result: IGetAWSCredentialsResponse = await this.api.get({
        url: `/upload/${fileExtension}`,
      });
      return result;
    } catch (err) {
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default'),
      });
    }

    return null;
  };

  public uploadAvatar = async (file: RcFile, credentials: IGetAWSCredentialsResponse) => {
    try {
      const { data } = credentials;
      const form = new FormData();
      form.append('key', data.fields.Key);
      form.append('bucket', data.fields.bucket);
      form.append('x-amz-algorithm', data.fields['X-Amz-Algorithm']);
      form.append('x-amz-credential', data.fields['X-Amz-Credential']);
      form.append('x-amz-date', data.fields['X-Amz-Date']);
      form.append('policy', data.fields.Policy);
      form.append('x-amz-signature', data.fields['X-Amz-Signature']);
      form.append('file', file);

      await this.api.post({
        withToken: false,
        headers: { 'Content-Type': 'multipart/form-data' },
        baseURL: data.url,
        data: form,
      });

      return `${credentials.data.url}/${credentials.data.fields.Key}`;
    } catch (error) {
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default'),
      });
    }

    return null;
  };

  public exportUserList = async ({ type, to }: IExportUserListRequest, withFilters: boolean): Promise<void> => {
    try {
      const { search } = window.location;
      let params = { to };
      if (withFilters) {
        const userParams = parseParams(search);
        delete userParams['page'];
        params = { ...params, ...userParams }
      }
      const { url }: any = await this.api.get({
        url: `/user/export/tables/${type}`,
        params
      });
      const baseUrl = process.env.REACT_APP_API_URL;
      window.open(baseUrl + url, "_blank");
    } catch (error) {
      this.dispatch({ type: UsersActionType.GetUsersFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  }

  public applyUserInvitePartner = async (id: number, userId: number, type: string) => {
    try {
      const { inviteId } = await this.api.post({ url: `/partner-invite/${id}/${type}` });
      if (inviteId) {
        await this.getUserById(String(userId));
      }
    } catch (error) {
      console.error(error);
    }
  };

  public updateUser = async (data: any, id: string): Promise<boolean> => {
    try {
      await this.api.patch({ url: `/user/admin/${id}`, data });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: i18n.t('notifications.editUser'),
      });
      return true;
    } catch (error) {
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default'),
      });
      return false;
    }
  };
};