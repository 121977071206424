import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useLocation, useParams } from 'react-router';
import './styles.css';
import { useActions } from '../../store';
import { takeResultsPerPage } from '../../constants';
import parseParams from '../../utility/parse-params';
import WatchStatus from '../../constants/watch-status';
import i18n from '../../features/utils/i18n';

interface IPaginationState {
  statuses: string[];
  take?: number;
  page?: number;
}

export const FilterUserWatches = ({
  currentPage,
  setCallbackPage,
}: {
  currentPage: number;
  setCallbackPage: (page: number) => void;
}) => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const { watchActions } = useActions();
  const params = parseParams(location.search);
  const { statuses, take, page }: any = params;
  const formattedStatuses = statuses ? statuses.split(',') : [];
  const [selectedOptions, setSelectedOptions] = useState<IPaginationState>({
    statuses: formattedStatuses,
    take: take || takeResultsPerPage,
    page: page || currentPage,
  });

  const handleStatusChange = (event: CheckboxChangeEvent) => {
    let statuses = [...selectedOptions.statuses];
    const { checked, value } = event?.target;
    if (checked) {
      statuses.push(value);
    } else {
      statuses = statuses.filter((status) => status !== value);
    }
    setSelectedOptions({ ...selectedOptions, statuses });
    setCallbackPage(1);
  };

  useEffect(() => {
    const data = { ...selectedOptions, page: currentPage, id };
    watchActions.getUserWatchesById(data);
  }, [selectedOptions, currentPage]);

  return (
    <div className="filter-user-watches">
      <div>
        <Checkbox
          value={WatchStatus.APPRAISAL_IN_PROGRESS}
          checked={selectedOptions.statuses.includes(WatchStatus.APPRAISAL_IN_PROGRESS)}
          onChange={handleStatusChange}
        >
          {i18n.t('common.inProcess')}
        </Checkbox>
        <Checkbox
          value={WatchStatus.APPRAISED}
          checked={selectedOptions.statuses.includes(WatchStatus.APPRAISED)}
          onChange={handleStatusChange}
        >
          {i18n.t('common.evaluated')}
        </Checkbox>
        <Checkbox
          value={WatchStatus.REJECTED}
          checked={selectedOptions.statuses.includes(WatchStatus.REJECTED)}
          onChange={handleStatusChange}
        >
          {i18n.t('common.rejected')}
        </Checkbox>
      </div>
    </div>
  );
};

export default FilterUserWatches;
