import React from 'react';
import {
  Avatar, Badge, Button, Card, Modal, Tag,
} from 'antd';
import { QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import './styles.css';
import { IUser } from '../../store/types/users/user-entity';
import { useActions, useStore } from '../../store';
import { PhoneMask, Roles, SubscriptionTypes } from '../../constants';
import getFullName from '../../utility/get-full-name';
import { BANK_DETAILS, EDIT_USER } from '../../router/constants';
import { isPremiumUser } from '../../utility/revenue-cat';
import i18n from '../../features/utils/i18n';

interface IProps {
  user: IUser | null;
  hideButtons?: boolean;
}

const { confirm } = Modal;

export const UserCard: React.FC<IProps> = ({ user, hideButtons = false }) => {
  const { usersActions } = useActions();
  const { users, auth } = useStore();
  const {
    avatar,
    firstName,
    lastName,
    email,
    phoneNumber,
    subscriptions = [],
    blocked,
    id,
    isSubscribed
  } = user || {};
  const fullName = getFullName(firstName, lastName);
  const blockedAction = blocked ? 'Unblock' : 'Block';
  const isSuperAdmin = auth.user?.role === Roles.SUPER_ADMIN;

  if (!user) {
    return null;
  }
  const isPremium = isPremiumUser(users.revCatUser);
  const showBlockUserConfirmation = () => confirm({
    title: i18n.t(`users.confirm${blockedAction}`),
    icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
    centered: true,
    onOk() {
      if (id) {
        usersActions.setBlockedUser({ id, blocked: !blocked });
      }
    },
  });

  return (
    <div className="user-card">
      <div className="user-card__left-side">
        {avatar ? (
          <Avatar size={64} src={avatar} className="user-card__avatar" />
        ) : (
          <Avatar
            size={64}
            icon={<UserOutlined />}
            className="user-card__avatar"
          />
        )}
        <div className="user-card__info">
          <div className="user-card__item user-card__name">{fullName}</div>
          <div className="user-card__item">{email}</div>
          <NumberFormat className="user-card__item" value={phoneNumber} format={PhoneMask.TEN_DIGITS} mask="_" />
          <div>
            {i18n.t('common.status')}
            :
            {' '}
            {i18n.t(`profile.${isSubscribed ? "platinum" : "nonPlatinum"}`)}
          </div>
          <div className="user-card__item">
            {
              isPremium && <Tag color="orange">{i18n.t('users.premium')}</Tag>
            }
            {/* {subscriptions && subscriptions.map(({ subscription }) => subscription && (
              <Tag
                color={subscription.name === SubscriptionTypes.premium ? 'orange' : 'blue'}
                key={subscription.id}
              >
                {subscription.name}
              </Tag>
            ))} */}
          </div>
        </div>
      </div>
      {!hideButtons && (
        <div className="user-card__buttons">
          <Link to={{ pathname: `${BANK_DETAILS}/${id}` }}>
            <Button className="button--ghost button--ghost--green" ghost>{i18n.t('valuations.bankDetails')}</Button>
          </Link>
          <Link to={{ pathname: `${EDIT_USER}/${id}` }}><Button type="primary" ghost>{isSuperAdmin ? i18n.t('common.edit') : i18n.t('valuations.view')}</Button></Link>
          <Button
            danger
            onClick={showBlockUserConfirmation}
            loading={users.setBlockedUserLoading}
          >
            {blockedAction}
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserCard;
