import React from 'react';
import {
  Empty, Pagination, Spin, Table, Modal,
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useActions, useStore } from '../../store';
import columns from './columns';
import './style.css';
import { EDIT_WATCH } from '../../router/constants';
import i18n from '../../features/utils/i18n';

interface IWatchConfigsTableProps {
  page: number;
  onPageChange: (page: number) => void;
  selectedIds: React.Key[];
  handleSelect: (selectedKeys: React.Key[]) => void;
}

export const DEFAULT_TAKE_COUNT = 17;

const { confirm } = Modal;

const WatchConfigsTable: React.FC<IWatchConfigsTableProps> = ({
  page, onPageChange, selectedIds, handleSelect,
}) => {
  const { location, push } = useHistory();
  const { watchConfigsActions } = useActions();
  const { watchConfigs } = useStore();
  const data = watchConfigs.list.map((item) => ({ ...item, key: item.id }));
  const currentUrl = `${location.pathname}${location.search}`;

  const onEditClick = (id: number) => {
    push({ pathname: `${EDIT_WATCH}/${id}` }, { prevPath: currentUrl });
  };

  const onDeleteClick = (id: number) => {
    confirm({
      title: i18n.t('watches.wantDeleteWatch'),
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      onOk() {
        watchConfigsActions.delete(id);
      },
    });
  };

  if (watchConfigs.loading) {
    return <Spin className="spinner" />;
  }

  if (!watchConfigs.count) {
    return <Empty className="empty-list--min-height" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const rowSelection = {
    selectedRowKeys: selectedIds,
    onChange: handleSelect,
  };

  return (
    <>
      <Table
        bordered
        columns={columns(onDeleteClick, onEditClick)}
        pagination={false}
        dataSource={data}
        loading={false}
        scroll={{ x: true }}
        sticky
        className="custom-table"
        onRow={(record) => ({
          onClick: () => onEditClick(record.id),
        })}
      />
      {watchConfigs.count > DEFAULT_TAKE_COUNT && (
        <div className="pagination-wrapper">
          <Pagination
            defaultCurrent={page}
            current={page}
            defaultPageSize={DEFAULT_TAKE_COUNT}
            total={watchConfigs.count}
            onChange={onPageChange}
          />
        </div>
      )}
    </>
  );
};

export default WatchConfigsTable;
