import { plainToClass } from 'class-transformer';
import { validate } from 'class-validator';

export const validateForm = (schema: any, payload: any) => {
  const plain = plainToClass(schema, payload);
  return validate(plain).then((errors) => {
    let formatedErrors;
    if (errors.length) {
      formatedErrors = errors.reduce(
        (ers, error) => ({
          ...ers,
          [error.property]: Object.values(error.constraints || {})[0],
        }),
        {},
      );
      return formatedErrors;
    }
    return formatedErrors;
  });
};
