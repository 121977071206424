import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { Checkbox, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useLocation } from 'react-router';

import { useActions } from '../../store';
import { takeResultsPerPage } from '../../constants';
import parseParams from '../../utility/parse-params';
import { IGetLoansListQueryParams } from '../../store/types/loan/loan-dto';
import LoanStatus from '../../constants/loan-status';
import CustomDateRangePicker from '../date-range-picker';
import i18n from '../../features/utils/i18n';

interface ILoansFilterProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

interface IFilterState {
  dateTo?: Date | string;
  dateFrom?: Date | string;
  take?: number;
  page?: number;
  keyword?: string;
  statuses?: string;
  closingRequest?: boolean;
}

export const LoansFilterBar: React.FC<ILoansFilterProps> = ({ currentPage, setCurrentPage }) => {
  const location = useLocation();
  const params = parseParams(location.search);
  const {
    dateTo,
    dateFrom,
    keyword = '',
    take,
    page,
    statuses: initialStatuses,
    closingRequest,
  }: IGetLoansListQueryParams = params;
  const { loansActions } = useActions();

  const from = dateFrom && dateFrom.toLocaleString();
  const to = dateTo && dateTo.toLocaleString();
  const [filterState, setFilterState] = useState<IFilterState>({
    dateTo: to,
    dateFrom: from,
    take: take || takeResultsPerPage,
    page: page || currentPage,
    statuses: initialStatuses,
    closingRequest,
    keyword,
  });

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const debouncedSearch = debounce(() => {
      setFilterState({ ...filterState, keyword: event.target.value });
    }, 100);
    debouncedSearch();
    setCurrentPage(1);
  };

  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    const { checked, value } = event?.target;
    const { statuses = '' } = filterState;
    let selected = statuses ? statuses.split(',') : [];

    if (checked) {
      selected = [...selected, value];
    } else {
      selected = selected.filter((i) => i !== value);
    }
    setFilterState({ ...filterState, statuses: selected.join(',') });
    setCurrentPage(1);
  };

  const handleDateChange = (values) => {
    setFilterState({
      ...filterState,
      dateFrom: get(values, '[0]'),
      dateTo: get(values, '[1]'),
    });
    setCurrentPage(1);
  };

  const handleCompletingRequestChange = (event: CheckboxChangeEvent) => {
    const { value } = event?.target;
    setFilterState({ ...filterState, closingRequest: value });
    setCurrentPage(1);
  };

  useEffect(() => {
    loansActions.getLoans({ ...filterState, page: currentPage });
  }, [filterState, currentPage]);

  return (
    <div className="header-holder">
      <div className="top-row">
        <div className="page-title__holder">
          <h1>{i18n.t('financialAgreements.finAgreement')}</h1>
        </div>
        <div className="search-bar-holder">
          <Input
            placeholder={i18n.t('common.search')}
            suffix={<SearchOutlined />}
            defaultValue={filterState.keyword}
            onChange={onSearchChange}
          />
        </div>
      </div>
      <div className="filter-bar__filters">
        <CustomDateRangePicker
          value={[filterState.dateFrom, filterState.dateTo]}
          handleChange={handleDateChange}
        />
        <div className="filter-bar__status-filters">
          <Checkbox
            checked={filterState.closingRequest}
            onChange={handleCompletingRequestChange}
            value={!closingRequest}
          >
            {i18n.t('financialAgreements.completingRequest')}
          </Checkbox>
        </div>
        <div className="filter-bar__status-filters">
          <Checkbox
            checked={filterState.statuses?.includes(LoanStatus.IN_REVIEW)}
            onChange={handleCheckboxChange}
            value={LoanStatus.IN_REVIEW}
          >
            {i18n.t('common.inProcess')}
          </Checkbox>
          <Checkbox
            checked={filterState.statuses?.includes(LoanStatus.ACTIVE)}
            onChange={handleCheckboxChange}
            value={LoanStatus.ACTIVE}
          >
            {i18n.t('common.active')}
          </Checkbox>
          <Checkbox
            checked={filterState.statuses?.includes(LoanStatus.CLOSED)}
            onChange={handleCheckboxChange}
            value={LoanStatus.CLOSED}
          >
            {i18n.t('common.completed')}
          </Checkbox>
          <Checkbox
            checked={filterState.statuses?.includes(LoanStatus.REJECTED)}
            onChange={handleCheckboxChange}
            value={LoanStatus.REJECTED}
          >
            {i18n.t('common.rejected')}
          </Checkbox>
          <Checkbox
            checked={filterState.statuses?.includes(LoanStatus.DEFAULTED)}
            onChange={handleCheckboxChange}
            value={LoanStatus.DEFAULTED}
          >
            {i18n.t('common.defaulted')}
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export default LoansFilterBar;
