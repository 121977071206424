import React, { useState } from 'react';
import {
  Input, Form, Button, Spin, Select, Checkbox, Badge, Card,
} from 'antd';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router';

import { useActions, useStore } from '../../../../store';

import ValidateContacts from './validation-schema';
import { IUser } from '../../../../store/types/users/user-entity';
import { validateForm } from '../../../../utility/validation/class-validator';
import { Roles } from '../../../../constants';
import i18n from '../../../../features/utils/i18n';
import Partner from '../../../../pages/user/partner';
import { PromocodeTable } from '../../../promocode';

import './styles.css';

const fields: any = [
  {
    label: i18n.t('common.firstName'),
    type: 'text',
    name: 'firstName',
    rules: [{ required: true }]
  },
  {
    label: i18n.t('common.lastName'),
    type: 'text',
    name: 'lastName',
    rules: [{ required: true }]
  },
  {
    label: i18n.t('common.phone'),
    type: 'number',
    name: 'phoneNumber',
    rules: [{ required: true }]
  },
  {
    label: i18n.t('common.emailLabel'),
    type: 'email', required: true,
    name: 'email',
    rules: [{ required: true, type: 'email' }]
  },
];

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

export interface IProps {
  profile: IUser;
  loggedInAdmin: boolean;
}

const { Option } = Select;

const PersonalDetails: React.FC<IProps> = ({ profile, loggedInAdmin }) => {
  const { auth: { loading, user } } = useStore();
  const { id } = useParams<{ id: string }>();
  const [dataChanged, setDataChanged] = useState<any>({});

  const [form] = Form.useForm();

  const {
    authActions: { updateUserInfo },
    usersActions: { setUserRole, setUserSubscribe, updateUser },
  } = useActions();
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    promocodes = []
  } = profile;

  const initialValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    email,
    phoneNumber: phoneNumber || undefined,
  };

  const formik = useFormik({
    initialValues,
    validate: async (values) => {
      const result = await validateForm(ValidateContacts, values);
      return result;
    },
    onSubmit: (values) => updateUserInfo({ ...values }),
  });
  const { users: { current, currentLoading } } = useStore();
  const [currentRole, setCurrentRole] = useState(current?.role);
  const handleSelect = (value: string) => {
    setCurrentRole(value);
    onChangeData('role');
  };

  const [currentUserIsSubscribed, setCurrentUserIsSubscribed] = useState(
    current?.isSubscribed === undefined
      ? false
      : current?.isSubscribed,
  );

  const handleSave = async (values) => {
    const { role, subscribe, ...formDataChanged } = dataChanged;

    if (role && currentRole) {
      await setUserRole({ id: Number(id), role: currentRole });
    }

    if (Object.keys(formDataChanged).length) {
      const updatedUserData = {
        email: current?.email
      };
      for (const key in formDataChanged) {
        updatedUserData[key] = values[key]
      }
      await updateUser(updatedUserData, id);
    }
    setDataChanged({});

    if (current?.isSubscribed === currentUserIsSubscribed) return;
    // setUserSubscribe({ id: Number(id), isSubscribed: currentUserIsSubscribed });
  };

  const isSuperAdmin = user?.role === Roles.SUPER_ADMIN;

  const isSaveDisabled = !formik.dirty || !isEmpty(formik.errors);

  const handleCheckbox = (e) => {
    setCurrentUserIsSubscribed(e.target.checked);
    onChangeData('subscribe');
  };

  const onChangeData = (name: string) => {
    setDataChanged({
      ...dataChanged,
      [name]: true
    })
  };

  if (loading) {
    return <Spin className="spinner-height-auto" />;
  }

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        validateMessages={validateMessages}
        name="profile-info"
        layout="vertical"
        onFinish={handleSave}
      >
        <div className="personal-details__form">
          {fields.map(field => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
            >
              <Input type={field.type} onChange={() => onChangeData(field.name)} />
            </Form.Item>
          ))}
        </div>
        {promocodes.length ? (
          <PromocodeTable
            title
            small
            promocodes={promocodes}
            className="promocode-table"
          />
        ) : null}
        {profile && <Partner />}
        {!loggedInAdmin && (
          <>
            <p className="edit-user-form__label">{i18n.t('subscriptions.subscription')}</p>
            <Checkbox
              className="edit-user-form__premium"
              onChange={handleCheckbox}
              checked={currentUserIsSubscribed}
            >
              {i18n.t('users.premium')}
            </Checkbox>
          </>
        )}
        {loggedInAdmin && <Button disabled={isSaveDisabled} htmlType="submit" className="ant-btn button--primary profile__save-btn">{i18n.t('common.save')}</Button>}
      </Form>
      {(!loggedInAdmin && isSuperAdmin) && (
        <>
          { current?.role !== Roles.SUPER_ADMIN && (

            <>
              <p className="edit-user-form__label">{i18n.t('profile.role')}</p>
              <Select
                onSelect={handleSelect}
                value={currentRole}
                defaultValue={currentRole}
                style={{ width: 110 }}
                className="edit-user-form__select"
                disabled={!isSuperAdmin}
              >
                <Option key={Roles.USER} value={Roles.USER}>{i18n.t('users.user')}</Option>
                <Option key={Roles.ADMIN} value={Roles.ADMIN}>{i18n.t('profile.admin')}</Option>
              </Select>
            </>
          )}
          <Button
            className="button--default edit-user-form__save-btn"
            onClick={form.submit}
            loading={currentLoading}
            disabled={!Object.keys(dataChanged).length}
          >
            {i18n.t('common.save')}
          </Button>
        </>
      )}
    </>
  );
};

export default PersonalDetails;
