import React from 'react';
import {
  Empty, Modal, Spin, Table,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import IOwnWatchEntity from '../../store/types/own-watches/own-watch-entity';
import { ownWatchesColumns } from './columns';
import { useActions, useStore } from '../../store';
import './styles.css';
import { OWN_WATCH } from '../../router/constants';
import { getPropertyWatchName, watchWhiteListProp } from '../../utility/get-watch-data';
import i18n from '../../features/utils/i18n';

const { confirm } = Modal;
const OwnWatchesTable: React.FC = () => {
  const { location, push } = useHistory();
  const { ownWatches } = useStore();
  const { ownWatchesActions } = useActions();
  const currentUrl = `${location.pathname}${location.search}`;
  const listWithKeys = ownWatches.list.map((item, i) => ({ ...item, key: i }));

  const watchDataSelected = listWithKeys.map((watchItem) => ({
    ...watchItem,
    buckle: getPropertyWatchName(watchItem, watchWhiteListProp.buckle),
    caseMetal: getPropertyWatchName(watchItem, watchWhiteListProp.caseMetal),
    dialColor: getPropertyWatchName(watchItem, watchWhiteListProp.dialColor),
    complication: getPropertyWatchName(watchItem, watchWhiteListProp.complication),
    model: getPropertyWatchName(watchItem, watchWhiteListProp.model),
  }));

  const handleClickRow = (ownWatch: IOwnWatchEntity) => {
    push({ pathname: `${OWN_WATCH}/${ownWatch.id}` }, { prevPath: currentUrl });
  };

  const onDeleteClick = (id: number) => {
    confirm({
      title: i18n.t('ownWatches.deleteWatch'),
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      onOk() {
        ownWatchesActions.delete(id);
      },
    });
  };

  if (ownWatches.loading) {
    return <Spin className="spinner" />;
  }

  if (!ownWatches.loading && !ownWatches.list.length) {
    return <Empty className="empty-list--min-height" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div className="entity-table">
      <Table
        bordered
        columns={ownWatchesColumns(onDeleteClick)}
        pagination={false}
        dataSource={watchDataSelected}
        loading={ownWatches.loading}
        scroll={{ x: true }}
        sticky
        className="custom-table custom-table--min-height"
        onRow={(record) => ({
          onClick: () => handleClickRow(record),
        })}
      />
    </div>
  );
};

export default OwnWatchesTable;
