import { SearchOutlined } from '@ant-design/icons';
import { Input, Layout, Pagination, Tabs } from 'antd';
import React from 'react';
import {
  PromoModal,
  PromocodeTable
} from '../../components/promocode';
import { takeResultsPerPage } from '../../constants';
import i18n from '../../features/utils/i18n';
import { useActions, useStore } from '../../store';
import './styles.css';

const { Content } = Layout;

let timeout: NodeJS.Timeout;

const Promocode: React.FC = () => {
  const [filter, setFilter] = React.useState({
    page: 1,
    status: 'active',
    name: '',
    take: takeResultsPerPage
  });

  const { promocodeActions } = useActions();
  const { promocode } = useStore();
  const { list, loading, count } = promocode;

  React.useEffect(() => {
    promocodeActions.getPromocodes({ ...filter });
  }, [filter]);

  React.useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  const handleTabChange = (tab: string) => {
    setFilter({...filter, status: tab});
  };

  const handlePageChange = (page: number) => {
    setFilter({ ...filter, page });
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setFilter({
        ...filter,
        page: 1,
        name: event.target.value
      });
    }, 1000)
  };

  return (
    <Layout className="container">
      <header className="promocode__header">
        <h1 className="promocode-title">
          {i18n.t('promocode.title')}
        </h1>
        <Input
          className="promocode__search-input"
          placeholder={i18n.t('common.search')}
          suffix={<SearchOutlined />}
          defaultValue={filter.name}
          onChange={onSearchChange}
        />
        <PromoModal filter={filter} />
      </header>
      <Content>
        <Tabs
          onChange={handleTabChange}
        >
          <Tabs.TabPane tab={i18n.t('promocode.active')} key="active">
            <PromocodeTable
              clickable
              loading={loading}
              promocodes={list}
            />
            {count > takeResultsPerPage && (
              <div className="pagination-wrapper">
                <Pagination
                  defaultCurrent={filter.page}
                  current={filter.page}
                  defaultPageSize={takeResultsPerPage}
                  total={count || 1}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={i18n.t('promocode.expired')} key="expired">
            <PromocodeTable
              clickable
              loading={loading}
              promocodes={list}
            />
            {count > takeResultsPerPage && (
              <div className="pagination-wrapper">
                <Pagination
                  defaultCurrent={filter.page}
                  current={filter.page}
                  defaultPageSize={takeResultsPerPage}
                  total={count || 1}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab={i18n.t('promocode.awaiting')} key="awaiting">
            <PromocodeTable
              clickable
              loading={loading}
              promocodes={list}
            />
            {count > takeResultsPerPage && (
              <div className="pagination-wrapper">
                <Pagination
                  defaultCurrent={filter.page}
                  current={filter.page}
                  defaultPageSize={takeResultsPerPage}
                  total={count || 1}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </Tabs.TabPane>
        </Tabs>
      </Content>
    </Layout>
  );
}

export default Promocode;
