import React from 'react';
import { Layout } from 'antd';
import FinancialAgreementTerms from '../../components/financial-agreement-terms/FinancialAgreementTerms';
import i18n from '../../features/utils/i18n';

import './style.css';

const { Content } = Layout;

const LoanTerms = () => (
  <Layout className="loan-terms">
    <div className="container">
      <header className="loan-terms__header">{i18n.t('loan.agreementTerms')}</header>
      <Content>
        <FinancialAgreementTerms />
      </Content>
    </div>
  </Layout>
);

export default LoanTerms;
