import React from 'react';
import classNames from 'classnames';

const PlusInCircle: React.FC<{classname: string}> = ({ classname }) => (
  <div className={classNames({ [classname]: classname })}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5635 11H16.4357C16.5132 11 16.5519 11.0371 16.5519 11.1111V20.8889C16.5519 20.9629 16.5132 21 16.4357 21H15.5635C15.486 21 15.4473 20.9629 15.4473 20.8889V11.1111C15.4473 11.0371 15.486 11 15.5635 11Z" fill="#40A9FF" />
      <path d="M11.1162 15.4722H20.8838C20.9613 15.4722 21 15.5091 21 15.5832V16.4166C21 16.4907 20.9613 16.5277 20.8838 16.5277H11.1162C11.0387 16.5277 11 16.4907 11 16.4166V15.5832C11 15.5091 11.0387 15.4722 11.1162 15.4722Z" fill="#40A9FF" />
      <circle cx="16" cy="16" r="15.5" stroke="#40A9FF" />
    </svg>
  </div>
);

export default PlusInCircle;
