import {
  BANK_DETAILS, EDIT_USER, SUBSCRIPTIONS, USER,
  USERS, USER_WATCH, EDIT_SUBSCRIPTION, LOANS,
  VALUATIONS, LOAN, OWN_WATCHES, OWN_WATCH,
  WATCHES, EDIT_WATCH, EDIT_USER_WATCH, PROMOCODES, PROMOCODE, PARTNERS, PARTNER,
} from './constants';

import Valuations from '../pages/valuations/valuations';
import Users from '../pages/users';
import WatchConfigs from '../pages/watch-configs';
import Loans from '../pages/loans';
import Subscriptions from '../pages/subscriptions';
import Templates from '../pages/templates/Templates';
import LoanTerms from '../pages/loan-terms/LoanTerms';
import Notifications from '../pages/notifications/Notifications';
import LoanPage from '../pages/loan/Loan';
import User from '../pages/user';
import Watch from '../pages/watch';
import Profile from '../pages/profile';
import EditSubscription from '../pages/edit-subscription';
import OwnWatches from '../pages/own-watches';
import OwnWatch from '../pages/own-watch';
import AddWatchPage from '../pages/add-watch-page';
import EditWatchPage from '../pages/edit-watch-page';
import EditUser from '../pages/edit-user';
import BankDetails from '../pages/bank-details';
import PrivacyPolicy from '../pages/privacy-policy';
import EditUserWatchPage from '../pages/edit-user-watch';
import ForgotPassword from '../pages/forgot-password';
import ResetPassword from '../pages/reset-password';
import Login from '../pages/login';
import Promocodes from '../pages/promocodes';
import Promocode from '../pages/promocode';
import Partners from '../pages/partners';
import Partner from '../pages/partner';

export const Routes = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:accessToken',
  users: USERS,
  user: `${USER}/:id`,
  editUser: `${EDIT_USER}/:id`,
  bankDetails: `${BANK_DETAILS}/:id`,
  valuations: VALUATIONS,
  userWatch: `${USER_WATCH}/:id`,
  editUserWatch: `${EDIT_USER_WATCH}/:id`,
  subscriptions: SUBSCRIPTIONS,
  editSubscription: `${EDIT_SUBSCRIPTION}/:id`,
  loans: LOANS,
  loan: `${LOAN}/:id`,
  ownWatches: OWN_WATCHES,
  ownWatch: `${OWN_WATCH}/:id`,
  watches: WATCHES,
  addWatch: `${WATCHES}/add-watch`,
  editWatch: `${EDIT_WATCH}/:id`,
  templates: '/templates',
  notifications: '/notifications',
  loanTerms: '/loan-terms',
  profile: '/profile',
  privacyPolicy: '/privacy-policy',
  promocodes: PROMOCODES,
  promocode: `${PROMOCODE}/:id`,
  partners: PARTNERS,
  partner: `${PARTNER}/:id`
};

export const guestRoutes = [
  {
    path: Routes.login,
    component: Login,
  },
  {
    path: Routes.resetPassword,
    component: ResetPassword,
  },
  {
    path: Routes.forgotPassword,
    component: ForgotPassword,
  },
  {
    path: Routes.privacyPolicy,
    component: PrivacyPolicy,
  },
]

export const privateRoutes = [
  {
    path: Routes.users,
    component: Users,
  },
  {
    path: Routes.user,
    component: User,
  },
  {
    path: Routes.editUser,
    component: EditUser,
  },
  {
    path: Routes.bankDetails,
    component: BankDetails,
  },
  {
    path: Routes.userWatch,
    component: Watch,
  },
  {
    path: Routes.editUserWatch,
    component: EditUserWatchPage
  },
  {
    path: Routes.subscriptions,
    component: Subscriptions,
  },
  {
    path: Routes.editSubscription,
    component: EditSubscription,
  },
  {
    path: Routes.loans,
    component: Loans,
  },
  {
    path: Routes.loan,
    component: LoanPage,
  },
  {
    path: Routes.ownWatches,
    component: OwnWatches,
  },
  {
    path: Routes.promocodes,
    component: Promocodes,
  },
  {
    path: Routes.promocode,
    component: Promocode,
  },
  {
    path: Routes.partners,
    component: Partners,
  },
  {
    path: Routes.partner,
    component: Partner,
  },
  {
    path: Routes.ownWatch,
    component: OwnWatch,
  },
  {
    path: Routes.watches,
    component: WatchConfigs,
  },
  {
    path: Routes.editWatch,
    component: EditWatchPage,
  },
  {
    path: Routes.addWatch,
    component: AddWatchPage,
  },
  {
    path: Routes.templates,
    component: Templates,
  },
  {
    path: Routes.notifications,
    component: Notifications,
  },
  {
    path: Routes.loanTerms,
    component: LoanTerms,
  },
  {
    path: Routes.profile,
    component: Profile,
  },
  {
    path: Routes.valuations,
    component: Valuations,
  }
]