import { MaxLength, MinLength } from 'class-validator';
import i18n from '../../../../features/utils/i18n'

class DeliveryMethod {
  public constructor(name = '') {
    this.name = name;
  }

  @MinLength(1, {
    message: i18n.t('validation.minLength'),
  })
  @MaxLength(50, {
    message: i18n.t('validation.lessThan25'),
  })
  name: string;
}

export default DeliveryMethod;
