import { Dispatch } from 'react';

import { IAction } from '../action';
import { IApiService } from '../../services/api-service/api-service';
import { WatchActionType } from '../actions-types';
import { IWatch } from '../../types/watch/watch-entity';
import { IAppraiseWatchRequest, IGetWatchesResponse } from '../../types/watch/watch-dto';
import buildQueryString from '../../../utility/build-query-string';

import { IPushTo } from '../../../router/types';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';
import { USER } from '../../../router/constants';

export interface IGetUserWatchesParams {
  page?: number | string;
  statuses?: string[];
  take?: number;
  id: string;
}

export interface IWatchActions {
  getUserWatchesById: (payload: IGetUserWatchesParams) => Promise<void>;
  getUserWatchById: (id: string) => Promise<void>;
  appraiseWatch: (payload: IAppraiseWatchRequest, isEdit: boolean) => Promise<void>;
  rejectWatch: (id: number) => Promise<void>;
}

export class WatchActions implements IWatchActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  protected readonly pushTo: IPushTo;

  public constructor(dispatch: Dispatch<IAction>, api: IApiService, pushTo: IPushTo) {
    this.dispatch = dispatch;
    this.api = api;
    this.pushTo = pushTo;
  }

  public getUserWatchesById = async ({ id, ...payload }: IGetUserWatchesParams): Promise<void> => {
    try {
      const queryString = buildQueryString(payload);

      if (queryString) {
        this.pushTo(`${USER}/${id}?${queryString}`);
      }

      this.dispatch({ type: WatchActionType.GetUserWatchesByIdRequest, payload: null });
      const response: IGetWatchesResponse = await this.api.get({
        url: `watch/user-watches/${id}?${queryString}`,
      });
      this.dispatch({
        type: WatchActionType.GetUserWatchesByIdSuccess,
        payload: response,
      });
    } catch (error) {
      this.dispatch({ type: WatchActionType.GetUserWatchesByIdFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getUserWatchById = async (id: string): Promise<void> => {
    try {
      this.dispatch({ type: WatchActionType.GetUserWatchByIdRequest, payload: null });
      const response: IGetWatchesResponse = await this.api.get({
        url: `watch/admin/single/${id}`,
      });
      this.dispatch({
        type: WatchActionType.GetUserWatchByIdSuccess,
        payload: response,
      });
    } catch (error) {
      this.dispatch({ type: WatchActionType.GetUserWatchByIdFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public appraiseWatch = async (
    payload: IAppraiseWatchRequest,
    isReEdit: boolean,
  ): Promise<void> => {
    try {
      const {
        id, minCost, maxCost, description,
      } = payload;
      this.dispatch({ type: WatchActionType.AppraiseWatchRequest, payload: null });
      const response: IWatch = await this.api.post({
        url: `watch/appraise/${id}`,
        data: { minCost, maxCost, description },
      });
      this.dispatch({
        type: WatchActionType.AppraiseWatchSuccess,
        payload: response,
      });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: isReEdit ? 'Evaluation was changed' : 'Watch was evaluated' });
    } catch (error) {
      this.dispatch({ type: WatchActionType.AppraiseWatchFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public rejectWatch = async (id: number): Promise<void> => {
    try {
      this.dispatch({ type: WatchActionType.RejectWatchRequest, payload: null });
      const response: IWatch = await this.api.patch({
        url: `watch/admin/reject/${id}`,
      });
      this.dispatch({
        type: WatchActionType.RejectWatchSuccess,
        payload: response,
      });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: 'Watch was rejected' });
    } catch (error) {
      this.dispatch({ type: WatchActionType.RejectWatchFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };
}
