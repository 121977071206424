import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Checkbox, Layout, Modal, Pagination, Spin,
} from 'antd';
import { QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { useStore } from '../../store';
import renderMessage from './renderMessage';
import emptyNotify from '../../assets/images/empty_notify.svg';
import './style.css';
import CustomDateRangePicker from '../../components/date-range-picker';
import defineNotificationConfig from './defineNotificationConfig';
import useNotifications, { IUseNotificationsData } from './use-notifications';
import i18n from '../../features/utils/i18n';

const { confirm } = Modal;
const { Content } = Layout;

const DEFAULT_NOTIFICATION_PAGE_SIZE = 6;

const Notifications = () => {
  const notificationsData: IUseNotificationsData = useNotifications();
  const { notifications } = useStore();

  const { location, push } = useHistory();
  const currentUrl = `${location.pathname}${location.search}`;
  const redirectToTargetPage = (link) => {
    if (!link) return;
    push({ pathname: link }, { prevPath: currentUrl });
  };

  if (notifications.loading) {
    return <Spin className="spinner full-width" />;
  }
  return (
    <Layout className="notifications">
      <div className="container">
        <div className="header-holder">
          <div className="page-title__holder">
            <h1 className="notifications__header">{i18n.t('notifications.notifications')}</h1>
            <h4 className="notifications__total">
              {i18n.t('notifications.total')}
              {notifications.count}
            </h4>
          </div>
          <div className="filter-bar__filters">
            <CustomDateRangePicker
              value={[notificationsData.filterState.dateFrom, notificationsData.filterState.dateTo]}
              handleChange={notificationsData.handleDateChange}
            />
            <div className="filter-bar__status-filters">
              <Checkbox
                checked={notificationsData.filterState.byNovelty}
                onChange={notificationsData.handleCheckboxChange}
                value={notificationsData.filterState.byNovelty}
              >
                {i18n.t('notifications.novelty')}
              </Checkbox>
            </div>
          </div>
        </div>
        <Content>
          {!notifications.loading && !notifications.list.length
            ? <img className="notifications__img" src={emptyNotify} alt="emptyNotify" />
            : notifications.list.map((notification) => {
              const { linkCreator } = defineNotificationConfig(notification);
              const linkForRedirect = linkCreator && linkCreator(notification);
              return (
                <div
                  key={notification.id}
                  className={classNames({ 'notifications__wrapper--not-viewed': !notification.viewed, notifications__wrapper: true })}
                >
                  <div className="notifications__icon-wrapper">
                    {defineNotificationConfig(notification).icon}
                  </div>
                  <div
                    className="notifications__name-wrapper"
                    role="button"
                    onClick={() => redirectToTargetPage(linkForRedirect)}
                  >
                    {renderMessage(notification)}
                  </div>
                  <div className="notifications__date-wrapper">
                    <span className="notifications__date">
                      {moment(notification.createdAt).format('D MMM, YYYY')}
                    </span>
                    <span className="notifications__time">
                      {moment(notification.createdAt).format('h:mm a')}
                    </span>
                  </div>
                  <CloseOutlined
                    className="notifications__remove"
                    role="button"
                    onClick={
                      () => {
                        confirm({
                          title: i18n.t('notifications.deleteNotification'),
                          icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
                          centered: true,
                          onOk() {
                            notificationsData.handleDeleteNotification(notification.id);
                          },
                        });
                      }
                    }
                  />
                </div>
              );
            })}
          {notifications.count > DEFAULT_NOTIFICATION_PAGE_SIZE && (
            <div className="pagination-wrapper">
              <Pagination
                defaultCurrent={notificationsData.currentPage}
                defaultPageSize={DEFAULT_NOTIFICATION_PAGE_SIZE}
                total={notifications.count || 1}
                onChange={notificationsData.handlePageChange}
              />
            </div>
          )}
        </Content>
      </div>
    </Layout>
  );
};

export default Notifications;
