import React, { useState, useEffect } from 'react';
import { Input, Checkbox, Button } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router';

import './styles.css';
import { useActions } from '../../store';
import parseParams from '../../utility/parse-params';
import CustomDateRangePicker from '../date-range-picker';
import { SubscriptionTypes } from '../../constants';
import { IGetUsersListQueryParams } from '../../store/types/users/users-dto';
import i18n from '../../features/utils/i18n';
import UploadModal from './upload-modal';

interface IPaginationState {
  subscriptions: string[];
  blocked?: boolean;
  dateTo?: Date | string;
  dateFrom?: Date | string;
  role?: string | null;
  page?: number;
  noSubscription?: boolean;
}

export const UsersFilterBar = ({
  currentPage,
  setCallbackPage,
}: {
  currentPage: number;
  setCallbackPage: (page: number) => void;
}) => {
  const location = useLocation();
  const params = parseParams(location.search);
  const {
    subscriptions, blocked, dateTo, dateFrom, name, page, noSubscription,
  }: IGetUsersListQueryParams = params;
  const { usersActions } = useActions();

  const to = dateTo && dateTo.toLocaleString();
  const from = dateFrom && dateFrom.toLocaleString();

  const formattedSubscriptions = subscriptions ? subscriptions.split(',') : [];
  const [selectedOptions, setSelectedOptions] = useState<IPaginationState>({
    subscriptions: formattedSubscriptions,
    blocked,
    dateTo: to,
    dateFrom: from,
    page: page || currentPage,
    noSubscription,
  });

  const [search, setSearch] = useState<string | null>(name || null);

  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    const { checked, value } = event?.target;
    setSelectedOptions({ ...selectedOptions, [value]: checked });
    setCallbackPage(1);
  };

  const handleSubscriptionChange = (event: CheckboxChangeEvent) => {
    let newSubscriptions = [...selectedOptions.subscriptions];
    const { checked, value } = event?.target;

    if (value === 'noSubscription') {
      return setSelectedOptions({ ...selectedOptions, noSubscription: checked, subscriptions: [] });
    }

    if (checked) {
      newSubscriptions.push(value);
    } else {
      newSubscriptions = newSubscriptions.filter((subscription) => subscription !== value);
    }
    setSelectedOptions({ ...selectedOptions, subscriptions: newSubscriptions });
    return setCallbackPage(1);
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const debouncedSearch = debounce(() => setSearch(event.target.value), 2000);
    debouncedSearch();
    setCallbackPage(1);
  };

  const onDateChange = (values) => {
    setSelectedOptions({
      ...selectedOptions,
      dateFrom: values ? values[0] : null,
      dateTo: values ? values[1] : null,
    });
    setCallbackPage(1);
  };

  useEffect(() => {
    const data = { ...selectedOptions, name: search, page: currentPage };
    usersActions.getUsers(data);
  }, [selectedOptions, search, currentPage]);

  return (
    <div className="users-filter-bar">
      <div className="input-holder">
        <Input
          placeholder={i18n.t('common.search')}
          suffix={(
            <SearchOutlined
              style={{
                color: '#8C8C8C',
              }}
            />
          )}
          defaultValue={search || ''}
          onChange={onSearchChange}
        />
      </div>
      <div className="users-filter-bar__row">
        <CustomDateRangePicker
          value={[selectedOptions.dateFrom, selectedOptions.dateTo]}
          handleChange={onDateChange}
        />
        <div className="filter-bar__wrapper">
          <div className="users-filter-bar__checkbox-row">
            <Checkbox
              value={SubscriptionTypes.premium}
              checked={selectedOptions.subscriptions.includes(SubscriptionTypes.premium)}
              disabled={!!selectedOptions.noSubscription}
              onChange={handleSubscriptionChange}
            >
              {SubscriptionTypes.premium}
            </Checkbox>
            <Checkbox
              value="noSubscription"
              checked={!!selectedOptions.noSubscription}
              disabled={!!selectedOptions.subscriptions.length}
              onChange={handleSubscriptionChange}
            >
              {i18n.t('users.withoutSubscription')}
            </Checkbox>
            <Checkbox
              value="blocked"
              checked={selectedOptions.blocked}
              onChange={handleCheckboxChange}
            >
              {i18n.t('users.block')}
            </Checkbox>
          </div>
          <UploadModal />
        </div>
      </div>
    </div>
  );
};

export default UsersFilterBar;
