import { Dispatch } from 'react';

import {
  IDeleteManyWatchConfigsRequest,
  ICreateWatchConfigRequestData,
  IGetWatchesConfigsQueryParams,
  IUpdateWatchConfigRequestData,
  IEditWatchRequest,
} from '../../types/watch-config/watch-config.dto';
import { IPushTo } from '../../../router/types';
import { IApiService } from '../../services/api-service/api-service';
import { IAction } from '../action';

import { WatchConfigsActionsType } from '../actions-types';
import buildQueryString from '../../../utility/build-query-string';
import toastNotification from '../../../utility/toast-notification/toast-notification';
import ToastNotificationTypes, { ToastNotificationMessage } from '../../../utility/toast-notification/types';
import { IDeleteResult } from '../../types/common';
import { IWatchParameters } from '../../types/watch-config/watch-config-state';
import { IWatchConfigEntity } from '../../types/watch-config/watch-config.entity';
import { Routes } from '../../../router/routes';
import { WATCHES } from '../../../router/constants';
import i18n from '../../../features/utils/i18n';

export interface IWatchConfigsActions {
  create: (data: ICreateWatchConfigRequestData) => Promise<void>;
  getList: (queryParams: IGetWatchesConfigsQueryParams) => Promise<void>;
  getOne: (id: string) => Promise<void>;
  update: (id: number, data: IUpdateWatchConfigRequestData) => Promise<void>;
  updateWatch: (watch: IEditWatchRequest) => Promise<void>;
  delete: (id: number) => Promise<void>;
  deleteMany: (ids: React.Key[]) => Promise<void>;
  getWatchParameters: () => Promise<void>;
}

export class WatchConfigsActions implements IWatchConfigsActions {
  protected readonly dispatch: Dispatch<IAction>;

  private readonly api: IApiService;

  protected readonly pushTo: IPushTo;

  constructor(dispatch: Dispatch<IAction>, api: IApiService, pushTo: IPushTo) {
    this.dispatch = dispatch;
    this.api = api;
    this.pushTo = pushTo;
  }

  public create = async (data: ICreateWatchConfigRequestData) => {
    try {
      this.dispatch({ type: WatchConfigsActionsType.CreateWatchConfigRequest, payload: null });
      await this.api.post({ url: '/watch-config', data });
      this.dispatch({ type: WatchConfigsActionsType.CreateWatchConfigSuccess, payload: null });
      this.pushTo(Routes.watches);
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: i18n.t('notifications.watchAdded'),
      });
    } catch (error) {
      this.dispatch({ type: WatchConfigsActionsType.CreateWatchConfigFailure, payload: null });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getList = async (queryParams: IGetWatchesConfigsQueryParams) => {
    try {
      this.dispatch({ type: WatchConfigsActionsType.GetWatchConfigsListRequest, payload: null });
      const queryString = buildQueryString(queryParams);
      if (queryString) {
        this.pushTo(`${WATCHES}?${queryString}`);
      }
      const result = await this.api.get({ url: `/watch-config?${queryString}` });
      this.dispatch({ type: WatchConfigsActionsType.GetWatchConfigsListSuccess, payload: result });
    } catch (error) {
      this.dispatch({ type: WatchConfigsActionsType.GetWatchConfigsListFailure, payload: error });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getOne = async (id: string) => {
    try {
      this.dispatch({ type: WatchConfigsActionsType.GetWatchConfigSingleRequest, payload: null });
      const result: IWatchConfigEntity = await this.api.get({ url: `/watch-config/${id}` });
      this.dispatch({ type: WatchConfigsActionsType.GetWatchConfigSingleSuccess, payload: result });
    } catch (err) {
      this.dispatch({ type: WatchConfigsActionsType.GetWatchConfigSingleFailure, payload: err });
      console.error(err);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public update = async (id: number, data: IUpdateWatchConfigRequestData) => {
    try {
      this.dispatch({ type: WatchConfigsActionsType.UpdateWatchConfigRequest, payload: null });
      await this.api.patch({ url: `/watch-config/${id}`, data });
      this.dispatch({ type: WatchConfigsActionsType.UpdateWatchConfigSuccess, payload: data });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: i18n.t('notifications.watchEdited'),
      });
      this.pushTo(Routes.watches);
    } catch (error) {
      this.dispatch({ type: WatchConfigsActionsType.UpdateWatchConfigFailure, payload: null });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public updateWatch = async (watch: IEditWatchRequest) => {
    try {
      this.dispatch({ type: WatchConfigsActionsType.UpdateUserWatchConfigRequest, payload: null });
      await this.api.patch({ url: `/watch/edit-watch/${watch.id}`, data: watch });
      this.dispatch({ type: WatchConfigsActionsType.UpdateUserWatchConfigSuccess, payload: watch });
      toastNotification({ type: ToastNotificationTypes.SUCCESS, message: i18n.t('notifications.watchUpdated')});
    } catch (error) {
      this.dispatch({ type: WatchConfigsActionsType.UpdateUserWatchConfigFailure, payload: null });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.default'),
      });
    }
  };

  public delete = async (id: number) => {
    try {
      this.dispatch({ type: WatchConfigsActionsType.DeleteWatchConfigRequest, payload: null });
      const { id: deletedId }: IDeleteResult = await this.api.delete({ url: `/watch-config/${id}` });
      this.dispatch({ type: WatchConfigsActionsType.DeleteWatchConfigSuccess, payload: deletedId });
      toastNotification({
        type: ToastNotificationTypes.SUCCESS,
        message: i18n.t('notifications.deleteWatch'),
      });
    } catch (error) {
      this.dispatch({ type: WatchConfigsActionsType.DeleteWatchConfigFailure, payload: null });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: i18n.t('notifications.deleteWatchError'),
      });
    }
  };

  public deleteMany = async (ids: React.Key[]) => {
    try {
      this.dispatch({ type: WatchConfigsActionsType.DeleteManyWatchConfigsRequest, payload: null });
      const result: IDeleteManyWatchConfigsRequest = await this.api.delete({
        url: 'watch-config/delete/many',
        data: { ids },
      });
      this.dispatch({
        type: WatchConfigsActionsType.DeleteManyWatchConfigsSuccess,
        payload: result,
      });
    } catch (error) {
      this.dispatch({
        type: WatchConfigsActionsType.DeleteManyWatchConfigsFailure,
        payload: error,
      });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };

  public getWatchParameters = async () => {
    try {
      this.dispatch({ type: WatchConfigsActionsType.GetWatchParametersRequest, payload: null });
      const result: IWatchParameters = await this.api.get({ url: '/watch/parameters' });
      this.dispatch({ type: WatchConfigsActionsType.GetWatchParametersSuccess, payload: result });
    } catch (error) {
      this.dispatch({ type: WatchConfigsActionsType.GetWatchParametersFailure, payload: null });
      console.error(error);
      toastNotification({
        type: ToastNotificationTypes.ERROR,
        message: ToastNotificationMessage.DEFAULT_ERROR_MESSAGE,
      });
    }
  };
};