import React from 'react';
import { Breadcrumb, Layout, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';

import BackButton from '../../components/back-button';
import WatchFeatures from '../../components/watch-features';
import ValuateWatchForm from '../../components/valuate-watch-form';
import ImageGallery from '../../components/image-gallery';
import useWatchData from '../../hooks/use-watch-data';
import { Routes } from '../../router/routes';
import './styles.css';
import { useStore } from '../../store';
import { useBackPath } from '../../hooks/use-back-path';
import { USER } from '../../router/constants';
import i18n from '../../features/utils/i18n';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const Watch: React.FC = () => {
  const { userWatch } = useStore();
  const backPath = useBackPath(Routes.userWatch);
  useWatchData();

  const watchImagesList = userWatch.current?.watchMedia;

  if (userWatch.currentLoading) {
    return <Spin className="spinner full-width" />;
  }

  return (
    <Layout>
      <div className="page-wrapper">
        <div className="header-holder">
          <BackButton path={backPath} />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`${Routes.users}/?page=1`}>{i18n.t('users.users')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${USER}/${userWatch.current?.userId}`}>{i18n.t('users.user')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{i18n.t('watches.watch')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Content>
          <div className="watch-content">
            <div className="watch-content__left">
              {watchImagesList && watchImagesList?.length && <ImageGallery list={watchImagesList} />}
              { !isEmpty(userWatch.current) && <ValuateWatchForm /> }
            </div>
            {userWatch.current && <WatchFeatures watch={userWatch.current} loans={userWatch.loans} />}
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default Watch;
