import { IAction } from '../../actions/action';
import { IAppState } from '../../state-context';
import { IDeliverMethod } from '../../types/loan-terms/delivery-method-entity';
import { ICalculationsConfigResponse, LoanTermsPercents } from '../../types/loan-terms/loan-terms-dto';
import { IPeriodConfig } from '../../types/loan-terms/period-config-entity';

export const getDeliveryMethodsRequest = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    deliveryMethods: {
      ...state.loanTerms.deliveryMethods,
      loading: true,
    },
  },
});

export const getDeliveryMethodsSuccess = (
  state: IAppState,
  { payload }: IAction<IDeliverMethod[]>,
): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    deliveryMethods: {
      ...state.loanTerms.deliveryMethods,
      data: payload,
      loading: false,
    },
  },
});

export const getDeliveryMethodsFailure = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    deliveryMethods: {
      ...state.loanTerms.deliveryMethods,
      loading: false,
    },
  },
});

export const createDeliveryMethodSuccess = (
  state: IAppState,
  { payload }: IAction<IDeliverMethod>,
): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    deliveryMethods: {
      ...state.loanTerms.deliveryMethods,
      data: [...state.loanTerms.deliveryMethods.data, payload],
    },
  },
});

export const deleteDeliveryMethodSuccess = (
  state: IAppState,
  { payload: deletedId }: IAction<number>,
): IAppState => {
  return ({
    ...state,
    loanTerms: {
      ...state.loanTerms,
      deliveryMethods: {
        ...state.loanTerms.deliveryMethods,
        data: state.loanTerms.deliveryMethods.data.filter((i) => i.id !== deletedId),
      },
    },
  });
};

export const getPeriodConfigRequest = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    periodConfig: {
      ...state.loanTerms.periodConfig,
      loading: true,
    },
  },
});

export const getPeriodConfigSuccess = (
  state: IAppState,
  { payload }: IAction<IPeriodConfig>,
): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    periodConfig: {
      ...state.loanTerms.periodConfig,
      data: payload,
      loading: false,
    },
  },
});

export const getPeriodConfigFailure = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    periodConfig: {
      ...state.loanTerms.periodConfig,
      loading: false,
    },
  },
});

export const updatePeriodConfigSuccess = (
  state: IAppState,
  { payload }: IAction<IPeriodConfig>,
): IAppState => {
  return {
    ...state,
    loanTerms: {
      ...state.loanTerms,
      periodConfig: {
        data: payload,
        loading: false,
      },
    },
  };
};

export const getCalculationsConfigRequest = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    calculationsConfig: {
      ...state.loanTerms.calculationsConfig,
      loading: true,
    },
  },
});

export const getCalculationsConfigSuccess = (
  state: IAppState,
  { payload }: IAction<ICalculationsConfigResponse>,
): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    calculationsConfig: {
      ...state.loanTerms.calculationsConfig,
      data: payload,
      loading: false,
    },
  },
});

export const updateCalculationsConfigSuccess = (
  state: IAppState,
  { payload }: IAction<ICalculationsConfigResponse>,
): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    calculationsConfig: {
      ...state.loanTerms.calculationsConfig,
      data: payload,
      loading: false,
    },
  },
});

export const getCalculationsConfigFailure = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    calculationsConfig: {
      ...state.loanTerms.calculationsConfig,
      loading: false,
    },
  },
});

export const getPercentCalculationsRequest = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    percentConfig: {
      ...state.loanTerms.percentConfig,
      loading: true,
    },

  },
});

export const getPercentCalculationsSuccess = (
  state: IAppState,
  { payload }: IAction<LoanTermsPercents>,
): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    percentConfig: {
      ...state.loanTerms.percentConfig,
      data: payload,
      loading: false,
    },
  },
});

export const getPercentCalculationsFailure = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    percentConfig: {
      ...state.loanTerms.percentConfig,
      loading: false,
    },
  },
});

export const updatePercentCalculationsRequest = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    percentConfig: {
      ...state.loanTerms.percentConfig,
      loading: true,
    },
  },
});

export const updatePercentCalculationsSuccess = (
  state: IAppState, { payload }: IAction<number>,
): IAppState => {
  const key = Object.keys(payload)[0];
  const data = state.loanTerms.percentConfig.data
    ? { ...state.loanTerms.percentConfig.data, [key]: payload[key] }
    : null;

  return ({
    ...state,
    loanTerms: {
      ...state.loanTerms,
      percentConfig: {
        ...state.loanTerms.percentConfig,
        data,
        loading: false,
      },
    },
  });
};

export const updatePercentCalculationsFailure = (state: IAppState): IAppState => ({
  ...state,
  loanTerms: {
    ...state.loanTerms,
    percentConfig: {
      ...state.loanTerms.percentConfig,
      loading: false,
    },
  },
});
