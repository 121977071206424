import React from 'react';
import { Layout } from 'antd';

import UsersTab from '../../components/users-tabs';
import './styles.css';

const Users: React.FC = () => (
  <Layout>
    <div className="header-holder users__header">
      <UsersTab />
    </div>
  </Layout>
);

export default Users;
