import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import i18n from '../../features/utils/i18n';

const getPasswordValidationRules = (t) => {
  return (
    <ul>
      <p>{t('auth.mustContain')}</p>
      <li>{t('auth.leastCharacters')}</li>
      <li>{t('auth.lowerCase')}</li>
      <li>{t('auth.upperCase')}</li>
      <li>{t('auth.leastNumber')}</li>
      {/* <li>
      The following characters can be used
      !@#$%^&*
    </li> */}
    </ul>
  );
};

const PasswordValidationTooltip = () => {
  return (
    <Tooltip
      placement="bottom"
      title={getPasswordValidationRules(i18n.t)}
      overlayClassName="custom-tooltip"
    >
      <ExclamationCircleOutlined style={{ color: '#1890FF', fontSize: '15px', marginLeft: '10px' }} />
    </Tooltip>
  );
};

export default PasswordValidationTooltip;
