import WatchStatus from './watch-status';

const WatchStatusColor = {
  [WatchStatus.APPRAISAL_IN_PROGRESS]: '#FAAD14',
  [WatchStatus.FOR_SALE]: '#FAAD14',
  [WatchStatus.REJECTED]: '#F5222D',
  [WatchStatus.INCLUDED_IN_LOAN]: '#52C41A',
  [WatchStatus.APPRAISED]: '#52C41A',
  [WatchStatus.SOLD]: '#8C8C8C',
};

export default WatchStatusColor;
