import React from 'react';
import moment from 'moment';
import {
  Alert, Button, Empty, Modal, Spin, Table, Tag,
} from 'antd';
import { useHistory } from 'react-router-dom';

import { IUser } from '../../store/types/users/user-entity';
import LoanStatus from '../../constants/loan-status';
import { ILoan } from '../../store/types/loan/loan-entity';
import {useActions, useStore } from '../../store';
import './style.css';
import getFullName from '../../utility/get-full-name';
import { LOAN } from '../../router/constants';
import i18n from '../../features/utils/i18n';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const loansColumns = (
  onDelete: (id: number) => void,
) => {
  return  [
    {
      title: i18n.t('common.number'),
      dataIndex: 'loanNumber',
      width: '18.3%',
      className: 'loans-table__number',
    },
    {
      title: i18n.t('common.name'),
      dataIndex: 'user',
      width: '18.3%',
      className: 'loans-table__name',
      render: (user: IUser) => getFullName(user.firstName, user.lastName),
    },
    {
      title: i18n.t('loan.numberWatches'),
      dataIndex: 'numberOfWatches',
      width: '22%',
      className: 'loans-table__number-of-watches',
    },
    {
      title: i18n.t('common.date'),
      dataIndex: 'createdAt',
      width: '20%',
      className: 'loans-table__date',
      sorter: {
        compare: (a: { createdAt: string }, b: { createdAt: string }): number => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return Number(dateA) - Number(dateB);
        },
        render: (data) => moment(data).format('MM.DD.YYYY')
      }
    },
    {
      title: i18n.t('common.status'),
      dataIndex: 'status',
      width: '20%',
      className: 'loans-table__status',
      render: (status: string) => {
        let color = '#000';
        let loanStatus = status;
        switch (loanStatus) {
          case LoanStatus.IN_REVIEW:
            color = '#FAAD14';
            loanStatus = i18n.t('common.inProgress');
            break;
          case LoanStatus.CLOSED:
            loanStatus = i18n.t('common.completed');
            color = '#8C8C8C';
            break;
          case LoanStatus.REJECTED:
            color = '#F5222D';
            loanStatus = i18n.t('common.rejected');
            break;
          case LoanStatus.ACTIVE:
            color = '#52C41A';
            loanStatus = i18n.t('common.open');
            break;
          case LoanStatus.DEFAULTED:
            loanStatus = i18n.t('common.defaulted');
            color = '#2F54EB';
            break;
          default:
            loanStatus = '';
            color = '';
        }
            return (
              <>
                {loanStatus === LoanStatus.ACTIVE && (
                  <div className="alert-wrapper">
                    <Alert
                      message=""
                      type="warning"
                      showIcon
                    />
                  </div>
                )}
                <div style={{color}}>{loanStatus}</div>
              </>
            );
        }
    },
    {
      title: i18n.t('common.action'),
      width: '10%',
      className: 'loans-table__action',
      render: (_,loanConfig: ILoan ) => {
        return (
          <div className="loans-actions" role="button" onClick={(e) => e.stopPropagation()}>
            <Button
              className="button--danger"
              onClick={() => onDelete(loanConfig.id)}
            >
              {i18n.t('common.delete')}
            </Button>
          </div>
        );
      },
    },
  ];
}

interface ILoansTableProps {
  data: ILoan[];
  isLoading: boolean;
}

const { confirm } = Modal;
const LoansTable: React.FC<ILoansTableProps> = ({ data, isLoading }) => {
  const history = useHistory();
  const { loans } = useStore();
  const { loansActions } = useActions();
  const list = data.map((item, i) => ({ ...item, key: i }))
  const handleClickRow = (loan) => {
    history.push(`${LOAN}/${loan.id}`);
  };

  if (loans.loading) {
    return <Spin className="spinner" />;
  }

  if (!loans.loading && !loans.list.length) {
    return <Empty className="empty-list--min-height" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const onDeleteClick = (id: number) => {
    confirm({
      title: i18n.t('loan.deleteLoan'),
      centered: true,
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      onOk() {
       loansActions.delete(id)
      },
    });
  };

  return (
    <Table
      bordered
      columns={loansColumns(onDeleteClick)}
      pagination={false}
      dataSource={list}
      rowClassName={(data) => (data.closingRequest ? 'highlight-row' : 'hide-warning')}
      loading={isLoading}
      scroll={{ x: true }}
      sticky
      className="custom-table custom-table--min-height"
      onRow={(record) => ({
        onClick: () => handleClickRow(record),
      })}
    />
  );
};

export default LoansTable;
