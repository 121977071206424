import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
  isEmail,
  isNumber,
} from 'class-validator';

import { isDigits } from './is-digits';

@ValidatorConstraint({ name: 'login', async: false })
export class CustomLoginValidator implements ValidatorConstraintInterface {
  validate(login: string) {
    return isEmail(login) || isNumber(+login);
  }

  defaultMessage(args: ValidationArguments) {
    if (/\d/.test(args.value) && /[a-zA-Z]/.test(args.value)) {
      return 'Please enter a valid phone number';
    }
    return 'Please enter a valid email';
  }
}

@ValidatorConstraint({ name: 'phoneNumber', async: false })
export class CustomPhoneValidator implements ValidatorConstraintInterface {
  validate(phoneNumber: string | undefined) {
    return !phoneNumber || isDigits(phoneNumber);
  }

  defaultMessage() {
    return 'Phone number must contain 10 numbers (0-9)';
  }
}

@ValidatorConstraint({ name: 'email', async: false })
export class CustomEmailValidator implements ValidatorConstraintInterface {
  validate(email: string) {
    return isEmail(email);
  }

  defaultMessage() {
    return 'Please enter a valid email';
  }
}
