import React from 'react';

import { IUser } from '../../store/types/users/user-entity';
import ProfileContentWrapper from '../../components/profile-content';

interface IProps {
  user: IUser;
}

const AdminProfile: React.FC<IProps> = ({ user }) => {
  return (
    <div className="profile">
      <ProfileContentWrapper loggedInAdmin={false} user={user} />
    </div>
  );
};

export default AdminProfile;
