import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useActions } from '../store/actions-context';

export const useOwnWatchData = (): void => {
  const { ownWatchesActions } = useActions();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      ownWatchesActions.getOwnWatchById(id);
    }
  }, []);
};

export default useOwnWatchData;
