import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useBackPath = (defaultPath: string) => {
  const { state } = useLocation();
  const [backPath, setBackPath] = useState(defaultPath);

  useEffect(() => {
    if (state?.prevPath) {
      setBackPath(state?.prevPath);
    }
  }, [state?.prevPath]);

  return backPath;
};
