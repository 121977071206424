import React, { useEffect } from 'react';
import {
  Alert, Breadcrumb, Button, Layout, Spin,
} from 'antd';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import BackButton from '../../components/back-button';
import LoansDetails from '../../components/loan-details/LoanDetails';
import UserCard from '../../components/user-card';
import LoanStatus from '../../constants/loan-status';
import WatchStatus from '../../constants/watch-status';
import i18n from '../../features/utils/i18n';
import { USER } from '../../router/constants';
import { Routes } from '../../router/routes';
import { useActions, useStore } from '../../store';

import './style.css';

const LoanPage = () => {
  const params = useParams<{ id: string }>();
  const { loansActions } = useActions();
  const { loans } = useStore();
  const { pathname } = useLocation();

  useEffect(() => {
    loansActions.getLoanById(Number(params.id));
  }, []);

  if (loans.currentLoading) {
    return <Spin className="spinner full-width" />;
  }

  if (!loans.current) {
    return null;
  }

  const { current: { closingRequest } } = loans;
  const showWarning = closingRequest && loans?.current.status === LoanStatus.ACTIVE;
  const disabledToOwnWatchButton = (loans?.current.status !== LoanStatus.DEFAULTED)
    || loans?.currentLoading;
  const addToOwnWatchesClicked = loans.current.status === LoanStatus.DEFAULTED
  && (loans.current.watches[0].status === WatchStatus.FOR_SALE || loans.current.watches[0].status === WatchStatus.SOLD);

  const handleAddToOwnWatches = () => {
    if (loans?.current) {
      loansActions.addToOwnWatches(loans.current.id);
    }
  };

  return (
    <Layout className="loan">
      <div className="container">
        <div className="header-holder">
          <div className="loan__row">
            <BackButton path={Routes.loans} />
            <Button
              className="button--primary loan__addToOwnWatches-btn"
              onClick={handleAddToOwnWatches}
              disabled={disabledToOwnWatchButton || addToOwnWatchesClicked}
            >
              {i18n.t('loan.addToTheOwn')}
            </Button>
          </div>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={Routes.loans}>{i18n.t('loan.loans')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{i18n.t('loan.collection')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        { showWarning && (
          <div className="warning-message">
            <Alert
              message={i18n.t('loan.userWants')}
              type="warning"
              showIcon
            />
          </div>
        ) }
        <div>
          {loans.current?.user && <Link to={{ pathname: `${USER}/${loans.current?.user.id}`, state: { prevPath: pathname } }}><UserCard hideButtons user={loans.current?.user} /></Link>}
          <LoansDetails />
        </div>
      </div>
    </Layout>
  );
};

export default LoanPage;
