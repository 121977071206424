import { createContext, useContext } from 'react';

import IAuthState from './types/auth/auth-state';
import IUsersState from './types/users/users-state';
import ILoansState from './types/loan/loan-state';
import INotificationsState from './types/notification/notification-state';
import IUserWatchState from './types/watch/watch-state';
import IUIState from './types/ui/ui-state';
import { ILoanTermsState } from './types/loan-terms/loan-terms-state';
import IValuationState from './types/valuation/valuation-state';
import ISubscriptionsState from './types/subscription/subscription-state';
import IOwnWatchesState from './types/own-watches/own-watch-state';
import { IWatchConfigsState } from './types/watch-config/watch-config-state';
import ITemplateState from './types/template/template-state';
import IPromocodeState from './types/promocode/promocode-state';
import IPartnerState from './types/partner/partner-state';

export interface IAppState {
  auth: IAuthState;
  ui: IUIState;
  users: IUsersState;
  loans: ILoansState;
  notifications: INotificationsState;
  userWatch: IUserWatchState;
  loanTerms: ILoanTermsState;
  valuations: IValuationState;
  subscriptions: ISubscriptionsState;
  ownWatches: IOwnWatchesState;
  watchConfigs: IWatchConfigsState;
  templates: ITemplateState;
  promocode: IPromocodeState;
  partner: IPartnerState;
}

export const initialState: IAppState = {
  auth: {
    accessToken: null,
    refreshToken: null,
    error: undefined,
    loading: false,
    user: null,
  },
  ui: {
    activeUsersTab: '',
  },
  users: {
    list: [],
    count: 0,
    loading: false,
    current: null,
    currentLoading: false,
    setBlockedUserLoading: false,
    error: null,
    admins: [],
    adminsLoading: false,
    adminsCount: 0,
    revCatUser: null,
    userInvitePartner: null,
    inviteId: null,
  },
  loans: {
    list: [],
    count: 0,
    loading: false,
    current: null,
    currentLoading: false,
    error: null,
  },
  notifications: {
    list: [],
    count: 0,
    newCount: 0,
    loading: false,
    current: null,
    currentLoading: false,
    error: null,
  },
  userWatch: {
    list: [],
    count: 0,
    loading: false,
    current: null,
    currentLoading: false,
    error: null,
    loans: [],
  },
  loanTerms: {
    deliveryMethods: {
      data: [],
      loading: false,
    },
    periodConfig: {
      data: null,
      loading: false,
    },
    calculationsConfig: {
      data: {
        feeConfig: null,
        monthlyPercentage: null,
      },
      loading: false,
    },
    percentConfig: {
      data: null,
      loading: false,
    },
  },
  valuations: {
    list: [],
    count: 0,
    loading: false,
    current: null,
    currentLoading: false,
    error: null,
  },
  subscriptions: {
    list: [],
    count: 0,
    loading: false,
    current: null,
    currentLoading: false,
    error: null,
  },
  ownWatches: {
    list: [],
    count: 0,
    loading: false,
    current: null,
    currentLoading: false,
    error: null,
  },
  watchConfigs: {
    list: [],
    count: 0,
    loading: false,
    current: null,
    params: {
      boxAndPapers: [],
      bracelet: [],
      brand: [],
      buckle: [],
      caseMetal: [],
      caseSize: [],
      caseDiameter: [],
      condition: [],
      dialColor: [],
      strap: [],
      watchForm: [],
      tier: [],
      model: [],
      complication: [],
      referenceNumber: [],
      form: [],
    },
    paramsLoading: false,
    currentLoading: false,
    error: null,
  },
  templates: {
    list: [],
    loading: false,
    error: null,
  },
  promocode: {
    list: [],
    current: null,
    loading: false,
    count: 0,
    error: null,
    users: [],
  },
  partner: {
    list: [],
    current: null,
    loading: false,
    count: 0,
    error: null,
    users: [],
  }
};

export const StateContext = createContext<IAppState>(initialState);
export const useStore = (): IAppState => useContext<IAppState>(StateContext);

export default StateContext;
