import React from 'react';
import { Col, Row } from 'antd';

import LoginForm from '../../components/login-form';
import loginImage from '../../assets/images/loginImage.svg';
import './styles.css';
import i18n from '../../features/utils/i18n';

const Login = () => (
  <Row className="auth-section" align="middle">
    <Col xs={0} lg={10}>
      <div className="auth-screen-wrapper">
        <img src={loginImage} alt="loginImage" className="auth-screen-wrapper__image" />
      </div>
    </Col>
    <Col xs={24} lg={14}>
      <div className="auth-screen-wrapper__form">
        <h1 className="auth-screen-wrapper__form__title">{i18n.t('common.logo')}</h1>
        <LoginForm />
      </div>
    </Col>
  </Row>
);

export default Login;
