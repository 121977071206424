import React, { useEffect } from 'react';
import omit from 'lodash/omit';
import get from 'lodash/get';
import {
  Form, Input, Modal, Button,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormikValues, useFormik } from 'formik';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { useActions, useStore } from '../../../../store';
import './style.css';
import { FeeConfig } from '../../../../store/types/loan-terms/fee-config-entity';
import { MonthlyPercentageConfig } from '../../../../store/types/loan-terms/monthly-percantage-config-entity';
import { validateCalculationsForm } from './validation-schema';
import PercentConfigForms from './PercentConfigForms';
import i18n from '../../../../features/utils/i18n';

const { confirm } = Modal;

enum ValuesPath {
  FEE_CONFIG = 'feeConfig',
  MONTHLY_PERCENTAGE = 'monthlyPercentage',
}

type ConfigData = Omit<FeeConfig | MonthlyPercentageConfig, 'id'>;

const renderInputs = (
  values: FormikValues,
  handleChange: (e: React.ChangeEvent) => void,
  config: FeeConfig | MonthlyPercentageConfig,
  valuesPath: ValuesPath,
  errors: FormikValues,
) => {
  const configData: ConfigData = omit(config, 'id');

  return Object.keys(configData).map((key) => {
    const name = `${valuesPath}.${key}`;
    return (
      <div key={key}>
        <Input
          key={key}
          min="0"
          max="100"
          step=".01"
          type="number"
          name={name}
          placeholder="0,00"
          value={get(values, name)}
          onChange={handleChange}
          className="calculations-form__input"
        />
        <div className="error-validation error-validation--color">
          {has(errors[valuesPath], key) && errors[valuesPath][key]}
        </div>
      </div>
    );
  });
};

const getInitialValues = (
  fee: FeeConfig | null,
  monthly: MonthlyPercentageConfig | null,
) => {
  if (!fee || !monthly) {
    return {};
  }
  const feeConfig: ConfigData = omit(fee, 'id');
  const monthlyPercentage: ConfigData = omit(monthly, 'id');
  return {
    feeConfig,
    monthlyPercentage,
  };
};

const CalculationsForm = () => {
  const { loanTermsActions } = useActions();
  const {
    loanTerms: {
      calculationsConfig: { data },
      percentConfig: { data: percents, loading }
    },
  } = useStore();
  useEffect(() => {
    loanTermsActions.getCalculationsConfig();
    loanTermsActions.getPercentCalculations();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(data.feeConfig, data.monthlyPercentage),
    validate: async (values) => validateCalculationsForm(values),
    onSubmit: (values) => {
      confirm({
        title: i18n.t('subscriptions.saveChanges'),
        icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
        centered: true,
        onOk() {
          const feeValues = values.feeConfig;
          const monthlyValue = values.monthlyPercentage;
          if (!feeValues || !monthlyValue || !data.feeConfig || !data.monthlyPercentage) {
            return;
          }
          const { id: feeId } = data.feeConfig;
          const { id: monthlyId } = data.monthlyPercentage;

          loanTermsActions.updateCalculationsConfig(
            { id: feeId, ...feeValues },
            { id: monthlyId, ...monthlyValue },
          );
        },
      });
    },
  });

  if (!data.feeConfig || !data.monthlyPercentage) {
    return null;
  }

  const isSaveDisabled = !formik.dirty
    || !isEmpty(formik.errors.feeConfig)
    || !isEmpty(formik.errors.monthlyPercentage);

  return (
    <div className="forms">
      <Form className="calculations-form">
        <div className="calculations-form__content">
          <div className="calculations-form__column">
            <p className="calculations-form__column-title">{i18n.t('common.period')}</p>
            <p className="calculations-form__label">1 {i18n.t('finAgreementTerms.month')}</p>
            <p className="calculations-form__label">2 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">3 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">4 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">5 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">6 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">7 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">8 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">9 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">10 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">11 {i18n.t('common.months')}</p>
            <p className="calculations-form__label">12 {i18n.t('common.months')}</p>
          </div>
          <div className="calculations-form__column">
            <p className="calculations-form__column-title">{i18n.t('finAgreementTerms.monthlyPercentage')}</p>
            {renderInputs(
              formik.values,
              formik.handleChange,
              data.monthlyPercentage,
              ValuesPath.MONTHLY_PERCENTAGE,
              formik.errors,
            )}
          </div>
          <div className="calculations-form__column">
            <p className="calculations-form__column-title">{i18n.t('finAgreementTerms.initiationFee')}</p>
            {renderInputs(
              formik.values,
              formik.handleChange,
              data.feeConfig,
              ValuesPath.FEE_CONFIG,
              formik.errors,
            )}
          </div>
        </div>
        <Button disabled={isSaveDisabled} className="button--primary fin-agreement-save-btn calculations-form__save-btn" onClick={formik.submitForm}>{i18n.t('common.save')}</Button>
      </Form>
      <div className="config-forms">
        {(percents && percents.length) ? (
          percents.map(el => (
            <div key={el.id}>
              <div className="config-form__title">
                {el.id === 2 ? `${i18n.t('finAgreementTerms.withPartners')}` : `${i18n.t('finAgreementTerms.withoutPartners')}` }
              </div>
              <PercentConfigForms percents={el} loading={loading} />
            </div>
          ))
        ) : null}
      </div>
    </div>
  );
};

export default CalculationsForm;
