import React from 'react';
import { Select, Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import LoanStatus from '../../constants/loan-status';
import LoanTransitions from '../../constants/loan-transitions';
import i18n from '../../features/utils/i18n';

interface ILoanStatusSelectorProps {
  handleChange: (value: LoanStatus) => void;
  value: LoanStatus;
}

const getOptions = (t) => {
  return [
    {
      value: LoanStatus.IN_REVIEW,
      label: t('common.inProcess'),
    },
    {
      value: LoanStatus.ACTIVE,
      label: t('common.active'),
    },
    {
      value: LoanStatus.REJECTED,
      label: t('common.rejected'),
    },
    {
      value: LoanStatus.CLOSED,
      label: t('common.closed'),
    },
    {
      value: LoanStatus.DEFAULTED,
      label: t('common.defaulted'),
    },
  ];
};

// const options = [
//   {
//     value: LoanStatus.IN_REVIEW,
//     label: 'In process',
//   },
//   {
//     value: LoanStatus.ACTIVE,
//     label: 'Active',
//   },
//   {
//     value: LoanStatus.REJECTED,
//     label: 'Rejected',
//   },
//   {
//     value: LoanStatus.CLOSED,
//     label: 'Closed',
//   },
//   {
//     value: LoanStatus.DEFAULTED,
//     label: 'Defaulted',
//   },
// ];

const LoanStatusSelector: React.FC<ILoanStatusSelectorProps> = ({ handleChange, value }) => {
  const validateLoanStatusTransition = (currentStatus: LoanStatus, newStatus: LoanStatus) => {
    const allowedStatuses: LoanStatus[] = LoanTransitions[currentStatus] || [];
    const notAllowed = !allowedStatuses.includes(newStatus);
    return notAllowed;
  };

  const onSelect = (selected: LoanStatus) => Modal.confirm({
    title: i18n.t('loan.changeStatus'),
    icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
    centered: true,
    onOk() {
      handleChange(selected);
    },
  });
  return (
    <Select
      defaultValue={value}
      value={value}
      style={{ width: 120 }}
      onSelect={onSelect}
    >
      {getOptions(i18n.t).map((option) => (
        <Select.Option
          key={option.value}
          value={option.value}
          disabled={validateLoanStatusTransition(value, option.value)}
        >
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default LoanStatusSelector;
