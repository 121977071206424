import { IWatchParameter } from '../../store/types/common/watch-params';
import { IWatchParameters } from '../../store/types/watch-config/watch-config-state';
import { IWatchConfigEntity } from '../../store/types/watch-config/watch-config.entity';
import { ISelectOption } from '../selector';

export enum BandType {
  STRAP = 'STRAP',
  BRACELET = 'BRACELET'
}

export const watchFormFields = {
  BRAND_AUTOCOMPLETE: 'brandAutoComplete',
  MODEL_AUTOCOMPLETE: 'modelAutoComplete',
  CASE_DIAMETER_AUTOCOMPLETE: 'caseDiameterAutoComplete',
  BRAND: 'brandId',
  MODEL: 'modelId',
  BOX_AND_PAPERS: 'boxAndPapersId',
  BRACELET: 'braceletId',
  BUCKLE: 'buckleId',
  CASE_METAL: 'caseMetalId',
  CASE_SIZE: 'caseSizeId',
  CASE_DIAMETER: 'caseDiameterId',
  CONDITION: 'conditionId',
  DIAL_COLOR: 'dialColorId',
  STRAP: 'strapId',
  BAND_TYPE: 'bandType',
  FORM: 'formId',
  TIER: 'tierId',
  MIN_COST: 'minCost',
  MAX_COST: 'maxCost',
};

export interface IWatchFormValues {
  brandId: number | null;
  modelId: number | null;
  braceletId: number | null;
  buckleId: number | null;
  caseMetalId: number | null;
  caseSizeId: number | null;
  caseDiameterId: number | null;
  dialColorId: number | null;
  strapId: number | null;
  formId: number | null;
  tierId: number | null;
  bandType: BandType,
  brandAutoComplete: string,
  modelAutoComplete: string,
  caseDiameterAutoComplete: string,
  minCost: number | null,
  maxCost: number | null,
}

export const getInitialValues = (watchConfig?: IWatchConfigEntity) => {
  if (watchConfig) {
    return {
      brandId: watchConfig.brandId,
      modelId: watchConfig.modelId,
      buckleId: watchConfig.buckleId,
      caseMetalId: watchConfig.caseMetalId,
      caseSizeId: watchConfig.caseSizeId,
      caseDiameterId: watchConfig.caseDiameterId,
      dialColorId: watchConfig.dialColorId,
      formId: watchConfig.formId,
      tierId: watchConfig.tierId,
      braceletId: watchConfig.braceletId,
      strapId: watchConfig.strapId,
      bandType: watchConfig.strapId ? BandType.STRAP : BandType.BRACELET,
      brandAutoComplete: watchConfig.brand.name,
      modelAutoComplete: watchConfig.model.name,
      caseDiameterAutoComplete: watchConfig.caseDiameter?.name,
      minCost: watchConfig.minCost,
      maxCost: watchConfig.maxCost,
    };
  }

  return {
    brandId: null,
    modelId: null,
    buckleId: null,
    caseMetalId: null,
    caseSizeId: null,
    caseDiameterId: null,
    dialColorId: null,
    formId: null,
    tierId: null,
    braceletId: null,
    strapId: null,
    bandType: BandType.STRAP,
    brandAutoComplete: '',
    modelAutoComplete: '',
    caseDiameterAutoComplete: '',
    minCost: null,
    maxCost: null,
  };
};

export const getOptions = (params: IWatchParameter[] = []): ISelectOption[] => {
  return params.map(({ id, name, ...meta }) => ({ value: id, label: name, meta }));
};

export const prepareSelects = (params: IWatchParameters) => {
  return {
    brand: getOptions(params.brand),
    boxAndPapers: getOptions(params.boxAndPapers),
    buckle: getOptions(params.buckle),
    caseMetal: getOptions(params.caseMetal),
    caseSize: getOptions(params.caseSize),
    caseDiameter: getOptions(params.caseDiameter),
    condition: getOptions(params.condition),
    dialColor: getOptions(params.dialColor),
    bracelet: getOptions(params.bracelet),
    strap: getOptions(params.strap),
    watchForm: getOptions(params.watchForm),
    tier: getOptions(params.tier),
    model: getOptions(params.model),
    bandType: [
      { value: BandType.BRACELET, label: BandType.BRACELET },
      { value: BandType.STRAP, label: BandType.STRAP },
    ],
  };
};
