import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Form, Input, Spin, Modal,
} from 'antd';
import { useFormik } from 'formik';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { validateForm } from '../../utility/validation/class-validator';
import { Routes } from '../../router/routes';
import { useActions, useStore } from '../../store';
import { SocialNetworkTypes } from '../../constants';
import prepareAuthErrors, { IErrorsState } from './prepare-auth-errors';
import Login from './validation-schema';
import { ILoginRequest } from '../../store/types/auth/auth-dto';
import i18n from '../../features/utils/i18n';
// import SocialButton from './social-button';
import './styles.css';

// import google from '../../assets/images/google.svg';
// import facebook from '../../assets/images/facebook.svg';
// import amazon from '../../assets/images/amazon.svg';

const { info } = Modal;

export const showForbiddenSocialNetworkAuth = () => info({
  title: i18n.t('auth.noCredential'),
  content: <div>{i18n.t('auth.adminPanel')}</div>,
  icon: <ExclamationCircleOutlined style={{ color: '#F5222D' }} />,
  centered: true,
});

interface RefObject {
  logout: () => void
}

const LoginForm: React.FC = () => {
  const { authActions } = useActions();
  const { auth } = useStore();
  const { push } = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState<IErrorsState>({ login: [], password: [] });
  const socialGGERef = useRef<RefObject>();
  const socialFBRef = useRef<RefObject>();
  const socialAZRef = useRef<RefObject>();

  useEffect(() => {
    if (auth.error) {
      const payload = prepareAuthErrors(auth.error);
      setErrors(payload);
    }
  }, [auth.error?.message]);

  const initialValues: ILoginRequest = {
    login: '',
    password: '',
  };

  const handleSubmit = (values: ILoginRequest) => {
      authActions.loginUser(values)
  };

  const toogleSocialAuthDisabled = (value: boolean) => setDisabled(value);

  const handleSocialLoginFB = async ({ token }) => {
    toogleSocialAuthDisabled(true);
    await authActions.socialNetworkLoginUser({ token, type: SocialNetworkTypes.facebook });
    toogleSocialAuthDisabled(false);
    handleLogout(socialFBRef);
  };

  const handleSocialLoginAZ = async ({ token }) => {
    toogleSocialAuthDisabled(true);
    await authActions.socialNetworkLoginUser({ token, type: SocialNetworkTypes.amazon });
    toogleSocialAuthDisabled(false);
    handleLogout(socialAZRef);
  };

  const handleSocialLoginGGE = async ({ token }) => {
    toogleSocialAuthDisabled(true);
    await authActions.socialNetworkLoginUser({ token, type: SocialNetworkTypes.google });
    toogleSocialAuthDisabled(false);
    handleLogout(socialGGERef);
  };

  const handleLogout = (ref) => {
    if (ref.current) {
      ref.current.logout();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: async (values) => validateForm(Login, values),
    onSubmit: handleSubmit,
  });

  const disabledSubmit = disabled
    || !formik.dirty
    || (!!formik.errors.login || !!formik.errors.password);

  if (auth.accessToken) {
    push(Routes.users);
  }

  if (auth.loading) {
    return <Spin className="spinner full-width" style={{ height: 'auto' }} />;
  }

  return (
    <div className="login-form">
      <h3 className="login-form__title">{i18n.t('auth.login')}</h3>
      <Form name="login" onFinish={formik.handleSubmit}>
        <Form.Item label={i18n.t('auth.emailOrPhone')} className="login-form__label">
          <Input
            name="login"
            placeholder="e.g. yourname@example.com"
            value={formik.values.login}
            onChange={formik.handleChange}
            className="login-form__input"
          />
          <div className="error-validation error-validation--color">
            {(formik.dirty && formik.errors.login)
              || (Array.isArray(errors.login)
                && errors.login.map((err, i) => <div key={i}>{err}</div>))}
          </div>
        </Form.Item>
        <Form.Item label={i18n.t('auth.password')} className="login-form__label">
          <Input.Password
            name="password"
            placeholder={i18n.t('auth.enterYourPassword')}
            value={formik.values.password}
            onChange={formik.handleChange}
            className="login-form__input"
            iconRender={(visible: boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
          {/* <PasswordValidationTooltip /> */}
          <div className="error-validation error-validation-password error-validation--color">
            {(formik.dirty && formik.errors.password)
              || (Array.isArray(errors.password)
              && errors.password.map((err, i) => <div key={i}>{err}</div>))}
          </div>
        </Form.Item>
        <div className="login-form__additional">
          {/* <div className="login-form__login-type">{i18n.t('auth.orLoginWith')}</div> */}
          <div className="login-form__forgot-password">
            <Link to={Routes.forgotPassword}>{i18n.t('auth.forgotPassword')}</Link>
          </div>
        </div>
        {/* <div className="login-form__social-icons">
          <SocialButton
            disabled={disabled}
            provider={SocialNetworkTypes.google}
            appId={process.env.REACT_APP_GOOGLE_APP_ID || ''}
            onLoginSuccess={handleSocialLoginGGE}
            onLoginFailure={console.log}
            ref={socialGGERef}
          >
            <img src={google} alt={SocialNetworkTypes.google} />
          </SocialButton>
          <SocialButton
            disabled={disabled}
            provider={SocialNetworkTypes.facebook}
            appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
            onLoginSuccess={handleSocialLoginFB}
            onLoginFailure={console.log}
            ref={socialFBRef}
          >
            <img src={facebook} alt={SocialNetworkTypes.facebook} />
          </SocialButton>
          <SocialButton
            disabled={disabled}
            provider={SocialNetworkTypes.amazon}
            appId={process.env.REACT_APP_AMAZON_APP_ID || ''}
            onLoginSuccess={handleSocialLoginAZ}
            onLoginFailure={console.log}
            ref={socialAZRef}
          >
            <img src={amazon} alt={SocialNetworkTypes.amazon} />
          </SocialButton>
        </div> */}
        <Button htmlType="submit" disabled={disabledSubmit} className="button--primary login-form__submit-btn">
          {i18n.t('auth.logIn')}
        </Button>
      </Form>
    </div>
  );
};

export default LoginForm;
