import LoanStatus from './loan-status';

const LoanTransitions = {
  [LoanStatus.IN_REVIEW]: [LoanStatus.ACTIVE, LoanStatus.REJECTED],
  [LoanStatus.ACTIVE]: [LoanStatus.CLOSED, LoanStatus.DEFAULTED, LoanStatus.IN_REVIEW],
  [LoanStatus.CLOSED]: [],
  [LoanStatus.DEFAULTED]: [],
  [LoanStatus.REJECTED]: [],
};

export default LoanTransitions;
