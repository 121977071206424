import React from 'react';
import {
  Form, Input, Button, Modal,
} from 'antd';
import { useFormik } from 'formik';
import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';

import { validateForm } from '../../utility/validation/class-validator';
import { useActions } from '../../store';
import ResetPassword from './validation-schema';
import PasswordValidationTooltip from '../password-validation-tooltip';
import i18n from '../../features/utils/i18n';
import { useLocation } from 'react-router';

const InputIcon = (visible: boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />);

interface IFromState {
  password: string;
  confirmPassword: string;
}

const { info } = Modal;

export const showResetPasswordSuccessModal = () => info({
  title: i18n.t('auth.resetPassword'),
  content:
  <div>
    {i18n.t('auth.emailMessage')}
  </div>,
  icon: <ExclamationCircleOutlined />,
  centered: true,
});

const ForgotPasswordForm = () => {
  const { authActions } = useActions();
  const initialValues: IFromState = {
    password: '',
    confirmPassword: '',
  };
  const { search } = useLocation();

  const handleSubmit = (values: IFromState) => {
    authActions.resetPassword({ password: values.password }, search);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validate: (values) => validateForm(ResetPassword, values),
    onSubmit: handleSubmit,
  });

  return (
    <div className="login-form">
      <h3 className="login-form__title">{i18n.t('auth.forgotPassword')}</h3>
      <p className="login-form__description">{i18n.t('auth.enterNewPassword')}</p>
      <Form name="login" onFinish={formik.handleSubmit}>
        <Form.Item label={i18n.t('auth.password')} className="login-form__label">
          <Input.Password
            name="password"
            placeholder={i18n.t('auth.enterNewPassword')}
            value={formik.values.password}
            onChange={formik.handleChange}
            className="login-form__input"
            iconRender={InputIcon}
          />
          <PasswordValidationTooltip />
          <div className="error-validation error-validation-password error-validation--color">
            {formik.errors.password ? formik.errors.password : ''}
          </div>
        </Form.Item>
        <Form.Item label={i18n.t('auth.repeatNewPassword')} className="login-form__label">
          <Input.Password
            name="confirmPassword"
            placeholder={i18n.t('auth.repeatNewPassword')}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            className="login-form__input"
            iconRender={InputIcon}
          />
          <PasswordValidationTooltip />
          <div className="error-validation error-validation-password error-validation--color">
            {formik.errors.confirmPassword ? formik.errors.confirmPassword : ''}
          </div>
        </Form.Item>
        <Button htmlType="submit" className="button--primary login-form__submit-btn" disabled={!formik.dirty || !isEmpty(formik.errors)}>
          {i18n.t('auth.resetPassword')}
        </Button>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
