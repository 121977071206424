import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useParams } from 'react-router';
import {
  Breadcrumb,
  Layout,
  Spin,
} from 'antd';
import { Routes } from '../../router/routes';
import { USER, USER_WATCH } from '../../router/constants';
import BackButton from '../../components/back-button';
import { useActions, useStore } from '../../store';
import { useBackPath } from '../../hooks/use-back-path';
import EditUserWatchForm from '../../components/edit-user-watch-form';
import ImageGallery from './image-gallery';

import './styles.css';
import { IEditWatchRequest } from '../../store/types/watch-config/watch-config.dto';
import { IEditWatchFormValues } from '../../components/edit-user-watch-form/utils';
import i18n from '../../features/utils/i18n';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const EditUserWatchPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useLocation();
  const { auth: { user } } = useStore();
  const {
    watchConfigsActions: { getWatchParameters },
    watchConfigsActions: { updateWatch },
    watchActions: { getUserWatchById },
  } = useActions();

  const backPath = useBackPath(Routes.userWatch);

  const [getWatchParamsLoading, setGetWatchParamsLoading] = useState<boolean>(true);
  const [getWatchDataLoading, setGetWatchPDataLoading] = useState<boolean>(true);
  const { userWatch: { current } } = useStore();
  const watchMediaList = current?.watchMedia;

  const [mediaList, setMediaList] = useState(watchMediaList);
  useEffect(() => {
    setMediaList(watchMediaList);
  }, [current]);

  const getWatchParametersRequest = useCallback(async () => {
    try {
      await getWatchParameters();
      setGetWatchParamsLoading(false);
    } catch (error) {
      setGetWatchParamsLoading(false);
    }
  }, []);

  const getWatchDataRequest = useCallback(async () => {
    try {
      await getUserWatchById(id);
      setGetWatchPDataLoading(false);
    } catch (error) {
      setGetWatchPDataLoading(false);
    }
  }, []);

  useEffect(() => {
    getWatchParametersRequest();
  }, []);

  useEffect(() => {
    getWatchDataRequest();
  }, []);

  const onSubmit = (values: IEditWatchFormValues) => {
    if (values && current) {
      const updatedWatch: IEditWatchRequest = {
        id: current.id,
        modelId: values.modelId || null,
        referenceNumber: values.referenceNumber,
        brandId: values.brandId,
        boxAndPapersId: values.boxAndPapersId,
        conditionId: values.conditionId,
        caseMetalId: values.caseMetalId,
        caseSizeId: values.caseSizeId,
        caseDiameterId: values.caseDiameterId,
        complicationId: values.complicationId,
        dialColorId: values.dialColorId,
        strapId: values.strapId,
        braceletId: values.braceletId,
        formId: values.formId,
        buckleId: values.buckleId,
        tierId: values.tierId,
        notListedInfoId: Number(current.notListedInfoId),
        watchMedia: mediaList,
        notListedInfo: {
          id: Number(current.notListedInfoId),
          customWidth: values.otherCustomWidth && values.otherCustomWidth.toString(),
          customHeight: values.otherCustomHeight && values.otherCustomHeight.toString(),
          brand: values.otherBrand,
          boxAndPapers: values.otherBoxAndPapers,
          caseMetal: values.otherCaseMetal,
          condition: values.otherCondition,
          dialColor: values.otherDialColor,
          complication: values.otherComplication,
          model: values.otherModel,
          bracelet: values.otherBracelet,
          strap: values.otherStrap,
          buckle: values.otherBuckle,
          form: values.otherForm,
        }
      };
      updateWatch(updatedWatch);
    }
  };

  const removeMedia = (index) => {
    if (!mediaList) return;
    setMediaList(mediaList.filter((listItem) => mediaList[index] !== listItem));
  };

  const renderContent = useMemo(() => {
    return (
      <Content>
        <>
          {!getWatchDataLoading
            ? <ImageGallery callBack={removeMedia} mediaList={mediaList} />
            : null}
          {current && !getWatchParamsLoading
            ? <EditUserWatchForm onSubmitCallback={onSubmit} watch={current} />
            : <Spin className="spinner-height-auto" />}
        </>
      </Content>
    );
  }, [getWatchParamsLoading, getWatchDataLoading, watchMediaList, onSubmit, removeMedia]);

  const userBreadcrumb = () => (
    <>
      <Breadcrumb.Item>
        <Link to={`${Routes.users}/?page=1`}>{i18n.t('users.users')}</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={`${USER}/${current?.userId}`}>{i18n.t('users.user')}</Link>
      </Breadcrumb.Item>
    </>
  );

  const ownWatchBreadcrumb = () => (
    <Breadcrumb.Item>
      <Link to={`${Routes.ownWatches}/?page=1`}>{i18n.t('ownWatches.ownWatches')}</Link>
    </Breadcrumb.Item>
  );

  return (
    <Layout>
      <div className="page-wrapper">
        <div className="header-holder">
          <BackButton path={backPath} />
          <Breadcrumb>
            { history?.state.prevPath.includes('user') ? userBreadcrumb : ownWatchBreadcrumb }
            <Breadcrumb.Item>
              <Link to={`${USER_WATCH}/${current?.id}`}>{i18n.t('watches.watch')}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{i18n.t('common.edit')}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {renderContent}
      </div>
    </Layout>
  );
};

export default EditUserWatchPage;
