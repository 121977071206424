import React from 'react';
import { Empty, Layout, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';

import TemplateCard from '../../components/template-card/template-card';
import useTemplates from './use-templates';
import './styles.css';
import i18n from '../../features/utils/i18n';

const { Content } = Layout;

const Templates = () => {
  const { getActionCallback, templates } = useTemplates();

  return (
    <Layout className="templates">
      <div className="container">
        <div className="header-holder">
          <div className="page-title__holder">
            <h1 className="templates__title">{i18n.t('templates.templates')}</h1>
          </div>
        </div>
        <Content>
          <div className="templates__content">
            <div className="templates__subtitle">{i18n.t('templates.message')}</div>
            <TemplateCard getCallback={getActionCallback} isNew />
          </div>
          {templates.loading && <Spin className="spinner full-width" />}
          {!templates.loading && isEmpty(templates.list) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={i18n.t('templates.noSaved')} />}
          {!isEmpty(templates.list) && (
            <div>
              <div className="templates__subtitle">{i18n.t('templates.saved')}</div>
              <div className="templates__list">
                {templates.list.map((template) => (
                  <TemplateCard
                    key={template.id}
                    template={template}
                    getCallback={getActionCallback}
                  />
                ))}
              </div>
            </div>
          )}
        </Content>
      </div>
    </Layout>
  );
};

export default Templates;
