import React from 'react';
import { Select } from 'antd';

import './style.css';

const { Option } = Select;

export interface ISelectOption {
  value: any;
  label: string;
  meta?: any;
}

interface ISelectorProps {
  options: ISelectOption[];
  value?: any,
  defaultValue?: any,
  onSelect: (value: any) => void;
  className?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
}

export const Selector: React.FC<ISelectorProps> = ({
  value,
  options,
  onSelect,
  defaultValue,
  placeholder = '',
  className = '',
  label = '',
  disabled = false,
}) => {
  return (
    <div className={`selector ${className}`}>
      {label && <p className="selector__label">{label}</p>}
      <Select
        value={value}
        className="selector__input"
        onSelect={onSelect}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {options.map((o) => <Option key={o.value} value={o.value}>{o.label}</Option>)}
      </Select>
    </div>
  );
};
