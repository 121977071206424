import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Spin } from 'antd';

const SuspenseHOC: FunctionComponent<{ load: () => any }> = ({ load }) => {
  const Component = lazy(load);

  return (
    <Suspense fallback={<Spin className="spinner" />}>
      <Component />
    </Suspense>
  );
};

export default React.memo(SuspenseHOC);
