import { Min } from 'class-validator';
import { validateForm } from '../../utility/validation/class-validator';
import { BandType } from '../watch-form/utils';
import {
  FormCases, IEditWatchFormValues, watchFormFields,
} from './utils';
import i18n from '../../features/utils/i18n'

class ValidationSchema {
  @Min(1, { message: i18n.t('validation.tierSelected') })
  tierId: number;

  @Min(1, { message: i18n.t('validation.conditionSelected')})
  conditionId: number;

  @Min(1, { message: i18n.t('validation.boxAndPapersSelected') })
  boxAndPapersId: number;
}

export const validate = async (values: IEditWatchFormValues) => {
  const errors = await validateForm(ValidationSchema, values) || {};

  // Model
  if (values.modelId === null && !values.referenceNumber && !values.otherModel) {
    errors[watchFormFields.OTHER_MODEL] = i18n.t('validation.customModelValue');
  }

  if (values.modelId !== null && !values.modelId && !values.referenceNumber) {
    errors[watchFormFields.MODEL] = i18n.t('validation.modelEntered');
  }

  // Reference
  if (!values.referenceNumber && values.modelId === null && !values.otherModel) {
    errors[watchFormFields.REFERENCE_NUMBER] = i18n.t('validation.referenceNumber');
  }

  // Complication
  if (!values.complicationId && values.complicationId !== null) {
    errors[watchFormFields.COMPLICATION] = i18n.t('validation.complicationSelected');
  }

  if (!values.otherComplication && values.complicationId === null) {
    errors[watchFormFields.OTHER_COMPLICATION] = i18n.t('validation.customComplicationSelected');
  }

  // Dial Color
  if (!values.dialColorId && values.dialColorId !== null) {
    errors[watchFormFields.DIAL_COLOR] = i18n.t('validation.complicationSelected');
  }

  if (!values.otherDialColor && values.dialColorId === null) {
    errors[watchFormFields.OTHER_DIAL_COLOR] = i18n.t('validation.customDialColor');
  }

  // Case Metal
  if (!values.caseMetalId && values.caseMetalId !== null) {
    errors[watchFormFields.CASE_METAL] = i18n.t('validation.caseMetalSelected');
  }

  if (!values.otherCaseMetal && values.caseMetalId === null) {
    errors[watchFormFields.OTHER_CASE_METAL] = i18n.t('validation.customCaseMetalSelected');
  }

  // Buckle
  if (!values.buckleId && values.buckleId !== null) {
    errors[watchFormFields.BUCKLE] = i18n.t('validation.buckleSelected');
  }

  if (!values.otherBuckle && values.buckleId === null) {
    errors[watchFormFields.OTHER_BUCKLE] = i18n.t('validation.customBuckleSelected');
  }

  // Form
  if (values.formId === FormCases.RECTANGLE) {
    if (!values.otherCustomWidth) {
      errors[watchFormFields.OTHER_CUSTOM_WIDTH] = i18n.t('validation.widthSelected');
    }
    if (!values.otherCustomHeight) {
      errors[watchFormFields.OTHER_CUSTOM_HEIGHT] = i18n.t('validation.heightSelected');
    }
  } else if (values.formId === FormCases.SQUARE && !values.otherCustomWidth) {
    errors[watchFormFields.OTHER_CUSTOM_WIDTH] = i18n.t('validation.sideLength');
  } else if (values.formId === FormCases.TONNEAU) {
    if (!values.otherCustomWidth) {
      errors[watchFormFields.OTHER_CUSTOM_WIDTH] = i18n.t('validation.widthSelected');
    }
    if (!values.otherCustomHeight) {
      errors[watchFormFields.OTHER_CUSTOM_HEIGHT] = i18n.t('validation.heightSelected');
    }
  } else if (values.formId === FormCases.ROUND && !values.caseSizeId) {
    errors[watchFormFields.CASE_SIZE] = i18n.t('validation.caseDiameterSelected');
  } else if (values.formId === FormCases.CONVEX_ROUND && !values.caseSizeId) {
    errors[watchFormFields.CASE_SIZE] = i18n.t('validation.caseDiameterSelected');
  } else if (!values.formId && !values.otherForm) {
    errors[watchFormFields.OTHER_FORM] =  i18n.t('validation.caseTypeSelected');
  }

  // Bracelet
  if (values[watchFormFields.BAND_TYPE] === BandType.BRACELET
    && !values.braceletId
    && !values.otherBracelet
  ) {
    errors[watchFormFields.BRACELET] = i18n.t('validation.braceletSelected');
  }

  // Strap
  if (values[watchFormFields.BAND_TYPE] === BandType.STRAP
    && !values.strapId
    && !values.otherStrap
  ) {
    errors[watchFormFields.STRAP] = i18n.t('validation.strapSelected');
  }

  return errors;
};
