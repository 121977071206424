import React, { useState } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router';

import { useStore } from '../../store';
import parseParams from '../../utility/parse-params';
import ValuationsFilterBar from '../../components/valuations-filter-bar';
import ValuationsTable from '../../components/valuations-table';
import { IGetValuationsListQueryParams } from '../../store/types/valuation/valuation-dto';
import './styles.css';

const { Header, Content } = Layout;

const ValuationsContent = () => {
  const location = useLocation();
  const params: IGetValuationsListQueryParams = parseParams(location.search);
  const { valuations } = useStore();
  const [currentPage, setCurrentPage] = useState<number>(Number(params.page) || 1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Layout className="valuations">
      <div className="container">
        <Header>
          <ValuationsFilterBar
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Header>
        <Content>
          <div className="table-wrapper">
            <ValuationsTable
              currentPage={currentPage}
              isLoading={valuations.loading}
              data={valuations.list}
              count={valuations.count}
              handlePageChange={handlePageChange}
            />
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default ValuationsContent;
