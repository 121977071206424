import React from 'react';
import { Button } from 'antd';
import { DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons';
import defaultWatchImage from '../../assets/images/default-watch-image.svg';
import { IWatchMedia } from '../../store/types/watch/watch-entity';
import i18n from '../../features/utils/i18n';

interface IProps {
  callBack: (index: number) => void;
  mediaList?: IWatchMedia[];
}

const ImageGallery: React.FC<IProps> = ({ callBack, mediaList }) => {
  if (!mediaList) return null;

  const getVideoThumbnail = (key) => (
    <div className="image-gallery__video-thumbnail" key={key}>
      <PlayCircleOutlined style={{ fontSize: '20px', color: '#bfbfbf' }} />
    </div>
  );

  return (
    <div className="edit-user-watch-image-gallery__thumbnails">
      {mediaList.map((item, index) => {
        const { id, url, type } = item;
        return (
          <div key={id} className="edit-user-watch-image-gallery__item-wrapper">
            <div className="image-gallery--border-radius edit-user-watch-image-gallery__thumnbnai">
              {type === 'video'
                ? getVideoThumbnail(id)
                : (
                  <img
                    alt=""
                    style={{ width: '174' }}
                    src={url || defaultWatchImage}
                    key={id}
                  />
                )}
            </div>
            <Button
              disabled={mediaList.length < 4}
              icon={<DeleteOutlined />}
              className="edit-user-watch-image-gallery__delete-button"
              onClick={() => callBack(index)}
            >
              {i18n.t('common.delete')}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default ImageGallery;
