import {
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from './auth/auth-reducers';

import {
  authRequest,
  authSuccess,
  authFailure,
  unauthorizedErrorReceived,
  logout,
  getUserRequest,
  getUserSuccess,
  getUserFailure,
  updateUserInfoRequest,
  updateUserInfoSuccess,
  updateUserInfoFailure,
  updateUserPasswordRequest,
  updateUserPasswordSuccess,
  updateUserPasswordFailure,
} from './auth';
import { setActiveUsersTab } from './ui';
import {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  getUserByIdRequest,
  getUserByIdSuccess,
  getUserByIdFailure,
  getRevenueCatUserByIdSuccess,
  setBlockedUserRequest,
  setBlockedUserSuccess,
  setBlockedUserFailure,
  SetUserSubscribeRequest,
  SetUserSubscribeSuccess,
  SetUserSubscribeFailure,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure
} from './users';
import {
  changeLoanStatusSuccess,
  getLoanByIdRequest,
  getLoanByIdSuccess,
  getLoanByIdFailure,
  getLoansFailure,
  getLoansRequest,
  getLoansSuccess,
  addToOwnWatchesRequest,
  addToOwnWatchesFailure,
  deleteLoanFailure,
  deleteLoanRequest,
  deleteLoanSuccess
} from './loans';
import {
  getNotificationsFailure,
  getNotificationsRequest,
  getNotificationsSuccess,
  deleteNotificationsSuccess,
  setNotificationViewedSuccess,
  getNotificationsCounterSuccess,
} from './notifications';

import {
  AuthActionType, UiActionType, UsersActionType, LoanActionType, WatchActionType,
  LoanTermsActionType, ValuationsActionType, SubscriptionsActionType,
  OwnWatchesActionType, NotificationsActionType, WatchConfigsActionsType, TemplatesActionType, PromocodeActionType,
  PartnerActionType
} from '../actions';
import {
  getUserWatchesByIdRequest,
  getUserWatchesByIdSuccess,
  getUserWatchesByIdFailure,
  getUserWatchByIdRequest,
  getUserWatchByIdSuccess,
  getUserWatchByIdFailure,
  appraiseWatchRequest,
  appraiseWatchSuccess,
  appraiseWatchFailure,
  rejectWatchRequest,
  rejectWatchSuccess,
  rejectWatchFailure,
  getLoansByWatchSuccess,
  getLoansByWatchFailure,
} from './watch';
import {
  getValuationsRequest,
  getValuationsSuccess,
  getValuationsFailure,
  valuateBunchWatchesRequest,
  valuateBunchWatchesSuccess,
  valuateBunchWatchesFailure,
  deleteValuationRequest,
  deleteValuationSuccess,
  deleteValuationFailure,
} from './valuations';
import {
  getSubscriptionsRequest, getSubscriptionsSuccess, getSubscriptionsFailure,
  getSubscriptionByIdSuccess, getSubscriptionByIdRequest, getSubscriptionByIdFailure,
  updateSubscriptionByIdRequest, updateSubscriptionByIdSuccess, updateSubscriptionByIdFailure,
} from './subscriptions/subscriptions-reducers';
import {
  getOwnWatchesRequest, getOwnWatchesSuccess, getOwnWatchesFailure,
  getOwnWatchByIdRequest, getOwnWatchByIdSuccess, getOwnWatchByIdFailure,
  changeOwnWatchStatusByIdRequest, changeOwnWatchStatusByIdSuccess, changeOwnWatchStatusByIdFailure,
  deleteOwnWatchRequest, deleteOwnWatchSuccess
} from './own-watches/own-watches-reducers';

import { IAction } from '../actions/action';

import { IAppState } from '../state-context';
import {
  createDeliveryMethodSuccess,
  deleteDeliveryMethodSuccess,
  getCalculationsConfigFailure,
  getCalculationsConfigRequest,
  getCalculationsConfigSuccess,
  getDeliveryMethodsFailure,
  getDeliveryMethodsRequest,
  getDeliveryMethodsSuccess,
  getPercentCalculationsSuccess,
  getPeriodConfigFailure,
  getPeriodConfigRequest,
  getPeriodConfigSuccess,
  updateCalculationsConfigSuccess,
  updatePercentCalculationsRequest,
  updatePercentCalculationsSuccess,
  updatePercentCalculationsFailure,
  updatePeriodConfigSuccess,
  getPercentCalculationsRequest,
  getPercentCalculationsFailure,
} from './loan-terms/loan-terms-reducer';
import {
  deleteManyWatchConfigsFailure,
  deleteManyWatchConfigsRequest,
  deleteManyWatchConfigsSuccess,
  createWatchConfigFailure,
  createWatchConfigRequest,
  createWatchConfigSuccess,
  deleteWatchConfigFailure,
  deleteWatchConfigRequest,
  deleteWatchConfigSuccess,
  getOneWatchConfigFailure,
  getOneWatchConfigRequest,
  getOneWatchConfigSuccess,
  getWatchesConfigsListFailure,
  getWatchesConfigsListRequest,
  getWatchesConfigsListSuccess,
  getWatchParamsFailure,
  getWatchParamsRequest,
  getWatchParamsSuccess,
  updateWatchConfigRequest,
  updateWatchConfigSuccess,
  updateWatchConfigFailure,
} from './watch-configs';
import {
  getTemplatesRequest, getTemplatesSuccess, getTemplatesFailure,
  addTemplateRequest, addTemplateSuccess, addTemplateFailure,
  updateTemplateRequest, updateTemplateSuccess, updateTemplateFailure,
  deleteTemplateRequest, deleteTemplateSuccess, deleteTemplateFailure,
} from './templates/templates-reducers';
import {
  enableLoading,
  getPromoByIdFailure,
  getPromoByIdSuccess,
  getPromocodesFailure,
  getPromocodesSuccess,
  resetCurrentPromo,
  setPromoLoading,
  setPromoUsers
} from './promocode';
import {
  enablePartnerLoading,
  getPartnersFailure,
  getPartnersSuccess,
  getPartnerByIdSuccess,
  getPartnerByIdFailure,
  resetCurrentPartner,
  setPartnerLoading,
  setPartnerUsers
} from './partner';

export type ActionHandler = (state: IAppState, action: IAction) => IAppState;

const handlersMap = new Map();

// ui actions
handlersMap.set(UiActionType.SetActiveUsersTab, setActiveUsersTab);

// auth actions
handlersMap.set(AuthActionType.AuthRequest, authRequest);
handlersMap.set(AuthActionType.AuthSuccess, authSuccess);
handlersMap.set(AuthActionType.AuthFailure, authFailure);
handlersMap.set(AuthActionType.UnauthorizedErrorReceived, unauthorizedErrorReceived);
handlersMap.set(AuthActionType.Logout, logout);
handlersMap.set(AuthActionType.GetUserRequest, getUserRequest);
handlersMap.set(AuthActionType.GetUserSuccess, getUserSuccess);
handlersMap.set(AuthActionType.GetUserFailure, getUserFailure);
handlersMap.set(AuthActionType.ForgotPasswordRequest, forgotPasswordRequest);
handlersMap.set(AuthActionType.ForgotPasswordSuccess, forgotPasswordSuccess);
handlersMap.set(AuthActionType.ForgotPasswordFailure, forgotPasswordFailure);
handlersMap.set(AuthActionType.DeleteUserRequest, deleteUserRequest);
handlersMap.set(AuthActionType.DeleteUserSuccess, deleteUserSuccess);
handlersMap.set(AuthActionType.DeleteUserFailure, deleteUserFailure);
handlersMap.set(AuthActionType.UpdateUserInfoRequest, updateUserInfoRequest);
handlersMap.set(AuthActionType.UpdateUserInfoSuccess, updateUserInfoSuccess);
handlersMap.set(AuthActionType.UpdateUserInfoFailure, updateUserInfoFailure);
handlersMap.set(AuthActionType.UpdateUserPasswordRequest, updateUserPasswordRequest);
handlersMap.set(AuthActionType.UpdateUserPasswordSuccess, updateUserPasswordSuccess);
handlersMap.set(AuthActionType.UpdateUserPasswordFailure, updateUserPasswordFailure);

// users actions
handlersMap.set(UsersActionType.GetUsersRequest, getUsersRequest);
handlersMap.set(UsersActionType.GetUsersSuccess, getUsersSuccess);
handlersMap.set(UsersActionType.GetUsersFailure, getUsersFailure);
handlersMap.set(UsersActionType.GetUserByIdRequest, getUserByIdRequest);
handlersMap.set(UsersActionType.GetUserByIdSuccess, getUserByIdSuccess);
handlersMap.set(UsersActionType.GetUserByIdFailure, getUserByIdFailure);
handlersMap.set(UsersActionType.GetRevenueCatUserByIdSuccess, getRevenueCatUserByIdSuccess);
handlersMap.set(UsersActionType.SetBlockedUserRequest, setBlockedUserRequest);
handlersMap.set(UsersActionType.SetBlockedUserSuccess, setBlockedUserSuccess);
handlersMap.set(UsersActionType.SetBlockedUserFailure, setBlockedUserFailure);
handlersMap.set(UsersActionType.SetUserSubscribeRequest, SetUserSubscribeRequest);
handlersMap.set(UsersActionType.SetUserSubscribeSuccess, SetUserSubscribeSuccess);
handlersMap.set(UsersActionType.SetUserSubscribeFailure, SetUserSubscribeFailure);
handlersMap.set(UsersActionType.DeleteUserFailure, deleteUserFailure);
handlersMap.set(UsersActionType.DeleteUserSuccess, deleteUserSuccess);
handlersMap.set(UsersActionType.DeleteUserRequest, deleteUserRequest);

// loans actions
handlersMap.set(LoanActionType.GetLoansRequest, getLoansRequest);
handlersMap.set(LoanActionType.GetLoansSuccess, getLoansSuccess);
handlersMap.set(LoanActionType.GetLoansFailure, getLoansFailure);
handlersMap.set(LoanActionType.GetLoanByIdRequest, getLoanByIdRequest);
handlersMap.set(LoanActionType.GetLoanByIdSuccess, getLoanByIdSuccess);
handlersMap.set(LoanActionType.GetLoanByIdFailure, getLoanByIdFailure);
handlersMap.set(LoanActionType.ChangeLoanStatusSuccess, changeLoanStatusSuccess);
handlersMap.set(LoanActionType.AddToOwnWatchesRequest, addToOwnWatchesRequest);
handlersMap.set(LoanActionType.AddToOwnWatchesFailure, addToOwnWatchesFailure);
handlersMap.set(LoanActionType.GetLoansByWatchSuccess, getLoansByWatchSuccess);
handlersMap.set(LoanActionType.GetLoansByWatchFailure, getLoansByWatchFailure);
handlersMap.set(LoanActionType.DeleteLoanRequest, deleteLoanRequest);
handlersMap.set(LoanActionType.DeleteLoanSuccess, deleteLoanSuccess);
handlersMap.set(LoanActionType.DeleteLoanFailure, deleteLoanFailure);

// notifications actions
handlersMap.set(NotificationsActionType.GetNotificationsRequest, getNotificationsRequest);
handlersMap.set(NotificationsActionType.GetNotificationsSuccess, getNotificationsSuccess);
handlersMap.set(NotificationsActionType.GetNotificationsFailure, getNotificationsFailure);
handlersMap.set(NotificationsActionType.DeleteNotificationSuccess, deleteNotificationsSuccess);
handlersMap.set(NotificationsActionType.SetNotificationViewedSuccess, setNotificationViewedSuccess);
handlersMap.set(
  NotificationsActionType.GetNotificationsCounterSuccess,
  getNotificationsCounterSuccess,
);

// watch actions
handlersMap.set(WatchActionType.GetUserWatchesByIdRequest, getUserWatchesByIdRequest);
handlersMap.set(WatchActionType.GetUserWatchesByIdSuccess, getUserWatchesByIdSuccess);
handlersMap.set(WatchActionType.GetUserWatchesByIdFailure, getUserWatchesByIdFailure);
handlersMap.set(WatchActionType.GetUserWatchByIdRequest, getUserWatchByIdRequest);
handlersMap.set(WatchActionType.GetUserWatchByIdSuccess, getUserWatchByIdSuccess);
handlersMap.set(WatchActionType.GetUserWatchByIdFailure, getUserWatchByIdFailure);
handlersMap.set(WatchActionType.AppraiseWatchRequest, appraiseWatchRequest);
handlersMap.set(WatchActionType.AppraiseWatchSuccess, appraiseWatchSuccess);
handlersMap.set(WatchActionType.AppraiseWatchFailure, appraiseWatchFailure);
handlersMap.set(WatchActionType.RejectWatchRequest, rejectWatchRequest);
handlersMap.set(WatchActionType.RejectWatchSuccess, rejectWatchSuccess);
handlersMap.set(WatchActionType.RejectWatchFailure, rejectWatchFailure);

// Loan terms actions
handlersMap.set(LoanTermsActionType.GetDeliveryMethodsRequest, getDeliveryMethodsRequest);
handlersMap.set(LoanTermsActionType.GetDeliveryMethodsSuccess, getDeliveryMethodsSuccess);
handlersMap.set(LoanTermsActionType.GetDeliveryMethodsFailure, getDeliveryMethodsFailure);
handlersMap.set(LoanTermsActionType.CreateDeliveryMethodSuccess, createDeliveryMethodSuccess);
handlersMap.set(LoanTermsActionType.DeleteDeliveryMethodSuccess, deleteDeliveryMethodSuccess);
handlersMap.set(LoanTermsActionType.GetPeriodConfigRequest, getPeriodConfigRequest);
handlersMap.set(LoanTermsActionType.GetPeriodConfigSuccess, getPeriodConfigSuccess);
handlersMap.set(LoanTermsActionType.GetPeriodConfigFailure, getPeriodConfigFailure);
handlersMap.set(LoanTermsActionType.UpdatePeriodConfigSuccess, updatePeriodConfigSuccess);
handlersMap.set(LoanTermsActionType.GetCalculationsConfigRequest, getCalculationsConfigRequest);
handlersMap.set(LoanTermsActionType.GetCalculationsConfigSuccess, getCalculationsConfigSuccess);
handlersMap.set(LoanTermsActionType.GetCalculationsConfigFailure, getCalculationsConfigFailure);
handlersMap.set(
  LoanTermsActionType.UpdateCalculationsConfigSuccess,
  updateCalculationsConfigSuccess,
);
handlersMap.set(
  LoanTermsActionType.GetPercentCalculationsRequest,
  getPercentCalculationsRequest,
);
handlersMap.set(
  LoanTermsActionType.GetPercentCalculationsSuccess,
  getPercentCalculationsSuccess,
);
handlersMap.set(
  LoanTermsActionType.GetPercentCalculationsFailure,
  getPercentCalculationsFailure,
);
handlersMap.set(
  LoanTermsActionType.UpdatePercentCalculationsRequest,
  updatePercentCalculationsRequest,
);
handlersMap.set(
  LoanTermsActionType.UpdatePercentCalculationsSuccess,
  updatePercentCalculationsSuccess,
);
handlersMap.set(
  LoanTermsActionType.UpdatePercentCalculationsFailure,
  updatePercentCalculationsFailure,
);

// valuation actions
handlersMap.set(ValuationsActionType.GetValuationsRequest, getValuationsRequest);
handlersMap.set(ValuationsActionType.GetValuationsSuccess, getValuationsSuccess);
handlersMap.set(ValuationsActionType.GetValuationsFailure, getValuationsFailure);
handlersMap.set(ValuationsActionType.ValuateBunchWatchesRequest, valuateBunchWatchesRequest);
handlersMap.set(ValuationsActionType.ValuateBunchWatchesSuccess, valuateBunchWatchesSuccess);
handlersMap.set(ValuationsActionType.ValuateBunchWatchesFailure, valuateBunchWatchesFailure);
handlersMap.set(ValuationsActionType.DeleteValuationRequest, deleteValuationRequest);
handlersMap.set(ValuationsActionType.DeleteValuationSuccess, deleteValuationSuccess);
handlersMap.set(ValuationsActionType.DeleteValuationFailure, deleteValuationFailure);

// subscriptions actions
handlersMap.set(SubscriptionsActionType.GetSubscriptionsRequest, getSubscriptionsRequest);
handlersMap.set(SubscriptionsActionType.GetSubscriptionsSuccess, getSubscriptionsSuccess);
handlersMap.set(SubscriptionsActionType.GetSubscriptionsFailure, getSubscriptionsFailure);
handlersMap.set(SubscriptionsActionType.GetSubscriptionByIdRequest, getSubscriptionByIdRequest);
handlersMap.set(SubscriptionsActionType.GetSubscriptionByIdSuccess, getSubscriptionByIdSuccess);
handlersMap.set(SubscriptionsActionType.GetSubscriptionByIdFailure, getSubscriptionByIdFailure);
handlersMap.set(
  SubscriptionsActionType.UpdateSubscriptionByIdRequest,
  updateSubscriptionByIdRequest,
);
handlersMap.set(
  SubscriptionsActionType.UpdateSubscriptionByIdSuccess,
  updateSubscriptionByIdSuccess,
);
handlersMap.set(
  SubscriptionsActionType.UpdateSubscriptionByIdFailure,
  updateSubscriptionByIdFailure,
);

// own watches actions
handlersMap.set(OwnWatchesActionType.GetOwnWatchesRequest, getOwnWatchesRequest);
handlersMap.set(OwnWatchesActionType.GetOwnWatchesSuccess, getOwnWatchesSuccess);
handlersMap.set(OwnWatchesActionType.GetOwnWatchesFailure, getOwnWatchesFailure);
handlersMap.set(OwnWatchesActionType.GetOwnWatchByIdRequest, getOwnWatchByIdRequest);
handlersMap.set(OwnWatchesActionType.GetOwnWatchByIdSuccess, getOwnWatchByIdSuccess);
handlersMap.set(OwnWatchesActionType.GetOwnWatchByIdFailure, getOwnWatchByIdFailure);
handlersMap.set(
  OwnWatchesActionType.ChangeOwnWatchStatusByIdRequest,
  changeOwnWatchStatusByIdRequest,
);
handlersMap.set(
  OwnWatchesActionType.ChangeOwnWatchStatusByIdSuccess,
  changeOwnWatchStatusByIdSuccess,
);
handlersMap.set(
  OwnWatchesActionType.ChangeOwnWatchStatusByIdFailure,
  changeOwnWatchStatusByIdFailure,
);
handlersMap.set(OwnWatchesActionType.DeleteOwnWatchRequest, deleteOwnWatchRequest);
handlersMap.set(OwnWatchesActionType.DeleteOwnWatchSuccess, deleteOwnWatchSuccess);
handlersMap.set(OwnWatchesActionType.DeleteOwnWatchFailure, deleteTemplateFailure);

// watch configs
handlersMap.set(WatchConfigsActionsType.CreateWatchConfigRequest, createWatchConfigRequest);
handlersMap.set(WatchConfigsActionsType.CreateWatchConfigSuccess, createWatchConfigSuccess);
handlersMap.set(WatchConfigsActionsType.CreateWatchConfigFailure, createWatchConfigFailure);
handlersMap.set(WatchConfigsActionsType.GetWatchConfigsListRequest, getWatchesConfigsListRequest);
handlersMap.set(WatchConfigsActionsType.GetWatchConfigsListSuccess, getWatchesConfigsListSuccess);
handlersMap.set(WatchConfigsActionsType.GetWatchConfigsListFailure, getWatchesConfigsListFailure);
handlersMap.set(WatchConfigsActionsType.GetWatchConfigSingleRequest, getOneWatchConfigRequest);
handlersMap.set(WatchConfigsActionsType.GetWatchConfigSingleSuccess, getOneWatchConfigSuccess);
handlersMap.set(WatchConfigsActionsType.GetWatchConfigSingleFailure, getOneWatchConfigFailure);
handlersMap.set(WatchConfigsActionsType.DeleteWatchConfigRequest, deleteWatchConfigRequest);
handlersMap.set(WatchConfigsActionsType.DeleteWatchConfigSuccess, deleteWatchConfigSuccess);
handlersMap.set(WatchConfigsActionsType.DeleteWatchConfigFailure, deleteWatchConfigFailure);
handlersMap.set(WatchConfigsActionsType.GetWatchParametersRequest, getWatchParamsRequest);
handlersMap.set(WatchConfigsActionsType.GetWatchParametersSuccess, getWatchParamsSuccess);
handlersMap.set(WatchConfigsActionsType.GetWatchParametersFailure, getWatchParamsFailure);
handlersMap.set(
  WatchConfigsActionsType.DeleteManyWatchConfigsRequest,
  deleteManyWatchConfigsRequest,
);
handlersMap.set(
  WatchConfigsActionsType.DeleteManyWatchConfigsSuccess,
  deleteManyWatchConfigsSuccess,
);
handlersMap.set(
  WatchConfigsActionsType.DeleteManyWatchConfigsFailure,
  deleteManyWatchConfigsFailure,
);
handlersMap.set(
  WatchConfigsActionsType.UpdateWatchConfigRequest,
  updateWatchConfigRequest,
);
handlersMap.set(
  WatchConfigsActionsType.UpdateWatchConfigSuccess,
  updateWatchConfigSuccess,
);
handlersMap.set(
  WatchConfigsActionsType.UpdateWatchConfigFailure,
  updateWatchConfigFailure,
);

// templates actions
handlersMap.set(TemplatesActionType.GetTemplatesListRequest, getTemplatesRequest);
handlersMap.set(TemplatesActionType.GetTemplatesListSuccess, getTemplatesSuccess);
handlersMap.set(TemplatesActionType.GetTemplatesListFailure, getTemplatesFailure);
handlersMap.set(TemplatesActionType.AddTemplateRequest, addTemplateRequest);
handlersMap.set(TemplatesActionType.AddTemplateSuccess, addTemplateSuccess);
handlersMap.set(TemplatesActionType.AddTemplateFailure, addTemplateFailure);
handlersMap.set(TemplatesActionType.UpdateTemplateRequest, updateTemplateRequest);
handlersMap.set(TemplatesActionType.UpdateTemplateSuccess, updateTemplateSuccess);
handlersMap.set(TemplatesActionType.UpdateTemplateFailure, updateTemplateFailure);
handlersMap.set(TemplatesActionType.DeleteTemplateRequest, deleteTemplateRequest);
handlersMap.set(TemplatesActionType.DeleteTemplateSuccess, deleteTemplateSuccess);
handlersMap.set(TemplatesActionType.DeleteTemplateFailure, deleteTemplateFailure);

// promocode actions
handlersMap.set(PromocodeActionType.GetPromocodesRequest, enableLoading);
handlersMap.set(PromocodeActionType.GetPromocodesSuccess, getPromocodesSuccess);
handlersMap.set(PromocodeActionType.GetPromocodesFailure, getPromocodesFailure);
handlersMap.set(PromocodeActionType.GetPromoByIdRequest, enableLoading);
handlersMap.set(PromocodeActionType.GetPromoByIdSuccess, getPromoByIdSuccess);
handlersMap.set(PromocodeActionType.GetPromoByIdFailure, getPromoByIdFailure);
handlersMap.set(PromocodeActionType.ResetCurrent, resetCurrentPromo);
handlersMap.set(PromocodeActionType.SetPromoUsers, setPromoUsers);
handlersMap.set(PromocodeActionType.SetLoading, setPromoLoading);

// partner actions
handlersMap.set(PartnerActionType.GetPartnersRequest, enablePartnerLoading);
handlersMap.set(PartnerActionType.GetPartnersSuccess, getPartnersSuccess);
handlersMap.set(PartnerActionType.GetPartnersFailure, getPartnersFailure);
handlersMap.set(PartnerActionType.GetPartnerByIdRequest, enablePartnerLoading);
handlersMap.set(PartnerActionType.GetPartnerByIdSuccess, getPartnerByIdSuccess);
handlersMap.set(PartnerActionType.GetPartnerByIdFailure, getPartnerByIdFailure);
handlersMap.set(PartnerActionType.ResetCurrentPartner, resetCurrentPartner);
handlersMap.set(PartnerActionType.SetLoading, setPartnerLoading);
handlersMap.set(PartnerActionType.SetPartnerUsers, setPartnerUsers);

export default handlersMap;
