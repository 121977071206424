import React from 'react';
import { useFormik } from 'formik';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Modal, Form, Input, Button,
} from 'antd';
import { ISubscription } from '../../store/types/subscription/subscription-entity';
import { validateForm } from '../../utility/validation/class-validator';
import './styles.css';
import { useActions, useStore } from '../../store';
import EditSubscription from './validation-schema';
import i18n from '../../features/utils/i18n';

interface IProps {
  subscription: ISubscription | null;
}

const { confirm } = Modal;

const EditSubscriptionForm: React.FC<IProps> = ({ subscription }) => {
  const { subscriptions } = useStore();
  const { subscriptionsActions } = useActions();
  const initialValues = {
    name: subscription?.name,
    price: subscription?.price,
    period: subscription?.period,
    description: subscription?.description,
  };

  const handleSubmit = (values) => {
    if (subscription?.id) {
      const payload = { ...values, id: subscription.id };
      subscriptionsActions.updateSubscriptionById(payload);
    }
  };
  const showConfirmationModal = () => {
    confirm({
      title: i18n.t('subscriptions.saveChanges'),
      icon: <QuestionCircleOutlined style={{ color: '#F5222D' }} />,
      centered: true,
      onOk() {
        formik.values.name = formik.values.name?.trim();
        handleSubmit(formik.values);
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate: async (values) => validateForm(EditSubscription, values),
    onSubmit: showConfirmationModal,
  });

  return (
    <div className="edit-subscription-form">
      <h2 className="edit-subscription-form__title">
        { i18n.t('subscriptions.set') + subscription?.name + i18n.t('subscriptions.parameters')}
      </h2>
      <Form
        name="edit-subscription"
        onFinish={formik.handleSubmit}
      >
        <Form.Item
          label={i18n.t('subscriptions.name')}
          className="edit-subscription-form__label"
        >
          <Input
            name="name"
            placeholder={i18n.t('common.name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            className="edit-subscription-form__input"
            style={{ width: '325px' }}
          />
          <div className="error-validation--color">{formik.errors.name}</div>
        </Form.Item>
        <div className="edit-subscription-form__row">
          <Form.Item
            label={i18n.t('common.price')}
            className="edit-subscription-form__label"
          >
            <Input
              type="number"
              prefix="$"
              name="price"
              placeholder={i18n.t('common.price')}
              value={formik.values.price}
              onChange={formik.handleChange}
              className="edit-subscription-form__input"
            />
            <div className="error-validation--color">{formik.errors.price}</div>
          </Form.Item>
          <span className="edit-subscription-form__text--grey">{i18n.t('subscriptions.per')}</span>
          <Form.Item
            label={i18n.t('subscriptions.period')}
            className="edit-subscription-form__label"
          >
            <Input
              name="period"
              placeholder={i18n.t('subscriptions.period')}
              value={formik.values.period}
              onChange={formik.handleChange}
              className="edit-subscription-form__input"
              disabled
            />
          </Form.Item>
        </div>
        <Form.Item
          label={i18n.t('subscriptions.description')}
          className="edit-subscription-form__label"
        >
          <Input.TextArea
            name="description"
            placeholder={i18n.t('subscriptions.description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            className="edit-subscription-form__input edit-subscription-form__input--textarea"
          />
          <div className="error-validation--color">{formik.errors.description}</div>
        </Form.Item>
        <Button
          disabled={!formik.dirty || subscriptions.currentLoading}
          htmlType="submit"
          className="button--primary edit-subscription-form__save-btn"
        >
          {i18n.t('common.save')}
        </Button>
      </Form>
    </div>
  );
};

export default EditSubscriptionForm;
